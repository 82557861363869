import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class ExecucaoPlanoDeTrabalho {

    public parId: number;
    public planoId: number;
    public planoIdentificador: string;
    
    public planoSituacao: number;
    public planoEfetivacao: boolean;

    public planoDataIniExecucaoFormat: NgbDateStruct;
    public planoDataFimExecucaoFormat: NgbDateStruct;
    public planoDataIniExecucao: Date;
    public planoDataFimExecucao: Date;
    public planoDataOperacao: Date;
    public planoOperacaoUsuario: string;
    public planoIdentificacaoObjeto: string;
    public planoAnoFundacaoOsc: number;
    public planoFocoAtuacaoOsc;
    public planoExperienciaOSC: string;
    public planoQtdProfissionaisOsc: string;
    public planoJustificativa: string;
    public planoDescricao: string;
    public planoFormaExecucao: string;
    public planoEspacoFisico: string;
    public planoPai:number;
    public planoJustificativaAlteracao:string
    public planoContaCorrenteNumeroOsc: string;
    public planoContaCorrenteBancoOsc: string;
    public planoContaCorrenteAgenciaOsc: string;
    public planoContaProvisaoNumeroOsc: string;
    public planoContaProvisaoBancoOsc: string;
    public planoContaProvisaoAgenciaOsc: string;
}

