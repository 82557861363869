export class ItemAvaliacaoPlano {

public planoId: number;
public itemId: number;
public itemPlano: string;
public avaliacaoItem: number;
public recomendacaoItem: string;
public itemUsuarioOperacao:string;
public itemOperacaoData:Date;
public limpa:boolean;
}