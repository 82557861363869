<div style="margin-top: 20px">
    <!--<div class="execucao tituloSecao">PREVISÃO DE EQUIPE DE TRABALHO PARA REALIZAÇÃO DO OBJETO DA PARCERIA</div>-->
    <div *ngIf="previsaoEquipeLista?.length==0">
        <span class="mensagem-vazio">Nenhuma previsão de equipe foi encontrada.</span>
    </div>
    <div *ngIf="!disableBotao()">
        <button (click)="adicionarPrevisaoEquipe() " id="button-insert_doc " class="btn btn-primary btn-info"
            type="button " title="Adicionar novo" [hidden]="false">
            <i class="fa fa-plus "><span class="texto-botao">Adicionar</span> </i>
        </button>
    </div>
    <div *ngIf="previsaoEquipeLista?.length > 0">
        <table class="table" style="margin-top:30px" id="tabelaPrevisaoEquipe" [mfData]="previsaoEquipeLista"
            #mtPrevEquipe="mfDataTable">
            <tbody>
                <tr *ngFor="let data of mtPrevEquipe.data; let j = index;" class="mb-5 border rounded p-3">
                    <td>
                        <div class="row mb-2">
                            <div class="col-md-3 font-weight-bold">Cargo/Função:<span *ngIf="!disableCampos()"
                                    class="text-danger">*</span>
                            </div>
                            <div class="col-md-9">
                                <input [disabled]="disableCampos()" class="form-control input-tabela" id="cargoFuncao"
                                    name="cargoFuncao" #cargoFuncao="ngModel" type="text" maxlength="255"
                                    [(ngModel)]="data.cargoFuncao">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-3 font-weight-bold">CBO:<span *ngIf="!disableCampos()"
                                    class="text-danger">*</span>
                            </div>
                            <div class="col-md-9">
                                <ng-select [ngModelOptions]="{standalone: true}" [compareWith]="compareFn"
                                    [disabled]="disableCampos()" [searchFn]="customSearchFn" virtualScroll="true"
                                    class="form-control" [items]="cboLista" [(ngModel)]="data.cbo" #cbo="ngModel"
                                    (change)="updateCbo($event, data)">
                                    <ng-template ng-label-tmp let-item="item">
                                        {{item.codigo}} - {{item.atividade}}
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                                        {{item.codigo}} - {{item.atividade}}
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div class="row mb=2">
                            <div class="col-md-3 font-weight-bold">Modalidade de Contratação:<span
                                    *ngIf="!disableCampos()" class="text-danger">*</span>
                            </div>
                            <div class="col-md-9">
                                <select name="tipoVinculo" #tipoVinculo="ngModel" style="width: 100%"
                                    class="form-control form-select custom-select" [disabled]="disableCampos()"
                                    [(ngModel)]="data.tipoVinculo" (change)="atualizaValoresGraficos()" required>
                                    <option [value]="option.id" *ngFor="let option of this.utils.modalidadeContratacao">
                                        {{option.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row mb=2" [hidden]="data.tipoVinculo != 6">
                            <div class="col-md-3 font-weight-bold">&nbsp;&nbsp;&nbsp;&nbsp;Qual?<span
                                    class="text-danger">*</span>
                            </div>
                            <div class="col-md-9">
                                <input [disabled]="disableCampos()" class="form-control input-tabela" id="outroVinculo"
                                    name="outroVinculo" #outroVinculo="ngModel" type="text" maxlength="255"
                                    [(ngModel)]="data.outroVinculo">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-3 font-weight-bold">Tipo de atividade:<span *ngIf="!disableCampos()"
                                    class="text-danger">*</span>
                            </div>
                            <div class="col-md-9">
                                <div class="select-wrapper" style="width: 100%">
                                    <select [disabled]="disableCampos()" [(ngModel)]="data.tipoAtividade"
                                        (change)="atualizaValoresGraficos()"
                                        class="form-control form-select custom-select" #tipoAtividade="ngModel"
                                        name="tipoAtividade" required>
                                        <option value="1">Meio</option>
                                        <option value="2">Fim</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-3 font-weight-bold">Nível de escolaridade:<span *ngIf="!disableCampos()"
                                    class="text-danger">*</span>
                            </div>
                            <div class="col-md-9">
                                <div class="select-wrapper">
                                    <select name="nivelEscolaridade" #nivelEscolaridade="ngModel" style="width: 100%"
                                        (change)="atualizaValoresGraficos()"
                                        class="form-control form-select custom-select" [disabled]="disableCampos()"
                                        [(ngModel)]="data.escolaridade" required>
                                        <option [value]="option.id" *ngFor="let option of this.utils.escolaridade">
                                            {{option.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-3 font-weight-bold">Remuneração Bruta Mensal:<span
                                    *ngIf="!disableCampos()" class="text-danger">*</span></div>
                            <div class="col-md-9">
                                <input class="form-control input-tabela" [disabled]="disableCampos()"
                                    (blur)="atualizaValoresGraficos()" style="width: 100%" currencyMask
                                    [(ngModel)]="data.remuneracaoMensal"
                                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" name="remuneracaoMensal"
                                    #valorDocC="ngModel" class="form-control" required validaInputZero />
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-3 font-weight-bold">Carga Horária Semanal:<span *ngIf="!disableCampos()"
                                    class="text-danger">*</span>
                            </div>
                            <div class="col-md-9">
                                <input class="form-control input-tabela" [disabled]="disableCampos()"
                                    style="width: 100%" [(ngModel)]="data.cargaHorariaSemanal" [textMask]="mascaraNro"
                                    name="cargaHorariaSemanal" #valorDocC="ngModel" class="form-control" required
                                    validaInputZero />
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-3 font-weight-bold">Nº de Profissionais:<span *ngIf="!disableCampos()"
                                    class="text-danger">*</span>
                            </div>
                            <div class="col-md-9">
                                <input class="form-control input-tabela" [disabled]="disableCampos()"
                                    (blur)="atualizaValoresGraficos()" style="width: 100%" [textMask]="mascaraNro"
                                    [(ngModel)]="data.numProfissionais" name="numProfissionais" #valorDocC="ngModel"
                                    class="form-control" required validaInputZero />
                            </div>
                        </div>
                    </td>
                    <td *ngIf="!disableApagarIcone()">
                        <i ngbTooltip="apagar" class="fa fa-trash-o" aria-hidden="true"
                            (click)="excluirPrevisaoEquipe(data)"></i>
                    </td>
                </tr>
            </tbody>
        </table>
        <div style="font-weight: bold;">Nº total de profissionais: {{totalProfissionais}}</div>
        <table *ngIf="totalProfissionais > 0">
            <tr valign="middle">
                <td>
                    <div style="width: 300px; height: 200px;">
                        <canvas id="i" baseChart [data]="pieChartDataProfEscolaridade" *ngIf="totalProfissionais > 0"
                            [labels]="pieChartLabelsProfEscolaridade" [chartType]="pieChartType" [legend]="false"
                            [options]="chartOptions" [colors]="pieChartColors1">
                        </canvas>
                    </div>
                </td>
                <td>
                    <div class="legenda-grafico" *ngIf="totalProfissionais > 0">
                        <b>Profissionais por escolaridade</b>
                        <ul>
                            <li *ngIf="totalProfFundamentalIncompleto > 0">
                                <div class="cor-legenda" style="background: #3D642D;"></div>
                                Fundamental Incompleto: {{totalProfFundamentalIncompleto}}
                                ({{aplicaMascaraReais((totalProfFundamentalIncompleto*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalProfFundamental > 0">
                                <div class="cor-legenda" style="background: #F39F18;"></div>
                                Fundamental: {{totalProfFundamental}}
                                ({{aplicaMascaraReais((totalProfFundamental*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalProfMedioTecnico > 0">
                                <div class="cor-legenda" style="background: #F44611;"></div>
                                Médio: {{totalProfMedioTecnico}}
                                ({{aplicaMascaraReais((totalProfMedioTecnico*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalProfMagisterio > 0">
                                <div class="cor-legenda" style="background: #646B63;"></div>
                                Magistério: {{totalProfMagisterio}}
                                ({{aplicaMascaraReais((totalProfMagisterio*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalProfSuperior > 0">
                                <div class="cor-legenda" style="background: #00BB2D;"></div>
                                Superior: {{totalProfSuperior}}
                                ({{aplicaMascaraReais((totalProfSuperior*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalProfPosGraduacao > 0">
                                <div class="cor-legenda" style="background: #5D9B9B;"></div>
                                Pós-graduação: {{totalProfPosGraduacao}}
                                ({{aplicaMascaraReais((totalProfPosGraduacao*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalProfMestrado > 0">
                                <div class="cor-legenda" style="background: #6A5D4D;"></div>
                                Mestrado: {{totalProfMestrado}}
                                ({{aplicaMascaraReais((totalProfMestrado*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalProfDoutorado > 0">
                                <div class="cor-legenda" style="background: #A03472;"></div>
                                Doutorado: {{totalProfDoutorado}}
                                ({{aplicaMascaraReais((totalProfDoutorado*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalProfPosDoutorado > 0">
                                <div class="cor-legenda" style="background: #C93C20;"></div>
                                Pós-doutorado: {{totalProfPosDoutorado}}
                                ({{aplicaMascaraReais((totalProfPosDoutorado*100)/totalProfissionais)}}%)
                            </li>
                        </ul>
                    </div>
                </td>
                <td>
                    <div style="width: 300px; height: 200px;">
                        <canvas id="i" baseChart [data]="pieChartDataRemEscolaridade" *ngIf="totalProfissionais > 0"
                            [labels]="pieChartLabelsRemEscolaridade" [chartType]="pieChartType" [legend]="false"
                            [options]="chartOptions" [colors]="pieChartColors1">
                        </canvas>
                    </div>
                </td>
                <td>
                    <div class="legenda-grafico" *ngIf="totalProfissionais > 0">
                        <b>Remuneração bruta por escolaridade</b>
                        <ul>
                            <li *ngIf="totalRemFundIncAte2000 > 0">
                                <div class="cor-legenda" style="background: #3D642D;"></div>Fund. Incompleto, até R$
                                2.000: {{totalRemFundIncAte2000}}
                                ({{aplicaMascaraReais((totalRemFundIncAte2000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemFundIncMaisDe2000 > 0">
                                <div class="cor-legenda" style="background: #F39F18;"></div>Fund. Incompleto, mais de R$
                                2.000: {{totalRemFundIncMaisDe2000}}
                                ({{aplicaMascaraReais((totalRemFundIncMaisDe2000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemFundAte2000 > 0">
                                <div class="cor-legenda" style="background: #F44611;"></div>Fundamental, até R$ 2.000:
                                {{totalRemFundAte2000}}
                                ({{aplicaMascaraReais((totalRemFundAte2000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemFundMaisDe2000 > 0">
                                <div class="cor-legenda" style="background: #646B63;"></div>Fundamental, mais de R$
                                2.000: {{totalRemFundMaisDe2000}}
                                ({{aplicaMascaraReais((totalRemFundMaisDe2000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemMedAte2000 > 0">
                                <div class="cor-legenda" style="background: #00BB2D;"></div>Médio, até R$ 2.000:
                                {{totalRemMedAte2000}}
                                ({{aplicaMascaraReais((totalRemMedAte2000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemMed2001a5000 > 0">
                                <div class="cor-legenda" style="background: #5D9B9B;"></div>Médio, de R$ 2.000,01 a R$
                                5.000,00: {{totalRemMed2001a5000}}
                                ({{aplicaMascaraReais((totalRemMed2001a5000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemMedMaisDe5000 > 0">
                                <div class="cor-legenda" style="background: #6A5D4D;"></div>Médio, mais de R$ 5.000:
                                {{totalRemMedMaisDe5000}}
                                ({{aplicaMascaraReais((totalRemMedMaisDe5000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemMagAte5000 > 0">
                                <div class="cor-legenda" style="background: #A03472;"></div>Magistério, até R$ 5.000:
                                {{totalRemMagAte5000}}
                                ({{aplicaMascaraReais((totalRemMagAte5000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemMag5001a7500 > 0">
                                <div class="cor-legenda" style="background: #C93C20;"></div>Magistério, de R$ 5.000,01 a
                                R$ 7.500: {{totalRemMag5001a7500}}
                                ({{aplicaMascaraReais((totalRemMag5001a7500*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemMagMaisDe7500 > 0">
                                <div class="cor-legenda" style="background: #252850;"></div>Magistério, mais de R$
                                7.500: {{totalRemMagMaisDe7500}}
                                ({{aplicaMascaraReais((totalRemMagMaisDe7500*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemSupAte5000 > 0">
                                <div class="cor-legenda" style="background: #8E402A;"></div>Superior, até R$ 5.000:
                                {{totalRemSupAte5000}}
                                ({{aplicaMascaraReais((totalRemSupAte5000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemSup5001a7500 > 0">
                                <div class="cor-legenda" style="background: #A65E2E;"></div>Superior, de R$ 5.000,01 a
                                R$ 7.500: {{totalRemSup5001a7500}}
                                ({{aplicaMascaraReais((totalRemSup5001a7500*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemSup7501a10000 > 0">
                                <div class="cor-legenda" style="background: #26252D;"></div>Superior, de R$ 7.500,01 a
                                R$ 10.000: {{totalRemSup7501a10000}}
                                ({{aplicaMascaraReais((totalRemSup7501a10000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemSupMaisDe10000 > 0">
                                <div class="cor-legenda" style="background: #CAC4B0;"></div>Superior, mais de R$ 10.000:
                                {{totalRemSupMaisDe10000}}
                                ({{aplicaMascaraReais((totalRemSupMaisDe10000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemPosAte5000 > 0">
                                <div class="cor-legenda" style="background: #23282B;"></div>Pós-graduação, até R$ 5.000:
                                {{totalRemPosAte5000}}
                                ({{aplicaMascaraReais((totalRemPosAte5000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemPos5001a7500 > 0">
                                <div class="cor-legenda" style="background: #015D52;"></div>Pós-graduação, de R$
                                5.000,01 a R$ 7.500: {{totalRemPos5001a7500}}
                                ({{aplicaMascaraReais((totalRemPos5001a7500*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemPos7501a10000 > 0">
                                <div class="cor-legenda" style="background: #4C9141;"></div>Pós-graduação, de R$
                                7.500,01 a R$ 10.000: {{totalRemPos7501a10000}}
                                ({{aplicaMascaraReais((totalRemPos7501a10000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemPosMaisDe10000 > 0">
                                <div class="cor-legenda" style="background: #B8B799;"></div>Pós-graduação, mais de R$
                                10.000: {{totalRemPosMaisDe10000}}
                                ({{aplicaMascaraReais((totalRemPosMaisDe10000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemMesAte6000 > 0">
                                <div class="cor-legenda" style="background: #424632;"></div>Mestrado, até R$ 6.000:
                                {{totalRemMesAte6000}}
                                ({{aplicaMascaraReais((totalRemMesAte6000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemMes6001a10000 > 0">
                                <div class="cor-legenda" style="background: #1B5583;"></div>Mestrado, de R$ 6.000,01 a
                                R$ 10.000: {{totalRemMes6001a10000}}
                                ({{aplicaMascaraReais((totalRemMes6001a10000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemMes10001a15000 > 0">
                                <div class="cor-legenda" style="background: #0A0A0A;"></div>Mestrado, de R$ 10.000,01 a
                                R$ 15.000: {{totalRemMes10001a15000}}
                                ({{aplicaMascaraReais((totalRemMes10001a15000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemMesMaisDe15000 > 0">
                                <div class="cor-legenda" style="background: #015D52;"></div>Mestrado, mais de R$ 15.000:
                                {{totalRemMesMaisDe15000}}
                                ({{aplicaMascaraReais((totalRemMesMaisDe15000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemDoutAte6000 > 0">
                                <div class="cor-legenda" style="background: #6D3F5B;"></div>Doutorado, até R$ 6.000:
                                {{totalRemDoutAte6000}}
                                ({{aplicaMascaraReais((totalRemDoutAte6000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemDout6001a10000 > 0">
                                <div class="cor-legenda" style="background: #1D1E33;"></div>Doutorado, de R$ 6.000,01 a
                                R$ 10.000: {{totalRemDout6001a10000}}
                                ({{aplicaMascaraReais((totalRemDout6001a10000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemDout10001a15000 > 0">
                                <div class="cor-legenda" style="background: #2C5545;"></div>Doutorado, de R$ 10.000,01 a
                                R$ 15.000: {{totalRemDout10001a15000}}
                                ({{aplicaMascaraReais((totalRemDout10001a15000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemDoutMaisDe15000 > 0">
                                <div class="cor-legenda" style="background: #6C6874;"></div>Doutorado, mais de R$
                                15.000: {{totalRemDoutMaisDe15000}}
                                ({{aplicaMascaraReais((totalRemDoutMaisDe15000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemPosDoutAte6000 > 0">
                                <div class="cor-legenda" style="background: #256D7B;"></div>Pós-doutorado, até R$ 6.000:
                                {{totalRemPosDoutAte6000}}
                                ({{aplicaMascaraReais((totalRemPosDoutAte6000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemPosDout6001a10000 > 0">
                                <div class="cor-legenda" style="background: #2F4538;"></div>Pós-doutorado, de R$
                                6.000,01 a R$ 10.000: {{totalRemPosDout6001a10000}}
                                ({{aplicaMascaraReais((totalRemPosDout6001a10000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemPosDout10001a15000 > 0">
                                <div class="cor-legenda" style="background: #45322E;"></div>Pós-doutorado, de R$
                                10.000,01 a R$ 15.000: {{totalRemPosDout10001a15000}}
                                ({{aplicaMascaraReais((totalRemPosDout10001a15000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemPosDoutMaisDe15000 > 0">
                                <div class="cor-legenda" style="background: #9B111E;"></div>Pós-doutorado, mais de R$
                                15.000: {{totalRemPosDoutMaisDe15000}}
                                ({{aplicaMascaraReais((totalRemPosDoutMaisDe15000*100)/totalProfissionais)}}%)
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
            <tr valign="middle">
                <td>
                    <div style="width: 300px; height: 200px;">
                        <canvas id="i" baseChart [data]="pieChartDataProfTipoAtividade" *ngIf="totalProfissionais > 0"
                            [labels]="pieChartLabelsProfTipoAtividade" [chartType]="pieChartType" [legend]="false"
                            [options]="chartOptions" [colors]="pieChartColors1">
                        </canvas>
                    </div>
                </td>
                <td>
                    <div class="legenda-grafico" *ngIf="totalProfissionais > 0">
                        <b>Profissionais por tipo de atividade</b>
                        <ul>
                            <li *ngIf="totalProfMeio > 0">
                                <div class="cor-legenda" style="background: #3D642D;"></div>
                                Meio: {{totalProfMeio}}
                                ({{aplicaMascaraReais((totalProfMeio*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalProfFim > 0">
                                <div class="cor-legenda" style="background: #F39F18;"></div>
                                Fim: {{totalProfFim}} ({{aplicaMascaraReais((totalProfFim*100)/totalProfissionais)}}%)
                            </li>
                        </ul>
                    </div>
                </td>
                <td>
                    <div style="width: 300px; height: 200px;">
                        <canvas id="i" baseChart [data]="pieChartDataRemTipoAtividade" *ngIf="totalProfissionais > 0"
                            [labels]="pieChartLabelsRemTipoAtividade" [chartType]="pieChartType" [legend]="false"
                            [options]="chartOptions" [colors]="pieChartColors1">
                        </canvas>
                    </div>
                </td>
                <td>
                    <div class="legenda-grafico" *ngIf="totalProfissionais > 0">
                        <b>Remuneração bruta por tipo de atividade</b>
                        <ul>
                            <li *ngIf="totalRemMeioAte2000 > 0">
                                <div class="cor-legenda" style="background: #3D642D;"></div>Meio, até R$ 2.000:
                                {{totalRemMeioAte2000}}
                                ({{aplicaMascaraReais((totalRemMeioAte2000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemMeio2001a5000 > 0">
                                <div class="cor-legenda" style="background: #F39F18;"></div>Meio, de R$ 2.000,01 a R$
                                5.000:
                                {{totalRemMeio2001a5000}}
                                ({{aplicaMascaraReais((totalRemMeio2001a5000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemMeio5001a10000 > 0">
                                <div class="cor-legenda" style="background: #F44611;"></div>Meio, de R$ 5.000,01 a R$
                                10.000: {{totalRemMeio5001a10000}}
                                ({{aplicaMascaraReais((totalRemMeio5001a10000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemMeioMaisDe10000 > 0">
                                <div class="cor-legenda" style="background: #646B63;"></div>Meio, mais de R$ 10.000:
                                {{totalRemMeioMaisDe10000}}
                                ({{aplicaMascaraReais((totalRemMeioMaisDe10000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemFimAte2000 > 0">
                                <div class="cor-legenda" style="background: #00BB2D;"></div>Fim, até R$ 2.000:
                                {{totalRemFimAte2000}}
                                ({{aplicaMascaraReais((totalRemFimAte2000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemFim2001a5000 > 0">
                                <div class="cor-legenda" style="background: #5D9B9B;"></div>Fim, de R$ 2.000,01 a R$
                                5.000:
                                {{totalRemFim2001a5000}}
                                ({{aplicaMascaraReais((totalRemFim2001a5000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemFim5001a10000 > 0">
                                <div class="cor-legenda" style="background: #6A5D4D;"></div>Fim, de R$ 5.000,01 a R$
                                10.000:
                                {{totalRemFim5001a10000}}
                                ({{aplicaMascaraReais((totalRemFim5001a10000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemFimMaisDe10000 > 0">
                                <div class="cor-legenda" style="background: #A03472;"></div>Fim, mais de R$ 10.000:
                                {{totalRemFimMaisDe10000}}
                                ({{aplicaMascaraReais((totalRemFimMaisDe10000*100)/totalProfissionais)}}%)
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
            <tr valign="middle">
                <td>
                    <div style="width: 300px; height: 200px;">
                        <canvas id="i" baseChart [data]="pieChartDataProfTipoVinculo" *ngIf="totalProfissionais > 0"
                            [labels]="pieChartLabelsProfTipoVinculo" [chartType]="pieChartType" [legend]="false"
                            [options]="chartOptions" [colors]="pieChartColors1">
                        </canvas>
                    </div>
                </td>
                <td>
                    <div class="legenda-grafico" *ngIf="totalProfissionais > 0">
                        <b>Profissionais por modalidade de contratação</b>
                        <ul>
                            <li *ngIf="totalProfClt > 0">
                                <div class="cor-legenda" style="background: #3D642D;"></div>
                                CLT: {{totalProfClt}} ({{aplicaMascaraReais((totalProfClt*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalProfVoluntario > 0">
                                <div class="cor-legenda" style="background: #F39F18;"></div>
                                Voluntário: {{totalProfVoluntario}}
                                ({{aplicaMascaraReais((totalProfVoluntario*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalProfEstagioRemunerado > 0">
                                <div class="cor-legenda" style="background: #F44611;"></div>
                                Estágio remunerado: {{totalProfEstagioRemunerado}}
                                ({{aplicaMascaraReais((totalProfEstagioRemunerado*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalProfEstagioNaoRemunerado > 0">
                                <div class="cor-legenda" style="background: #646B63;"></div>
                                Estágio não remunerado: {{totalProfEstagioNaoRemunerado}}
                                ({{aplicaMascaraReais((totalProfEstagioNaoRemunerado*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalProfMenorAprendiz > 0">
                                <div class="cor-legenda" style="background: #00BB2D;"></div>
                                Menor aprendiz: {{totalProfMenorAprendiz}}
                                ({{aplicaMascaraReais((totalProfMenorAprendiz*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalProfOutras > 0">
                                <div class="cor-legenda" style="background: #5D9B9B;"></div>
                                Outras: {{totalProfOutras}}
                                ({{aplicaMascaraReais((totalProfOutras*100)/totalProfissionais)}}%)
                            </li>
                        </ul>
                    </div>
                </td>
                <td>
                    <div style="width: 300px; height: 200px;">
                        <canvas id="i" baseChart [data]="pieChartDataRemTipoVinculo" *ngIf="totalProfissionais > 0"
                            [labels]="pieChartLabelsRemTipoVinculo" [chartType]="pieChartType" [legend]="false"
                            [options]="chartOptions" [colors]="pieChartColors1">
                        </canvas>
                    </div>
                </td>
                <td>
                    <div class="legenda-grafico" *ngIf="totalProfissionais > 0">
                        <b>Remuneração bruta por modalidade de contratação</b>
                        <ul>
                            <li *ngIf="totalRemCltAte2000 > 0">
                                <div class="cor-legenda" style="background: #3D642D;"></div>CLT, até R$ 2.000:
                                {{totalRemCltAte2000}}
                                ({{aplicaMascaraReais((totalRemCltAte2000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemClt2001a5000 > 0">
                                <div class="cor-legenda" style="background: #F39F18;"></div>CLT, de R$ 2.000,01 a R$
                                5.000: {{totalRemClt2001a5000}}
                                ({{aplicaMascaraReais((totalRemClt2001a5000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemClt5001a10000 > 0">
                                <div class="cor-legenda" style="background: #F44611;"></div>CLT, de R$ 5.000,01 a R$
                                10.000: {{totalRemClt5001a10000}}
                                ({{aplicaMascaraReais((totalRemClt5001a10000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemCltMaisDe10000 > 0">
                                <div class="cor-legenda" style="background: #646B63;"></div>CLT, mais de R$ 10.000:
                                {{totalRemCltMaisDe10000}}
                                ({{aplicaMascaraReais((totalRemCltMaisDe10000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemVoluntarioAte2000 > 0">
                                <div class="cor-legenda" style="background: #00BB2D;"></div>Voluntário, até R$ 2.000:
                                {{totalRemVoluntarioAte2000}}
                                ({{aplicaMascaraReais((totalRemVoluntarioAte2000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemVoluntarioMaisDe2000 > 0">
                                <div class="cor-legenda" style="background: #5D9B9B;"></div>Voluntário, mais de R$
                                2.000: {{totalRemVoluntarioMaisDe2000}}
                                ({{aplicaMascaraReais((totalRemVoluntarioMaisDe2000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemEstRemAte1500 > 0">
                                <div class="cor-legenda" style="background: #6A5D4D;"></div>Estágio remunerado, até R$
                                1.500: {{totalRemEstRemAte1500}}
                                ({{aplicaMascaraReais((totalRemEstRemAte1500*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemEstRemMaisDe1500 > 0">
                                <div class="cor-legenda" style="background: #A03472;"></div>Estágio remunerado, mais de
                                R$ 1.500: {{totalRemEstRemMaisDe1500}}
                                ({{aplicaMascaraReais((totalRemEstRemMaisDe1500*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalProfEstagioNaoRemunerado > 0">
                                <div class="cor-legenda" style="background: #C93C20;"></div>Estágio não remunerado:
                                {{totalProfEstagioNaoRemunerado}}
                                ({{aplicaMascaraReais((totalProfEstagioNaoRemunerado*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemMenAprAte1000 > 0">
                                <div class="cor-legenda" style="background: #252850;"></div>Menor aprendiz, até R$
                                1.000: {{totalRemMenAprAte1000}}
                                ({{aplicaMascaraReais((totalRemMenAprAte1000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemMenAprMaisDe1000 > 0">
                                <div class="cor-legenda" style="background: #8E402A;"></div>Menor aprendiz, mais de R$
                                1.000: {{totalRemMenAprMaisDe1000}}
                                ({{aplicaMascaraReais((totalRemMenAprMaisDe1000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemOutrasAte2000 > 0">
                                <div class="cor-legenda" style="background: #A65E2E;"></div>Outras, até R$ 2.000:
                                {{totalRemOutrasAte2000}}
                                ({{aplicaMascaraReais((totalRemOutrasAte2000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemOutras2001a5000 > 0">
                                <div class="cor-legenda" style="background: #26252D;"></div>Outras, de R$ 2.000,01 a R$
                                5.000: {{totalRemOutras2001a5000}}
                                ({{aplicaMascaraReais((totalRemOutras2001a5000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemOutras5001a10000 > 0">
                                <div class="cor-legenda" style="background: #CAC4B0;"></div>Outras, de R$ 5.000,01 a R$
                                10.000: {{totalRemOutras5001a10000}}
                                ({{aplicaMascaraReais((totalRemOutras5001a10000*100)/totalProfissionais)}}%)
                            </li>
                            <li *ngIf="totalRemOutrasMaisDe10000 > 0">
                                <div class="cor-legenda" style="background: #23282B;"></div>Outras, mais de R$ 10.000:
                                {{totalRemOutrasMaisDe10000}}
                                ({{aplicaMascaraReais((totalRemOutrasMaisDe10000*100)/totalProfissionais)}}%)
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>