import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExibeParceriaPorUsuarioComponent } from './modulo2/exibeParceriaPorUsuario/exibeParceriaPorUsuario.component';
import { ExibeParceriasPortalComponent } from './portal/exibeParceriasPortal/exibeParceriasPortal.component';
import { DetalheParceriaComponent } from './modulo2/detalheParceria/detalheParceria.component';
import { DetalheParceriaPortalComponent } from './portal/detalheParceriaPortal/detalheParceriaPortal.component';
import { DetalheParceriaM3Component } from './modulo3/detalheParceria/detalheParceriaM3.component';
import { ExibeParceriasM3Component } from './modulo3/exibeParcerias/exibeParceriasM3.component';
import { DetalheParceriaM1Component } from './modulo1/detalheParceria/detalheParceriaM1.component';
import { ExibeParceriasM1Component } from './modulo1/exibeParcerias/exibeParceriasM1.component';
import { ExibeParceriasM2Component } from './modulo2/exibeParcerias/exibeParceriasM2.component';
import { VerNotificacoesComponent } from './notificacoes/verNotificacoes.component';
import { PageNotFoundComponent } from './pageNotFound/pageNotFound.component';
import { PageNotAuthorizedComponent } from './pageNotAuthorized/pageNotAuthorized.component';
import { AuthGuard } from './@core/authGuard';

import { RelatorioDocumentoComponent } from './relatorios/documento/relatorioDocumento.component';
import { RelatorioResumoComponent } from './relatorios/resumo/relatorioResumo.component';
import { RelatorioDesembolsoComponent } from './relatorios/desembolso/relatorioDesembolso.component';
import { ExibeParceriasMTComponent } from './trava/exibeParcerias/exibeParceriasMT.component';
import { DetalheParceriaMTComponent } from './trava/detalheParceria/detalheParceriaMT.component';
import { CadastroBeneficarioComponent } from './modulo2/beneficiarios/cadastro/cadastroBeneficiario.component';
import { FrequenciaBeneficarioComponent } from './modulo2/beneficiarios/frequencia/frequenciaBeneficiario.component';
import { VinculoBeneficarioComponent } from './modulo2/beneficiarios/vinculos/vinculosBeneficiario.component';
import { ExibeEntidadesComponent } from './modulo2/beneficiarios/entidade/exibeEntidades.component';
import { RelatorioTurmaComponent } from './relatorios/turma/relatorioTurma.component';
import { RelatorioFrequenciaComponent } from './relatorios/frequencia/relatorioFrequencia.component';
import { RelatorioTermoComponent } from './relatorios/termoColaboracao/relatorioTermo.component';
import { RelatorioParceriasComponent } from './relatorios/parcerias/relatorioParceria.component';
import { RelatorioBeneficiariosComponent } from './relatorios/beneficiarios/relatorioBeneficiarios.component';
import { RelatorioNotificacoesComponent } from './relatorios/notificacoes/relatorioNotificacoes.component';
import { ColaboradorComponent } from './modulo2/colaboradores/colaborador.component';
import { LogStatusNotificacoesComponent } from './relatorios/notificacoes/logStatusNotificacoes.component';
import { RelatorioStatusTermoComponent } from './relatorios/statusConferenciaTermo/relatorioStatusConfTermo.component';
import { ExibeParceriasLogonComponent } from './liberacaoLogon/parcerias/exibeParcerias/exibeParceriasLogon.component';
import { RelatorioDocComplemetarArquivoComponent } from './relatorios/docComplementarArquivo/relatorioDocComplementarArquivo.component';
import { RelatorioDevolucaoAplicacaoArquivoComponent } from './relatorios/devolucaoArquivo/relatorioDevolAplicArquivo.component';
import { ParametrosAuditoriaComponent } from './auditoria/parametrizacao/parametrosAuditoria.component';
import { GraficosAuditoriaComponent } from './auditoria/graficos/graficos.component';
import { PriorizacaoCompletaAuditoriaComponent } from './auditoria/priorizacaoCompleta/priorizacaoComplementa.component';
import { VisitacaoAuditoriaComponent } from './auditoria/visitacao/visitacao.component';
import { RelatorioValoresPagosComponent } from './relatorios/valoresPagos/relatorioValoresPagos.component';
import { ListaColaboradoresPlanilhaComponent } from './relatorios/listaColaboradoresPlanilha/listaColaboradoresPlanilha.component';
import { AuditorLogonComponent } from './liberacaoLogon/auditor/auditorLogon.component';
import { PrestadorServTerComponent } from './cadastroServicoTerceiros/prestadorServTer.component';
import { RelatorioGastoPessoalComponent } from './relatorios/gastoPessoal/relatorioGastoPessoal.component';
import { PlanoDeTrabalhoNovoComponent } from './modulo2/planoDeTrabalho/planoDeTrabalho.component';


const routes: Routes = [
	{ path: '', redirectTo: 'execucao', pathMatch: 'full', canActivate: [AuthGuard] },
	{
		path: 'relatorio',
		children: [
			{
				path: 'desembolso',
				component: RelatorioDesembolsoComponent, canActivate: [AuthGuard]
			},
			{
				path: 'documento',
				component: RelatorioDocumentoComponent, canActivate: [AuthGuard]
			},
			{
				path: 'resumo',
				component: RelatorioResumoComponent, canActivate: [AuthGuard]
			},
			{
				path: 'turmas',
				component: RelatorioTurmaComponent, canActivate: [AuthGuard]
			},
			/* //  StandBy pelo usuário
			{
				path: 'frequencia',
				component: RelatorioFrequenciaComponent, canActivate: [AuthGuard]
			},*/
			{
				path: 'termos',
				component: RelatorioTermoComponent, canActivate: [AuthGuard]
			},
			{
				path: 'parcerias',
				component: RelatorioParceriasComponent, canActivate: [AuthGuard]
			},
			/*  //  StandBy pelo usuário
			{
				path: 'beneficiarios',
				component: RelatorioBeneficiariosComponent, canActivate: [AuthGuard]
			}
			*/
			{
				path: 'notificacoes',
				component: RelatorioNotificacoesComponent, canActivate: [AuthGuard]
			},
			{
				path: 'logStatusNotificacoes',
				component: LogStatusNotificacoesComponent, canActivate: [AuthGuard]
			},
			{
				path: 'statusConfTermo',
				component: RelatorioStatusTermoComponent, canActivate: [AuthGuard]
			},
			{
				path: 'docComplArquivo',
				component: RelatorioDocComplemetarArquivoComponent, canActivate: [AuthGuard]
			},
			{
				path: 'devolAplicArquivo',
				component: RelatorioDevolucaoAplicacaoArquivoComponent, canActivate: [AuthGuard]
			},
			{
				path: 'valoresPagos',
				component: RelatorioValoresPagosComponent, canActivate: [AuthGuard]
			},
			{
				path: 'listaColaboradoresPlanilha',
				component: ListaColaboradoresPlanilhaComponent, canActivate: [AuthGuard]
			},
			{
				path: 'gastoPessoal',
				component: RelatorioGastoPessoalComponent, canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'execucao', //, component: ExecucaoComponent,
		children: [
			{
				path: '',
				redirectTo: 'exibeParceriaEntidadeM2', pathMatch: 'full',
			},
			{
				path: 'exibeParceriaEntidadeM2',
				component: ExibeParceriaPorUsuarioComponent, canActivate: [AuthGuard]
			},
			{
				path: 'detalheParceriaM2/:id',
				component: DetalheParceriaComponent, canActivate: [AuthGuard]
			},
			{
				path: 'exibeParceriasM2',
				component: ExibeParceriasM2Component, canActivate: [AuthGuard]
			},
			{
				path:'planoDeTrabalho/:id',
				component: PlanoDeTrabalhoNovoComponent, canActivate: [AuthGuard]

			}			,
			{
				path:'monitoramentoAvaliacao/:id',
				component: PlanoDeTrabalhoNovoComponent, canActivate: [AuthGuard]

			}
			/*  //  StandBy pelo usuário{
				path: 'beneficiarios/frequenciaBeneficiarios/:id',
				component: FrequenciaBeneficarioComponent, canActivate: [AuthGuard]
			},
			{
				path: 'beneficiarios/vinculoBeneficiarios/:id',
				component: VinculoBeneficarioComponent, canActivate: [AuthGuard]
			}*/
		]
	},
	{
		path: 'planejamento', //, component: ExecucaoComponent,
		children: [
			{
				path: '',
				redirectTo: 'exibeParceriaM1', pathMatch: 'full',
			},
			{
				path: 'exibeParceriaM1',
				component: ExibeParceriasM1Component, canActivate: [AuthGuard],
			},
			{
				path: 'detalheParceriaM1',
				component: DetalheParceriaM1Component, canActivate: [AuthGuard]
			}
		]
	},
	/* /*  //  StandBy pelo usuário
	{
		path: 'beneficiario', //, component: ExecucaoComponent,
		children: [
			{
				path: '',
				redirectTo: 'cadastroBeneficiarios/',  pathMatch: 'full',
			},
			{
				path: 'exibeEntidades',
				component: ExibeEntidadesComponent, canActivate: [AuthGuard]
			},
			{
				path: 'cadastroBeneficiarios/',
				component: CadastroBeneficarioComponent, canActivate: [AuthGuard]
			}
		]
	},*/
	{
		path: 'cadastro', //, component: ExecucaoComponent,
		children: [
			{
				path: '',
				redirectTo: 'colaboradores',  pathMatch: 'full',
			},
			{
				path: 'exibeEntidades',
				component: ExibeEntidadesComponent, canActivate: [AuthGuard]
			},
			{
				path: 'colaboradores',
				component: ColaboradorComponent, canActivate: [AuthGuard]
			}
			,
			{
				path: 'prestadorServTerc',
				component: PrestadorServTerComponent, canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'trava', //, component: ExecucaoComponent,
		children: [
			{
				path: '',
				redirectTo: 'exibeParceriaMT', pathMatch: 'full',
			},
			{
				path: 'exibeParceriaMT',
				component: ExibeParceriasMTComponent, canActivate: [AuthGuard]
			},
			{
				path: 'detalheParceriaMT/:id',
				component: DetalheParceriaMTComponent, canActivate: [AuthGuard]
			}
		]
	},
	{ 
		path: 'notificacoes',
		children: [
			{
				path: 'verNotificacoes',
				component: VerNotificacoesComponent, canActivate: [AuthGuard]
			}
		]
	},
	{ 
		path: 'logon',
		children: [
			{
				path: '',
				redirectTo: 'exibeParceriasLogon', pathMatch: 'full',
			},
			{
				path: 'exibeParceriasLogon',
				component: ExibeParceriasLogonComponent, canActivate: [AuthGuard]
			},
			{
				path: 'detalheParceriaLogon/:id',
				component: DetalheParceriaMTComponent, canActivate: [AuthGuard]
			}, 
			{
				path: 'auditorLogon',
				component: AuditorLogonComponent, canActivate: [AuthGuard]
			}
		]
	},
	{
		path: 'auditoria', //, component: ExecucaoComponent,
		children: [
			{
				path: 'grafico',
				component: GraficosAuditoriaComponent, canActivate: [AuthGuard],
			},
			{
				path: 'parametrizacao',
				component: ParametrosAuditoriaComponent, canActivate: [AuthGuard]
			},
			{
				path: 'priorizacaoCompleta',
				component: PriorizacaoCompletaAuditoriaComponent, canActivate: [AuthGuard]
			},
			{
				path: 'visitacao',
				component: VisitacaoAuditoriaComponent, canActivate: [AuthGuard]
			}
		]
	},
	// { path: 'detalheParceriaM3/:id', component: DetalheParceriaM3Component, canActivate: [AuthGuard]},
	// { path: 'exibeParceriaM3', component: ExibeParceriasM3Component},
	{ path: 'exibeParceriasPortal', component: ExibeParceriasPortalComponent },
	{ path: 'detalheParceriaPortal/:id', component: DetalheParceriaPortalComponent },
	{ path: '**', component: PageNotFoundComponent },
	{ path: 'pageNotAuthorized', component: PageNotAuthorizedComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
