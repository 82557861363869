import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, TemplateRef, ElementRef, ViewChildren, QueryList, AfterViewInit, Directive, ContentChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { share, timeout } from 'rxjs/operators';
import { AlertService } from '../../../@core/alert.service';
import { ParceriaInfo } from './../parceriaInfo';
import { PessoaInfo } from "../pessoaInfo";
import { DetalheParceriaApi } from '../../../apis/detalheParceriaApi';
import { Mascaras } from '../../../@shared/components/Mascaras';
import { DespesaParceria } from './despesaParceria';
import { NgbModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateConverter } from '../../../@core/date-converter';
import * as jspdf from 'jspdf';
import { UserService } from '../../../@core/user.service';
import saveAs from 'file-saver';
import * as Json2csvParser from 'json2csv';
import { DatePipe } from '@angular/common';
import { DetalheParceriaPortalApi } from '../../../apis/detalheParceriaPortalApi';
import { ImagensUrlData } from 'src/app/@shared/imagens-urldata';
import { ExcelService } from 'src/app/@shared/excel-service';
import { PesquisaData } from '../../pesquisaData';
import { PaginationInstance } from 'ngx-pagination';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';
import { NFSe } from '../nfse';
import { NFe } from './nfe';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { NFeSgp } from './nfeSgp';
import { Utils } from 'src/app/@shared/utils';
import { DatetimeRange } from 'src/app/@shared/components/datepicker/datatime-range';
import { FiltroDesembolso } from './fitroDesembolso';
import { PrestadorServTer } from 'src/app/cadastroServicoTerceiros/prestadorServTer';
import { forEach } from 'lodash';
import { OcupacaoLaboral } from 'src/app/cadastroServicoTerceiros/oupacaoLaboral';
import { Colaborador } from '../../colaboradores/colaborador';
import { DespesaListDTO } from './despesaWrapper';
import ParExecucaoExecucaoSendDTO from './despesaParceriaSend';
import DespesaParceriaSend from './despesaParceriaSend';
import { ServicoPrestadoPf } from 'src/app/cadastroServicoTerceiros/servicoPrestadoPF';

@Component({
  selector: 'app-desembolso-parceria',
  templateUrl: './desembolso.component.html',
  styleUrls: ['./../detalheParceria.component.scss'],
  providers: [DatePipe],
})
export class DesembolsoComponent implements OnInit, OnChanges {

  @Input()
  private parceriaInfo: ParceriaInfo;
  @Input()
  isPortal: boolean;
  @Input()
  pesquisaData: PesquisaData;
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  depesaParceria: Array<DespesaParceria> = [];
  dataIni: NgbDateStruct;
  dataFim: NgbDateStruct;
  filtroDataEmissaoPagamento = 0;
  tipoDataEmissaoPagamento = 0;
  tipo = 1; // alterado para data pagamento
  isFiscal: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  filtro: FiltroDesembolso;
  isSaude: boolean;
  @Input()
  isAuditorExterno: boolean;
  showUpload1: boolean;
  // tslint:disable-next-line:max-line-length
  mascaraCNPJ = { mask: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/], guide: false };
  mascaraCPF = { mask: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/], guide: false };
  mascaraNroDoc = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/], guide: false };
  mascaraData = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/], guide: false };
  // tslint:disable-next-line:max-line-length
  mascaraChaveAcesso = { mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/], guide: false };
  mascaraChaveAcessoNFSe = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/], guide: false };
  maskCPFPipe = '999.999.999-99';
  maskCNPJPipe = '99.999.999/9999-99';
  pessoaInfo: PessoaInfo = new PessoaInfo();
  despesaSend: ParExecucaoExecucaoSendDTO;
  cloneOfDespesaSend: ParExecucaoExecucaoSendDTO;
  showCamposEdicao: boolean = false;
  modalEdicaoReference: any;
  inValidCpfCnpj: boolean = false;
  execTotVlrConsumo: number;
  execTotQtdConsumo: number;
  execTotQtdPF: number;
  execTotQtdPJ: number;
  execTotQtdPerm: number;
  execTotQtdOutros: number;
  execTotQtdOutrasDP: number;
  execTotQtd: number;
  execTotVlr: number;
  execTotVlrPF: number;
  execTotVlrPJ: number;
  execTotVlrPerm: number;
  execTotVlrOutros: number;
  execTotVlrOutrasDP: number;
  arquivo: string;
  docs: string;
  p = 1;
  nfse: NFSe = new NFSe();
  nfe: NFe = new NFe();
  siteKey: string;
  token: string;
  pagina: number;
  paginaC: number;
  blockCNPJ: boolean = false;
  username: string;
  comboNatureza: Array<{ id: string, name: string }>;
  logErroArquivo: Array<string>;
  pageBucket: number;
  nomeArquivoImportado: string;
  prestadores: Array<PrestadorServTer>;
  prestadorOption = [];
  ocupacaoLaboralSelecionada: OcupacaoLaboral;
  valorDiferenca: number;
  servPrestadoPfSelecionado: any;
  contratoPjSelecionado: any;
  colaboradores: Array<Colaborador>;
  depesaParceriaColab: Array<ParExecucaoExecucaoSendDTO> = [];
  depesaParceriaColabTemp: Array<ParExecucaoExecucaoSendDTO> = [];
  depesaParceriaColabSalvar: Array<ParExecucaoExecucaoSendDTO> = [];
  disabledRPCI: boolean = false;
  selectAll: boolean;
  colaboradorNome: string = "";
  cnpj: string;



  @ViewChild('contentImportLog') contentImport: ElementRef;
  @ViewChild('contentConfirmaSalvar') confirmaSalvaModal: ElementRef;

  public config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 10,
    currentPage: 1
  };

  public configColab: PaginationInstance = {
    id: 'customC',
    itemsPerPage: 10,
    currentPage: 1
  };

  // @ViewChild(DataTable) child: DataTable;
  constructor(
    private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    private parceriaDespesaDevolApi: PublicoPrivadaApi,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    public userService: UserService,
    private mascaras: Mascaras,
    private excelService: ExcelService,
    private datePipe: DatePipe,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private utils: Utils) {
  }

  ngOnInit() {
    this.userService.userInfo.subscribe(user => this.username = user.username);
    this.siteKey = "6LdS-xoeAAAAAHgC5W48d2G7wP9F07geMWONKP6u";
    this.setupInterface();
    this.isFiscal = this.userService.isFiscal();
    this.isSMTC = this.userService.isSMTC();
    this.isConsulta = this.userService.isConsulta();
    if (this.utils.isSaude(this.parceriaInfo.zgororgaoCdorgao2)) {
      this.isSaude = true;
    }
    else {
      this.isSaude = false;
    }
    this.pageBucket = 1;
  }

  disableCampos(despesaParceriaSend) {
    if (despesaParceriaSend.execConferir === 2 || despesaParceriaSend.execConferir === 4 || despesaParceriaSend.execConferir === 5) {
      return true;
    }
    return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }

  disableApagarIcone() {
    return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }

  enableEditarIcone(despesa) {
    if (this.isConsulta || this.isSMTC || this.isPortal || this.isAuditorExterno) {
      return false;
    }
    return (despesa.execJustificaExclusao == null && this.isFiscal) ||
      (!this.isFiscal && (despesa.execConferir == 1 || despesa.execConferir == 3 )); // || despesa.execConferir == 7)); // a conferir, a corrigir, reconferir ... CORRIGIDO... RECONFERIR NÃO DEVE PERMITIR EDIÇÃO NA VISÃO OSC
  }

  visualizarBotao(despesaParceriaSend) {
    return ((this.isSMTC || this.isConsulta || this.isAuditorExterno || (this.isPortal && despesaParceriaSend.execOperacao === 7)) || ((!this.isFiscal && (despesaParceriaSend.execConferir === 2
      || despesaParceriaSend.execConferir === 4 || despesaParceriaSend.execConferir === 5 || despesaParceriaSend.execConferir === 7))));
  }

  setupInterface() {
    this.loading.next(true);
    this.depesaParceria = [];
    this.parceriaInfo.totalDesembolsos = (this.parceriaInfo.execTotVlrPerm + this.parceriaInfo.execTotVlrOutros + this.parceriaInfo.execTotVlrOutrasDP +
      this.parceriaInfo.execTotVlrPF + this.parceriaInfo.execTotVlrPJ + this.parceriaInfo.execTotVlrConsumo);
    this.parceriaInfo.quantidadeTotal = this.parceriaInfo.execTotQtdPerm + this.parceriaInfo.execTotQtdOutros + +this.parceriaInfo.execTotQtdOutrasDP +
      this.parceriaInfo.execTotQtdPF + this.parceriaInfo.execTotQtdPJ + this.parceriaInfo.execTotQtdConsumo;
    const myDate = new Date(new Date().getFullYear(), (Number(new Date().getMonth())), 1, 0, 0, 0);
    //    console.log(myDate);
    if (this.pesquisaData === undefined || this.pesquisaData.dataInicial === undefined) {
      this.pesquisaData = new PesquisaData();
      this.pesquisaData.dataInicial = myDate;
      this.pesquisaData.dataFinal = new Date();
      this.pesquisaData.tipo = "1";
    }
    //this.comboNatureza = new Array<{id: string, name: string}>();
    this.comboNatureza = this.utils.naturezaOptions.slice();
    this.inicializaFiltro();
    this.configColab.currentPage = 1;
  }

  convertDateToUTC(date) {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(),
      date.getUTCMinutes(), date.getUTCSeconds()));
  }

  getDespesaParceria(filtro) {

    //console.log(this.datePipe.transform(dataIni, 'dd/MM/yyyy'));
    filtro.parId = this.parceriaInfo.parId;
    const filtroD = JSON.stringify(this.filtro, this.replacerFiltro);
    this.parceriaDespesaDevolApi.getDespesaParceriaPeriodo(filtroD, this.isPortal)
      .subscribe(async (response) => {
        if (response) {
          this.depesaParceria = response as Array<DespesaParceria>;
          this.execTotVlrConsumo = 0;
          this.execTotQtdConsumo = 0;
          this.execTotQtdPF = 0;
          this.execTotQtdPJ = 0;
          this.execTotQtdPerm = 0;
          this.execTotQtdOutros = 0;
          this.execTotQtdOutrasDP = 0;
          this.execTotQtd = 0;
          this.execTotVlr = 0;
          this.execTotVlrPF = 0;
          this.execTotVlrPJ = 0;
          this.execTotVlrPerm = 0;
          this.execTotVlrOutros = 0;
          this.execTotVlrOutrasDP = 0;

          // remove elemento da visualização apenas da entidade
          const cloneArray = this.depesaParceria.slice();
          const despesaExcluida = new Array<DespesaParceria>();
          this.depesaParceria.forEach(element => {
            if ((element.execJustificaExclusao !== null) && (this.isPortal || (!this.isSMTC && !this.isFiscal))) {
              cloneArray.splice(cloneArray.indexOf(element), 1);
              despesaExcluida.push(element);
            }


          }, []);
          this.depesaParceria = cloneArray;
          this.totalizaDespesasPorNatureza();
          await this.getDespesaColaboradorTmp(filtro);
        } else {
          this.alertService.warning('Nenhum registro encontrado');
          this.depesaParceria = [];
        }
        if (this.modalEdicaoReference != undefined)
          this.modalEdicaoReference.close();
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));



  }
  async getDespesaColaboradorTmp(filtro) {

    //console.log(this.datePipe.transform(dataIni, 'dd/MM/yyyy'));
    filtro.parId = this.parceriaInfo.parId;
    const filtroD = JSON.stringify(this.filtro, this.replacerFiltro);
    this.detalheParceriaApi.getDespesaColaboradorTmp(filtroD)
      .subscribe(async (response) => {
        if (response) {
          let despesaParceriaColabTempDesp = new Array<DespesaParceria>();
          this.depesaParceriaColabTemp = new Array<ParExecucaoExecucaoSendDTO>();
          despesaParceriaColabTempDesp = response as Array<DespesaParceria>;
          despesaParceriaColabTempDesp.map(element => {
            this.setDespesaSend(element);
            this.depesaParceriaColabTemp.push(this.despesaSend);
          })
          this.getColaboradoresAtivosNaOsc();
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  pageChanged(event) {
    //console.log(event);
    this.config.currentPage = event;
  }
  pageChangedC(event) {
    //console.log(event);
    this.configColab.currentPage = event;
  }

  private totalizaDespesasPorNatureza() {
    this.depesaParceria.forEach(element => {
      element.cnpj9 = (element.execNroCpfcnpj === '99999999999999' ? true : false);
      element.edicao = false;
      element.execNroCpfcnpj = this.mascaras.aplicaMascaraDocumento(element.execNroCpfcnpj.toString(), element.execTpCpfcnpj);
      if (element.execJustificaExclusao == null) {
        switch (element.execNatureza) {
          case 1: {
            this.execTotVlrConsumo = this.execTotVlrConsumo + element.execValorDoc;
            this.execTotVlr = this.execTotVlr + element.execValorDoc;
            this.execTotQtdConsumo++;
            this.execTotQtd++;
            break;
          }
          case 2: {
            this.execTotVlrPF = this.execTotVlrPF + element.execValorDoc;
            this.execTotVlr = this.execTotVlr + element.execValorDoc;
            this.execTotQtdPF++;
            this.execTotQtd++;
            break;
          }
          case 3: {
            this.execTotVlrPJ = this.execTotVlrPJ + element.execValorDoc;
            this.execTotVlr = this.execTotVlr + element.execValorDoc;
            this.execTotQtdPJ++;
            this.execTotQtd++;
            break;
          }
          case 4: {
            this.execTotVlrPerm = this.execTotVlrPerm + element.execValorDoc;
            this.execTotVlr = this.execTotVlr + element.execValorDoc;
            this.execTotQtdPerm++;
            this.execTotQtd++;
            break;
          }
          case 5: {
            this.execTotVlrOutros = this.execTotVlrOutros + element.execValorDoc;
            this.execTotVlr = this.execTotVlr + element.execValorDoc;
            this.execTotQtdOutros++;
            this.execTotQtd++;
            break;
          }
          case 6: {
            this.execTotVlrOutrasDP = this.execTotVlrOutrasDP + element.execValorDoc;
            this.execTotVlr = this.execTotVlr + element.execValorDoc;
            this.execTotQtdOutrasDP++;
            this.execTotQtd++;
            break;
          }
        }
      }
    });
  }

  buscarDespesaPorFiltro(event) {
    const newVal = event.currentTarget.value;
    this.filtro.tipoDataFiltro = newVal;

    this.filtro.dataInicial = this.datePipe.transform(this.pesquisaData.dataInicial, 'dd/MM/yyyy');
    this.filtro.dataFinal = this.datePipe.transform(this.pesquisaData.dataFinal, 'dd/MM/yyyy');
    this.filtro.parId = this.parceriaInfo.parId;
    this.getDespesaParceria(this.filtro);
    this.dataIni = this.dateConverter.fromTimestampToNgbDateStruct(
      this.dateConverter.fromDateToTimestamp(this.pesquisaData.dataInicial));
    this.dataFim = this.dateConverter.fromTimestampToNgbDateStruct(
      this.dateConverter.fromDateToTimestamp(this.pesquisaData.dataFinal));
  }

  pesquisaDespesaTipo(event) {
    const newVal = event.currentTarget.value;
    this.tipo = newVal;

    this.filtro.dataInicial = this.datePipe.transform(this.pesquisaData.dataInicial, 'dd/MM/yyyy');
    this.filtro.dataFinal = this.datePipe.transform(this.pesquisaData.dataFinal, 'dd/MM/yyyy');
    this.filtro.orderBy = newVal;
    this.filtro.parId = this.parceriaInfo.parId;
    this.getDespesaParceria(this.filtro);
    this.dataIni = this.dateConverter.fromTimestampToNgbDateStruct(
      this.dateConverter.fromDateToTimestamp(this.pesquisaData.dataInicial));
    this.dataFim = this.dateConverter.fromTimestampToNgbDateStruct(
      this.dateConverter.fromDateToTimestamp(this.pesquisaData.dataFinal));
  }

  buscaPessoa(documento) {

    const tipo = documento.replace(/[^\d]+/g, '').length < 14 ? 1 : 2;
    //this.setDespesaSend(undefined);
    this.inValidCpfCnpj = !this.mascaras.validaCpfCnpj(documento);
    if (!this.inValidCpfCnpj) {
      this.despesaSend.execNroCpfcnpj = documento.replace(/[^\d]+/g, '');
      if (documento === '99.999.999/9999-99') {
        this.despesaSend.cnpj9 = true;
        this.despesaSend.execNomeCredor = undefined;
        this.despesaSend.execNroCpfcnpj = 99999999999999;
        this.despesaSend.execTpCpfcnpj = 2;
        this.showCamposEdicao = true;
        return;
      }
      // if (this.parceriaInfo.gastosPessoal){
      //   this.despesaSend.tipoDeServico = await this.getTipoDeServicoPorPessoa(documento.replace(/[^\d]+/g, ''), this.despesaSend.execTpCpfcnpj, this.parceriaInfo.parOrgCnpj);
      // }

      this.loading.next(true);
      this.detalheParceriaApi.getPessoa(documento.replace(/[^\d]+/g, ''), tipo)
        .subscribe(async (response) => {
          if (response) {
            this.pessoaInfo = response as PessoaInfo;
            this.despesaSend.execNomeCredor = this.pessoaInfo.nome;
            this.despesaSend.execNroCpfcnpj = documento;
            this.despesaSend.execTpCpfcnpj = this.pessoaInfo.idCodTipoPes;
            this.loading.next(false);

            this.showCamposEdicao = true;
            return this.pessoaInfo.nome;
          } else {
            //this.alertService.warning('Nenhum CPF/CNPJ encontrado');
            //this.loading.next(false);
            if (tipo == 1) {
              await this.getNomeColaborador(documento, this.parceriaInfo.parId);
              this.despesaSend.execTpCpfcnpj =1;
            }
            else{
              this.despesaSend.cnpj9 = true;
              this.despesaSend.execTpCpfcnpj = 2;
            }
          }

        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        }, () => this.loading.next(false));
      this.loading.next(false);
    } else {
      this.inValidCpfCnpj = true;
    }
  }
 async getNomeColaborador(cpf, parId) {
    this.detalheParceriaApi.getNomeColaborador(cpf.replace(/[^\d]+/g, ''), parId)
      .subscribe( (response) => {
        if (response) {
          this.despesaSend.execNomeCredor = response as string;
          this.despesaSend.execNroCpfcnpj = cpf;
          this.despesaSend.execTpCpfcnpj = 1;
        } else {
          return "Colaborador não encontrador, favor inserir em Cadastro de Colaboradores";
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        
      }, () => this.loading.next(false));
    return "";
  }

  buscaPessoaFiltro(documento) {
    const tipo = documento.replace(/[^\d]+/g, '').length < 14 ? 1 : 2;
    //this.setDespesaSend(undefined);
    this.inValidCpfCnpj = !this.mascaras.validaCpfCnpj(documento);
    if (!this.inValidCpfCnpj) {
      this.filtro.execNroCpfcnpj = documento.replace(/[^\d]+/g, '');
      this.loading.next(true);
      this.detalheParceriaApi.getPessoa(documento.replace(/[^\d]+/g, ''), tipo)
        .subscribe((response) => {
          if (response) {
            this.pessoaInfo = response as PessoaInfo;
            this.filtro.execNomeCredor = this.pessoaInfo.nome;
            this.filtro.execNroCpfcnpj = documento.replace(/[^\d]+/g, '');;
            this.loading.next(false);
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        }, () => this.loading.next(false));
      this.loading.next(false);
    } else {
      this.inValidCpfCnpj = true;
    }
  }

  adicionaDespesa() {
    const des = new DespesaParceria();
    des.execConferir = 1;
    des.edicao = true;
    des.cnpj9 = false;
    this.depesaParceria.push(des);
  }

  getArquivo(parId, execId, tipo, nome, isTmp) {
    this.loading.next(true);
    this.parceriaDespesaDevolApi.getArquivoDes(parId, execId, tipo, this.isPortal, isTmp)
      .subscribe((response) => {
        if (response) {
          console.log(response);
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(response.body);
          a.href = url;
          a.download = nome;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else {
          this.alertService.warning('Nenhum arquivo encontrado');
        }
      }, (response) => {
        // if (response.status >= 500) {
        //   this.alertService.danger(response.error);
        // } else {
        //   this.alertService.warning(response.error);
        // }
        this.alertService.danger('Erro ao buscar arquivo de desembolso.');
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  onFileChanged(event, documento, _desepesaSend) {
    const fileList: FileList = event.currentTarget.files;
    //console.log(fileList);
    const reader = new FileReader();
    if (fileList.length > 0) {
      const array = fileList[0].type.split('/');
      const fileSize = fileList[0].size / 1024 / 1024; // in MB
      if (fileSize > 10) {
        this.alertService.danger('Tamanho de arquivo superior ao limite de 10 MB. Esse arquivo possui ' + fileSize.toPrecision(2) + " MB");
        return;
      }
      if (array[1] === 'pdf') {

        reader.readAsDataURL(fileList[0]);
        reader.onload = () => {
          if (documento === 'documento') {
            _desepesaSend.execDocumento = (reader.result).toString().split(',')[1];
            _desepesaSend.execDocumentoTipo = array[1];
            _desepesaSend.execDocumentoArquivo = fileList[0].name;
            _desepesaSend.showUpload = false;
          }
          if (documento === 'documento1') {
            _desepesaSend.execDocumento1 = (reader.result).toString().split(',')[1];
            _desepesaSend.execDoccumentoTipo1 = array[1];
            _desepesaSend.execDocumentoArq1 = fileList[0].name;
            _desepesaSend.showUpload1 = false;
          }
        };
      } else {
        event.currentTarget.value = '';
        this.alertService.warning('Formato de arquivo não suportado!');
      }
    }
  }

  resetFile(i) {
    //this.depesaParceria[i].execDocumento = undefined;
    this.depesaParceria[i].execDocumentoTipo = undefined;
    this.depesaParceria[i].execDocumentoArquivo = undefined;
  }

  ngOnChanges(changes: SimpleChanges) {


    this.inicializaFiltro();

    for (const property in changes) {

      if (changes.hasOwnProperty(property) && property === 'pesquisaData') {
        if (this.pesquisaData === undefined || this.pesquisaData.dataInicial === undefined) {
          const myDate = new Date(new Date().getFullYear(), (Number(new Date().getMonth())), 1, 0, 0, 0);
          this.pesquisaData = new PesquisaData();
          this.pesquisaData.dataInicial = myDate;
          this.pesquisaData.dataFinal = new Date();
          this.filtro.dataInicial = this.datePipe.transform(this.pesquisaData.dataInicial, 'dd/MM/yyyy');
          this.filtro.dataFinal = this.datePipe.transform(this.pesquisaData.dataFinal, 'dd/MM/yyyy');
        }
        if (this.pesquisaData.dataFinal === undefined) {
          this.pesquisaData.dataFinal = new Date();
          this.filtro.dataFinal = this.datePipe.transform(this.pesquisaData.dataFinal, 'dd/MM/yyyy');
        }
        this.config.currentPage = 1;

        if (this.pesquisaData.dataInicial != null && this.pesquisaData.dataFinal != null) {
          this.filtro.dataInicial = this.datePipe.transform(this.pesquisaData.dataInicial, 'dd/MM/yyyy');
          this.filtro.dataFinal = this.datePipe.transform(this.pesquisaData.dataFinal, 'dd/MM/yyyy');
          this.getDespesaParceria(this.filtro);
        }
        this.dataIni = this.dateConverter.fromTimestampToNgbDateStruct(
          this.dateConverter.fromDateToTimestamp(this.pesquisaData.dataInicial));
        this.dataFim = this.dateConverter.fromTimestampToNgbDateStruct(
          this.dateConverter.fromDateToTimestamp(this.pesquisaData.dataFinal));

      }
    }
    //this.child.setPage(this.pageActive, this.qtRow);
  }

  private inicializaFiltro() {
    if (this.filtro === undefined) {
      this.filtro = new FiltroDesembolso();
      this.filtro.execId;
      this.filtro.parId = this.parceriaInfo.parId;
      this.filtro.execNomeCredor = '';
      this.filtro.execNroCpfcnpj;
      this.filtro.execNatureza = 0;
      this.filtro.execTpDoc = 0;
      this.filtro.execNroDoc = '';
      this.filtro.execValorDoc = 0;
      this.filtro.execOperacao = 0;
      this.filtro.dataPagamento = null;
      this.filtro.execDocumentoData = null;
      this.filtro.valorTotalDocumento = 0;
      this.filtro.execDocumentoDataFormat = null;
      this.filtro.dataPagamentoFormat = null;
      this.filtro.dataInicial = null;
      this.filtro.dataFinal = null;
      this.filtro.orderBy = 0;

    }
  }

  updateTextArea(valor) {
    this.despesaSend.execMotivo = valor;
  }
  updateTextAreaJustificativa(valor) {
    this.despesaSend.execJustificaExclusao = valor;
  }

  updateOperacao(event) {
    const newVal = event.currentTarget.value;
    if (this.despesaSend != undefined) {
      this.despesaSend.execOperacao = newVal;

      if (this.despesaSend.execOperacao == 7) {
        this.consultaNFSe();
      }
      if (this.despesaSend.execOperacao == 4) {
        this.consultaNFe();
      }
      if (this.despesaSend.execOperacao == 16) {
        this.consultaNFSeNacional();
      }
    }
    if (this.filtro != undefined) {
      this.filtro.execOperacao = newVal;
    }
    this.blockCNPJ = false;
    switch (newVal) {
      case "2":
        this.despesaSend.execNroCpfcnpj = 360305000104;
        this.blockCNPJ = true;
        this.buscaPessoa("00360305000104");
        break;
      case "11":
        this.despesaSend.execNroCpfcnpj = 360305000104;
        this.blockCNPJ = true;
        this.buscaPessoa("00360305000104");
        break;
      case "12":
        this.despesaSend.execNroCpfcnpj = 360305000104;
        this.blockCNPJ = true;
        this.buscaPessoa("00360305000104");
        break;
      case "13":
        this.despesaSend.execNroCpfcnpj = 394460005887;
        this.blockCNPJ = true;
        this.buscaPessoa("00394460005887");
        break;
      case "14":
        this.despesaSend.execNroCpfcnpj = 29979036000140;
        this.blockCNPJ = true;
        this.buscaPessoa("29979036000140");
        break;
    }

  }
  isExecBloqueio(despesaSend) {
    if (despesaSend.execOperacao == 2 || despesaSend.execOperacao > 10 && despesaSend.execOperacao < 15)
      return true;

    else
      return false;
  }

  updateNatureza(event) {
    const newVal = event.currentTarget.value;
    if (this.despesaSend != undefined) {
      this.despesaSend.execNatureza = newVal;
    }
    if (this.filtro != undefined) {
      this.filtro.execNatureza = newVal;
    }
    if (this.despesaSend.execNatureza != 3) {
      this.despesaSend.tipoDeServico = 0;
      this.despesaSend.contratoPj = 0;
      this.despesaSend.servPrestadoPf = 0;
      this.contratoPjSelecionado = '';
      this.servPrestadoPfSelecionado = '';
      this.valorDiferenca = 0;
      this.blockCNPJ = false;
      this.disabledRPCI = false;
    }
  }


  updateSituacao(event) {
    const newVal = event.currentTarget.value;
    this.despesaSend.execConferir = newVal;
  }

  updateTipoServicoTerceiros(event) {
    const newVal = event.currentTarget.value;
    this.despesaSend.tipoDeServico = newVal;
    this.despesaSend.contratoPj = 0;
    this.despesaSend.servPrestadoPf = 0;
    this.contratoPjSelecionado = '';
    this.servPrestadoPfSelecionado = '';
    this.valorDiferenca = 0;
    this.despesaSend.execNroCpfcnpj = null;
    this.despesaSend.execNomeCredor = null;
    if (newVal == 1 || newVal == 2) {
      if (newVal == 1) {
        this.despesaSend.execOperacao = 5;
        this.disabledRPCI = true;
        this.blockCNPJ = false;
      }
      else {
        this.disabledRPCI = false;
        this.blockCNPJ = true;
        this.despesaSend.execOperacao = 0;
      }
      this.getPrestadores(this.parceriaInfo.parOrgCnpj, newVal);
    }
    else {
      this.despesaSend.execOperacao = 0;
      this.despesaSend.execNroCpfcnpj = null;
      this.disabledRPCI = false;
      this.blockCNPJ = false;
      this.buscaPessoa(null);
    }
  }

  async getParceriaInfo(id) {
    this.loading.next(true);
    this.detalheParceriaPortalApi.getParceriaInfo(id)
      .subscribe((response) => {
        if (response) {
          this.parceriaInfo = response as ParceriaInfo;
          this.parceriaInfo.totalDesembolsos = (this.parceriaInfo.execTotVlrPerm + this.parceriaInfo.execTotVlrOutros +
            this.parceriaInfo.execTotVlrOutrasDP +
            this.parceriaInfo.execTotVlrPF + this.parceriaInfo.execTotVlrPJ + this.parceriaInfo.execTotVlrConsumo);
          this.parceriaInfo.quantidadeTotal = this.parceriaInfo.execTotQtdPerm + this.parceriaInfo.execTotQtdOutros +
            this.parceriaInfo.execTotQtdOutrasDP +
            this.parceriaInfo.execTotQtdPF + this.parceriaInfo.execTotQtdPJ + this.parceriaInfo.execTotQtdConsumo;
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));

  }


  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }
  isNFSeOuNFeConveniada() {
    return (this.despesaSend.execOperacao == 7 || this.despesaSend.execOperacao == 16 || (this.despesaSend.execOperacao == 4 && this.isBlank(this.despesaSend.chaveAcessoNFE))
      || (this.despesaSend.execOperacao == 4 && (!this.despesaSend.chaveAcessoNFE.startsWith('23') &&
        !this.despesaSend.chaveAcessoNFE.startsWith('31') && !this.despesaSend.chaveAcessoNFE.startsWith('35'))));
  }

  async salvaDespesa() {
    const clonedes = JSON.stringify(this.cloneOfDespesaSend);
    const original = JSON.stringify(this.despesaSend);
    //console.log("clone "+clonedes);
    //console.log(" orig "+original);

    //Validação Alteração de Campos
    if (clonedes === original) {
      this.alertService.danger('Nenhum campo foi alterado. Não há informações a serem salvas.');
      return;
    }


    if (!this.isFiscal) {

      if ((this.despesaSend.execDocumentoArquivo != null && this.despesaSend.execDocumentoArquivo.length > 50) ||
        (this.despesaSend.execDocumentoArq1 != null && this.despesaSend.execDocumentoArq1.length > 50)) {
        this.alertService.danger('Nome do arquivo superior a 50 caracteres, por favor renomeie antes de fazer upload.');
        return;
      }

      if (this.isBlank(this.despesaSend.execNroDoc) && (this.despesaSend.execNatureza != null)
        && (this.despesaSend.execNatureza != 3 || this.despesaSend.tipoDeServico != 1)) {

        this.alertService.danger('Verifique o preenchimento de todos os campos obrigatórios. Não foi possível salvar os dados.');
        return;
      }

      if (this.isBlank(this.despesaSend.execNomeCredor) || this.isBlank(this.despesaSend.execNroCpfcnpj.toString)
        || (this.despesaSend.execNatureza === undefined || this.despesaSend.execNatureza === null) ||
        this.despesaSend.execOperacao === null || this.despesaSend.execOperacao === undefined ||
        this.despesaSend.execValorDoc === 0 || this.despesaSend.valorTotalDocumento === 0 ||
        (!this.despesaSend.execDocumentoArquivo && (this.despesaSend.execOperacao != 7 && this.despesaSend.execOperacao != 4 && this.despesaSend.execOperacao != 16))) {
        this.alertService.danger('Verifique o preenchimento de todos os campos obrigatórios. Não foi possível salvar os dados.');
        return;
      }

      if ((this.despesaSend.execOperacao == 4 || this.despesaSend.execOperacao == 8) && (!this.despesaSend.chaveAcessoNFE || this.despesaSend.chaveAcessoNFE.length < 54)) {
        this.alertService.danger('Campo chave de acesso não preenchido corretamente. Não foi possível salvar os dados.');
        return;
      }

      // SGP-526: Comentada esta verificação, para permitir uso do tipo "NF-e Manual" para qualquer NF-e
      // if ((this.despesaSend.execOperacao == 8) && (!this.despesaSend.chaveAcessoNFE.startsWith('35')
      //   && !this.despesaSend.chaveAcessoNFE.startsWith('31') && !this.despesaSend.chaveAcessoNFE.startsWith('23'))) {
      //   this.alertService.danger('Campo chave de acesso não permite inclusão de estados com convênio. Use o tipo NFe.');
      //   return;
      // }

      if (this.despesaSend.execId == -1 && this.nfe.situacao == 'Cancelada') {
        this.alertService.danger('Não é permitido a inclusão de Nota Fiscal Eletrônica Cancelada.');
        return;
      }

      // SGP-526: Comentada esta verificação, para permitir uso do tipo "NF-e Manual" para qualquer NF-e
      // if (this.despesaSend.execOperacao == 4 && (this.despesaSend.chaveAcessoNFE.startsWith('23') ||
      //   this.despesaSend.chaveAcessoNFE.startsWith('31') ||
      //   this.despesaSend.chaveAcessoNFE.startsWith('35'))) {
      //   this.alertService.danger('Verifique o preenchimento de todos os campos obrigatórios. Não foi possível salvar os dados.');
      //   return;
      // }

      if ((this.despesaSend.execOperacao == 7 || this.despesaSend.execOperacao == 9) && (this.isBlank(this.despesaSend.execInscricaoMunicipal))) {
        this.alertService.danger('Campo inscrição municipal não preenchido corretamente. Não foi possível salvar os dados.');
        return;
      }

      if (this.despesaSend.execOperacao == 16 && this.isBlank(this.despesaSend.chaveAcessoNFE)) {
        this.alertService.danger('Campo chave de acesso não preenchido corretamente. Não foi possível salvar os dados.');
        return;
      }

      if ((this.despesaSend.execOperacao == 7 || this.despesaSend.execOperacao == 16) && !this.isBlank(this.despesaSend.statusCancelamento)) {
        this.alertService.danger('A NFS-e informada foi cancelada.');
        return;
      }

      if (this.despesaSend.execOperacao == 7 && this.isBlank(this.nfse.codInscMuni)) {
        this.alertService.danger('NFS-e inválida, verifique se a Inscrição Municipal e número da nota estão corretos.');
        return;
      }

      if ((this.despesaSend.execTpDoc == 2 && this.despesaSend.execNatureza == 2)
        && (this.despesaSend.execNroCpfcnpj != 360305000104 &&
          this.despesaSend.execNroCpfcnpj != 394460005887 && this.despesaSend.execNroCpfcnpj != 29979036000140)) {
        this.alertService.danger('Este Tipo Documento não permite informar CNPJ para natureza Pagamento de Pessoal.');
        return;
      }

      if (this.despesaSend.execOperacao === undefined) {
        this.alertService.danger('Campo tipo doc deve ser preenchido.');
        return;
      }
    }

    if (this.isFiscal && (this.despesaSend.execConferir == 3 || this.despesaSend.execConferir == 4
      || this.despesaSend.execConferir == 5)) {
      if (this.isBlank(this.despesaSend.execMotivo)) {
        this.alertService.danger('Campo apontamento deve ser preenchido.');
        return;
      }
    }
    this.despesaSend.execDocumentoData = this.dateConverter.fromNgbDateStructToDate(this.despesaSend.execDocumentoDataFormat);
    if (!this.utils.isValidDate(this.despesaSend.execDocumentoData)) {
      this.alertService.danger('Data Emissão Inválida.');
      return;
    }

    if (this.despesaSend.dataPagamentoFormat != null) {
      this.despesaSend.dataPagamento = this.dateConverter.fromNgbDateStructToDate(this.despesaSend.dataPagamentoFormat);
      if (!this.utils.isValidDate(this.despesaSend.dataPagamento)) {
        this.alertService.danger('Data Pagamento Inválida.');
        return;

      }
    }
    else {
      this.alertService.danger('Data Pagamento Inválida.');
      return;
    }

    if (this.isSaude && this.despesaSend.tipoDeServico == 1) {
      if (this.servPrestadoPfSelecionado == null || this.servPrestadoPfSelecionado == '') {
        this.alertService.danger('Selecione o RPCI');
        return;
      }
    }

    if (this.isSaude && this.despesaSend.tipoDeServico == 2) {
      if (this.contratoPjSelecionado == null || this.contratoPjSelecionado == '') {
        this.alertService.danger('Selecione o contrato');
        return;
      }
    }

    if (this.despesaSend.execConferir === null) {
      this.despesaSend.execConferir = 1;
    }
    
    if (!this.isFiscal) {       
       // se não for fiscal, e o status é a corrigir 
       // adiciona o status da situação para a reconferir
      if (this.despesaSend.execConferir == 3){
        this.despesaSend.execConferir = 7;
      }
    }
    this.despesaSend.operacaoData = new Date();
    this.despesaSend.operacaoUsuario = this.username;
    this.despesaSend.execNroCpfcnpj = Number(this.despesaSend.execNroCpfcnpj.toString().replace(/[^\d]+/g, ''));
    const data = JSON.stringify(this.despesaSend, this.replacerDespesa);
    //console.log(this.despesaSend);
    this.detalheParceriaApi.updateDespesaParceria(data).subscribe(async (response) => {
      if (response) {

        this.setupInterface();
        this.setDespesaSend(undefined);
        this.modalEdicaoReference.close();
        this.arquivo = '';
        await this.getParceriaInfo(this.despesaSend.parId);
        const myDate = new Date(new Date().getFullYear(), (Number(new Date().getMonth())), 1, 0, 0, 0);
        //    console.log(myDate);
        if (this.pesquisaData === undefined || this.pesquisaData.dataInicial === undefined) {
          this.pesquisaData = new PesquisaData();
          this.pesquisaData.dataInicial = myDate;
          this.pesquisaData.dataFinal = new Date();
        }
        this.filtro.dataInicial = this.datePipe.transform(this.pesquisaData.dataInicial, 'dd/MM/yyyy');
        this.filtro.dataFinal = this.datePipe.transform(this.pesquisaData.dataFinal, 'dd/MM/yyyy');
        this.filtro.orderBy = this.tipo;
        this.filtro.parId = this.parceriaInfo.parId;
        this.filtro.execNatureza = 0;
        this.filtro.execOperacao = 0;

        this.getDespesaParceria(this.filtro);
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      this.modalEdicaoReference.close();
    }, () => this.loading.next(false));
    //this.child.setPage(4, 10);
  }

  excluirDespesa() {
    // this.despesaSend.execNroCpfcnpj = Number(this.despesaSend.execNroCpfcnpj.toString().replace(/[^\d]+/g, ''));
    // const today = new Date();
    // const year1 = this.despesaSend.execDocumentoDataFormat.year;
    // const year2 = today.getFullYear();
    // const month1 = this.despesaSend.execDocumentoDataFormat.month;
    // const month2 = today.getMonth() + 1;
    // const day2 = today.getDate();

    // const numberOfMonths = (year2 - year1) * 12 + (month2 - month1);

    this.despesaSend.operacaoData = new Date();
    this.despesaSend.operacaoUsuario = this.username;

    // if ((numberOfMonths > 1) || (numberOfMonths === 1 && day2 > 10)) {

    if (this.isBlank(this.despesaSend.execJustificaExclusao)) {
      this.alertService.danger('A justificativa é obrigatória para a exclusão da despesa.');
      return;
    }


    this.loading.next(true);
    this.despesaSend.execNroCpfcnpj = Number(this.despesaSend.execNroCpfcnpj.toString().replace(/[^\d]+/g, ''));
    const data = JSON.stringify(this.despesaSend, this.replacerDespesa);
    this.detalheParceriaApi.updateDespesaParceria(data).subscribe(async (response) => {
      if (response) {

        const myDate = new Date(new Date().getFullYear(), (Number(new Date().getMonth())), 1, 0, 0, 0);
        //    console.log(myDate);
        if (this.pesquisaData === undefined || this.pesquisaData.dataInicial === undefined) {
          this.pesquisaData = new PesquisaData();
          this.pesquisaData.dataInicial = myDate;
          this.pesquisaData.dataFinal = new Date();
        }

        this.filtro.dataInicial = this.datePipe.transform(this.pesquisaData.dataInicial, 'dd/MM/yyyy');
        this.filtro.dataFinal = this.datePipe.transform(this.pesquisaData.dataFinal, 'dd/MM/yyyy');
        this.filtro.orderBy = this.tipo;
        this.filtro.parId = this.parceriaInfo.parId;
        this.getDespesaParceria(this.filtro);
        this.getParceriaInfo(this.despesaSend.parId);
        this.setDespesaSend(undefined);
        this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      this.modalEdicaoReference.close();
    }, () => this.loading.next(false));
    return;
    // } else {
    //   this.loading.next(true);
    //   this.detalheParceriaApi.excluirDespesaParceria(this.despesaSend).subscribe((response) => {
    //     if (response) {
    //       this.setupInterface();
    //       this.modalEdicaoReference.close();
    //     }
    //   }, (response) => {
    //     if (response.status >= 500) {
    //       this.alertService.danger(response.error);
    //     } else {
    //       this.alertService.warning(response.error);
    //     }
    //     this.loading.next(false);
    //   }, () => this.loading.next(false));
    // }
  }

  setDespesaSend(depesaParceria) {
    this.despesaSend = new ParExecucaoExecucaoSendDTO();
    if (depesaParceria) {
      this.despesaSend.edicao = false;
      this.despesaSend.cnpj9 = (depesaParceria.execNroCpfcnpj === '99.999.999/9999-99' ? true : false);
      this.despesaSend.parId = depesaParceria.parId;
      this.despesaSend.execId = depesaParceria.execId;
      this.despesaSend.execChave = depesaParceria.execChave;
      this.despesaSend.execDoccumentoTipo1 = depesaParceria.execDoccumentoTipo1;
      this.despesaSend.execDocumento1 = depesaParceria.execDocumento1;
      this.despesaSend.execDocumento = depesaParceria.execDocumento;
      this.despesaSend.execDocumentoArquivo = depesaParceria.execDocumentoArquivo;
      this.despesaSend.execDocumentoData = depesaParceria.execDocumentoData;
      if (this.despesaSend.execDocumentoData != null)
        this.despesaSend.execDocumentoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(
          this.dateConverter.fromDateToTimestamp(this.despesaSend.execDocumentoData));
      this.despesaSend.execDocumentoTipo = depesaParceria.execDocumentoTipo;
      this.despesaSend.execNatureza = depesaParceria.execNatureza;
      this.despesaSend.execNomeCredor = depesaParceria.execNomeCredor;
      this.despesaSend.execNroCpfcnpj = depesaParceria.execNroCpfcnpj;
      this.despesaSend.execNroDoc = depesaParceria.execNroDoc;
      this.despesaSend.execOperacao = depesaParceria.execOperacao;
      this.despesaSend.execTpCpfcnpj = depesaParceria.execTpCpfcnpj;
      this.despesaSend.execTpDoc = depesaParceria.execTpDoc;
      this.despesaSend.execValorDoc = depesaParceria.execValorDoc;
      this.despesaSend.execConferir = depesaParceria.execConferir;
      this.despesaSend.execDocumentoArq1 = depesaParceria.execDocumentoArq1;
      this.showUpload1 = depesaParceria.execDocumentoArq1 ? false : true;
      if (this.despesaSend.execDocumentoArquivo != null) {
        this.despesaSend.showUpload = false;
      }
      else {
        this.despesaSend.showUpload = true;
      }
      if (this.despesaSend.execDocumentoArq1 != null) {
        this.despesaSend.showUpload1 = false;
      }
      else {
        this.despesaSend.showUpload1 = true;
      }
      this.despesaSend.operacaoData = new Date();
      if (!this.isPortal) {
        this.despesaSend.operacaoUsuario = this.username;
      }
      this.despesaSend.dataPagamento = depesaParceria.dataPagamento;
      if (this.despesaSend.dataPagamento != null)
        this.despesaSend.dataPagamentoFormat = depesaParceria.dataPagamento != null ? this.dateConverter.fromTimestampToNgbDateStruct(
          this.dateConverter.fromDateToTimestamp(this.despesaSend.dataPagamento)) : undefined;
      this.despesaSend.valorTotalDocumento = depesaParceria.valorTotalDocumento;
      this.despesaSend.chaveAcessoNFE = depesaParceria.chaveAcessoNFE;
      this.despesaSend.execMotivo = depesaParceria.execMotivo;
      this.despesaSend.execDevolucao = depesaParceria.execDevolucao;
      this.despesaSend.execInscricaoMunicipal = depesaParceria.execInscricaoMunicipal;
      this.despesaSend.descricaoServico = depesaParceria.descricaoServico;
      this.despesaSend.statusCancelamento = depesaParceria.statusCancelamento;
      this.despesaSend.tipoDeServico = depesaParceria.tipoDeServico;
      if (this.despesaSend.tipoDeServico == 1) {
        this.getPrestadores(this.parceriaInfo.parOrgCnpj, 1);
        this.servPrestadoPfSelecionado = depesaParceria.prestadorServTer + "_" + depesaParceria.servPrestadoPf;
        this.despesaSend.servPrestadoPf = depesaParceria.servPrestadoPf;
        this.despesaSend.prestadorServTer = depesaParceria.prestadorServTer;
      } else if (this.despesaSend.tipoDeServico == 2) {
        this.getPrestadores(this.parceriaInfo.parOrgCnpj, 2);
        this.contratoPjSelecionado = depesaParceria.prestadorServTer + "_" + depesaParceria.contratoPj;
        this.despesaSend.prestadorServTer = depesaParceria.prestadorServTer;
        this.despesaSend.contratoPj = depesaParceria.contratoPj;
        this.despesaSend.valorMensalVigenteContrato = depesaParceria.valorMensalVigenteContrato;
        this.valorDiferenca = this.despesaSend.valorTotalDocumento - this.despesaSend.valorMensalVigenteContrato;
      }

    } else {
      this.despesaSend.showUpload = true;
      this.despesaSend.showUpload1 = true;
      this.showUpload1 = true;
      this.showCamposEdicao = false;
      this.despesaSend.parId = this.parceriaInfo.parId;
      this.despesaSend.execId = -1;
      this.despesaSend.execValorDoc = 0;
      this.despesaSend.execConferir = 1;
      this.despesaSend.valorTotalDocumento = 0;
      this.despesaSend.execDevolucao = 0;
      this.despesaSend.valorMensalVigenteContrato = 0;
      this.contratoPjSelecionado = '';
      this.servPrestadoPfSelecionado = '';
      this.despesaSend.contratoPj = 0;
      this.despesaSend.servPrestadoPf = 0;
      this.despesaSend.prestadorServTer = 0;
      this.arquivo = '';
    }

    const cloneOfObject = JSON.parse(JSON.stringify(this.despesaSend));
    this.cloneOfDespesaSend = cloneOfObject;

  }

  dataEmissao(dataRangeEvent: DatetimeRange) {
    this.despesaSend.execDocumentoData = this.dateConverter.fromNgbDateToDate(dataRangeEvent.fromNgbDate);
  }

  dataPagamento(dataRangeEvent: DatetimeRange) {
    this.despesaSend.dataPagamento = this.dateConverter.fromNgbDateToDate(dataRangeEvent.fromNgbDate);
  }

  getDocs(event) {
    const newVal = event.currentTarget.value;
    this.getFile(this.despesaSend.parId, this.despesaSend.execId, newVal, false);
  }
  async getFile(parId, execId, tipo, isTmp) {
    this.parceriaDespesaDevolApi.getArquivoDes(parId, execId, tipo, this.isPortal, isTmp)
      .subscribe((response) => {
        if (response) {
          //console.log(response);
          if (response.body.size > 0) {
            this.arquivo = URL.createObjectURL(response.body);
          } else {
            this.arquivo = '';
          }

        }
      }, (response) => {
        // if (response.status >= 500) {
        //   this.alertService.danger(response.error);
        // } else {
        //   this.alertService.warning(response.error);
        // }
        this.alertService.danger('Erro ao buscar arquivo de desembolso.');
        this.loading.next(false);
      }, () => this.loading.next(false));
  }

  async openEdicaoDespesa(content, depesaParceria) {
    this.showCamposEdicao = true;
    this.nfse = new NFSe();
    this.nfe = new NFe();
    this.nfe.nfe = new NFeSgp();
    this.setDespesaSend(depesaParceria);
    await this.verifyCaptcha();
    if (this.despesaSend.execId !== -1 && this.despesaSend.execOperacao != 7) {
      if (((this.despesaSend.execOperacao != 5 || this.despesaSend.execTpCpfcnpj == 2)
        && this.isPortal) || !this.isPortal)
        await this.getFile(this.despesaSend.parId, this.despesaSend.execId, 'documento', false);
    }
    if (this.despesaSend.execOperacao == 4 && this.despesaSend.execId != -1) {
      this.consultaNFe();
    }
    if (this.despesaSend.execOperacao == 7 && this.despesaSend.execId != -1) {
      this.consultaNFSe();
    }
    if (this.despesaSend.execOperacao == 16 && this.despesaSend.execId != -1) {
      this.consultaNFSeNacional();
    }

    this.inValidCpfCnpj = false;
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: "modalProcesso" });
  }

  openExcluirDespesa(content, depesaParceria) {
    this.setDespesaSend(depesaParceria);
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
  }

  openExportacao(content, depesasParcerias) {
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: "modalProcesso" });
  }

  openFiltro(content, depesasParcerias) {
    // this.filtro = new FiltroDesembolso();
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: "modalProcesso" });
  }

  replacerDespesa(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['execDocumentoDataFormat', 'dataPagamentoFormat', 'showUpload', "limpa"];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  replacer(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['totalDesembolsos', 'quantidadeTotal'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  replacerFiltro(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['execDocumentoDataFormat', 'dataPagamentoFormat', 'execNomeCredor', 'showUpload'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  async exportAs(type, valor, dtIni, dtFim, filtroDataEmissaoPagamento, tipoDataEmissaoPagamento) {

    let nome;
    nome = 'Dem_Desem_' + this.parceriaInfo.parOrgCnpj;

    if (dtIni === undefined) {
      dtIni = this.pesquisaData.dataInicial;
      dtFim = this.pesquisaData.dataFinal;
    }
    else {
      dtIni = this.dateConverter.fromNgbDateStructToDate(dtIni);
      if (dtFim === undefined) {
        dtFim = new Date();
      }
      else {
        dtFim = this.dateConverter.fromNgbDateStructToDate(dtFim);
      }
    }

    this.filtro.dataInicial = this.datePipe.transform(dtIni, 'dd/MM/yyyy');
    this.filtro.dataFinal = this.datePipe.transform(dtFim, 'dd/MM/yyyy');
    this.filtro.orderBy = filtroDataEmissaoPagamento;
    this.filtro.parId = this.parceriaInfo.parId;
    this.filtro.tipoDataFiltro = tipoDataEmissaoPagamento;
    const filtroD = JSON.stringify(this.filtro, this.replacerFiltro);

    this.parceriaDespesaDevolApi.getDespesaParceriaPeriodo(filtroD, this.isPortal).subscribe(async (response) => {
      if (response) {
        console.log(response);
        const fields = [{
          label: 'Id',
          value: 'execId'
        }, {
          label: 'CPF/CNPJ',
          value: 'execNroCpfcnpj'
        }, {
          label: 'Credor',
          value: 'execNomeCredor'
        }, {
          label: 'Valor Pago',
          value: 'execValorDoc'
        }, {
          label: 'Valor Total',
          value: 'valorTotalDocumento'
        }, {
          label: 'Data Emissão',
          value: 'execCpfmask'
        },
        {
          label: 'Data Pagto',
          value: 'execDocArqOrig1'
        }, {
          label: 'Tipo Doc',
          value: 'execCnpjmask'
        }, {
          label: 'Nº',
          value: 'execNroDoc'
        }, {
          label: 'Natureza',
          value: 'execChave'
        }, {
          label: 'Chave Acesso',
          value: 'chaveAcessoNFE'
        }, {
          label: 'Situação',
          value: 'execConferirStr'
        }, {
          label: 'Motivo',
          value: 'execMotivo'
        }, {
          label: 'Doc',
          value: 'execDocumentoArquivo'
        }, {
          label: 'Doc 2',
          value: 'execDocumentoArq1'
        }];

        const despesa = response as DespesaParceria[];

        despesa.forEach(element => {
          element.execNroCpfcnpj = this.mascaras.aplicaMascaraDocumento(element.execNroCpfcnpj.toString(), element.execTpCpfcnpj);
          element.execNroDoc = element.execNroDoc.trim();
          // adaptação técnica para guardar valor string em campo não exibido na tela, a fim de evitar construir um novo objeto
          element.execConferirStr = this.utils.getExecConferirStr(element.execConferir);
          element.execChave = this.utils.getExecNaturezaStr(element.execNatureza);
          element.execOperacaoStr = this.utils.getExecOperacaoStr(element.execOperacao);
          //element.execValorDoc = Mascaras.aplicaMascaraReais(element.execValorDoc);
          const datePipe = new DatePipe('en-US');
          element.execDocumentoDataStr = datePipe.transform(element.execDocumentoData, 'dd/MM/yyyy');
          element.execDataPagamentoStr = datePipe.transform(element.dataPagamento, 'dd/MM/yyyy');
        });

        const despesaExc = new Array<DespesaParceria>();
        despesa.forEach(element => {
          if (element.execJustificaExclusao == null) {
            despesaExc.push(element);
          }
        });
        if (type === 'xlsx') {
          const newJson = despesaExc.map(rec => {
            return {
              'ID': rec.execId,
              'CPF/CNPJ': rec.execNroCpfcnpj == null?'':rec.execNroCpfcnpj,
              'Nome do Credor': rec.execNomeCredor == null?'':rec.execNomeCredor,
              'Natureza': rec.execChave == null?'':rec.execChave,
              'Data Emissão': rec.execDocumentoDataStr,
              'Valor Total': rec.valorTotalDocumento,
              'Data Pagamento': rec.execDataPagamentoStr,
              'Valor Pago': rec.execValorDoc,
              'Situação': rec.execConferirStr,
              'Chave de Acesso': rec.chaveAcessoNFE == null?'':rec.chaveAcessoNFE,
              'Motivo': rec.execMotivo== null?'':rec.execMotivo,
              'Nome Documento 1': rec.execDocumentoArquivo == null?'': rec.execDocumentoArquivo,
              'Nome Documento 2': rec.execDocumentoArq1== null?'':rec.execDocumentoArq1
            };
          });
          this.excelService.exportAsExcelFile(newJson, nome);
        } else if (type === 'csv') {
          const fields = [{
            label: 'ID',
            value: 'ID'
          }, {
            label: 'CPF/CNPJ',
            value: 'execNroCpfcnpj'
          }, {
            label: 'Credor',
            value: 'execNomeCredor'
          }, {
            label: 'Natureza',
            value: 'execChave'
          }, {
            label: 'Data Emissão',
            value: 'execDocumentoDataStr'
          },
          {
            label: 'Data Pagto',
            value: 'execDataPagamentoStr'
          }, {
            label: 'Tipo Doc',
            value: 'execOperacaoStr'
          }, {
            label: 'Nº',
            value: 'execNroDoc'
          }, {
            label: 'Valor Pago',
            value: 'execValorDoc'
          }, {
            label: 'Valor Total Doc',
            value: 'valorTotalDocumento'
          }, {
            label: 'Chave Acesso',
            value: 'chaveAcessoNFE'
          }, {
            label: 'Situação',
            value: 'execConferirStr'
          }, {
            label: 'Motivo',
            value: 'execMotivo'
          }, {
            label: 'Doc',
            value: 'execDocumentoArquivo'
          }, {
            label: 'Doc 2',
            value: 'execDocumentoArq1'
          }];
          const json2csvParser = new Json2csvParser.Parser({ fields });
          const csv = json2csvParser.parse(despesaExc);
          const blob = new Blob([csv], { type: 'text/csv' });
          saveAs(blob, nome + '.csv');
        }
      }

    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));
  }

  public async generatePDF(dtIni, dtFim, filtroDataEmissaoPagamento, tipoDataEmissaoPagamento) {

    this.loading.next(true);
    let data;
    let pdf = new jspdf('l', 'mm', 'a4'); // A4 size page of PDF
    pdf.setFont('helvetica');
    pdf.setLineWidth(0.5);
    let nome;
    let columns;
    let rows;

    const brasao = new ImagensUrlData().getBrasaoSMF();
    pdf.addImage(brasao, 'JPEG', 5, 4, 33, 13);
    nome = 'DemonstrativoDesembolso_' + this.parceriaInfo.parOrgCnpj + '.pdf';
    pdf.setFontSize(12);
    pdf.setFontType('bold');
    pdf.text(90, 10, 'Demonstrativo de Desembolso ');
    pdf.setFontSize(9);
    pdf.setFontType('normal');

    pdf.text(50, 14, this.parceriaInfo.parOrgNome.concat(' - ')
      .concat(this.mascaras.aplicaMascaraDocumento(this.parceriaInfo.parOrgCnpj.toString(), 2)));
    pdf.text(220, 14, 'Data/Hora: ' + this.datePipe.transform(Date.now(), 'dd/MM/yyyy HH:mm:ss'));

    pdf.text(50, 18, 'Termo: ' + this.parceriaInfo.parNum);

    if (dtIni === undefined) {
      dtIni = this.pesquisaData.dataInicial;
      dtFim = this.pesquisaData.dataFinal;
    }
    else {
      dtIni = this.dateConverter.fromNgbDateStructToDate(dtIni);
      if (dtFim === undefined) {
        dtFim = new Date();
      }
      else {
        dtFim = this.dateConverter.fromNgbDateStructToDate(dtFim);
      }
    }

    pdf.text(100, 18, 'Período: ' + this.datePipe.transform(dtIni, 'dd/MM/yyyy') + '  a ' + this.datePipe.transform(dtFim, 'dd/MM/yyyy'));
    let resp = await this.getColunaLinhasDesembolso(dtIni, dtFim, rows, columns, pdf, filtroDataEmissaoPagamento, nome, tipoDataEmissaoPagamento);
    columns = resp.columns;
    rows = resp.rows;
    this.loading.next(false);
  }

  async getColunaLinhasDesembolso(dtIni: any, dtFim: any, rows: any, columns: any, pdf: any, filtroDataEmissaoPagamento: any, nome: any, tipoDataEmissaoPagamento: any) {
    // tslint:disable-next-line:max-line-length
    this.filtro.dataInicial = this.datePipe.transform(dtIni, 'dd/MM/yyyy');
    this.filtro.dataFinal = this.datePipe.transform(dtFim, 'dd/MM/yyyy');
    this.filtro.orderBy = filtroDataEmissaoPagamento;
    this.filtro.parId = this.parceriaInfo.parId;
    this.filtro.tipoDataFiltro = tipoDataEmissaoPagamento;
    const filtroD = JSON.stringify(this.filtro, this.replacerFiltro);

    this.parceriaDespesaDevolApi.getDespesaParceriaPeriodo(filtroD, this.isPortal).subscribe(async (response) => {
      if (response) {
        //console.log(response);
        // tslint:disable-next-line:max-line-length
        const cols = ['Id', 'CPF/CNPJ', 'Credor', 'Valor Pago', 'Valor Total', 'Data Emissão', 'Data Pagto', 'Tipo Doc', 'Nº', 'Natureza', 'Situação'];
        let despesa = response as DespesaParceria[];
        rows = [];
        despesa.forEach(element => {
          if (element.execJustificaExclusao == null) {
            element.execNroCpfcnpj = this.mascaras.aplicaMascaraDocumento(element.execNroCpfcnpj.toString(), element.execTpCpfcnpj);
            element.execNroDoc = element.execNroDoc.trim();
            // adaptação técnica para guardar valor string em campo não exibido na tela, a fim de evitar construir um novo objeto
            element.execConferirStr = this.utils.getExecConferirStr(element.execConferir);
            element.execChave = this.utils.getExecNaturezaStr(element.execNatureza);
            element.execOperacaoStr = this.utils.getExecOperacaoStr(element.execOperacao);
            let datePipe = new DatePipe('en-US');
            element.execDocumentoDataStr = datePipe.transform(element.execDocumentoData, 'dd/MM/yyyy');
            element.execDataPagamentoStr = datePipe.transform(element.dataPagamento, 'dd/MM/yyyy');
            let temp = [element.execId, element.execNroCpfcnpj, element.execNomeCredor,
            this.mascaras.aplicaMascaraReais(element.execValorDoc),
            this.mascaras.aplicaMascaraReais(element.valorTotalDocumento),
            element.execDocumentoDataStr, element.execDataPagamentoStr, element.execOperacaoStr, element.execNroDoc,
            element.execChave, element.execConferirStr];
            rows.push(temp);
          }
        });

        columns = cols;
        pdf.autoTable(columns, rows, {
          headerStyles: { fillColor: [8, 146, 151] },
          tableWidth: 'wrap',
          margin: { horizontal: 7, vertical: 22 },
          bodyStyles: { valign: 'top' },
          styles: { cellPadding: 0.5, fontSize: 7.5, overflow: 'linebreak', columnWidth: 'wrap' },
          columnStyles: { text: { columnWidth: 'auto' }, 3: { halign: 'right' }, 4: { halign: 'right' }, 8: { halign: 'right' } }
        });
        pdf.save(nome); // Generated PDF
      }
      else {
        this.alertService.warning('Nenhum registro encontrado');
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      }
      else {
        this.alertService.warning(response.error);
      }
    }, () => this.loading.next(false));
    return { rows, columns };
  }

  aplicaMascaraTelefone(telefone, ddd) {
    return this.mascaras.aplicaMascaraTelefone(telefone, ddd);
  }
  aplicaMascaraReais(valor) {
    if (valor != undefined || valor != null) {
      return this.mascaras.aplicaMascaraReais(valor);
    }
    else return '0,00';
  }

  aplicaMascaraCep(valor) {
    return this.mascaras.aplicaMascaraCep(valor);
  }
  aplicaMascaraUnidade(valor) {
    return this.mascaras.aplicaMascaraUnidade(valor);
  }


  aplicaMascaraCNPJ(doc) {
    if (doc != null && doc != undefined) {
      return this.mascaras.aplicaMascaraDocumento(doc.toString(), 2);
    } else return '';
  }

  aplicaMascaraCPF(doc) {
    if (doc != null && doc != undefined) {
      return this.mascaras.aplicaMascaraDocumento(doc.toString(), 1);
    } else return '';
  }

  consultaNFeNFSe() {
    if (this.despesaSend.execOperacao == 4) {
      this.consultaNFe();
    } else if (this.despesaSend.execOperacao == 16) {
      this.consultaNFSeNacional();
    }
  }

  preencheCamposNFSe(nfse) {
    this.nfse = nfse;
    this.despesaSend.execNroCpfcnpj = nfse.codCnpj;
    this.despesaSend.execTpCpfcnpj = 2;
    this.despesaSend.valorTotalDocumento = nfse.valServ;
    this.despesaSend.descricaoServico = nfse.desDiscServ;
    this.despesaSend.execNomeCredor = nfse.nomEmpr;

    var dataEmissao = new Date(nfse.datEmisNfse);
    this.despesaSend.execDocumentoData = dataEmissao;
    this.despesaSend.execDocumentoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(this.dateConverter.fromDateToTimestamp(dataEmissao));
    var strStatusCanc: string;
    strStatusCanc = null;
    if (nfse.opcStatNfse == 'CANCELADA') {
      // strStatusCanc = "Esta NFS-e foi cancelada em " + new Date(nfse.ncDatCanc).toLocaleString() + ". Motivo: ";
      // switch (nfse.ncIdnMotiCanc) {
      //   case 1: strStatusCanc += "Erro na emissão";
      //     break;
      //   case 2: strStatusCanc += "Serviço não concluído";
      //     break;
      //   case 3: strStatusCanc += "Erro na assinatura";
      //     break;
      // }
      strStatusCanc = "Esta NFS-e foi cancelada.";
    }
    this.despesaSend.statusCancelamento = strStatusCanc;

    if (nfse.nomEmpr == '(NÃO INFORMADO)') {
      this.alertService.warning("Nome correspondente ao CNPJ " + nfse.codCnpj + " não foi encontrado na NFS-e nem no cadastro de pessoas.");
    }
  }

  async consultaNFSe() {
    this.despesaSend.statusCancelamento = null;
    if (this.despesaSend.execOperacao == 7 &&
      !this.isBlank(this.despesaSend.execNroDoc) &&
      !this.isBlank(this.despesaSend.execInscricaoMunicipal)) {
      this.loading.next(true);
      if (this.despesaSend.execNroDoc.trim().match(/^\d{4}\/\d{1,11}$/)) {
        let numero = this.despesaSend.execNroDoc.trim().split('/');
        this.parceriaDespesaDevolApi.consultaNFSe(this.despesaSend.execInscricaoMunicipal, numero[0] + numero[1].padStart(11, '0'), this.token, this.isPortal).subscribe(async (response) => {
          if (response) {
            this.preencheCamposNFSe(response);
          } else {
            this.alertService.warning('Nenhuma NFS-e encontrada com a IM e número informados');
            this.despesaSend.execNroDoc = '';
          }
          this.loading.next(false);
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        });
      }
      else {
        this.nfse = new NFSe();
        this.alertService.warning('Número da nota não atende ao padrão: Ano/Número.');
        this.loading.next(false);
      }
    }
  }

  async consultaNFSeNacional() {
    this.despesaSend.statusCancelamento = null;
    if (this.despesaSend.execOperacao == 16 && !this.isBlank(this.despesaSend.chaveAcessoNFE)) {
      this.loading.next(true);
      this.parceriaDespesaDevolApi.consultaNFSeNacional(this.despesaSend.chaveAcessoNFE, this.token, this.isPortal).subscribe(async (response) => {
        if (response) {
          this.preencheCamposNFSe(response);
          this.despesaSend.execNroDoc = response.codAnoNume;
        } else {
          this.alertService.warning('Nenhuma NFS-e encontrada com a chave de acesso informada');
          this.despesaSend.execNroDoc = '';
        }
        this.loading.next(false);
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      });
    }
  }

  consultaNFe() {
    this.despesaSend.statusCancelamento = null;
    if (this.despesaSend.execOperacao == 4 &&
      !this.isBlank(this.despesaSend.chaveAcessoNFE)) {
      this.loading.next(true);
      let cpf = this.parceriaInfo.parOrgCpfDirigente != null ? this.parceriaInfo.parOrgCpfDirigente : '01234567890';
      this.parceriaDespesaDevolApi.consultaNFe(this.despesaSend.chaveAcessoNFE, cpf, this.token, this.isPortal).subscribe(async (response) => {
        if (response) {
          this.nfe = response as NFe;
          if (this.nfe.nfe == null) {
            if (!this.isFiscal && !this.isSMTC && !this.isPortal && !this.isConsulta) {
              this.despesaSend.execNroCpfcnpj = 0;
              this.despesaSend.execTpCpfcnpj = 2;
              this.despesaSend.execNroDoc = '';
              this.despesaSend.valorTotalDocumento = 0;
              this.despesaSend.execNomeCredor = '';
            }
            this.nfe.situacao = null;
            this.nfe.nfe = new NFeSgp();
            this.alertService.danger(this.nfe.motivo);
          } else {
            this.despesaSend.execNroCpfcnpj = parseInt(this.nfe.nfe.emitenteNroDocumento);
            this.despesaSend.execTpCpfcnpj = 2;
            this.despesaSend.execNroDoc = this.nfe.nfe.numero;
            this.despesaSend.valorTotalDocumento = parseFloat(this.nfe.nfe.vlrTotalNota);
            this.despesaSend.execNomeCredor = this.nfe.nfe.emitenteNomeRazaoSocial;
            var dateParts = this.nfe.nfe.dataEmissao.split("/");
            var dataEmissao = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
            this.despesaSend.execDocumentoDataFormat = this.dateConverter.fromTimestampToNgbDateStruct(this.dateConverter.fromDateToTimestamp(dataEmissao));
            var strStatusCanc: string;
            if (response.situacao == 'Cancelada') {
              strStatusCanc = "Esta NF-e foi cancelada. Motivo: " + this.nfe.motivo;
              this.alertService.warning(strStatusCanc);
            }
            this.despesaSend.statusCancelamento = strStatusCanc;
          }
        } else {
          this.alertService.warning('Nenhuma NF-e encontrada com a chave informada');
          this.despesaSend.chaveAcessoNFE = '';
        }
        this.loading.next(false);
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      });
    }
  }

  async verifyCaptcha() {
    this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
      //console.log('This is your token: ', token);
      this.token = token;
      // if (this.despesaSend.execId !== -1 && this.despesaSend.execOperacao == 7) {
      //   this.consultaNFSe();
      //   }
      // if (this.despesaSend.execId !== -1 && this.despesaSend.execOperacao == 4) {
      //     this.consultaNFe();
      // }
    });
  }

  aplicaFiltro() {
    if (this.filtro.execDocumentoDataFormat != null) {
      this.filtro.execDocumentoData = this.dateConverter.fromNgbDateStructToDate(this.filtro.execDocumentoDataFormat);
      if (!this.utils.isValidDate(this.filtro.execDocumentoData)) {
        this.alertService.danger('Data Emissão Inválida.');
        return;
      }
    }
    else {
      this.filtro.execDocumentoData = null;
    }
    if (this.filtro.dataPagamentoFormat != null) {
      this.filtro.dataPagamento = this.dateConverter.fromNgbDateStructToDate(this.filtro.dataPagamentoFormat);
      if (!this.utils.isValidDate(this.filtro.dataPagamento)) {
        this.alertService.danger('Data Pagamento Inválida.');
        return;
      }
    }
    else {
      this.filtro.dataPagamento = null;
    }
    this.getDespesaParceria(this.filtro);
  }

  consultaPagina(event) {
    let quociente = this.depesaParceria.length / this.config.itemsPerPage;
    let resto = this.depesaParceria.length % this.config.itemsPerPage;

    if (resto > 0) {
      quociente = quociente + 1;
    }
    this.pagina = event.currentTarget.value;
    if (this.pagina <= (quociente))
      this.config.currentPage = this.pagina;
  }
  consultaPaginaC(event) {
    let quociente = this.depesaParceriaColab.length / this.configColab.itemsPerPage;
    let resto = this.depesaParceriaColab.length % this.configColab.itemsPerPage;

    if (resto > 0) {
      quociente = quociente + 1;
    }
    this.paginaC = event.currentTarget.value;
    if (this.paginaC <= (quociente))
      this.configColab.currentPage = this.paginaC;

  }

  updateConferir(event) {
    if (event !== undefined) {
      this.filtro.execConferir = event.id;
    } else {
      this.filtro.execConferir = null;
    }
  }

  updateOperacaoFiltro(event) {
    if (event !== undefined) {
      this.filtro.execOperacao = event.id;
    } else {
      this.filtro.execOperacao = 0;
    }
  }

  updateNaturezaFiltro(event) {
    if (event !== undefined) {
      this.filtro.execNatureza = event.id;
    } else {
      this.filtro.execNatureza = 0;
    }
  }
  verificaExibicaoAnexo(despesa) {
    if (despesa.execOperacao === 5 && this.isPortal && despesa.execTpCpfcnpj === 1) {
      return false;
    }
    // else if (despesa.execOperacao == 5 && this.isPortal  && despesa.execTpCpfcnpj == 2 &&
    //   despesa.execNatureza == 3){
    //     return false;
    // }
    else {
      return true;
    }

  }

  openImportacaoArqDespPgtoPess(content) {
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: "modalProcesso" });
  }

  importaArquivo(event) {
    const fileList: FileList = event.currentTarget.files;
    //console.log(fileList);
    this.loading.next(true);
    const reader = new FileReader();
    if (fileList.length > 0) {
      const array = fileList[0].type.split('/');
      const fileSize = fileList[0].size / 1024 / 1024; // in MB
      if (fileSize > 10) {
        this.alertService.danger('Tamanho de arquivo superior ao limite de 10 MB. Esse arquivo possui ' + fileSize.toPrecision(2) + " MB");
        return;
      }
      if (array[1].endsWith('ms-excel') || array[1].endsWith('sheet')) {

        reader.readAsDataURL(fileList[0]);
        const despesaArquivo = new ParExecucaoExecucaoSendDTO();
        reader.onload = () => {

          despesaArquivo.parId = this.parceriaInfo.parId;
          despesaArquivo.execId = -1;
          despesaArquivo.operacaoUsuario = this.username;
          despesaArquivo.execDocumento = (reader.result).toString().split(',')[1];
          despesaArquivo.execDocumentoTipo = array[1];
          despesaArquivo.execDocumentoArquivo = fileList[0].name;
          despesaArquivo.execConferir = 99;
          this.nomeArquivoImportado = fileList[0].name;
          this.detalheParceriaApi.insereArquivoDespesaPagtoPess(despesaArquivo).subscribe(async (response) => {
            if (response) {
              this.logErroArquivo = response as Array<string>;
              if (this.logErroArquivo.length == 0) {
                this.alertService.info('Arquivo importado com sucesso, verifique em Colaboradores Cadastrados');
                this.atualizaTela();
              } else {

                this.openImportacaoArqDespPgtoPess(this.contentImport);
              }

            } else {
              this.alertService.warning('Erro ao importar o arquivo, tente novamente.');
              this.despesaSend.chaveAcessoNFE = '';
            }

            this.loading.next(false);
          }, (response) => {
            if (response.status >= 500) {
              this.alertService.danger(response.error);
            } else {
              this.alertService.warning(response.error);
            }
            this.loading.next(false);
          });

        };

      } else {
        event.currentTarget.value = '';
        this.loading.next(false);
        this.alertService.warning('Formato de arquivo não suportado!');
      }
    }
  }

  async atualizaTela() {
    await this.getParceriaInfo(this.parceriaInfo.parId);
    const myDate = new Date(new Date().getFullYear(), (Number(new Date().getMonth())), 1, 0, 0, 0);
    //    console.log(myDate);
    if (this.pesquisaData === undefined || this.pesquisaData.dataInicial === undefined) {
      this.pesquisaData = new PesquisaData();
      this.pesquisaData.dataInicial = myDate;
      this.pesquisaData.dataFinal = new Date();
    }
    this.filtro.dataInicial = this.datePipe.transform(this.pesquisaData.dataInicial, 'dd/MM/yyyy');
    this.filtro.dataFinal = this.datePipe.transform(this.pesquisaData.dataFinal, 'dd/MM/yyyy');
    this.filtro.orderBy = this.tipo;
    this.filtro.parId = this.parceriaInfo.parId;
    this.getDespesaParceria(this.filtro);
  }

  public onPageChange(mf) {
    //  this.rowsOnPage = event.rowsOnPage;
    this.pageBucket = (mf.activePage - 1) * mf.rowsOnPage;

  }

  async generateLogDeErroPDF() {

    this.loading.next(true);
    let data;
    let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF
    pdf.setFont('helvetica');
    pdf.setLineWidth(0.5);
    let nome;
    let columns;
    let rows;

    const brasao = new ImagensUrlData().getBrasaoSMF();
    pdf.addImage(brasao, 'JPEG', 5, 4, 33, 13);
    nome = 'LogInconsistencia_' + this.parceriaInfo.parOrgCnpj + '.pdf';
    pdf.setFontSize(12);
    pdf.setFontType('bold');
    pdf.text(50, 10, 'Log de Inconsistências do Arquivo: ' + this.nomeArquivoImportado);
    pdf.setFontSize(9);
    pdf.setFontType('normal');

    pdf.text(50, 14, this.parceriaInfo.parOrgNome.concat(' - ')
      .concat(this.mascaras.aplicaMascaraDocumento(this.parceriaInfo.parOrgCnpj.toString(), 2)));
    pdf.text(220, 14, 'Data/Hora: ' + this.datePipe.transform(Date.now(), 'dd/MM/yyyy HH:mm:ss'));

    pdf.text(50, 18, 'Termo: ' + this.parceriaInfo.parNum);

    const cols = ['#', 'Descrição Erro'];

    rows = [];
    let i = 1;
    this.logErroArquivo.forEach(element => {
      let temp = [i.toString(), element];
      rows.push(temp);
      i++;
    });

    columns = cols;
    pdf.autoTable(columns, rows, {
      headerStyles: { fillColor: [8, 146, 151] },
      tableWidth: 'wrap',
      margin: { horizontal: 7, vertical: 22 },
      bodyStyles: { valign: 'top' },
      styles: { cellPadding: 0.5, fontSize: 10, overflow: 'linebreak', columnWidth: 'wrap' },
      columnStyles: { text: { columnWidth: 'auto' }, 3: { halign: 'right' }, 4: { halign: 'right' }, 8: { halign: 'right' } }
    });

    this.loading.next(false);
    pdf.save(nome); // Generated PDF

    this.atualizaTela();
  }

  downloadModeloExcell() {

    const newJson = {
      'Nome': '',
      'CPF': '',
      'Data Emissão (dd/mm/aaaa)': '',
      'Valor Total sem "R$" (Ex.: 1000,00)': '',
      'Data Pagamento (dd/mm/aaaa)': '',
      'Valor Pago sem "R$" (Ex.: 1000,00)': '',
    };

    const row = new Array<any>();
    // row.push(newJson); 

    this.detalheParceriaApi.getListaColaboradoAtivoNaOsc(this.parceriaInfo.parId).subscribe(async (response) => {
      if (response) {
        let colaboradorL = response as Colaborador[];
        const newJson = colaboradorL.map(element => {
          return {
            'Nome': element.colaboradorNome,
            'CPF': this.aplicaMascaraCPF(element.colaboradorCpf),
            'Data Emissão (dd/mm/aaaa)': '',
            'Valor Total sem "R$" (Ex.: 1000,00)': '',
            'Data Pagamento (dd/mm/aaaa)': '',
            'Valor Pago sem "R$" (Ex.: 1000,00)': ''
          }
        });
        this.excelService.exportAsExcelFile(newJson, "ModeloUploadPessoaFisica");
      }
      else {
        this.alertService.warning('Nenhum registro encontrado');
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      }
      else {
        this.alertService.warning(response.error);
      }
    }, () => this.loading.next(false));
    return {};

  }

  async getPrestadores(cnpjOSC, tipo) {
    this.loading.next(true);
    this.detalheParceriaApi.getPrestadoresServTerPorOsc(cnpjOSC, true, this.despesaSend.tipoDeServico).subscribe((response) => {
      if (response) {
        this.prestadores = response as Array<PrestadorServTer>;
        
        this.prestadorOption = [{ id: '', name: '' }];
        this.prestadores.forEach(element => {

          let nameOption = "";
          if (element.isCPF && tipo == 1) {
            element.servicosPrestadosPf.forEach(elementServ => {
              nameOption = this.mascaras.aplicaMascaraDocumento(element.empresaCnpjCpf.toString(), 1) + " - " + this.datePipe.transform(elementServ.inicioExecucao, 'dd/MM/yyyy');
              if (elementServ.fimExecucao != null) {
                nameOption = nameOption + " a " + this.datePipe.transform(elementServ.fimExecucao, 'dd/MM/yyyy')
              }
              this.prestadorOption.push({
                id: element.id + "_" + elementServ.id,
                name: nameOption + " - " + elementServ.descricaoServico,
                valorB: elementServ.valorBrutoServico,
                valorL: elementServ.valorLiquido
              });
            });
          } else if (!element.isCPF && tipo == 2) {
            element.contratosPj.forEach(elementServ => {
              nameOption = this.mascaras.aplicaMascaraDocumento(element.empresaCnpjCpf.toString(), 2) + " - " + elementServ.numeroContrato;

              this.prestadorOption.push({
                id: element.id + "_" + elementServ.id,
                name: nameOption + " - " + elementServ.tipoDeServico
              });
            });

          }
        });

      } else {
        this.alertService.warning('Nenhum registro encontrado');
      }
      this.loading.next(false);
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));
  }

  updateRPCI(event) {
    const newVal = event.currentTarget.value.split('_').slice(0);
    let cpf = this.prestadores.find(prest => prest.id == newVal[0]).empresaCnpjCpf;
    this.despesaSend.execNroCpfcnpj = Number(cpf);
    this.despesaSend.execOperacao = 5;
    let  servicos = this.prestadores.find(prest => prest.id == newVal[0]).servicosPrestadosPf;
     
    this.despesaSend.valorTotalDocumento = servicos.find(serv => serv.id == newVal[1]).valorBrutoServico;
    this.despesaSend.execValorDoc = servicos.find(serv => serv.id == newVal[1]).valorLiquido;
    this.despesaSend.prestadorServTer = newVal[0];
    this.despesaSend.servPrestadoPf = newVal[1];
    this.contratoPjSelecionado = '';
    this.despesaSend.contratoPj = 0;
    this.servPrestadoPfSelecionado = event.currentTarget.value;

    this.despesaSend.execNomeCredor =  this.prestadores.find(prest => prest.id == newVal[0]).empresaRazaoSocial;
    this.despesaSend.execTpCpfcnpj = 1;
    //this.buscaPessoa(cpf);
  }

  updateContrato(event) {
    const newVal = event.currentTarget.value.split('_').slice(0);
    this.despesaSend.execNroCpfcnpj = Number(this.prestadores.find(prest => prest.id == newVal[0]).empresaCnpjCpf);
    this.despesaSend.execOperacao = 0;
    this.despesaSend.valorMensalVigenteContrato = this.prestadores.find(prest => prest.id == newVal[0]).contratosPj.find(contrato => contrato.id == newVal[1]).valorMensalVigente;
    this.valorDiferenca = this.despesaSend.valorTotalDocumento - this.despesaSend.valorMensalVigenteContrato;
    this.despesaSend.contratoPj = newVal[1];
    this.despesaSend.prestadorServTer = newVal[0];
    this.servPrestadoPfSelecionado = '';
    this.despesaSend.servPrestadoPf = 0;
    this.contratoPjSelecionado = event.currentTarget.value;
    this.despesaSend.execNomeCredor =  this.prestadores.find(prest => prest.id == newVal[0]).empresaRazaoSocial;
    this.despesaSend.execTpCpfcnpj = 2;
    
  }
  updateDiferenca() {
    this.valorDiferenca = this.despesaSend.valorTotalDocumento - this.despesaSend.valorMensalVigenteContrato;
  }

  async getColaboradoresAtivosNaOsc() {
    let hoje = new Date();
    const ano = hoje.getFullYear();
    const mes = hoje.getMonth() + 1;

    // this.detalheParceriaApi.getListaColaboradoAtivoNaOsc(this.parceriaInfo.parId).subscribe(async (response) => {
    // Alterado para que a lista de colaboradores possa ser mostrada de acordo com a parte do nome do colaborador digitado.
     this.detalheParceriaApi.getListaColaboradoAtivoNaOscPorNome(this.parceriaInfo.parId,this.colaboradorNome).subscribe(async (response) => {

      if (response) {
        this.colaboradores = response as Array<Colaborador>;

        if (this.depesaParceriaColabTemp.length == 0) {
          this.colaboradores.forEach(element => {
            let findItemDesp = -1;
            findItemDesp = this.depesaParceria.findIndex(item => item.colaborador && Number(item.execNroCpfcnpj.replace(/[^\d]+/g, '')) == Number(element.colaboradorCpf.replace(/[^\d]+/g, '')));
            if (findItemDesp === -1) {
              this.setDespesaSend(undefined);
              this.despesaSend.colaborador = true;
              this.despesaSend.execNroCpfcnpj = Number(element.colaboradorCpf.replace(/[^\d]+/g, ''));
              this.despesaSend.execNomeCredor = element.colaboradorNome;
              this.despesaSend.execNatureza = 2;
              this.despesaSend.execOperacao = 5;
              this.despesaSend.execTpCpfcnpj = 1;
              this.despesaSend.showUpload = true;
              this.despesaSend.showUpload1 = true;
              this.depesaParceriaColabTemp.push(this.despesaSend);
            }
          });
          this.depesaParceriaColab = this.depesaParceriaColabTemp.sort((a, b) => a.execNomeCredor.localeCompare(b.execNomeCredor));
        }
        else {

          this.colaboradores.forEach(element => {

            let updateItem = -1;
            let findItemDesp = -1;

            updateItem = this.depesaParceriaColabTemp.findIndex(item => item.execNroCpfcnpj == Number(element.colaboradorCpf.replace(/[^\d]+/g, '')));
            findItemDesp = this.depesaParceria.findIndex(item => item.colaborador && Number(item.execNroCpfcnpj.replace(/[^\d]+/g, '')) == Number(element.colaboradorCpf.replace(/[^\d]+/g, '')));
            if (findItemDesp >= 0 && (this.depesaParceria[findItemDesp].execDocumentoData != null
              && this.depesaParceria[findItemDesp].execDocumentoData != undefined)) {
              let execDocumentoData = new Date(this.depesaParceria[findItemDesp].execDocumentoData);
              const anoColab = execDocumentoData.getFullYear();
              const mesColab = execDocumentoData.getMonth() + 1;
              if (!(anoColab == ano && mesColab == mes)) {
                findItemDesp = -1;
              }
            }

            if (updateItem === -1 && findItemDesp === -1) {
              this.setDespesaSend(undefined);
              this.despesaSend.colaborador = true;
              this.despesaSend.execNroCpfcnpj = Number(element.colaboradorCpf.replace(/[^\d]+/g, ''));
              this.despesaSend.execNomeCredor = element.colaboradorNome;
              this.despesaSend.execNatureza = 2;
              this.despesaSend.execOperacao = 5;
              this.despesaSend.execTpCpfcnpj = 1;
              this.despesaSend.showUpload = true;
              this.despesaSend.showUpload1 = true;
              this.depesaParceriaColabTemp.push(this.despesaSend);
            }
            if (findItemDesp >= 0 && updateItem >= 0) {
              // tem na despesa remove da lista de colaboradores
              this.depesaParceriaColabTemp.splice(updateItem, 1);
            }

            
          });
          this.depesaParceriaColab = this.depesaParceriaColabTemp.sort((a, b) => a.execNomeCredor.localeCompare(b.execNomeCredor));
          
          
        }
        // Busca elemento do filtro. Se não tem no filtro, remove da lista de colaboradores apresentada na tela
        if (this.colaboradorNome && this.colaboradorNome.trim() !== "") {
          const indicesParaManter: Set<number> = new Set();
        
          this.colaboradores.forEach(element2 => {
            const filtroItem = this.depesaParceriaColab.findIndex(
              item2 => item2.execNroCpfcnpj == Number(element2.colaboradorCpf.replace(/[^\d]+/g, ''))
            );
        
            if (filtroItem >= 0) {
              indicesParaManter.add(filtroItem); // Marca o índice que deve ser mantido
            }
          });
        
          // Atualiza o array, mantendo apenas os itens nos índices marcados
          this.depesaParceriaColab = this.depesaParceriaColab.filter((_, index) => indicesParaManter.has(index));
        }


      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));
  }


  validaDepesaParceriaColab(rascunho: boolean) {

    let linhaPreenchida: boolean;
    let temProblema: boolean;
    linhaPreenchida = true;
    temProblema = false;
    let tudoPreenchido: boolean;
    tudoPreenchido = true;

    if (!this.isFiscal) {
      this.loading.next(true);
      this.depesaParceriaColabSalvar = this.depesaParceriaColab.slice();

      if (!rascunho) {

        this.depesaParceriaColab.forEach(element => {
          linhaPreenchida = true;
          temProblema = false;
          if (element.execDocumentoDataFormat == null && element.dataPagamentoFormat != null) {
            this.depesaParceriaColabSalvar.splice(this.depesaParceriaColabSalvar.indexOf(element), 1);
            linhaPreenchida = false;
          }
          if (linhaPreenchida && (element.execDocumentoDataFormat != null && element.dataPagamentoFormat == null)) {
            this.depesaParceriaColabSalvar.splice(this.depesaParceriaColabSalvar.indexOf(element), 1);
            linhaPreenchida = false;
          }
          if (linhaPreenchida && (element.execValorDoc === 0 || element.valorTotalDocumento === 0)) {
            this.depesaParceriaColabSalvar.splice(this.depesaParceriaColabSalvar.indexOf(element), 1);
            linhaPreenchida = false;
          }
          if (linhaPreenchida && (element.execDocumentoArquivo != null && element.execDocumentoArquivo.length > 50)) {
            this.depesaParceriaColabSalvar.splice(this.depesaParceriaColabSalvar.indexOf(element), 1);
            this.alertService.danger('Nome do arquivo superior a 50 caracteres, por favor renomeie antes de fazer upload.');
            temProblema = true;
            return;
          }
          if (linhaPreenchida && (element.execDocumentoDataFormat != null && element.dataPagamentoFormat != null)) {

            element.execDocumentoData = this.dateConverter.fromNgbDateStructToDate(element.execDocumentoDataFormat);
            element.dataPagamento = this.dateConverter.fromNgbDateStructToDate(element.dataPagamentoFormat);

            if (!this.utils.isValidDate(element.execDocumentoData)) {
              this.depesaParceriaColabSalvar.splice(this.depesaParceriaColabSalvar.indexOf(element), 1);
              this.alertService.danger('Verifique a(s) data(s) de emissão, data(s) inválida(s).');
              temProblema = true;
              return;
            }
            else if (!this.utils.isValidDate(element.dataPagamento)) {
              this.depesaParceriaColabSalvar.splice(this.depesaParceriaColabSalvar.indexOf(element), 1);
              this.alertService.danger('Verifique a(s) data(s) de pagamento, data(s) inválida(s).');
              temProblema = true;
              return;
            }
          }
          if ((!temProblema && linhaPreenchida) && (element.execDocumentoArquivo == null || element.execDocumentoArquivo == undefined)) {
            this.depesaParceriaColabSalvar.splice(this.depesaParceriaColabSalvar.indexOf(element), 1);
            linhaPreenchida = false;
          }

          if (!linhaPreenchida && tudoPreenchido) {
            tudoPreenchido = false;
          }
          if (temProblema) {
            return;
          }
        });

        if (temProblema) {
          this.loading.next(false);
          return;
        }
        if (!tudoPreenchido) {
          this.openConfirmaSalvar(this.confirmaSalvaModal);
          this.loading.next(false);

        } else {
          this.salvaDepesaParceriaColab(rascunho);
        }
      } else {

        this.depesaParceriaColab.forEach(element => {

          // if ((element.execDocumentoDataFormat == null && element.dataPagamentoFormat == null)
          //   && (element.execValorDoc === 0 && element.valorTotalDocumento === 0)) {
          //   this.depesaParceriaColabSalvar.splice(this.depesaParceriaColabSalvar.indexOf(element), 1);
          // }
          if (element.execDocumentoDataFormat != null) {
            element.execDocumentoData = this.dateConverter.fromNgbDateStructToDate(element.execDocumentoDataFormat);
            if (!this.utils.isValidDate(element.execDocumentoData)) {
              this.depesaParceriaColabSalvar.splice(this.depesaParceriaColabSalvar.indexOf(element), 1);
              this.alertService.danger('Verifique a(s) data(s) de emissão, data(s) inválida(s).');
              temProblema = true;
              return;
            }
          }
          else{
            element.execDocumentoData = null;
          }
          if (element.dataPagamentoFormat != null) {
            element.dataPagamento = this.dateConverter.fromNgbDateStructToDate(element.dataPagamentoFormat);
            if (!this.utils.isValidDate(element.dataPagamento)) {
              this.depesaParceriaColabSalvar.splice(this.depesaParceriaColabSalvar.indexOf(element), 1);
              this.alertService.danger('Verifique a(s) data(s) de pagamento, data(s) inválida(s).');
              temProblema = true;
              return;
            }
          }
          else{
            element.dataPagamento = null;
          }
          if ((element.execDocumentoArquivo != null && element.execDocumentoArquivo.length > 50)) {
            this.depesaParceriaColabSalvar.splice(this.depesaParceriaColabSalvar.indexOf(element), 1);
            this.alertService.danger('Nome do arquivo superior a 50 caracteres, por favor renomeie antes de fazer upload.');
            temProblema = true;
            return;
          }
          if (temProblema) {
            return;
          }
        });
        if (temProblema) {
          this.loading.next(false);
          return;
        }
        else {
          this.salvaDepesaParceriaColab(rascunho);
        }
      }
    }
  }

  async salvaDepesaParceriaColab(rascunho) {
    let atualizacoes = new Array();
    let sucesso: boolean;
    this.loading.next(true);
    const sleep = (ms) => new Promise(r => setTimeout(r, ms));

    this.depesaParceriaColabSalvar = this.depesaParceriaColabSalvar.sort((a, b) => a.execNomeCredor.localeCompare(b.execNomeCredor));
    let dataList = new Array<DespesaParceriaSend>();

    this.depesaParceriaColabSalvar.map((element) => {
      if (rascunho) {
        element.execConferir = 99;
      }
      else {
        element.execConferir = 1;
        element.execId =-1;
      }
      element.parId = this.parceriaInfo.parId;
      element.colaborador = true;
      element.operacaoData = new Date();
      element.operacaoUsuario = this.username;
      element.execNroCpfcnpj = Number(element.execNroCpfcnpj.toString().replace(/[^\d]+/g, ''));

      let data = JSON.stringify(element, this.replacerDespesa);
      dataList.push(JSON.parse(data) as ParExecucaoExecucaoSendDTO);

    })
    let despesaList = new DespesaListDTO();
    despesaList.despesas = dataList;

     this.detalheParceriaApi.updateDespesaParceriaList(despesaList).toPromise().then((value) => {
      sucesso = true;
      let size = value as any;
      const myDate = new Date(new Date().getFullYear(), (Number(new Date().getMonth())), 1, 0, 0, 0);
      if (this.pesquisaData === undefined || this.pesquisaData.dataInicial === undefined) {
        this.pesquisaData = new PesquisaData();
        this.pesquisaData.dataInicial = myDate;
        this.pesquisaData.dataFinal = new Date();
      }
      this.filtro.dataInicial = this.datePipe.transform(this.pesquisaData.dataInicial, 'dd/MM/yyyy');
      this.filtro.dataFinal = this.datePipe.transform(this.pesquisaData.dataFinal, 'dd/MM/yyyy');
      this.filtro.orderBy = this.tipo;
      this.filtro.parId = this.parceriaInfo.parId;
      this.getDespesaParceria(this.filtro);

      this.loading.next(false);
      this.modalEdicaoReference.close();
      if (size > 0 ) {
        this.alertService.success(size + " colaboradores salvos com sucesso.");
      }
      else {
        this.alertService.danger("Zero colaboradores salvos. Verifique campos obrigatórios ou se a data do(s) <BR> documento(s) está(ão) de acordo com a competência de lançamento da despesa.")
      }
    }
    )
      .catch((error) => {
        console.log(error);
        //this.alertService.danger(error.message) : this.alertService.warning(message);
        return error.status >= 500 ? this.alertService.danger(error.error) : this.alertService.warning(error.error);
      })
      .then(() => this.loading.next(false));

  }

  openConfirmaSalvar(content) {
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false, windowClass: 'modal-confirm' });
  }

  repetirData(tipo) {
    if (tipo === 1) {
      let data = this.depesaParceriaColab[0].execDocumentoDataFormat;
      this.depesaParceriaColab.forEach(item => item.execDocumentoDataFormat = data);
    }
    else if (tipo === 2) {
      let data = this.depesaParceriaColab[0].dataPagamentoFormat;
      this.depesaParceriaColab.forEach(item => item.dataPagamentoFormat = data);
    }
  }

  limpaValores(){
    this.depesaParceriaColab.map((element) => {
      if (element.limpa){
        element.dataPagamento = null;
        element.dataPagamentoFormat = null;
        element.execDocumentoData = null;
        element.execDocumentoDataFormat = null;
        element.execValorDoc = 0;
        element.valorTotalDocumento = 0;
        element.limpa = false;
      }     
    })
    this.selectAll = !this.selectAll;
  }
  selectDespColabAll(){
    this.depesaParceriaColab.map((element) => {
      if (!this.selectAll)
        element.limpa = true;
      else
      element.limpa = false;
    })
  }


  async selectNomeColaboradorAsync() {
    await this.getColaboradoresAtivosNaOsc();  
    }

}
