import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class GestorParceria {

    public parId: number;
    public gestorId: number;
    public gestorDataInicioDesignacao: Date;
    public gestorDataFimDesignacao: Date;
    public gestorNome: string;
    public gestorEmail: string;
    public gestorMatricula: number;
    public gestorDataInicioDesignacaoFormat: NgbDateStruct;
    public gestorDataFimDesignacaoFormat: NgbDateStruct;
    public operacaoData:Date;
    public operador:string;
    public gestorPerfil:number;
    public gestorOrgao:number;
}

export type GestorNome = {
    nome: string
  }
