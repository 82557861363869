import { Cbo } from "../../colaboradores/cbo";

export class PrevisaoEquipe {
    public id: number;
	public planoTrabalhoId: number;
	public cargoFuncao: string;
	public cbo: Cbo;
	public cboId: number;
	public tipoAtividade: number;
	public tipoVinculo: number;
	public outroVinculo: string;
	public escolaridade: number;
	public remuneracaoMensal: number;
	public cargaHorariaSemanal: number;
	public numProfissionais: number;
	public operacaoData: Date;
	public operacaoUsuario: string;
}