import { Component, Input, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { UserService } from 'src/app/@core/user.service';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificacaoSininho } from 'src/app/notificacoes/notificacaoSininho';
import { DetalheParceriaApi } from 'src/app/apis/detalheParceriaApi';
import { AlertService } from '../../../@core/alert.service';
import { AuthUser, KeycloakService } from '@procempa/ngx-keycloak';
import { LogonApi } from 'src/app/apis/logonApi';
import { AuditorLogon } from 'src/app/liberacaoLogon/auditor/auditorLogon';
import { ParceriaInfo } from 'src/app/modulo2/detalheParceria/parceriaInfo';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isLoggedIn: boolean;
  prod: boolean;
  @Output()
  isPortal: boolean;
  isFiscal: boolean;
  isConsulta: boolean;
  @Output()
  isAuditorExterno: boolean;
  isSMTC: boolean;
  childClicked: boolean;
  childAuditoriaClicked: boolean;
  childClickedCad: boolean;
  childClickedExec: boolean;
  childClickedLib: boolean;
  hasTrava: boolean;
  nome: string;
  path: string;
  notificacoesSininho: Array<NotificacaoSininho> = [];
  contNotificacoes: number;
  detalheParceriaApi: DetalheParceriaApi;
  logonApi: LogonApi;
  auditorLogonArray: Array<AuditorLogon> = [];
  private initialization;
  isSaude: boolean;

  constructor(public userService: UserService, private router: Router, location: Location, 
    detalheParceriaApi: DetalheParceriaApi, private alertService: AlertService, public keycloakService: KeycloakService,
    logonApi: LogonApi, private route: ActivatedRoute) {
    userService.userInfo.subscribe(user => this.nome = user.name)
    this.prod = environment.production;
    this.detalheParceriaApi = detalheParceriaApi;
    this.logonApi = logonApi;
    this.activeChildren(false);
    if (!userService.authenticated()) {
      this.isPortal = true;
    } else {

      this.isFiscal = userService.isFiscal();
      this.isConsulta = userService.isConsulta();
      this.isPortal = false;
      this.hasTrava = userService.hasTrava();

      // pegar qualificador
      this.keycloakService.getUser().subscribe((user: AuthUser) => {
        //console.log(`orgao: ${user.attributes.sgp_orgao[0]}`);
        if ((this.isFiscal || this.isConsulta) && user.attributes.sgp_orgao !== null) {
          // tslint:disable-next-line:no-unused-expression
          if (this.userService.isSMTC()) {
            this.isSMTC = true;
          }

        }
        if ((user.attributes.sgp_orgao != undefined || user.attributes.sgp_orgao !== null)
          && Number(user.attributes.sgp_orgao[0]) === 18) {
          this.isSaude = true;
        }
      });

      this.buscaNotificacoes();

      if (!this.isFiscal && !this.isConsulta) {
        this.initialization = this.init();
      } else {
        this.isAuditorExterno = false;
      }
    }
  }

  async init() {
    this.auditorLogonArray = await this.logonApi.getAuditorLogon(this.keycloakService['_keycloakInstance'].tokenParsed.email).toPromise();
    if (this.auditorLogonArray.length > 0) {
      this.isAuditorExterno = true;
    } else {
      this.isAuditorExterno = false;
    }
  }

  buscaNotificacoes() {
    if (this.userService.isFiscal()) {
      this.keycloakService.getUser().subscribe((user: AuthUser) => {
        this.detalheParceriaApi.getNotificacoesSininhoFiscal(Number(user.attributes.sgp_orgao[0])).subscribe((response) => {
          if (response) {
            this.notificacoesSininho = response;
            this.contNotificacoes = this.notificacoesSininho.length;
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
        });
      });
    } else {
      this.detalheParceriaApi.getNotificacoesSininhoOSC(this.keycloakService['_keycloakInstance'].tokenParsed.email).subscribe((response) => {
        if (response) {
          this.notificacoesSininho = response;
          this.contNotificacoes = this.notificacoesSininho.length;
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
      });
    }
  }

  ocultaNotificacao(id) {
    if (this.isFiscal) {
      this.detalheParceriaApi.ocultaNotifOrgao(id).subscribe((response) => {
        this.buscaNotificacoes();
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
      })
    } else {
      this.detalheParceriaApi.ocultaNotifOsc(id).subscribe((response) => {
        this.buscaNotificacoes();
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
      });
    }
  }

  logout() {
    this.userService.logout();
  }

  activeChild(value) {
    if (value) {
      this.activeChildren(false);
    }
    this.childClicked = value;
  }

  activeAuditoriaChild(value) {
    if (value) {
      this.activeChildren(false);
    }
    this.childAuditoriaClicked = value;
  }

  activeChildCad(value) {

    if (value) {
      this.activeChildren(false);
    }

    this.childClickedCad = value;
  }

  activeChildExec(value) {

    if (value) {
      this.activeChildren(false);
    }
    this.childClickedExec = value;
  }

  activeLiberacaoChild(value) {

    if (value) {
      this.activeChildren(false);
    }
    this.childClickedLib = value;
  }

  activeChildren(value) {
    this.activeChild(value);
    this.activeChildCad(value);
    this.activeAuditoriaChild(value);
    this.activeChildExec(value);
    this.activeLiberacaoChild(value);
  }

  openExecucao() {
    var path = (window.location.pathname).split('/')[1]
    var id = new URLSearchParams(window.location.search).get('id');
    if (id != null && path == 'planejamento') {
      this.router.navigate([`execucao/detalheParceriaM2/${id}`], { queryParams: { isAuditorExterno: this.isAuditorExterno } })
    }
    else {
      this.router.navigate([`execucao/exibeParceriaEntidadeM2`], { queryParams: { isAuditorExterno: this.isAuditorExterno } })
    }
  }

  openPlanoTrabalho() {
    var path = (window.location.pathname).split('/')[1]
    var id = new URLSearchParams(window.location.search).get('id');
    if (id != null && path == 'planejamento') {
      this.router.navigate([`execucao/detalheParceriaM2/${id}`], { queryParams: { isAuditorExterno: this.isAuditorExterno } })
    }
    else {
      this.router.navigate([`execucao`], { queryParams: { isAuditorExterno: this.isAuditorExterno, isExecPT: true } })
    }
  }
}
