import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, share } from 'rxjs/operators';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from './../detalheParceria/parceriaInfo';
import { DetalheParceriaPortalApi } from '../../apis/detalheParceriaPortalApi';
import { Mascaras } from '../../@shared/components/Mascaras';
import { UserService } from '../../@core/user.service';
import { Utils } from 'src/app/@shared/utils';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-info-parceria',
  templateUrl: './informacaoParceria.component.html',
  styleUrls: ['./informacaoParceria.component.scss']
})
export class InformacaoParceriaComponent implements OnInit {

  @Input()
  public  parceriaInfo: ParceriaInfo;
  @Input()
  private  isPortal: boolean;
  @Output() valueChange = new EventEmitter();
  @Input()
  private  modulo: string;
  isFiscal: boolean;
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  isAuditorExterno: boolean;
  isExecPT: boolean;
  isPlanPT: boolean;

  constructor(private alertService: AlertService,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    public userService: UserService,
    private mascaras: Mascaras,
    private utils: Utils,
    private route: ActivatedRoute) {

    }

  ngOnInit() {
//    this.setupInterface();
    this.isFiscal = this.userService.isFiscal();
    console.log(this.isFiscal);

    this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
      this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
    });

    this.route.queryParams.pipe(filter(params => params.isExecPT)).subscribe(params => {
      this.isExecPT = (params['isExecPT'] === undefined || params['isExecPT'].toLowerCase() === 'false' ? false : true)  });
    this.route.queryParams.pipe(filter(params => params.isPlanPT)).subscribe(params => {
        this.isPlanPT = (params['isPlanPT'] === undefined || params['isPlanPT'].toLowerCase() === 'false' ? false : true)  });

  }

  getParceriaInfo(id) {
    this.loading.next(true);
    this.detalheParceriaPortalApi.getParceriaInfo(id)
      .subscribe((response) => {
        if (response) {
          this.parceriaInfo = response as ParceriaInfo;
          // console.log(this.valoresLiberados);
        } else {
          this.alertService.warning('Nenhum registro encontrado');
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));

  }
  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }

  aplicaMascaraTelefone(telefone) {
    if (!this.isBlank(telefone))
      return this.mascaras.aplicaMascaraTelefone(telefone, '51');
    else
      return "";
  }

  aplicaMascaraReais(valor) {
    return this.mascaras.aplicaMascaraReais(valor);
  }

  aplicaMascaraCNPJ(doc) {
    return this.mascaras.aplicaMascaraDocumento(doc, 2);
  }

  aplicaMascaraCep(cep) {
    return this.mascaras.aplicaMascaraCep(cep);
  }

  getLabelStatusParceira(cod) {
    return this.utils.getStatusParceira(cod);
   }

  updatePortal(event) {
    const value = event.currentTarget.value;
    this.valueChange.emit({value, tipoPublicaPortal: 'Portal' });
    }

    getModalidadeStr(id) {
      if (id == 1) {
        return 'Atividades Culturais';
      }
      if (id == 2) {
        return 'Equipamentos Culturais';
      }
      if (id == 3) {
        return 'Educação Integral';
      }
      if (id == 4) {
        return 'Educação Infantil';
      }
      if (id == 5) {
        return 'Educação Especial';
      }
      if (id == 6) {
        return 'Educação Básica';
      }
      if (id == 7) {
        return 'PSB-Serviço de Convivência e Fortalecimento de Vínculos – SCFV';
      }
      if (id == 8) {
        return 'PSB-Serviço de Atendimento Familiar - SAF';
      }
      if (id == 9) {
        return 'PSB-Serviço de Proteção Integral à Família – PAIF';
      }
      if (id == 10) {
        return 'PSE-Serviço de Abordagem Social';
      }    
      if (id == 11) {
        return 'PSE-Centro-dia';
      }
      if (id == 12) {
        return 'PSE-Serviço de Proteção e Atendimentos Especializados às Famílias – PAEFI';
      }    
      if (id == 13) {
        return 'PSE-Serviço de Habilitação e Reabilitação';
      }
      if (id == 14) {
        return 'PSE-Serviço de Acolhimento';
      }
      if (id == 15) {
        return 'PSE-Casa Lar';
      }
      if (id == 16) {
        return 'PSE-República';
      }
      if (id == 17) {
        return 'PSE-Albergue';
      }
      if (id == 18) {
        return 'Outra';
      }
      return '';
    }
}

