<div>
  <div [hidden]="!(loading$ | async) ">
        <div class="loading-overlay "></div>
        <div class="loading-info ">
            <i class="fa fa-spinner fa-4x fa-spin "></i>
            <div id="carregando">Aguarde</div>
        </div> 
    </div>


    <div class="container">
        <!-- <div class="planoTrabalho" *ngIf="!this.isPortal && modulo == 'm2'">Monitoramento e Avaliação</div> -->
        <div *ngIf="parceriaInfo != undefined">
            <div [hidden]="verPlano">
                <div *ngIf="monitoramentoAvaliacaoList.length==0">
                    <span class="mensagem-vazio">Nenhum monitoramento foi encontrado.</span>
                </div>
                <div *ngIf="monitoramentoAvaliacaoList.length>0">

                    <table class="table table-striped" style="margin-top:30px" id="tabelaTermos"
                        [mfData]="monitoramentoAvaliacaoList" #mtPlanTrab="mfDataTable" [mfRowsOnPage]="5">

                        <thead>
                            <tr>
                                <th *ngIf="!disableApagarIconeBoolean" data-html2canvas-ignore="true"></th>
                                <th *ngIf="isPortal || isConsulta || isAuditorExterno" data-html2canvas-ignore="true"></th>
                                <th>ID Monitoramento</th>
                                <th>Plano de Trabalho Associado</th>
                                <th>Período Execução</th>
                                <th>Situação</th>
                                <th>Efetivado</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of mtPlanTrab.data ; let j = index;">
                                <td *ngIf="!disableApagarIconeBoolean" class="col-auto" data-html2canvas-ignore="true">
                                    <i ngbTooltip="apagar" *ngIf="data.planoSituacao===1" class="fa fa-trash-o"
                                        aria-hidden="true" (click)="openExcluirPlano(contentExcluirPlano, data)"></i>
                                </td>
                                <td *ngIf="isPortal || isConsulta || isAuditorExterno " class="col-auto" data-html2canvas-ignore="true">
                                    <i ngbTooltip="visualizar"  class="fa fa-search" style="color: #089297;"
                                        aria-hidden="true" (click)="visualizarPlano(data)"></i>
                                </td>
                                <td class="col-auto ">{{data.monitoramentoIdentificador}}
                                </td>
                                <td class="col-auto ">{{data.plano.planoIdentificador}}
                                </td>
                                <td class="col-auto">{{data.plano.planoDataIniExecucao | date: 'dd/MM/yyyy'}} -
                                    {{data.plano.planoDataFimExecucao | date: 'dd/MM/yyyy'}}</td>
                                <td class="col-auto">{{getSituacaoPlanoDeTrabalho(data.monitoramentoSituacao)}}</td>
                                <td class="col-auto">{{data.monitoramentoEfetivacao?'Sim':'Não'}}</td>
                                <td class="col-auto">
                                    <button (click)="visualizarPlano(data)" id="button-insert_doc" style="width: 100%;"
                                        class="btn btn-primary btn-info" type="button " title="Ver Plano"
                                        [hidden]="isPortal">
                                        <i class="fa fa-pencil" style="color: white;"><span class="texto-botao">Editar</span> </i>
                                    </button>
                                    <!-- <button (click)="visualizarPlano(data)" id="button-insert2_doc"
                                        style="padding-left: 10px;" class="btn btn-verAlteracao" type="button "
                                        title="Ver Monitoramento" style="width: 100%;"
                                        [hidden]="data.planoSituacao == 4 && !isFiscal) ">
                                        <i class="fa fa-search" style="color: #fff;"><span class="texto-botao">Ver
                                               Monitoramento</span> </i>
                                    </button> -->
                                </td>
                            </tr>
                        </tbody>
                        <tfoot data-html2canvas-ignore="true">
                            <tr>
                                <td colspan="12 ">
                                    <div *ngIf="monitoramentoAvaliacaoList.length>5" class="resultPorPagina">Resultados por
                                        página:
                                    </div>
                                    <mfBootstrapPaginator [rowsOnPageSet]="[5,10,25]"></mfBootstrapPaginator>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

            <div id="execucaoM" *ngIf="verPlano" style="margin-top: 50px;">
                <h2><a *ngIf="true" ngbTooltip="Voltar à lista de plano" (click)="verPlano=false; monitAvalVisualizado=undefined;">
                    <i class="fa fa-arrow-circle-left fa-lg" aria-hidden="true"></i>
                </a>
                    {{monitAvalVisualizado.monitoramentoIdentificador}}
                </h2>         
                <div style="margin-left: 35px; margin-top: -20px; font-weight: bold;">
                {{monitAvalVisualizado.plano.planoDataIniExecucao | date: 'dd/MM/yyyy' }} -
                {{monitAvalVisualizado.plano.planoDataFimExecucao | date: 'dd/MM/yyyy' }}
                <BR>
                Plano {{getSituacaoPlanoDeTrabalho(monitAvalVisualizado.monitoramentoSituacao) |lowercase}} -
                {{monitAvalVisualizado.plano.planoEfetivacao?'efetivado':'não efetivado'}}
                <BR />
                <BR />
                </div>
                <ngb-accordion #acc="ngbAccordion" (panelChange)="acordiaoChange($event)"  [destroyOnHide]="false">

                    <ngb-panel id="execucao" title="PERÍODO DE EXECUÇÃO">
                        <ng-template ngbPanelContent>
                            <div id="Periodo" style="margin-top: 20px;">
                                <!--<div class="execucao tituloSecao">PERÍODO DE EXECUÇÃO</div>-->

                                <div class="form-row">
                                    <div class="col-md-2">
                                        <label style="padding-top: 5%" for="planoDataIniExecucao">Início da
                                            Execução:</label>
                                        <span class="campoObrigatorio">*</span>
                                    </div>
                                    <div class="col-md-2">
                                        <input [disabled]="true" style="width: 100%" maxlength="10"
                                            class="form-control" name="planoDataIniExecucao" placeholder="dd/mm/aaaa"
                                            appMaskInput [appMaskValue]="monitAvalVisualizado.plano.planoDataIniExecucaoFormat"
                                            #planoDataIniExecucao="ngModel"
                                            [(ngModel)]="monitAvalVisualizado.plano.planoDataIniExecucaoFormat" required
                                            ngbDatepicker #d="ngbDatepicker">
                                        <i class="fa fa-calendar icon" (click)="d.toggle()" title="calendário"></i>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-2">
                                        <label style="padding-top: 5%" for="planoDataFimExecucao">Fim da
                                            Execução:
                                        </label>
                                        <span class="campoObrigatorio">*</span>
                                    </div>
                                    <div class="col-md-2">
                                        <input [disabled]="true" style="width: 100%" maxlength="10"
                                            class="form-control" name="planoDataFimExecucao"
                                            #planoDataFimExecucao="ngModel" placeholder="dd/mm/aaaa" appMaskInput
                                            [appMaskValue]="monitAvalVisualizado.plano.planoDataFimExecucaoFormat"
                                            [(ngModel)]="monitAvalVisualizado.plano.planoDataFimExecucaoFormat" required
                                            ngbDatepicker #df="ngbDatepicker">
                                        <i class="fa fa-calendar icon" (click)="df.toggle()" title="calendário"></i>

                                    </div>
                                </div>
                                <div>
                                    <div class="tituloSecundario">Competências da Execução</div>
                                    <div *ngIf="competenciasLista.length>0">
                                        <table class="table">
                                            <tbody>
                                                <tr *ngFor="let chunk of splitIntoChunks(paginatedCompetenciasLista, 12)"
                                                    [class.no-border]="chunk === splitIntoChunks(competenciasLista, 12)[0]">
                                                    <td *ngFor="let item of chunk"
                                                        [ngClass]="{'bold': item.includes('Mês')}">
                                                        {{ item }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="pagination pagination justify-content-center"
                                            *ngIf="competenciasLista.length > 0">
                                            <i (click)="changePage(config.currentPage - 1)"
                                                [ngStyle]="{ 'pointer-events': config.currentPage === 1 ? 'none' : 'auto' }"
                                                class="fa fa-chevron-circle-left"
                                                style="font-size:36px; padding-right:10px"> </i>

                                            <span> Página {{config.currentPage}} de {{totalPages}} </span>

                                            <i (click)="changePage(config.currentPage + 1)"
                                                [ngStyle]="{ 'pointer-events': config.currentPage === totalPages ? 'none' : 'auto' }"
                                                class="fa fa-chevron-circle-right"
                                                style="font-size:36px; padding-left:10px"> </i>

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </ng-template>
                    </ngb-panel>-

                    <ngb-panel id="meta" title="MONITORAMENTO DAS METAS DO PLANO DE TRABALHO">
                        <ng-template ngbPanelContent>                        
                            <app-parametro-monitoramento [monitoramento]="monitAvalVisualizado" [isPortal]="isPortal" [modulo]="modulo"></app-parametro-monitoramento>
                        </ng-template>
                    </ngb-panel> 

                    <ngb-panel id="evento" title="MONITORAMENTO DE OUTROS EVENTOS">
                        <ng-template ngbPanelContent>                        
                            <app-outros-eventos-monitoramento [monitoramento]="monitAvalVisualizado" [isPortal]="isPortal" [modulo]="modulo"></app-outros-eventos-monitoramento>
                        </ng-template>
                    </ngb-panel>
                  
                </ngb-accordion>

                <br />
                <br />
                <div class="row justify-content-center">
                    <div
                        *ngIf="!disableBotao() && monitAvalVisualizado.monitoramentoIdentificador != undefined && monitAvalVisualizado.monitoramentoIdentificador.startsWith('PM')">
                        <button (click)="salvaPlano()" id="button-insert_doc "
                            class="btn btn-primary btn-info action-btn" type="button " title="Salva Plano"
                            [hidden]="parceriaInfo.parId===-1">
                            <i><span class="texto-botao">Salva Plano</span> </i>
                        </button>
                    </div>

                    <div
                        *ngIf="!disableBotaoSub() && monitAvalVisualizado.monitoramentoIdentificador != undefined && monitAvalVisualizado.monitoramentoIdentificador.startsWith('PM')">

                        <button (click)="submeterPlano()" style="margin-left: 20px;" id="button-sub_doc "
                            class="btn btn-primary btn-info action-btn" type="button " title="Submeter Plano"
                          >
                            <i><span class="texto-botao">Submeter Plano</span> </i>
                        </button>
                    </div>
                </div>
            </div>


        </div>
        

    </div>