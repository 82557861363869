import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, ViewChildren, AfterContentInit, ComponentFactoryResolver, ViewContainerRef, ComponentRef, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DateConverter } from '../../@core/date-converter';
import { elementAt, filter, share, switchMap } from 'rxjs/operators';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from './../detalheParceria/parceriaInfo';
import { DetalheParceriaPortalApi } from '../../apis/detalheParceriaPortalApi';
import { Mascaras } from '../../@shared/components/Mascaras';
import { UserService } from '../../@core/user.service';
import { Utils } from 'src/app/@shared/utils';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DetalheParceriaApi } from 'src/app/apis/detalheParceriaApi';
import { ExecucaoPlanoDeTrabalho } from './execucaoPlanoDeTrabalho';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';
import { forEach } from 'lodash';
import { PaginationInstance } from 'ngx-pagination/dist/pagination-instance';
import { NgbAccordion, NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { MetaComponent } from './meta/meta.component';
import { PrevisaoReceitaComponent } from './previsaoReceita/previsaoReceita.component';
import { PlanoDeTrabalhoComponent } from 'src/app/modulo1/planoDeTrabalho/planoDeTrabalho.component';
import { PrevisaoDespesa } from './previsaoDespesa/previsaoDespesa';
import { PrevisaoDespesaComponent } from './previsaoDespesa/previsaoDespesa.component';
import { PrevisaoEquipeComponent } from './previsaoEquipe/previsaoEquipe.component';
import { AvaliacaoComponent } from './avaliacao/avaliacao.component';
import { Meta } from './meta/meta';
import { PrevisaoReceita } from './previsaoReceita/previsaoReceita';
import { PrevisaoEquipe } from './previsaoEquipe/previsaoEquipe';

@Component({
  selector: 'app-plano-trabalho-parceria',
  templateUrl: './planoDeTrabalho.component.html',
  styleUrls: ['../informacaoParceria/informacaoParceria.component.scss']
  
})
export class PlanoDeTrabalhoNovoComponent implements OnInit{

  @Input()
  public parceriaInfo: ParceriaInfo;
  @Input()
  public isPortal: boolean;
  @Output() valueChange = new EventEmitter();
  @Input()
  public modulo: string;
  isFiscal: boolean;
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  isAuditorExterno: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  isExecPT: boolean;
  isPlanPT: boolean;
  parId: number;
  username: string;
  planoPlanTrabalho: Array<ExecucaoPlanoDeTrabalho> = [];
  planoNovo: ExecucaoPlanoDeTrabalho;
  @Output()
  isSaude:boolean;
  @Output()
  planoVisualizado: ExecucaoPlanoDeTrabalho;
  planoASerApagado: ExecucaoPlanoDeTrabalho;
  planoASerDuplicado: ExecucaoPlanoDeTrabalho;
  qtdPlano: number;
  competenciasLista: String[] = [];
  verPlano: boolean = false;
  avaliaPlano: boolean = false;
  p = 1;
  pagina: number;
  modalEdicaoReference: any;
  dataInicioPlanoPai:Date;
  dataFimPlanoPai:Date; 
  public disableApagarIconeBoolean: boolean;
  public disableBotaoDuplicarPlanoBoolean: boolean;
  @Input()
  public isCriarSA: boolean;
  mascaraBanco = [/\d/, /\d/, /\d/, /\d/, /\d/];
  mascaraCC = (rawValue: string) => {
    // Remove caracteres inválidos, exceto números, letras e hífen
    const cleanedValue = rawValue.replace(/[^0-9a-zA-Z\-]/g, '');
  
    // Separa as partes da entrada
    const parts = cleanedValue.split('-');
  
    // Máscara inicial com base nos dígitos antes do hífen
    const digitCount = parts[0].length;
    const mask = Array(Math.min(digitCount, 20)).fill(/\d/);
  
    // Adiciona o hífen e o caractere alfanumérico se houver um hífen
    if (parts.length > 1) {
      mask.push('-', /[a-zA-Z0-9]/);
    }
  
    return mask;
  };
  mascaraAgencia = (rawValue: string) => {
    // Remove caracteres inválidos, exceto números, letras e hífen
    const cleanedValue = rawValue.replace(/[^0-9a-zA-Z\-]/g, '');
  
    // Separa as partes da entrada
    const parts = cleanedValue.split('-');
  
    // Máscara inicial com base nos dígitos antes do hífen
    const digitCount = parts[0].length;
    const mask = Array(Math.min(digitCount, 5)).fill(/\d/);
  
    // Adiciona o hífen e o caractere alfanumérico se houver um hífen
    if (parts.length > 1) {
      mask.push('-', /[a-zA-Z0-9]/);
    }
  
    return mask;
  };

  @ViewChild( NgbAccordion, { static: false })  accordionComponent: NgbAccordion;
  @ViewChild(MetaComponent, { static: false }) metaComponent: MetaComponent;
  @ViewChild(PrevisaoDespesaComponent, { static: false }) despesaComponent: PrevisaoDespesaComponent;
  @ViewChild(PrevisaoReceitaComponent, { static: false })  receitaComponent!: PrevisaoReceitaComponent;
  @ViewChild(PrevisaoEquipeComponent, { static: false }) equipeComponent!: PrevisaoEquipeComponent;
  @ViewChild(AvaliacaoComponent, { static: false }) avaliacaoComponent!: AvaliacaoComponent;
  metaList : Meta[];
  receitaList : PrevisaoReceita[];
  despesaList : PrevisaoDespesa[];
  equipeList : PrevisaoEquipe[];


  isAccordionRendered:boolean;
  public config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 24,
    currentPage: 1
  };


  
  constructor(private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    public userService: UserService,
    private parceriaApi: PublicoPrivadaApi,
    private mascaras: Mascaras,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    private utils: Utils,
    private route: ActivatedRoute) {

  }


  ngOnInit() {

    this.verPlano=false;
    this.isAccordionRendered = false;
    this.userService.userInfo.subscribe(user => this.username = user.username);
    this.isFiscal = this.userService.isFiscal();
    this.isConsulta = this.userService.isConsulta();
    this.isSMTC = this.userService.isSMTC();
    this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
      this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
    });

   // console.log("modulo");
   // console.log(this.modulo);

    if (this.modulo === undefined) {
      this.modulo = 'm2';
    }
   // console.log(this.modulo);
    if (this.isPortal === undefined) {
      this.isPortal = false;
    }
    //console.log(this.isPortal);

    this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
      this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
    });

    this.route.queryParams.pipe(filter(params => params.isExecPT)).subscribe(params => {
      this.isExecPT = (params['isExecPT'] === undefined || params['isExecPT'].toLowerCase() === 'false' ? false : true)
    });
    //console.log(this.isExecPT);

    this.route.queryParams.pipe(filter(params => params.isPlanPT)).subscribe(params => {
      this.isPlanPT = (params['isPlanPT'] === undefined || params['isPlanPT'].toLowerCase() === 'false' ? false : true)
    });

    this.route.paramMap.subscribe(params => {
      this.parId = Number(params.get('id')) == 0?undefined: Number(params.get('id')); 
      
    });

    if (this.parId== undefined && this.parceriaInfo !== undefined) {
      this.parId = this.parceriaInfo.parId;
    }
    if (this.parId !== undefined) {
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          this.detalheParceriaApi.getParceriaInfo(this.parId))
      ).subscribe(async d => {
        this.parceriaInfo = d;
        // console.log(this.parceriaInfo);
        this.isSaude = this.utils.isSaude(this.parceriaInfo.zgororgaoCdorgao2);
        this.loading.next(false);
      });
    }
    this.updateDisableApagar();
    this.setupInterface();
  }

 

  setupInterface() {

    this.loading.next(true);

    this.route.paramMap.pipe(
      switchMap(() =>
        this.parceriaApi.getPlanoDeTrabalho(this.parId, this.isPortal, this.isFiscal))
    ).subscribe((response) => {
      if (response) {
        this.planoPlanTrabalho = response as Array<ExecucaoPlanoDeTrabalho>;

        this.updateDisableBotaoDuplicarPlano();
        this.loading.next(false);
      } else {
        this.alertService.warning('Nenhum registro encontrado');
        this.loading.next(false);
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));


  }

  disableCampos() {
    if (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno)
      return true;

    if (this.planoVisualizado != undefined){
      if(!this.isFiscal && this.planoVisualizado.planoSituacao == 2 ){
        return true;  
      }
      if (this.planoVisualizado.planoSituacao == 4) {
        return true;
      }
    }
    return false; 

  }

  disableBotao() {
    if (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno)
    return true;

    if (this.planoVisualizado != undefined){
      if(!this.isFiscal && this.planoVisualizado.planoSituacao == 2 ){
        return true;  
      }
      if (this.planoVisualizado.planoSituacao == 4) {
        return true;
      }
    }
  return false; 
}
  
  disableBotaoSub() {
    if (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno)
      return true;
    
    if (this.planoVisualizado != undefined){
      if(!this.isFiscal && this.planoVisualizado.planoSituacao == 2 ){
        return true;  
      }
      if (this.planoVisualizado.planoSituacao == 4) {
        return true;
      }
    }
    return false;
  }

  updateDisableApagar() {
    this.disableApagarIconeBoolean = this.disableApagarIcone();
  }

  disableApagarIcone() {
    if (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno)
      return true;

    if (this.planoVisualizado != undefined && (this.planoVisualizado.planoSituacao == 2 || this.planoVisualizado.planoSituacao == 4)) {
      return true;
    }
    return false; 

  }

  updateDisableBotaoDuplicarPlano() {
    
    this.disableBotaoDuplicarPlanoBoolean = this.disableBotaoDuplicarPlano();
    
  }

  disableBotaoDuplicarPlano() {
    if (this.isFiscal || this.isConsulta || this.isSMTC || this.isAuditorExterno) {
      return true;
    }

    let temPlanoElabo = this.planoPlanTrabalho.find(element => element.planoSituacao === 1);
    if (temPlanoElabo != undefined) {
  
      return true;
    }

    return false;
  }
  
  enableIcone() {
    if (this.isFiscal || this.isConsulta || this.isSMTC || this.isAuditorExterno) {
      return false;
    }
    return true;
  }

  getSituacaoPlanoDeTrabalho(situacao) {

    return this.utils.getStatusPlanoDeTrabalho(situacao);
  }

  visualizarPlano(planoSelecionado) {

    this.isCriarSA =false;
    if (this.planoVisualizado === undefined) {
      this.verPlano = true;
    }
    else if (this.planoVisualizado == planoSelecionado) {
      this.verPlano = !this.verPlano;
    }
    this.planoVisualizado = planoSelecionado;

    if (this.verPlano) {

      if (this.planoVisualizado.planoDataFimExecucao != null)
        this.planoVisualizado.planoDataFimExecucaoFormat = this.dateConverter.fromTimestampToNgbDateStruct(
          this.dateConverter.fromDateToTimestamp(this.planoVisualizado.planoDataFimExecucao));
      if (this.planoVisualizado.planoDataIniExecucao != null)
        this.planoVisualizado.planoDataIniExecucaoFormat = this.dateConverter.fromTimestampToNgbDateStruct(
          this.dateConverter.fromDateToTimestamp(this.planoVisualizado.planoDataIniExecucao));

      if (this.metaComponent != undefined) {
        
        this.metaComponent.visualizaMeta(this.planoVisualizado,false);
       }

      if (this.equipeComponent != undefined) {
        this.equipeComponent.visualizaPrevisaoEquipe(this.planoVisualizado,false);
      }

      if (this.despesaComponent != undefined) {
        this.despesaComponent.visualizaDespesa(this.planoVisualizado,false);
      }

      if (this.planoVisualizado.planoIdentificador.startsWith('SA')){
        this.planoPlanTrabalho.map(element =>{
          if (element.planoId == this.planoVisualizado.planoPai){
              this.dataInicioPlanoPai = planoSelecionado.planoDataIniExecucao;
              this.dataFimPlanoPai = planoSelecionado.planoDataFimExecucao;
              if (this.avaliacaoComponent != undefined) {
              this.avaliacaoComponent.avaliaAlteracaoPlano(planoSelecionado, element);
              }
           }
        })
      }

      
      this.calculaCompetencia();
      if (this.avaliacaoComponent != undefined) {
        this.avaliacaoComponent.visualizaAvaliacao(this.planoVisualizado);
      }
    }
  }

  avaliarPlano(planoSelecionado) {
    this.isCriarSA = false;
    if (this.planoVisualizado === undefined) {
      this.avaliaPlano = true;
      this.verPlano = true;
    }
    else if (this.planoVisualizado == planoSelecionado) {
      this.avaliaPlano = !this.avaliaPlano;
      this.verPlano  != this.verPlano;
    }
    this.planoVisualizado = planoSelecionado;

    if (this.planoVisualizado.planoDataFimExecucao != null)
      this.planoVisualizado.planoDataFimExecucaoFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.planoVisualizado.planoDataFimExecucao));
    if (this.planoVisualizado.planoDataIniExecucao != null)
      this.planoVisualizado.planoDataIniExecucaoFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.planoVisualizado.planoDataIniExecucao));


    this.calculaCompetencia();

    if (this.avaliacaoComponent != undefined) {
      this.avaliacaoComponent.visualizaAvaliacao(this.planoVisualizado);
    }
    
  }
  avaliaAlteracaoPlano(planoAlterado){

    if (this.planoVisualizado === undefined) {
      this.avaliaPlano = true;
      this.verPlano = true;
    }
    else if (this.planoVisualizado == planoAlterado) {
      this.avaliaPlano = !this.avaliaPlano;
      this.verPlano  != this.verPlano;
    }
    this.planoVisualizado = planoAlterado;

    let find = this.planoPlanTrabalho.filter(element =>
      (element.planoId ==  planoAlterado.planoPai)
    );

    if (this.planoVisualizado.planoDataFimExecucao != null)
      this.planoVisualizado.planoDataFimExecucaoFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.planoVisualizado.planoDataFimExecucao));
    if (this.planoVisualizado.planoDataIniExecucao != null)
      this.planoVisualizado.planoDataIniExecucaoFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.planoVisualizado.planoDataIniExecucao));


    this.calculaCompetencia();

    if (find.length>0 && this.avaliacaoComponent != undefined){
      this.avaliacaoComponent.avaliaAlteracaoPlano(planoAlterado, find[0]);
    }
  }

  async adicionarNovoPlano() {

    this.verPlano=false;
    let planoElabo = this.planoPlanTrabalho.find(element => element.planoSituacao === 1);
    if (planoElabo != undefined) {
      this.alertService.info("Já existe um plano em elaboração. Não é possível adicionar um novo plano.");
      return;
    }

    this.planoNovo = new ExecucaoPlanoDeTrabalho();
    this.planoNovo.planoSituacao = 1;
    this.planoNovo.planoEfetivacao = false;
    this.planoNovo.parId = this.parceriaInfo.parId;
    this.planoNovo.planoOperacaoUsuario = this.username;
    
    await this.detalheParceriaPortalApi.getQtdPlanoTrabalhoPorTermo(this.parceriaInfo.parId,'PT', new Date().getFullYear()).subscribe(
      async qtd => {
        this.qtdPlano = qtd;
        if (this.qtdPlano >= 0) {
          let count = '' + (this.qtdPlano);
          let ano = new Date().getFullYear();
          this.planoNovo.planoIdentificador = "PT-" + count.padStart(3, "0") + "/" + ano;
          this.planoNovo.planoId = null;
          this.planoNovo.planoDataIniExecucao = new Date();
          this.planoNovo.planoDataFimExecucao = new Date();
          this.visualizarPlano(this.planoNovo);
        }
      }
    );
  }

  splitIntoChunks(array: any[], chunkSize: number) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  validaLimiteMaximo() {
    if (this.planoVisualizado.planoDataIniExecucaoFormat != undefined &&
      this.planoVisualizado.planoDataFimExecucaoFormat != undefined) {
      let dataIni = this.dateConverter.fromNgbDateStructToDate(this.planoVisualizado.planoDataIniExecucaoFormat);
      let dataFim = this.dateConverter.fromNgbDateStructToDate(this.planoVisualizado.planoDataFimExecucaoFormat);
      const quantidadeMeses = this.calculaQtdMeses(dataIni, dataFim);
      if (quantidadeMeses > 60) {
        this.alertService.danger('Limite máximo excedido. Permitido até 60 meses. ');
        return true;
      }
      else {
        this.competenciasLista = this.construirArrayMeses(dataIni, quantidadeMeses)
        return false;
      }
    }
    else {
      this.alertService.danger('Data (s) do Período de Execução Inválida.');
      return true;
    }
  }

  validaDataFimPlanoAlterado(){
    if (this.planoVisualizado.planoDataFimExecucao == undefined){
      this.planoVisualizado.planoDataFimExecucao = this.dataFimPlanoPai;
    }
    else if (this.planoVisualizado.planoDataFimExecucao > this.dataFimPlanoPai){
        this.alertService.danger('Data fim não pode ser posterior a vigência do Plano.');
        return true;
    }
    else if (this.planoVisualizado.planoDataIniExecucao < this.dataInicioPlanoPai){
      this.alertService.danger('Data início não pode ser inferior a vigência do Plano.');
      return true;
    }
    return false;
  }

  calculaQtdMeses(dataInicio: Date, dataFim: Date): number {
    let meses: number;

    // Calcule a diferença em anos e meses
    meses = (new Date(dataFim).getFullYear() - dataInicio.getFullYear()) * 12;
    meses -= dataInicio.getMonth();
    meses += new Date(dataFim).getMonth();
    meses = meses + 1;
    // Se a data de fim for antes do dia da data de início, subtraia um mês
    // if (new Date(dataFim).getDate() < dataInicio.getDate()) {
    //   meses--;
    // }

    return meses < 0 ? 0 : meses; // Retorna 0 se o resultado for negativo
  }

  construirArrayMeses(dataInicio: Date, quantidadeMeses: number): string[] {
    const mesesArray: string[] = [];
    const mesNomes = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
      'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    for (let i = 0; i < quantidadeMeses; i++) {
      const dataAtual = new Date(dataInicio);
      dataAtual.setMonth(new Date(dataInicio).getMonth() + i);

      const mes = mesNomes[dataAtual.getMonth()];
      const ano = dataAtual.getFullYear();
      let numero = '' + (i + 1);
      numero = numero.padStart(2, "0");
      mesesArray.push('Mês ' + `${numero}` + ':');
      mesesArray.push(`${mes}/${ano}`);
    }

    return mesesArray;
  }

  public calculaCompetencia() {

    const quantidadeMeses = this.calculaQtdMeses(new Date(this.planoVisualizado.planoDataIniExecucao),
      this.planoVisualizado.planoDataFimExecucao);
    this.competenciasLista = this.construirArrayMeses(this.planoVisualizado.planoDataIniExecucao, quantidadeMeses)
    // this.competenciasLista.forEach(element => {
    //   console.log(element);   
    // });


  }

  replacer(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['planoDataIniExecucaoFormat', 'planoDataFimExecucaoFormat'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  excluirPlanoTrabalho() {
    if (this.planoASerApagado.planoSituacao === 1) {
      const index = this.planoPlanTrabalho.indexOf(this.planoASerApagado); // Encontra o índice do item
      if (index > -1) {
        this.detalheParceriaApi.excluirPlanoDeTrabalho(this.planoASerApagado).subscribe(async (response) => {
          if (response) {
            this.planoPlanTrabalho.splice(index, 1); // Remove o item com base no índice
            this.modalEdicaoReference.close();
            if (this.planoVisualizado.planoId == this.planoASerApagado.planoId){
              this.verPlano =false;
            }
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        }, () => this.loading.next(false));

      }
    }

  }

  duplicarPlanoTrabalho() {
    this.isCriarSA = false;
    this.modalEdicaoReference.close();
    this.loading.next(true);

    if (this.planoASerDuplicado.planoSituacao === 4 && this.planoASerDuplicado.planoEfetivacao ) {
      const index = this.planoPlanTrabalho.indexOf(this.planoASerDuplicado); // Encontra o índice do item
      if (index > -1) {
        this.planoASerDuplicado.planoOperacaoUsuario = this.username;
        this.planoASerDuplicado.planoDataOperacao = new Date();
      
        this.detalheParceriaApi.duplicarPlanoDeTrabalho(this.planoASerDuplicado.planoId, this.planoASerDuplicado).subscribe(async (response) => {
          if (response) {
             this.setupInterface();
             this.loading.next(false);
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        }, () => this.loading.next(false));

      }
    }

  }

  openExcluirPlano(content, data) {
    this.planoASerApagado = data;
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
  }

  openDuplicarPlano(content, data) {
    this.planoASerDuplicado = data;
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
  }

  handleMetaUpdated(updatedList: Meta[]) {
    this.metaList = updatedList;
    //console.log('Received updated meta list:', updatedList);
  }
  handleDespesaUpdated(updatedList: PrevisaoDespesa[]) {
    this.despesaList = updatedList;
    //console.log('Received updated despesa list:', updatedList);
  }
  handleReceitaUpdated(updatedList: PrevisaoReceita[]) {
    this.receitaList = updatedList;
    //console.log('Received updated receita list:', updatedList);
  }
  handleEquipeUpdated(updatedList: PrevisaoEquipe[]) {
    this.equipeList = updatedList;
    //console.log('Received updated equipe list:', updatedList);
  }

  salvaPlano() {
    let teste = this.validaLimiteMaximo();
    console.log(teste);
    if (!teste) {

      if (this.planoVisualizado.planoDataIniExecucaoFormat != null) {
        this.planoVisualizado.planoDataIniExecucao = this.dateConverter.fromNgbDateStructToDate(this.planoVisualizado.planoDataIniExecucaoFormat);
        if (!this.utils.isValidDate(this.planoVisualizado.planoDataIniExecucao)) {
          this.alertService.danger('Data Início Execução Inválida.');
          return;
        }
      }
      else {
        this.alertService.danger('Data Início Execução Inválida.');
        return;
      }


      if (this.planoVisualizado.planoDataFimExecucaoFormat != null) {
        this.planoVisualizado.planoDataFimExecucao = this.dateConverter.fromNgbDateStructToDate(this.planoVisualizado.planoDataFimExecucaoFormat);
        if (!this.utils.isValidDate(this.planoVisualizado.planoDataFimExecucao)) {
          this.alertService.danger('Data Fim Execução Inválida.');
          return;
        }
      }
      else {
        this.alertService.danger('Data Fim Execução Inválida.');
        return;

      }

      if (this.isBlank(this.planoVisualizado.planoContaCorrenteAgenciaOsc)
        || this.isBlank(this.planoVisualizado.planoContaCorrenteBancoOsc)
      || this.isBlank(this.planoVisualizado.planoContaCorrenteNumeroOsc)){
        this.alertService.danger('Dado(s) bancário(s) da conta corrente inválido(s).');
        return;
      }

      if (this.isBlank(this.planoVisualizado.planoContaProvisaoAgenciaOsc)
        || this.isBlank(this.planoVisualizado.planoContaProvisaoBancoOsc)
      || this.isBlank(this.planoVisualizado.planoContaProvisaoNumeroOsc)){
        this.alertService.danger('Dado(s) bancário(s) da conta provisão inválido(s).');
        return;
      }

      if (this.isBlank(this.planoVisualizado.planoAnoFundacaoOsc)
        || this.isBlank(this.planoVisualizado.planoFocoAtuacaoOsc)
      || this.isBlank(this.planoVisualizado.planoExperienciaOSC)
      || this.isBlank(this.planoVisualizado.planoQtdProfissionaisOsc)){
        this.alertService.danger('Dado(s) da Apresentação e Histórico da Proponente Inválido(s).');
        return;
      }
           
      if (this.isBlank(this.planoVisualizado.planoJustificativa)
        || this.isBlank(this.planoVisualizado.planoIdentificacaoObjeto)
      || this.isBlank(this.planoVisualizado.planoFormaExecucao)
        || this.isBlank(this.planoVisualizado.planoEspacoFisico)
      || this.isBlank(this.planoVisualizado.planoDescricao)){
        this.alertService.danger('Dado(s) da Descrição do Objeto inválido(s).');
        return;
      }
      
      if (this.planoVisualizado.planoIdentificador.startsWith('SA')){
        if (this.validaDataFimPlanoAlterado()){
          return;
        }
      }

      if (this.metaComponent != undefined && this.metaList) {
        if (!this.metaComponent.validaCamposObrigatorios(this.metaList)){
          return;
        }
      }

      if (this.despesaComponent != undefined && this.despesaList) {
        if (!this.despesaComponent.validaCamposObrigatorios(this.despesaList)){
          return;
        }
      }

      if (this.receitaComponent != undefined && this.receitaList) {
        if (!this.receitaComponent.validaCamposObrigatorios(this.receitaList)){
          return;
        }
      }
      if (this.equipeComponent != undefined && this.equipeList) {
        if (!this.equipeComponent.validaCamposObrigatorios(this.equipeList)){
          return;
        }
      }

      this.planoVisualizado.planoOperacaoUsuario = this.username;
      this.planoVisualizado.planoDataOperacao = new Date();

      if ( this.planoVisualizado.planoSituacao != undefined && this.planoVisualizado.planoSituacao === 3 ) {

        this.planoVisualizado.planoSituacao = 3;

      } else {

          this.planoVisualizado.planoSituacao = 1;

      }

      const data = JSON.stringify(this.planoVisualizado, this.replacer);
      this.detalheParceriaApi.updatePlanoDeTrabalho(data).subscribe(async (response) => {
        if (response) {
          let planoId = response.body as unknown as number;
          this.alertService.info('Plano de trabalho salvo com sucesso.');
          this.planoVisualizado.planoId = planoId;
          if (this.metaList != undefined){
            this.metaComponent.salvaMeta(planoId, this.metaList);
          }
          if(this.despesaList != undefined){
            this.despesaComponent.salvaPrevisaoDespesa(planoId, this.despesaList);
          }
          if(this.receitaList != undefined){
            this.receitaComponent.salvaPrevisaoReceita(planoId, this.receitaList);
          }
          if(this.equipeList != undefined){
            this.equipeComponent.salvaPrevisaoEquipe(planoId,this.equipeList);
          }
          this.setupInterface();
          this.loading.next(false);
        }

      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));


    }
  }

  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }

  submeterPlano(){  

      let valorTotalDepesa = this.calcularTotal(this.despesaComponent.previsaoDespesaLista);
      let valorTotalReceita = this.calcularTotal(this.receitaComponent.previsaoReceitaLista);
      if (valorTotalReceita < valorTotalDepesa ){
        this.alertService.danger('Total de previsão de despesa é superior a previsão de receita.');
        this.loading.next(false);
        return;
      }

      this.planoVisualizado.planoSituacao = 2;
      this.planoVisualizado.planoDataOperacao = new Date();

      const data = JSON.stringify(this.planoVisualizado, this.replacer);
      this.detalheParceriaApi.updatePlanoDeTrabalho(data).subscribe(async (response) => {
        if (response) {
          let planoId = response.body as unknown as Number;
          this.setupInterface();
          this.loading.next(false);
          this.planoVisualizado = undefined;
          this.verPlano =false;
          this.alertService.info("O plano de trabalho foi submetido com sucesso!");
          
        }

      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));

  }

  get paginatedCompetenciasLista(): String[] {
    const start = (this.config.currentPage - 1) * this.config.itemsPerPage;
    const end = start + this.config.itemsPerPage;
    return this.competenciasLista.slice(start, end);
  }
  // Change page
  changePage(pageNumber: number): void {
    this.config.currentPage = pageNumber;
  }

  // Get the total number of pages
  get totalPages(): number {
    return Math.ceil(this.competenciasLista.length / this.config.itemsPerPage);
  }


  calcularTotal(previsaoDespesaReceitaLista): number {
    let total = 0;

    previsaoDespesaReceitaLista.forEach(previsao => {
        // Calcular a diferença entre a data de início e a data final em meses
        const meses = this.calcularMesesEntreDatas(previsao.vigenciaInicio, previsao.vigenciaFinal);
        
         
        // Calcular o valor total considerando o número de meses e o valor mensal
        total += previsao.valorMensal * (meses+1);
    });

    return total;
 }

 calcularMesesEntreDatas(dataInicio: Date, dataFinal: Date): number {
    const inicio = new Date(dataInicio);
    const fim = new Date(dataFinal);
    
    let meses = fim.getFullYear() * 12 + fim.getMonth() - (inicio.getFullYear() * 12 + inicio.getMonth());
    
    // Verificar se a data final está antes do dia de início no mesmo mês, ajustando o cálculo.
    if (fim.getDate() < inicio.getDate()) {
        meses--;
    }

    return meses > 0 ? meses : 0;  // Não permitir meses negativos
  }

  onDateChangedInicio(date: Date): void {
    this.planoVisualizado.planoDataIniExecucao = date;
  }

  onDateChangedFinal(date: Date): void {
    this.planoVisualizado.planoDataFimExecucao = date;
  }

  inibeBotaoAlteracao(planoSelecionado){
    
    if (this.isPortal || this.isFiscal){
      return true;
    }
    if (planoSelecionado.planoSituacao < 4){
      return true;
    }

    let find = this.planoPlanTrabalho.filter(element =>
      (element.planoPai ==  planoSelecionado.planoId) && 
      (element.planoSituacao < 4)
    );

    if (find.length > 0){
      return true;
    }
     
    else{
     return false;
    }
  }
  async criaAlteracaoPlano(planoSelecionado){

    this.isCriarSA = true;
    if (this.planoVisualizado === undefined) {
      this.verPlano = true;
    }
    else if (this.planoVisualizado == planoSelecionado) {
      this.verPlano = !this.verPlano;
    }
    else{
      this.verPlano = true;
    }
    this.planoVisualizado = planoSelecionado;

    if (this.planoVisualizado.planoDataFimExecucao != null)
      this.planoVisualizado.planoDataFimExecucaoFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.planoVisualizado.planoDataFimExecucao));
    if (this.planoVisualizado.planoDataIniExecucao != null)
      this.planoVisualizado.planoDataIniExecucaoFormat = this.dateConverter.fromTimestampToNgbDateStruct(
        this.dateConverter.fromDateToTimestamp(this.planoVisualizado.planoDataIniExecucao));


    if (planoSelecionado.planoSituacao == 4){

      this.planoVisualizado = planoSelecionado;

      const planoAlteracao = this.utils.copyWithoutProps(planoSelecionado, ['planoId', 'planoIdentificador',
      'planoDataOperacao','planoOperacaoUsuario']);

      
      planoAlteracao.planoSituacao = 1;
      planoAlteracao.planoEfetivacao = false;
      this.dataInicioPlanoPai = planoSelecionado.planoDataIniExecucao;
      this.dataFimPlanoPai = planoSelecionado.planoDataFimExecucao;
      planoAlteracao.planoPai = planoSelecionado.planoId;

      this.planoVisualizado = planoAlteracao;
      await this.detalheParceriaPortalApi.getQtdPlanoTrabalhoPorTermo(this.parceriaInfo.parId,'SA', new Date().getFullYear()).subscribe(
        async qtd => {
          this.qtdPlano = qtd;
          if (this.qtdPlano >= 0) {
            let count = '' + (this.qtdPlano);
            let ano = new Date().getFullYear();
            this.planoVisualizado.planoIdentificador = "SA-" + count.padStart(3, "0") + "/" + ano;

          }
        }
      );

    }
  }
    acordiaoChange(props: NgbPanelChangeEvent): void {
  

      if (this.accordionComponent) {
        const activeArray: Array<string> = Array.from(this.accordionComponent.activeIds);
  
        // fecha outros elementos do acordion quando abertos (ativos)
        if (activeArray.length >0)
          activeArray.forEach(element => {

            if (element !== props.panelId) {
              this.accordionComponent.toggle(element);

            }
          });
       }
    }


}