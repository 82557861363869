export class PrevisaoDespesa {
    public id: number;
	public planoTrabalhoId: number;
	public natureza: number;
	public detalhamento:string;
	public valorMensal: number;
	public vigenciaInicio: Date;
	public vigenciaFinal: Date;
	public frim:boolean;
	public custoIndireto:boolean;
	public operacaoData: Date;
	public operacaoUsuario: string;
}