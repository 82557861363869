<div *ngIf="(plano?.planoSituacao == 2 && isFiscal) || (plano?.planoSituacao == 3 || plano?.planoSituacao == 4)">
    <div *ngIf="plano.planoIdentificador.startsWith('PT')" class="execucao tituloSecao">AVALIAÇÃO DO PLANO DE TRABALHO</div>

    <div class="container" *ngIf="avaliacaoPlanoLista?.length > 0 && plano.planoIdentificador.startsWith('PT')">
        <table class="table" style="margin-top:30px" id="avaliacaoPlano" [mfData]="avaliacaoPlanoLista"
            #md="mfDataTable">
            <thead class="custom-thead">
                <tr>
                    <th style="background-color: #f0f0f0;"><input type="checkbox" [(ngModel)]="selectAll"
                            name="selectAll" (click)="selectAllItem()"></th>
                    <th style="background-color: #f0f0f0;">Item do Plano</th>
                    <th style="background-color: #f0f0f0;">Avaliação</th>
                    <th style="background-color: #f0f0f0;">Recomendações</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of md.data | paginate:config let idx = index">
                    <td class="form-check">
                        <input type="checkbox" [(ngModel)]="item.limpa" name="limpa"
                            [disabled]="disableItem(idx,'A')" />
                    </td>
                    <td class="col-auto"><textarea class="form-control input-tabela" [disabled]="disableItem(idx,'A')"
                            style="width: 100%; height: 80px;" [(ngModel)]="item.itemPlano" name="itemPlano" maxlength="1000"
                            #itemPlano="ngModel" class="form-control" required>
                    </textarea>
                    </td>
                    <td class="col-md-2">
                        <select class="form-control custom-select" [disabled]="disableItem(idx,'')"
                            style="height: 80px; white-space: pre-wrap;" name="avaliacaoItem"
                            [(ngModel)]="item.avaliacaoItem">
                            <option [value]="option.id" *ngFor="let option of this.utils.avaliacaoItem">{{option.name}}
                            </option>
                        </select>
                    </td>
                    <td class="col-auto"><textarea class="form-control input-tabela" [disabled]="disableItem(idx,'')"
                            style="width: 100%; height: 80px;" [(ngModel)]="item.recomendacaoItem" maxlength="1000"
                            name="recomendacaoItem" #recomendacaoItem="ngModel" class="form-control" required>
                </textarea>
                    </td>

                </tr>
            </tbody>
        </table>
        <div class="row">
            <div *ngIf="isFiscal && !disableBotao()" class="col-md-4" style="margin-left: 10px;">
                <button (click)="adicionarItemAvaliacaoPlano() " id="button-insert_doc "
                    class="btn btn-primary btn-info" type="button " title="Adicionar novo" [hidden]="false">
                    <i class="fa fa-plus "><span class="texto-botao">Adicionar item para avaliação</span> </i>
                </button>
            </div>
             <div *ngIf="isFiscal && !disableBotao()" class="col-md-4">
                <button (click)="openExcluirItemAvaliacao(contentExcluirAvaliacao)" id="button-apagar-ativ "
                    class="btn btn-primary btn-info ms-auto custom-margin-left" type="button "
                    title="Excluir Item de Avaliação">
                    <i class="fa fa-trash-o" style="color: white;"><span class="texto-botao">Excluir
                            Item de Avaliação</span> </i>
                </button>
            </div>
        </div>
        <div class="pagination pagination  justify-content-center" *ngIf="avaliacaoPlanoLista.length > 0" >
            <i (click)="changePage(config.currentPage - 1)"
                [ngStyle]="{ 'pointer-events': config.currentPage === 1 ? 'none' : 'auto' }"
                class="fa fa-chevron-circle-left" style="font-size:36px; padding-right:10px"> </i>

            <span> Página {{config.currentPage}} de {{totalPages}} </span>

            <i (click)="changePage(config.currentPage + 1)"
                [ngStyle]="{ 'pointer-events': config.currentPage === totalPages ? 'none' : 'auto' }"
                class="fa fa-chevron-circle-right" style="font-size:36px; padding-left:10px"> </i>

        </div>
        <div class="row font-weight-bold">
            Observações Gerais:
            <textarea class="form-control input-tabela" [disabled]="disableCampos()" style="width: 100%; height: 80px;"
                [(ngModel)]="avaliacaoPlano.observacoesGerais" name="observacoesGerais" #observacoesGerais="ngModel"
                class="form-control" maxlength="1000" required>
            </textarea>
        </div>

        <div class="justify-content-center">
            <br />
            <!-- Linha para "Parecer Final" e "Efetivar Alterações" -->
            <div class="row align-items-center">
                <!-- Coluna: Parecer Final -->
                <div class="col-md-6 d-flex align-items-center">
                    <label for="parecerPlano" class="col-md-7">
                        Parecer Final do Plano de Trabalho:<span class="text-danger">*</span>
                    </label>
                    <select id="parecerPlano" 
                            class="col-md-4 form-control custom-select"
                            [disabled]="disableCampos()"
                            name="parecerPlano" 
                            [(ngModel)]="avaliacaoPlano.parecerFinal">
                        <option [value]="option.id" *ngFor="let option of this.utils.parecerPlano">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
        
                <!-- Coluna: Efetivar Alterações -->
                <div *ngIf="!disableBotao()" class="col-md-4 d-flex align-items-center">
                    <button (click)="openEfetivarAlteracao(contentEfetivarAlt)"
                            id="button-efetivar-alteracao"
                            class="btn btn-primary btn-info" 
                            type="button"
                            title="Efetivar Alterações"
                            [disabled]="(disableBotao()  || avaliacaoPlano.parecerFinal!=1 || avaliacaoPlano.portalTransparencia == 1)">
                        <span class="texto-botao">Efetivar Alterações</span>
                    </button>
                </div>
            </div>
        
            <br />
        
            <!-- Linha para "Salvar Avaliação" -->
            <div class="row">
                <div *ngIf="!disableBotao()" class="col-md-3 d-flex align-items-center">
                    <button (click)="salvaAvaliacaoPlano()" 
                            id="button-salvar-avaliacao"
                            class="btn btn-primary btn-info" 
                            type="button"
                            title="Salvar Avaliação"
                            [disabled]="disableCampos()">
                        <span class="texto-botao">Salvar Avaliação</span>
                    </button>
                </div>
                <div *ngIf="!disableBotao()" class="col-md-4 d-flex align-items-center">
                    <button (click)="devolverPlano()" 
                            id="button-salvar-avaliacao"
                            class="btn btn-primary btn-info" 
                            type="button"
                            title="Devolver para correção"
                            [disabled]="avaliacaoPlano.parecerFinal==1">
                        <span class="texto-botao">Devolver para correção</span>
                    </button>
                </div>
            </div>
        </div>
        <ng-template #contentEfetivarAlt let-c="close" let-d="dismiss">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Efetivar Alterações no Plano</h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Confirma efetivação do Plano de Trabalho {{plano.planoIdentificador}} e Publicação no Portal
                    Transparência?
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" style="margin-top: 7px;" (click)="c('Close click')"
                    title="Não">Não</button>
                <button type="button" class="btn btn-secondary" (click)="efetivarAlteracao()"
                    title="Excluir">Sim</button>
            </div>
        </ng-template>
        <ng-template #contentExcluirAvaliacao let-c="close" let-d="dismiss">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Excluir Item(ns) de Avaliação</h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Tem certeza que deseja excluir o(s) item (ns) de avaliação selecionados?
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" style="margin-top: 7px;" (click)="c('Close click')"
                    title="Fechar">Fechar</button>
                <button type="button" class="btn btn-secondary" (click)="excluirItensAvalicao()"
                    title="Excluir">Excluir</button>
            </div>
        </ng-template>
    </div>
    <div *ngIf="plano.planoIdentificador.startsWith('SA')">
        <div class="execucao tituloSecao">SOLICITAÇÃO DE ALTERAÇÃO DE PLANO DE TRABALHO {{plano.planoIdentificador}}</div>
        <label style="padding-top: 2%"> Plano de Trabalho - {{planoPai.planoIdentificador}} - Período de Execução {{planoPai.planoDataIniExecucao |  date: 'dd/MM/yyyy'}} a {{planoPai.planoDataFimExecucao |  date: 'dd/MM/yyyy'}} </label>

        <div style="margin-top: 50px;" *ngIf="plano.planoIdentificador.startsWith('SA')" >
            <div class="row">
                <div class="col-md-3">
                    <label style="padding-top: 2%">
                        Justificativa da Alteração:
                        <span class="campoObrigatorio">*</span>
                    </label>
                </div>
                <div class="col-md-9">
                    <!--<i class="fa fa-times icon_input_fontawsome"  style="padding-top: 5%" aria-hidden="true" (click)="planoVisualizado.planoExperienciaOSC=undefined" title="limpar"></i>-->
                    <textarea [disabled]="disableCampos()" style="width:100%;"
                        name="planoJustificativaAlteracao" class="form-control" ng-model="name"
                        #planoJustificativaAlteracao="ngModel"
                        [(ngModel)]="plano.planoJustificativaAlteracao" maxlength="500"></textarea>
                </div>
            </div>
            <div class="row" style="margin-top: 20px;">
                     <div class="col-md-3 font-weight-bold">Vigência da Alteração&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;De:
                    <span class="text-danger">*</span>
                </div>
                <!--  <div class="col-md-1 font-weight-bold">De:<span class="text-danger">*</span>
                </div>-->
                <div class="col-md-3">
                    <app-date-selector [disabledFlag]="true"
                        [initialDate]="plano.planoDataIniExecucao"
                        ></app-date-selector>
                </div>
                <div class="col-md-1 font-weight-bold">Até:<span class="text-danger">*</span>
                </div>
                <div class="col-md-3">
                    <app-date-selector [disabledFlag]="true"
                        [initialDate]="plano.planoDataFimExecucao"
                        ></app-date-selector>
                </div>
            
            </div>
        </div>

         <label style="padding-top: 4%"> Alterações do Plano </label>
        <table class="table" *ngIf="alteracoesPlano?.length > 0" style="margin-top:30px" id="alteracoesPlano" [mfData]="alteracoesPlano"
        #mA="mfDataTable">
        <thead class="custom-thead">
            <tr>
                <th style="background-color: #f0f0f0;">Item</th>
                <th style="background-color: #f0f0f0;">Alteração De:</th>
                <th style="background-color: #f0f0f0;">Para:</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let alt of mA.data | paginate:config let idx = index">
               
                <td class="col-auto">
                    <span [ngClass]="{'font-weight-bold': isBold(alt.chave)}">
                        {{ alt.chave }}
                    </span>
                </td>
                <td class="col-auto">
                    {{alt.value1}}
                </td>
                <td class="col-auto">
                    {{alt.value2}}
                </td>

            </tr>
        </tbody>
    </table>
    <div class="pagination pagination" *ngIf="alteracoesPlano?.length > 0">
        <i (click)="changePage(config.currentPage - 1)"
            [ngStyle]="{ 'pointer-events': config.currentPage === 1 ? 'none' : 'auto' }"
            class="fa fa-chevron-circle-left" style="font-size:36px; padding-right:10px"> </i>

        <span> Página {{config.currentPage}} de {{totalPagesA}} </span>

        <i (click)="changePage(config.currentPage + 1)"
            [ngStyle]="{ 'pointer-events': config.currentPage === totalPagesA ? 'none' : 'auto' }"
            class="fa fa-chevron-circle-right" style="font-size:36px; padding-left:10px"> </i>

    </div>
    <div style="margin-top: 50px;" *ngIf="plano.planoIdentificador.startsWith('SA')" >
        <div class="row">
            <label style="padding-left: 1.3%">
                Avaliação do Órgão Gestor
                </label>
        </div>
        <div class="row">            
            <div class="col-md-3">
                <label style="padding-top: 2%">
                    Observações Gerais:
                    <span class="campoObrigatorio">*</span>
                </label>
            </div>
        </div>
        <div class="row"> 
            <div class="col-md-12">
                 <textarea [disabled]="disableCampos()" style="width:100%;"
                    name="observacoesGerais" class="form-control" ng-model="name"
                    #observacoesGerais="ngModel"
                    [(ngModel)]="avaliacaoPlano.observacoesGerais" maxlength="500"></textarea>
            </div>
        </div>
        <div class="justify-content-center">
            <br />
            <!-- Linha para "Parecer Final" e "Efetivar Alterações" -->
            <div class="row align-items-center">
                <!-- Coluna: Parecer Final -->
                <div class="col-md-4 d-flex align-items-center">
                    <label for="parecerPlano" >
                        Parecer Final da Solicitação de Alteração:<span class="text-danger">*</span>
                    </label>
                    
                </div>
                <div class="col-md-4 d-flex align-items-center">
                    
                    <select id="parecerPlano" 
                            class="col-md-6 form-control custom-select"
                            [disabled]="disableCampos()"
                            name="parecerPlano" 
                            (change)="selectAprovadoCorrige($event)"
                            [(ngModel)]="avaliacaoPlano.parecerFinal">
                        <option [value]="option.id" *ngFor="let option of this.utils.parecerPlano">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
        
                <!-- Coluna: Efetivar Alterações -->
                <div *ngIf="!disableBotao()" class="col-md-4 d-flex align-items-center">
                    <button (click)="openEfetivarAlteracao(contentEfetivarAlt)"
                            id="button-efetivar-alteracao"
                            class="btn btn-primary btn-info" 
                            type="button"
                            title="Efetivar Alterações"
                            [disabled]="(disableBotao() || avaliacaoPlano.parecerFinal!=1 || avaliacaoPlano.portalTransparencia == 1)">
                        <span class="texto-botao">Efetivar Alterações</span>
                    </button>
                </div>
            </div>
        
            <br />
        
            <!-- Linha para "Salvar Avaliação" -->
            <div class="row">
                <div *ngIf="!disableBotao()" class="col-md-4 d-flex align-items-center">
                    <button (click)="salvaAvaliacaoPlano()" 
                            id="button-salvar-avaliacao"
                            class="btn btn-primary btn-info" 
                            type="button"
                            title="Salvar Avaliação"
                            [disabled]="disableCampos()">
                        <span class="texto-botao">Salvar Avaliação</span>
                    </button>
                </div>
                <div *ngIf="!disableBotao()" class="col-md-4 d-flex align-items-center">
                        <button
                                id="button-assina-avaliacao"
                                class="btn btn-primary btn-info" 
                                type="button"
                                title="Assina Avaliação"
                                [disabled]="avaliacaoPlano.parecerFinal==1">
                            <span class="texto-botao">Assina Avaliação</span>
                        </button>
                </div>
                <div *ngIf="!disableBotao()" class="col-md-4 d-flex align-items-center">
                    <button (click)="devolverPlano()" 
                            id="button-devolve-avaliacao"
                            class="btn btn-primary btn-info" 
                            type="button"
                            title="Devolver para correção"
                            [disabled]="avaliacaoPlano.parecerFinal==1">
                        <span class="texto-botao">Devolver para correção</span>
                    </button>
                </div>
                <ng-template #contentEfetivarAlt let-c="close" let-d="dismiss">
                    <div class="modal-header">
                        <h4 class="modal-title" id="modal-basic-title">Efetivar Alterações no Plano</h4>
                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>Confirma efetivação do Plano de Trabalho {{plano.planoIdentificador}} e Publicação no Portal
                            Transparência?
                        </p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" style="margin-top: 7px;" (click)="c('Close click')"
                            title="Não">Não</button>
                        <button type="button" class="btn btn-secondary" (click)="efetivarAlteracao()"
                            title="Excluir">Sim</button>
                    </div>
                </ng-template>
            </div>
        </div>       
    </div>

</div>

