import { ItemAvaliacaoPlano } from "./itemAvaliacaoPlano";

export class AvaliacaoPlano {

    public planoId: number;
    public avaliacaoId: number;
    public observacoesGerais: string;
    public parecerFinal: number;
    public portalTransparencia: number;
    public itensAvaliacao: Array<ItemAvaliacaoPlano>
    public usuarioOperacao: string;
    public operacaoData: Date;
    public alteracao:boolean;

}