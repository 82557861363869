<div>
    <BR />
    <div class="col-md-3">
        <button (click)="adicionaEvento()" [hidden]="isPortal" *ngIf="!disableCampos()" id="button-insert_event"
            style="width: 90%; margin-left: -15px;" class="btn btn-primary" type="button " title="Adiciona Evento">
            <i class="fa fa-plus" style="color: white;"><span class="texto-botao">Adicionar Evento</span> </i>
        </button>
    </div>
    <BR />
    <BR />
    <div *ngIf="outrosEventosList.length==0">
        <span class="mensagem-vazio">Nenhum evento foi encontrado.</span>
    </div>
    <table class="table" style="margin-top:10px" id="tabelaMeta" [mfData]="outrosEventosList"
        #mtOutrosEventos="mfDataTable">

        <tbody>
            <div *ngFor="let data of mtOutrosEventos.data; let o = index;">
                <!-- Linha para Descrição da Meta -->
                <div [hidden]="isOperadorResponsavel && !isFiscal">
                    <div class="row">
                        <div class="col-md-2 monitoramento tituloSecaoM" style="font-size: large;">Tipo Evento: </div>
                        <div class="col-md-10 monitoramento tituloSecaoM">
                            <input class="form-control input-tabela" id="tipoEvento" maxlength="300" name="tipoEvento"
                                #tipoEvento="ngModel" type="text" [disabled]="disableCamposFiscal()"
                                [(ngModel)]="data.tipoEvento">
                        </div>
                    </div>
                </div>
                <!-- Loop para exibir cada evento -->


                <div *ngIf="!(isOperadorResponsavel && !isFiscal)"> <!--*ngIf="isFiscal" -->
                    <div class="row">
                        <div class="col-md-12 monitoramento tituloSecaoM" style="font-size: large; margin-left: 50px;
                         margin-top: -10px;">
                            Parâmetros de Monitoramento: </div>
                    </div>
                    <!-- Linha para Frequência -->
                    <div class="row mb-2">
                        <div class="col-md-1"></div>
                        <div class="col-md-3">Frequência:</div>
                        <div class="col-md-3">
                            <select [disabled]="disableCamposFiscal()" class="form-control custom-select"
                                name="frequenciaMonitoracao" [(ngModel)]="data.frequenciaMonitoracao">
                                <option value="1">Diária</option>
                                <option value="2">Semanal</option>
                                <option value="3">Quinzenal</option>
                                <option value="4">Mensal</option>
                                <option value="5">Bimestral</option>
                                <option value="6">Quadrimestral</option>
                                <option value="7">Semestral</option>
                                <option value="8">Anual</option>
                            </select>
                        </div>
                    </div>

                    <!-- Linha para Início da Execução -->
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-3">Início da Execução:
                        </div>
                        <div class="col-md-3">
                            <app-date-selector [disabledFlag]="disableCamposFiscal()"
                                [initialDate]="data.inicioMonitoracao"
                                (dateChanged)="onDateChanged(data, $event)"></app-date-selector>
                        </div>
                        <div class="col-md-3"></div>
                    </div>
                    <div *ngFor="let responsavel of data.responsavelMonitoramento; let l = index;">

                        <div class="row mb-2">
                            <div class="col-md-1"></div>
                            <div *ngIf="l == 0" class="col-md-3 font-weight-bold">Responsável monitoramento:<span
                                    class="text-danger">*</span>
                            </div>
                            <div *ngIf="l != 0" class="col-md-3 font-weight-bold">
                            </div>
                            <div class="col-md-0"><i ngbTooltip="apagar" class="fa fa-trash-o" aria-hidden="true"
                                    *ngIf="!disableCamposFiscal()"
                                    (click)="openExcluir(contentExcluir, responsavel, l+1, data)"
                                    style="margin-top: 15px;"></i>
                            </div>
                            <div class="col-md-7">
                                <select [ngModel]="responsavel.chaveEmail" [disabled]="disableCamposFiscal()"
                                    (change)="salvaEmailResp($event, responsavel, data,'E')"
                                    class="form-control form-select custom-select">
                                    <option [value]="option.id" *ngFor="let option of emailsRespSelect">
                                        {{option.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-1"></div>
                        <div class="col-md-8 font-weight-bold">
                        </div>
                        <div class="col-md-3">
                            <button (click)="adicionaResponsavel(data, 'E')" *ngIf="!disableCampos()"
                                id="button-insert_doc" style="width: 90%;" class="btn btn-primary" type="button "
                                title="Adiciona Responsavel" [hidden]="disableCamposFiscal()">
                                <i class="fa fa-plus" style="color: white;"><span class="texto-botao">Adicionar
                                        Responsável</span> </i>
                            </button>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-1"></div>
                        <div class="col-md-3 font-weight-bold">Período de Atualização:<span class="text-danger">*</span>
                        </div>
                        <div class="col-md-8">
                            <input class="form-control input-tabela" id="periodoAtualizacao"
                                [disabled]="disableCamposFiscal()" name="periodoAtualizacao"
                                #periodoAtualizacao="ngModel" type="text" [(ngModel)]="data.periodoAtualizacao">
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-1"></div>
                        <div class="col-md-3 font-weight-bold">Alerta por e-mail:
                        </div>
                        <div class="col-md-2" style="margin-top: 10px;">
                            <input type="radio" class="form-check-input" name="'alertaEmail'+{{o+1}}" id="30D{{o+1}}"
                                value="30" [disabled]="disableCamposFiscal()"
                                style="width: 15px; height: 20px; margin-top: 1px;"
                                (change)="updateAlertaEmail($event, data)" [checked]="data.alertaEmail === 30" />
                            <label for="30D{{o+1}}" class="form-check">30 Dias antes</label>
                        </div>
                        <div class="col-md-2" style="margin-top: 10px;">
                            <input type="radio" class="form-check-input" name="'alertaEmail'+ {{o+1}}" id="15D{{o+1}}"
                                value="15" [disabled]="disableCamposFiscal()" (change)="updateAlertaEmail($event, data)"
                                [checked]="data.alertaEmail === 15"
                                style="width: 15px; height: 20px; margin-top: 1px; margin-left: -10px" />
                            <label for="15D{{o+1}}" class="form-check" style="margin-left: -10px">15 Dias
                                antes</label>
                        </div>
                        <div class="col-md-2" style="margin-top: 10px;">
                            <input type="radio" class="form-check-input" name="'alertaEmail'+ {{o+1}}" id="7D{{o+1}}"
                                value="7" style="width: 15px; height: 20px; margin-top: 1px;"
                                (change)="updateAlertaEmail($event, data)" [disabled]="disableCamposFiscal()"
                                [checked]="data.alertaEmail === 7" />
                            <label for="7D{{o+1}}" class="form-check">7 Dias antes</label>
                        </div>
                        <div class="col-md-2" style="margin-top: 10px;">
                            <input type="radio" class="form-check-input" name="'alertaEmail'+ {{o+1}}" id="0D{{o+1}}"
                                value="0" [disabled]="disableCamposFiscal()" (change)="updateAlertaEmail($event, data)"
                                [checked]="data.alertaEmail === 0"
                                style="width: 15px; height: 20px; margin-top: 1px; margin-left: -30px" />
                            <label for="0D{{o+1}}" style="margin-left: -10px">No dia da atualização</label>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-1"></div>
                        <div class="col-md-3 font-weight-bold">Observação Órgão Gestor:
                        </div>
                        <div class="col-md-8">
                            <input class="form-control input-tabela" id="observacaoOrgaoGestor" maxlength="300"
                                name="observacaoOrgaoGestor" #observacaoOrgaoGestor="ngModel" type="text"
                                [disabled]="disableCamposFiscal()" [(ngModel)]="data.observacaoOrgaoGestor">
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-1"></div>
                        <div class="col-md-3 font-weight-bold">Anexo Obrigatório:
                        </div>
                        <div class="col-md-8">
                            <input type="checkbox" id="anexoObrigatorio" style="width: 20px; height: 25px;"
                                name="anexoObrigatorio" [disabled]="disableCamposFiscal()"
                                [(ngModel)]="data.anexoObrigatorio" [value]="data.anexoObrigatorio" />


                        </div>
                    </div>
                </div>
                <div class="row">
                    <BR *ngIf="isOperadorResponsavel && !isFiscal">
                    <div *ngIf="k==0" class="col-md-12 monitoramento tituloSecaoM"
                        style="font-size: large; margin-left: 50px;">
                        Execução do Monitoramento: </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-11" style="margin-left: 40px;">
                        <thead class="custom-thead">
                            <tr>
                                <th class="col-md-4 font-weight-bold custom-header"
                                    style="background-color: #f0f0f0;padding: 5px;margin: 5px;">
                                    Evento</th>
                                <!-- Colunas de meses -->
                                <th style="background-color: #f0f0f0;padding: 5px; margin: 5px"></th>
                                <ng-container
                                    *ngFor="let chunk of splitIntoChunks(paginatedCompetenciasLista(data), 12)">
                                    <ng-container *ngFor="let item of chunk">
                                        <th class="font-weight-bold custom-header"
                                            style="background-color: #f0f0f0; padding: 5px; margin: 5px;">
                                            {{ item }}
                                        </th>
                                    </ng-container>
                                </ng-container>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- Linha da Meta -->
                            <tr>
                                <!-- Primeira atividade da Meta -->

                                <td class="col-md-4" style="padding: 5px;margin: 5px;">
                                    {{ data.tipoEvento }}
                                </td>
                                <td style="padding: 5px;margin: 5px;">Planej.</td>
                                <!-- Valores dos meses para a primeira atividade -->
                                <ng-container *ngFor="let chunk of paginatedCompetenciaListaMetaAtividade(data)">
                                    <td *ngFor="let valor of chunk">
                                        <input type="checkbox" style="width: 20px; height: 25px; padding: 5px; margin: 5px"
                                            [disabled]="true" class="form-control input-tabela"
                                            [checked]="valor?.trim().toLowerCase() === 'true'">
                                    </td>
                                </ng-container>
                            </tr>
                            <tr>
                                <td class="col-md-4" style="padding: 5px;margin: 5px;"></td>
                                <td style="padding: 5px;margin: 5px;">Realiz.</td>
                                <ng-container *ngFor="let chunk of paginatedCompetenciaListaMetaAtividadeRealizado(data)">
                                    <td *ngFor="let atividadeMetaMonitoramentoRealizado of chunk; let r = index;" >
                                        <!--<input class="form-control input-tabela"  name="atividadeMetaMonitoramentoRealizado'+{{o+1}}{{k+1}}{{r+1}}"
                                                                id="atividadeMetaMonitoramentoRealizado{{o+1}}{{k+1}}{{r+1}}" 
                                                                #AMMRmodel="ngModel"  type="text" [(ngModel)]="atividadeMetaMonitoramentoRealizado">
                                                                -->
                                        <input type="checkbox" *ngIf="habilitaRealizado(atividadeMetaMonitoramentoRealizado) "
                                            [disabled]="disableCamposOperadorResponsavel(data)" class="form-control input-tabela"
                                            [ngModelOptions]="{standalone: true}" 
                                            [(ngModel)]="atividadeMetaMonitoramentoRealizado.realizado" 
                                            style="width: 20px; height: 25px;padding: 5px;margin: 5px;">
                                    </td>
                                </ng-container>
                            </tr>
                            <tr>
                                <td style="padding: 5px;margin: 5px;"></td>
                                <td style="padding: 5px;margin: 5px;">Arquivo</td>
                                <ng-container *ngFor="let chunk of paginatedCompetenciaListaMetaAtividadeRealizado(data)">
                                    <td *ngFor="let atividadeMetaMonitoramentoRealizado of chunk; let r = index;"
                                        style="padding: 5px;margin: 5px;">
                                        <!--<input class="form-control input-tabela"  name="atividadeMetaMonitoramentoRealizado'+{{o+1}}{{k+1}}{{r+1}}"
                                                                id="atividadeMetaMonitoramentoRealizado{{o+1}}{{k+1}}{{r+1}}" 
                                                                #AMMRmodel="ngModel"  type="text" [(ngModel)]="atividadeMetaMonitoramentoRealizado">
                                                                -->
                                        <div *ngIf="verificaUploadAnexo(atividadeMetaMonitoramentoRealizado)">
                                            <i ngbTooltip="anexar" (click)="fileInput.click()" *ngIf="!disableCamposOperadorResponsavel(data)"
                                                class="fa fa-upload justify-content-center" style="font-size:25px;" aria-hidden="true">
                                            </i>
                                            <input type="file" #fileInput style="display: none" accept=".pdf"
                                                [disabled]="disableCamposOperadorResponsavel(data)"
                                                (change)="onFileChanged($event, atividadeMetaMonitoramentoRealizado)" />
                                        </div>
                                        <div *ngIf="verificaExibicaoAnexo(atividadeMetaMonitoramentoRealizado)">
                                            <i ngbTooltip="download {{atividadeMetaMonitoramentoRealizado.nomeArquivoAnexado}}"
                                                class="fa fa-download justify-content-center" style="font-size:25px;" aria-hidden="true"
                                                (click)="getArquivo(atividadeMetaMonitoramentoRealizado, atividade.parametrosMonitoramento)"
                                                alt="download"></i>
                                            <i style="margin-left: 10px;" class="fa fa-times" aria-hidden="true"
                                                *ngIf="!disableCamposOperadorResponsavel(data)" (click)="atividadeMetaMonitoramentoRealizado.nomeArquivoAnexadoExcluido=atividadeMetaMonitoramentoRealizado.nomeArquivoAnexado;
                                                                        atividadeMetaMonitoramentoRealizado.nomeArquivoAnexado=undefined; 
                                                                        atividadeMetaMonitoramentoRealizado.arquivo= undefined;"
                                                alt="download"></i>
                                        </div>
                                    </td>
                                </ng-container>
                            </tr>
                            <tr>
                                <td class="col-md-4" style="padding: 5px;margin: 5px;"></td>
                                <td style="padding: 5px;margin: 5px;">Dado Sensível</td>
                                <ng-container *ngFor="let chunk of paginatedCompetenciaListaMetaAtividadeRealizado(data)">
                                    <td *ngFor="let atividadeMetaMonitoramentoRealizado of chunk; let r = index;" >

                                        <input type="checkbox" *ngIf="habilitaRealizado(atividadeMetaMonitoramentoRealizado) "
                                            [disabled]="disableCamposOperadorResponsavel(data)" class="form-control input-tabela"
                                            [ngModelOptions]="{standalone: true}" 
                                            [(ngModel)]="atividadeMetaMonitoramentoRealizado.dadoSensivel" 
                                            style="width: 20px; height: 25px;padding: 5px;margin: 5px;">
                                    </td>
                                </ng-container>
                            </tr>                            
                        </tbody>
</div>
</div>
<div class="pagination pagination justify-content-center" *ngIf="competenciasLista.length > 0">
    <i (click)="changePage(data, data.currentPage - 1)"
        [ngStyle]="{ 'pointer-events': data.currentPage === 1 ? 'none' : 'auto' }" class="fa fa-chevron-circle-left"
        style="font-size:36px; padding-right:10px"> </i>

    <span> Página {{data.currentPage}} de {{totalPages}} </span>

    <i (click)="changePage(data, data.currentPage + 1)"
        [ngStyle]="{ 'pointer-events': data.currentPage === totalPages ? 'none' : 'auto' }"
        class="fa fa-chevron-circle-right" style="font-size:36px; padding-left:10px"> </i>

</div>
<div class="row mb-2">
    <div class="col-md-1"></div>
    <div class="col-md-3 font-weight-bold">Observação Órgão Gestor:
    </div>
    <div class="col-md-8">
        {{data.observacaoOrgaoGestor}}
    </div>
</div>
<div class="row mb-2">
    <div class="col-md-1"></div>
    <div class="col-md-3 font-weight-bold">Observação Responsável Monitoramento:
    </div>
    <div class="col-md-8">
        <input class="form-control input-tabela" id="observacaoResponsavelMonitoramento" maxlength="300"
            [disabled]="disableCamposOperadorResponsavel(data)" name="observacaoResponsavelMonitoramento"
            #observacaoResponsavelMonitoramento="ngModel" type="text"
            [(ngModel)]="data.observacaoResponsavelMonitoramento">
    </div>
</div>
<div class="row mb-2" *ngIf="data.responsavelNomeUltimaMonitoracao != undefined">
    <div class="col-md-1"></div>
    <div class="col-md-11 font-weight-bold">Monitoramento realizado em {{data.operacapdata
        | date: 'dd/MM/yyyy'}} - {{dataresponsavelNomeUltimaMonitoracao}} - Responsável
        Técnico
    </div>
</div>
<BR />
</div>
</tbody>
</table>

<ng-template #contentExcluir let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Excluir Responsável</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Tem certeza que deseja excluir o responsável:
            <b>{{indiceResp}}</b>?
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" style="margin-top: 7px;" (click)="c('Close click')"
            title="Fechar">Fechar</button>
        <button type="button" class="btn btn-secondary" (click)="excluirResponsavel()" title="Excluir">Excluir</button>
    </div>
</ng-template>
</div>