import { AtividadeMeta } from "../../planoDeTrabalho/meta/atividadeMeta";
import { Meta } from "../../planoDeTrabalho/meta/meta";
import { AtividadeMetaMonitoramentoRealizado } from "./atividadeMetaMonitoramentoRealizado";
import { ResponsavelMonitoramento } from "./responsavelMonitoramento";


export class ParametrosMonitoramento {

    public monitoramentoId: number;
    public parametroId: number;
    public meta: Meta;
    public atividadeMeta: AtividadeMeta;
    public responsavelMonitoramento: Array<ResponsavelMonitoramento>; 
    public periodoAtualizacao: String;
    public alertaEmail: number;
    public observacaoOrgaoGestor: string;
    public efetivacaoMonitoramento:boolean;
    public operacaoData: Date;
    public operacaoUsuario: string;
    public frequenciaMonitoracao: number;
    public inicioMonitoracao: Date;
    public anexoObrigatorio:boolean;
    public observacaoResponsavelMonitoramento:string;
    public atividadeMetaMonitoramentoRealizado:Array<AtividadeMetaMonitoramentoRealizado>; 
    public responsavelNomeUltimaMonitoracao:string;
    public tipoEvento:string;
    public currentPage:number;
    public isOperadorResponsavel:boolean;

}
