<div *ngIf="parceriaInfo != undefined">
    <div *ngIf="gestores.length==0">
        <span class="mensagem-vazio">Nenhum gestor foi encontrado.</span>
    </div>
    <div *ngIf="gestores.length>0">
        <table class="table table-striped" style="margin-top:30px" id="tabelaGestor" [mfData]="gestores" #mtGestor="mfDataTable" [mfRowsOnPage]="5">

            <thead>
                <tr>
                    <th *ngIf="(!isPortal)" data-html2canvas-ignore="true"></th>
                    <th *ngIf="!disableApagarIcone()" data-html2canvas-ignore="true"></th>
                    <th>Matrícula</th>
                    <th>Nome</th>
                    <th>Data Início</th>
                    <th>Data Fim</th>
                    <th>Papel</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of mtGestor.data; let j = index;">

                    <td *ngIf="!isPortal" class="col-auto" data-html2canvas-ignore="true">
                        <i ngbTooltip="editar" *ngIf="enableEditarIcone()" class="fa fa-pencil" aria-hidden="true" (click)="openEdicao(contentEdicao, data)"></i>
                        <i ngbTooltip="visualizar" *ngIf="visualizarBotao()" class="fa fa-search" aria-hidden="true" (click)="openEdicao(contentEdicao, data)"></i>
                    </td>
                    <td *ngIf="!disableApagarIcone()" class="col-auto" data-html2canvas-ignore="true">
                        <i ngbTooltip="apagar" class="fa fa-trash-o" aria-hidden="true" (click)="openExcluir(contentExcluir, data)"></i>
                    </td>

                    <td class="col-auto">{{data.gestorMatricula}}</td>
                    <td class="col-auto">{{data.gestorNome}}</td>
                    <td class="col-auto">{{data.gestorDataInicioDesignacao | date: 'dd/MM/yyyy'}}</td>
                    <td class="col-auto">{{data.gestorDataFimDesignacao | date: 'dd/MM/yyyy'}}</td>
                    <td class="col-auto">{{getGestorPerfilNome(data.gestorPerfil)}}</td>
                </tr>
            </tbody>
            <tfoot data-html2canvas-ignore="true ">
                <tr>
                    <td colspan="12 ">
                        <div *ngIf="gestores.length>5" class="resultPorPagina">Resultados por página:</div>
                        <mfBootstrapPaginator [rowsOnPageSet]="[5,10,25]"></mfBootstrapPaginator>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
    <div *ngIf="!disableCampos()">
        <button (click)="openEdicao(contentEdicao, undefined) " id="button-insert_doc " class="btn btn-primary btn-info" type="button " title="Adicionar novo" [hidden]="parceriaInfo.parId===-1">
                    <i class="fa fa-plus "><span class="texto-botao">Adicionar novo</span> </i>
                </button>
    </div>

    <ng-template #contentEdicao let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Gestor</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                        <span aria-hidden="true">&times;</span>
                    </button>
        </div>
        <form (ngSubmit)="salvaGestor()" #edicao="ngForm" novalidate>
            <div class="modal-body" *ngIf="gestoresSend">
                <div class="form-group col-md-6">
                    <label for="matricula">Matrícula</label>
                    <span [hidden]="matricula.valid" class="campoObrigatorio">*</span> 
                    <input style="width: 100%" id="matricula" class="form-control" name="matricula" #matricula="ngModel" type="text" [textMask]="mascaraNroMat" [(ngModel)]="gestoresSend.gestorMatricula" (focusout)="gestoresSend.gestorNome=buscaNomeGestor(gestoresSend.gestorMatricula)" placeholder="Inserir Matrícula" required>
                    <i class="fa fa-times icon_input_fontawsome" aria-hidden="true" (click)="gestoresSend.matricula=undefined; showCamposEdicao=false" title="limpar"></i>
                </div>
                <div class="form-group col-md-6">
                    <label for="perfil">Perfil</label>
                    <select class="form-control custom-select" name="perfil"
                    [(ngModel)]="gestoresSend.gestorPerfil">
                        <option [value]="option.id" *ngFor="let option of this.utils.perfilGestor">{{option.name}}
                        </option>
                    </select>
                </div>
                <div class="form-group col-md-6">
                  <label for="nomeGestor">Nome Gestor</label>
                  <input style="width:100%;" id="nomeGestor" class="form-control" name="gestor" #gestor="ngModel" type="text" [(ngModel)]="gestoresSend.gestorNome" [disabled]="true" required>
                </div>
                <div class="form-group col-md-6">
                    <label for="nomeGestor">E-mail Gestor</label>
                    <input style="width:100%;" id="emailGestor" class="form-control" name="gestorEmail" #gestorEmail="ngModel" type="text" [(ngModel)]="gestoresSend.gestorEmail" maxlength="50"  emailvalidator required>
                  </div>
                <div class="form-group col-md-6">
                    <label for="dataI">Data Início Designação</label>
                    <span [hidden]="dataI.valid" class="campoObrigatorio">*</span>
                    <input style="width:100%" class="form-control" name="dataI" #dataI="ngModel" 
                    maxlength="10" placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="gestoresSend.gestorDataInicioDesignacaoFormat"
                    [(ngModel)]="gestoresSend.gestorDataInicioDesignacaoFormat" ngbDatepicker #dI="ngbDatepicker" required>
                    <i class="fa fa-calendar icon" (click)="dI.toggle()" title="calendário"></i>
                </div>
                <div class="form-group col-md-6">
                    <label for="dataF">Data Fim Designação</label>
                    <input style="width:100%" class="form-control" name="dataF" #dataF="ngModel" 
                    maxlength="10" placeholder="dd/mm/aaaa" appMaskInput [appMaskValue]="gestoresSend.gestorDataFimDesignacaoFormat" 
                    [(ngModel)]="gestoresSend.gestorDataFimDesignacaoFormat" ngbDatepicker #dF="ngbDatepicker">
                    <i class="fa fa-calendar icon" (click)="dF.toggle()" title="calendário"></i>
                </div>

            </div>
            <div *ngIf="!visualizarBotao()" class="modal-footer col-md-12">
                <button type="button" class="btn btn-default" (click)="c('Close click');setGestores(undefined)" title="Cancelar">Cancelar</button>
                <button type="submit" class="btn btn-primary" [disabled]="!edicao.form.valid" title="Salvar">Salvar</button>
            </div>
        </form>
    </ng-template>
    <ng-template #contentExcluir let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Excluir Gestor</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                        <span aria-hidden="true">&times;</span>
                    </button>
        </div>
        <div class="modal-body">
            <p>Tem certeza que deseja excluir o Gestor
                <b>{{gestoresSend.gestorMatricula}} - {{gestoresSend.gestorNome}}</b>?</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')" title="Fechar">Fechar</button>
            <button type="button" class="btn btn-secondary" (click)="excluirGestor()" style="margin-top: -2px;" title="Excluir">Excluir</button>
        </div>
    </ng-template>
</div>