<div *ngIf="!prod" class="homologacao">
  Você está usando o ambiente de homologação; as informações inseridas aqui não possuem validade jurídica.
</div>
<div class="header">

  <div class="container-fluid">
    <a href="/" title="Home" rel="home" class="home-link">
      <span class="site-title">SGP - Sistema de Gestão de Parcerias <br><small class="text-uppercase">Prefeitura de
          Porto Alegre</small></span>
    </a>

    <nav class="navbar navbar-expand-lg" [hidden]='isPortal'>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown" [hidden]='isPortal'>
        <ul class="navbar-nav">

          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" *ngIf='isFiscal || isConsulta || isAuditorExterno'
              [routerLink]="['planejamento']" [queryParams]="{ isAuditorExterno: isAuditorExterno}"
              queryParamsHandling="merge" (click)="activeChildren(false)">Planejamento <span
                class="sr-only">(current)</span></a>
          </li>
          <!-- //  StandBy pelo usuário
          <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="['beneficiario']" (click)="activeChild(false)">Beneficiário <span class="sr-only">(current)</span></a>
          </li>
          -->
          <li class="nav-item dropdown" ngbDropdown>
            <a class="nav-link dropdown-toggle" *ngIf='!isFiscal && !isConsulta && !isAuditorExterno'
              [ngClass]="childClickedCad ? 'active': ' '" routerLinkActive="active" id="dropdownCad"
              ngbDropdownToggle>Cadastro</a>
            <div class="dropdown-submenu" ngbDropdownMenu aria-labelledby="dropdownCad">
              <div><a class="dropdown-item" routerLinkActive="active" [routerLink]="['cadastro/colaboradores']"
                  skipLocationChange="true" (click)="activeChildCad(true)" href="#">Colaboradores</a>

              </div>
              <div><a class="dropdown-item" routerLinkActive="active" [routerLink]="['cadastro/prestadorServTerc']"
                  skipLocationChange="true" (click)="activeChildCad(true)" href="#">Prestadores de Serviços de Terceiros</a>
              </div>
            </div>
          </li>
          <!--
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['execucao']"
              (click)="activeChildren(false)" (click)="openExecucao()">
              Execução
            </a>
          </li>
-->
          <li class="nav-item dropdown" ngbDropdown>
            <a class="nav-link" routerLinkActive="active" *ngIf='isFiscal'
            [routerLink]="['execucao/exibeParceriaEntidadeM2']" [queryParams]="{ isAuditorExterno: isAuditorExterno}"
            queryParamsHandling="merge" (click)="activeChildren(false)">Execução <span
              class="sr-only">(current)</span></a>

            <a *ngIf='!isFiscal && !isConsulta && !isAuditorExterno && !isPortal'class="nav-link dropdown-toggle" 
              [ngClass]="childClickedExec ? 'active': ' '" routerLinkActive="active" id="dropdownExec"
              ngbDropdownToggle>Execução</a>
            <div *ngIf='!isFiscal && !isConsulta && !isAuditorExterno && !isPortal' class="dropdown-submenu" ngbDropdownMenu aria-labelledby="dropdownExec">
              <div><a class="dropdown-item" routerLinkActive="active" [routerLink]="['execucao/exibeParceriaEntidadeM2']"
                [queryParams]="{ isExecPT: false, isAuditorExterno: isAuditorExterno}"
                  skipLocationChange="true" (click)="activeChildExec(true);openExecucao()" href="#">Financeira</a>

              </div>
              <div *ngIf='!isFiscal && !isConsulta && !isAuditorExterno && !isPortal'>
                <a class="dropdown-item" routerLinkActive="active" [routerLink]="['execucao/planoDeTrabalho/:id']" [queryParams]="{ isExecPT: true, isAuditorExterno: isAuditorExterno}"
                  skipLocationChange="true" (click)="activeChildExec(true);openPlanoTrabalho()" href="#">Plano de Trabalho</a>
              </div>
            </div>
          </li>



          <li class="nav-item dropdown" ngbDropdown>
            <a class="nav-link dropdown-toggle" *ngIf='isFiscal || isConsulta || isAuditorExterno'
              [ngClass]="childAuditoriaClicked ? 'active ': ' '" routerLinkActive="active" id="dropdownA"
              ngbDropdownToggle>Auditoria</a>
            <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownA">
              <div class="dropdown-submenu" ngbDropdown>
                <div>
                  <a class="dropdown-item" *ngIf='isFiscal || isConsulta || isAuditorExterno' routerLinkActive="active"
                    [routerLink]="['auditoria/grafico']" skipLocationChange="true" (click)="activeAuditoriaChild(true)"
                    href="#">Gráfico de níveis operacionais</a>
                </div>
                <div>
                  <a class="dropdown-item" *ngIf='isFiscal && isSMTC' routerLinkActive="active"
                    [routerLink]="['auditoria/parametrizacao']" (click)="activeAuditoriaChild(true)"
                    href="#">Parametrização</a>
                </div>
                <div>
                  <a class="dropdown-item" *ngIf='isFiscal && isSMTC' routerLinkActive="active"
                    [routerLink]="['auditoria/priorizacaoCompleta']" (click)="activeAuditoriaChild(true)"
                    href="#">Priorização Completa</a>
                </div>
                <div>
                  <a class="dropdown-item" *ngIf='isFiscal && isSMTC' routerLinkActive="active"
                    [routerLink]="['auditoria/visitacao']" (click)="activeAuditoriaChild(true)" href="#">Visitação</a>
                </div>
              </div>
            </div>
          </li>

          <li class="nav-item dropdown" ngbDropdown>
            <a class="nav-link dropdown-toggle" *ngIf='isFiscal || isConsulta || isSMTC'
              [ngClass]="childClickedLib ? 'active ': ' '" routerLinkActive="active" id="dropdownLib"
              ngbDropdownToggle>Liberação Login</a>
            <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownLib">
              <div class="dropdown-submenu" ngbDropdown>
                <div>
                  <a class="dropdown-item" *ngIf='isFiscal || isConsulta' routerLinkActive="active"
                    [routerLink]="['logon/exibeParceriasLogon']" (click)="activeLiberacaoChild(true)" href="#">OSCs</a>
                </div>
                <div>
                  <a class="dropdown-item" *ngIf='isSMTC' routerLinkActive="active"
                    [routerLink]="['logon/auditorLogon']" (click)="activeLiberacaoChild(true)" href="#">Auditor
                    Externo</a>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" *ngIf='isFiscal || isConsulta' [routerLink]="['trava']"
              (click)="activeChildren(false)">Trava <span class="sr-only">(current)</span></a>
          </li>

          <li class="nav-item dropdown" ngbDropdown *ngIf='isFiscal || isConsulta || isAuditorExterno '>
            <a class="nav-link dropdown-toggle" [ngClass]="childClicked ? 'active ': ' '" routerLinkActive="active"
              id="dropdown0" ngbDropdownToggle>Relatórios</a>
            <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdown0">
              <div class="dropdown-submenu" ngbDropdown>
                <a tabindex="-1" class="dropdown-item" id="dropdown1" ngbDropdown>Execução</a>
          <li class="dropdown-submenu" ngbDropdownMenu aria-labelledby="dropdown1">
            <div class="dropdown-submenu">
              <a tabindex="-1" class="dropdown-item" ngbDropdown>Financeira</a>
              <div class="dropdown-menu">
                <div><a class="dropdown-item" routerLinkActive="active" [routerLink]="['relatorio/desembolso']"
                    [queryParams]="{ isAuditorExterno: isAuditorExterno}" queryParamsHandling="merge"
                    skipLocationChange="true" (click)="activeChild(true)" href="#">Desembolso</a></div>
                <div class="dropdown-submenu">
                  <a tabindex="-1" class="dropdown-item" ngbDropdown>Documentos</a>
                  <div class="dropdown-menu">
                    <div><a class="dropdown-item" routerLinkActive="active" [routerLink]="['relatorio/documento']"
                        [queryParams]="{ isAuditorExterno: isAuditorExterno}" queryParamsHandling="merge"
                        skipLocationChange="true" (click)="activeChild(true)" href="#">Demonstrativo de Desembolso</a>
                    </div>
                    <div><a class="dropdown-item" routerLinkActive="active" [routerLink]="['relatorio/docComplArquivo']"
                        [queryParams]="{ isAuditorExterno: isAuditorExterno}" queryParamsHandling="merge"
                        skipLocationChange="true" (click)="activeChild(true)" href="#">Documento Complementar</a></div>
                    <div><a class="dropdown-item" routerLinkActive="active"
                        [routerLink]="['relatorio/devolAplicArquivo']"
                        [queryParams]="{ isAuditorExterno: isAuditorExterno}" queryParamsHandling="merge"
                        skipLocationChange="true" (click)="activeChild(true)" href="#">Devolução e/ou
                        Aplicação/Poupança</a></div>
              </div>
                  
                </div>
                <div><a class="dropdown-item" routerLinkActive="active" [routerLink]="['relatorio/resumo']"
                    [queryParams]="{ isAuditorExterno: isAuditorExterno}" queryParamsHandling="merge"
                    skipLocationChange="true" (click)="activeChild(true)" href="#">Resumo</a></div>

                <div><a class="dropdown-item" routerLinkActive="active" [routerLink]="['relatorio/statusConfTermo']"
                    [queryParams]="{ isAuditorExterno: isAuditorExterno}" queryParamsHandling="merge"
                    skipLocationChange="true" (click)="activeChild(true)" href="#">Status de Conferência por Termo</a>
                </div>
                <div><a class="dropdown-item" routerLinkActive="active" [routerLink]="['relatorio/valoresPagos']"
                    [queryParams]="{ isAuditorExterno: isAuditorExterno}" queryParamsHandling="merge"
                    skipLocationChange="true" (click)="activeChild(true)" href="#">Valores Pagos</a></div>
                <div><a class="dropdown-item" routerLinkActive="active" *ngIf='isSaude || isSMTC || isConsulta || isAuditorExterno '  [routerLink]="['relatorio/gastoPessoal']"
                      [queryParams]="{ isAuditorExterno: isAuditorExterno}" queryParamsHandling="merge"
                      skipLocationChange="true" (click)="activeChild(true)" href="#">Gastos de Pessoal – SMS</a></div>    
              </div>
            </div>
            <!--  //  StandBy pelo usuário
                      <div class="dropdown-submenu"><a class="dropdown-item" tabindex="-1" ngbDropdown>Objeto</a>
                          <div class="dropdown-menu">
                              <div><a class="dropdown-item" routerLinkActive="active" [routerLink]="['relatorio/frequencia']" (click)="activeChild(true)" href="#">Frequência</a></div>
                              <div><a class="dropdown-item" routerLinkActive="active" [routerLink]="['relatorio/turmas']" (click)="activeChild(true)" href="#">Turmas</a></div>
                              <div><a class="dropdown-item" routerLinkActive="active" [routerLink]="['relatorio/beneficiarios']" (click)="activeChild(true)" href="#">Beneficiários</a></div>
                          </div>
                      </div>
                  -->
          </li>
      </div>
      <div class="dropdown-submenu" ngbDropdown>
        <a tabindex="-1" class="dropdown-item" id="dropdown2" ngbDropdown>Planejamento</a>
        <li class="dropdown-submenu" ngbDropdownMenu aria-labelledby="dropdown2">
          <div class="dropdown-submenu">
            <div><a class="dropdown-item" routerLinkActive="active" [routerLink]="['relatorio/parcerias']"
                [queryParams]="{ isAuditorExterno: isAuditorExterno}" queryParamsHandling="merge"
                skipLocationChange="true" (click)="activeChild(true)" href="#">Parceria</a></div>
            <div><a class="dropdown-item" routerLinkActive="active" [routerLink]="['relatorio/termos']"
                [queryParams]="{ isAuditorExterno: isAuditorExterno}" queryParamsHandling="merge"
                skipLocationChange="true" (click)="activeChild(true)" href="#">Termo</a></div>
          </div>
        </li>
        <div>
          <a class="dropdown-item" routerLinkActive="active" [routerLink]="['relatorio/notificacoes']"
            [queryParams]="{ isAuditorExterno: isAuditorExterno}" queryParamsHandling="merge" skipLocationChange="true"
            (click)="activeChild(true)" href="#">Notificações</a>
        </div>
        <div>
          <a class="dropdown-item" routerLinkActive="active" [routerLink]="['relatorio/logStatusNotificacoes']"
            [queryParams]="{ isAuditorExterno: isAuditorExterno}" queryParamsHandling="merge" skipLocationChange="true"
            (click)="activeChild(true)" href="#">Logs das Notificações</a>
        </div>
        <div>
          <a class="dropdown-item" routerLinkActive="active" [routerLink]="['relatorio/listaColaboradoresPlanilha']"
            [queryParams]="{ isAuditorExterno: isAuditorExterno}" queryParamsHandling="merge" skipLocationChange="true"
            (click)="activeChild(true)" href="#">Planilha de Colaboradores</a>
        </div>
      </div>
  </div>
  </li>
  </ul>
</div>
</nav>
</div>
</div>
<div class="float-right posicao-info-right" [hidden]='isPortal '>
  <div class="notificacao" [ngbPopover]="notificacoes" placement="bottom">
    <i class="fa fa-bell"></i>
    <div class="qtde-notificacoes" *ngIf="contNotificacoes > 0">{{contNotificacoes}}</div>
  </div>
  <div class="usuario">Bem-vindo, <b>{{nome}}</b></div>
  <div class="sair_header">
    <a (click)="logout()"> Sair <i class="fa fa-sign-out" aria-hidden="true"></i> </a>
  </div>
</div>

<ng-template #notificacoes>
  <div class="lista_notificacoes" *ngIf="notificacoesSininho.length>0">
    <ul>
      <li *ngFor="let data of notificacoesSininho">
        <i class="fa fa-close" (click)="ocultaNotificacao(data.notifItemId)"></i> <span
          [innerHTML]="data.textoSininho"></span>
      </li>
    </ul>
    <div class="text-center">
      <a routerLinkActive="active" [routerLink]="['notificacoes/verNotificacoes']" href="#">Ver todas notificações</a>
    </div>
  </div>
  <div class="lista_notificacoes" *ngIf="notificacoesSininho.length==0">
    <span>Não há notificações.</span>
  </div>
</ng-template>