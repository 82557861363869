import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DateConverter } from '../../../@core/date-converter';
import { filter, share, switchMap } from 'rxjs/operators';
import { AlertService } from '../../../@core/alert.service';
import { ParceriaInfo } from './../../detalheParceria/parceriaInfo';
import { DetalheParceriaPortalApi } from '../../../apis/detalheParceriaPortalApi';
import { Mascaras } from '../../../@shared/components/Mascaras';
import { UserService } from '../../../@core/user.service';
import { Utils } from 'src/app/@shared/utils';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DetalheParceriaApi } from 'src/app/apis/detalheParceriaApi';
import { ExecucaoPlanoDeTrabalho } from './../execucaoPlanoDeTrabalho';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';
import { forEach } from 'lodash';
import { PaginationInstance } from 'ngx-pagination/dist/pagination-instance';
import { Meta } from './meta';
import { AtividadeMeta } from './atividadeMeta';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-meta-plano',
  templateUrl: './meta.component.html',
  styleUrls: ['../../informacaoParceria/informacaoParceria.component.scss']
})
export class MetaComponent implements OnInit {

  @Input()
  public plano: ExecucaoPlanoDeTrabalho;
  @Input()
  public isPortal: boolean;
  @Input()
  public isCriarSA: boolean;
  @Output() valueChange = new EventEmitter();
  @Input()
  public modulo: string;
  isFiscal: boolean;
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  isAuditorExterno: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  isExecPT: boolean;
  isPlanPT: boolean;
  parId: number;
  username: string;
  metaDoPlanoLista: Array<Meta> = [];
  novaMeta: Meta;
  planoVisualizado: ExecucaoPlanoDeTrabalho;
  qtdPlano: number;
  competenciasLista: String[] = [];
  competenciaListaMetaAtividade: String[] = [];
  verPlano: boolean = false;
  p = 1;
  pagina: number;
  modalEdicaoReference: any;
  metaAserApagada: Meta;
  atividadeAserApagada: AtividadeMeta;
  indiceAtividade: number;
  indiceMeta: number;
  @Output() metaUpdated = new EventEmitter<Meta[]>();

  public config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 12,
    currentPage: 1
  };

  metaContext = {

    isPortal: false,
    modulo: "m2",
  };

  constructor(private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    public userService: UserService,
    private parceriaApi: PublicoPrivadaApi,
    private mascaras: Mascaras,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    private utils: Utils,
    private route: ActivatedRoute) {


  }

  ngOnInit() {

    this.userService.userInfo.subscribe(user => this.username = user.username);
    this.isFiscal = this.userService.isFiscal();
    this.isConsulta = this.userService.isConsulta();
    this.isSMTC = this.userService.isSMTC();
    this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
      this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
    });

    console.log("modulo");
    console.log(this.modulo);

    if (this.modulo === undefined) {
      this.modulo = 'm2';
    }
    if (this.isPortal === undefined) {
      this.isPortal = false;
    }
    //console.log(this.isPortal);

    this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
      this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
    });

    this.route.queryParams.pipe(filter(params => params.isExecPT)).subscribe(params => {
      this.isExecPT = (params['isExecPT'] === undefined || params['isExecPT'].toLowerCase() === 'false' ? false : true)
    });
    console.log(this.isExecPT);

    this.route.queryParams.pipe(filter(params => params.isPlanPT)).subscribe(params => {
      this.isPlanPT = (params['isPlanPT'] === undefined || params['isPlanPT'].toLowerCase() === 'false' ? false : true)
    });

    this.setupInterface(this.isCriarSA);
  }


  setupInterface(fazCopia) {


    if (this.plano != undefined) {

      this.loading.next(true);

      if (!(this.plano.planoId == undefined && this.plano.planoPai == undefined))
      this.parceriaApi.getMetasPlano(this.plano.planoId === undefined ? this.plano.planoPai : this.plano.planoId, this.isPortal).subscribe((response) => {
        if (response) {
          this.metaDoPlanoLista = response as Array<Meta>;
          //console.log(this.metaDoPlanoLista[0]);
          if (fazCopia) {
            this.metaDoPlanoLista = this.metaDoPlanoLista.map(element =>
              element = this.utils.copyWithoutProps(element, ['planoId', 'metaId', 
                'metaOperacaoData', 'metaOperacaoUsuario']))                
          }

          this.metaUpdated.emit(this.metaDoPlanoLista);
          //  this.planoVisualizado = this.metaDoPlanoLista[0];
          this.calculaCompetencia();
          console.log('Chunks:', this.splitIntoChunks(this.paginatedCompetenciasLista, 12));
        } else {
          this.alertService.warning('Nenhum registro encontrado');
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
    }
  }
  visualizaMeta(planoVisualizado, fazCopia) {
    this.plano = planoVisualizado;
    this.setupInterface(fazCopia);


  }

  disableCampos() {
    if (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno || this.isFiscal || this.plano == undefined) {
      return true;
    }

    if (this.plano.planoSituacao == 2 || this.plano.planoSituacao == 4) {
      return true;
    }
    return false;
  }

  disableBotao() {
    if (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno || this.isFiscal || this.plano == undefined) {
      return true;
    }

    if (this.plano.planoSituacao == 2 || this.plano.planoSituacao == 4) {
      return true;
    }
    return false;
  }
  disableApagarIcone() {
    return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno || this.plano == undefined);
  }
  enableEditarIcone() {
    if (this.isFiscal || this.isConsulta || this.isSMTC || this.isAuditorExterno || this.plano == undefined) {
      return false;
    }
    return true;
  }


  adicionarMeta() {
    let meta = new Meta()
    meta.planoId = this.plano.planoId;
    meta.metaId = undefined;
    let ativLista = new Array<AtividadeMeta>();
    let atividade = new AtividadeMeta();
    atividade.atividadeInicioExecucao = new Date();
    atividade.meta = meta;
    ativLista.push(atividade);
    meta.metaAtividades = ativLista;
    this.metaDoPlanoLista.push(meta);

    //this.metaDoPlanoLista = [...this.metaDoPlanoLista, meta];
    this.metaUpdated.emit(this.metaDoPlanoLista);

    console.log('Nova lista de metas:', this.metaDoPlanoLista);
  }

  adicionarAtividade(data) {
    let atividade = new AtividadeMeta();
    atividade.meta = data;
    //atividade.atividadeId = undefined;
    atividade.atividadeInicioExecucao = new Date();
    data.metaAtividades.push(atividade);
    this.metaUpdated.emit(this.metaDoPlanoLista);
   
  }

  excluirMeta() {

    const index = this.metaDoPlanoLista.indexOf(this.metaAserApagada); // Encontra o índice do item
    if (index > -1 && this.metaAserApagada.metaId != undefined) {
      this.detalheParceriaApi.excluirMetaAtividades(this.metaAserApagada).subscribe(async (response) => {
        if (response) {
          this.metaDoPlanoLista.splice(index, 1); // Remove o item com base no índice
          this.metaUpdated.emit(this.metaDoPlanoLista);
          this.modalEdicaoReference.close();
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));

    }
    else {
      this.metaDoPlanoLista.splice(index, 1); // Remove o item com base no índice
      this.modalEdicaoReference.close();
    }
  }

  excluiAtividade() {
    let index = this.indiceAtividade.toString().split(".");
    let indexM = Number(index[0]) - 1;
    let indexA = Number(index[1]) - 1;
    if (this.metaDoPlanoLista[indexM].metaAtividades[indexA] == this.atividadeAserApagada) {

      if (indexA > -1 && this.atividadeAserApagada.atividadeId != undefined) {
        this.detalheParceriaApi.excluirAtividade(this.atividadeAserApagada).subscribe(async (response) => {
          if (response) {
            this.metaDoPlanoLista[indexM].metaAtividades.splice(indexA, 1); // Remove o item com base no índice
            this.metaUpdated.emit(this.metaDoPlanoLista);
            this.modalEdicaoReference.close();
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        }, () => this.loading.next(false));
      }
      else {
        this.metaDoPlanoLista[indexM].metaAtividades.splice(indexA, 1);
        //this.metaDoPlanoLista.splice(indexA, 1); // Remove o item com base no índice
        this.modalEdicaoReference.close();
      }
    }  
  }

  openExcluirMeta(content, data, index) {
    this.metaAserApagada = data;
    this.indiceMeta = index;
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
  }
  openExcluirAtividade(content, data, index) {
    this.atividadeAserApagada = data;
    this.indiceAtividade = index;
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
  }

  onDateChanged(atividade, date: Date): void {
    atividade.atividadeInicioExecucao = date;
  }

  splitIntoChunks(array: any[], chunkSize: number) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }

  removeCircularReferences() {
    const seen = new WeakSet();
    return function (key: any, value: any) {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          console.log('Circular reference detected, ignoring:', key, value);
          return; // Skip circular reference
        }
        seen.add(value);
      }
      return value;
    };
  }

  validaCamposObrigatorios(metaDoPlanoListaPai) {
    let foundIssue = false;
    let foundDateIssue = false;

    metaDoPlanoListaPai.forEach(element => {
      element.metaOperacaoData = new Date();
      element.metaOperacaoUsuario = this.username;
      if (element.metaAtividades.length == 0) {
        foundIssue = true;
        
      }
      if (this.isBlank(element.metaMeioVerificacao) || this.isBlank(element.metaDescricao) ||
        this.isBlank(element.metaParamVerificacao)) {
        foundIssue = true;
       }
      element.metaAtividades.forEach(el => {
        el.atividadeOperacaoData = new Date();
        el.atividadeOperacaoUsuario = this.username;
        if (this.isBlank(el.atividadeDescricao) || this.isBlank(el.atividadeFrequencia)
          || this.isBlank(el.atividadeTipoApuracao) || this.isBlank(el.atividadeMeta)) {
          foundIssue = true;
        }

        let meses = this.calcularMesesEntreDatas(this.plano.planoDataIniExecucao, el.atividadeInicioExecucao);
        if (meses < 0) {
          foundDateIssue = true;
        }
      });

    });

    if (foundIssue) {
      this.alertService.danger('Existem campos obrigatórios em metas/atividades não preenchidos');
    }
    if (foundDateIssue) {
      this.alertService.danger('A data de início da atividade deve ser igual ou superior a data de início do plano de trabalho');
      foundIssue = foundDateIssue;
    }
    return !foundIssue;
  }

  calcularMesesEntreDatas(dataInicio: Date, dataFinal: Date): number {
    const inicio = new Date(dataInicio);
    const fim = new Date(dataFinal);

    let meses = fim.getFullYear() * 12 + fim.getMonth() - (inicio.getFullYear() * 12 + inicio.getMonth());

    // Verificar se a data final está antes do dia de início no mesmo mês, ajustando o cálculo.
    if (fim.getDate() < inicio.getDate()) {
      meses--;
    }

    return meses;
  }

  salvaMeta(planoId, metaDoPlanoLista) {

    metaDoPlanoLista.forEach(element => element.planoId = planoId);
    this.loading.next(true);
    const jsonPayload = JSON.stringify(metaDoPlanoLista, this.removeCircularReferences());
    this.detalheParceriaApi.updateMetasAtividades(jsonPayload).subscribe(async (response) => {
      if (response) {
        this.setupInterface(false);
        this.loading.next(false);
      }

    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));

  }
  calculaQtdMeses(dataInicio: Date, dataFim: Date): number {
    let meses: number;

    // Calcule a diferença em anos e meses
    meses = (new Date(dataFim).getFullYear() - dataInicio.getFullYear()) * 12;
    meses -= dataInicio.getMonth();
    meses += new Date(dataFim).getMonth();
    meses = meses + 1;
    // Se a data de fim for antes do dia da data de início, subtraia um mês
    // if (new Date(dataFim).getDate() < dataInicio.getDate()) {
    //   meses--;
    // }

    return meses < 0 ? 0 : meses; // Retorna 0 se o resultado for negativo
  }

  construirArrayMeses(dataInicio: Date, quantidadeMeses: number): string[] {
    const mesesArray: string[] = [];
    const mesNomes = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
      'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    for (let i = 0; i < quantidadeMeses; i++) {
      const dataAtual = new Date(dataInicio);
      dataAtual.setMonth(new Date(dataInicio).getMonth() + i);

      const mes = mesNomes[dataAtual.getMonth()];
      const ano = dataAtual.getFullYear();

      mesesArray.push(`${mes}/${ano % 100}`);
    }

    return mesesArray;
  }

  calculaCompetencia() {

    const quantidadeMeses = this.calculaQtdMeses(new Date(this.plano.planoDataIniExecucao),
      this.plano.planoDataFimExecucao);
    this.competenciasLista = this.construirArrayMeses(this.plano.planoDataIniExecucao, quantidadeMeses)
    // this.competenciasLista.forEach(element => {
    //   console.log(element);   
    // });

  }
  calculaCompetenciaAtividade(atividade) {
    const competListaMetaAtividade: string[] = [];
    const mesNomes = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    let atividadeMeta;
    // se for percentual

    if (atividade != undefined && atividade.atividadeTipoApuracao !== undefined && !this.isBlank(atividade.atividadeTipoApuracao)) {
      if (atividade.atividadeTipoApuracao == 2) {
         if (atividade.atividadeMeta.contains('%')){
          atividadeMeta = atividade.atividadeMeta.split('%')[0];
         }
         else if (this.isNumber(atividade.atividadeMeta)){
          atividadeMeta = atividade.atividadeMeta;
        }
        else {
          atividadeMeta = -1;
        }
      }
      else if (atividade.atividadeTipoApuracao == 1 && this.isNumber(atividade.atividadeMeta)) {
        atividadeMeta = atividade.atividadeMeta;
      }
      else {
        // se for textual Sim -> 1 Não -> 0
        if (atividade.atividadeMeta.toUpperCase() === 'SIM') {
          atividadeMeta = 1;
        }
        else if (atividade.atividadeMeta.toUpperCase() === 'NÃO') {
          atividadeMeta = 0;
        }
        else {
          atividadeMeta = -1;
        }

      }



      if (atividade && atividade.atividadeMeta !== undefined) {
        const qtd = Number(atividadeMeta);
        const inicioData = new Date(atividade.atividadeInicioExecucao);
        const inicioMes = inicioData.getMonth();
        const inicioAno = inicioData.getFullYear();
        const inicioCompetencia = `${mesNomes[inicioMes]}/${inicioAno % 100}`;

        let frequenciaValor = 0;
        let intervaloMeses = 0;

        // Define o valor e o intervalo de meses com base na frequência
        switch (Number(atividade.atividadeFrequencia)) {
          case 0: qtd == -1 ? frequenciaValor = -1 : atividade.atividadeMeta.toUpperCase() === 'SIM' ? frequenciaValor = 1
            : frequenciaValor = qtd * 30; intervaloMeses = 1; break; // Diário
          case 1: qtd == -1 ? frequenciaValor = -1 : atividade.atividadeMeta.toUpperCase() === 'SIM' ? frequenciaValor = 1
            : frequenciaValor = qtd * 4; intervaloMeses = 1; break;  // Semanal
          case 2: qtd == -1 ? frequenciaValor = -1 : atividade.atividadeMeta.toUpperCase() === 'SIM' ? frequenciaValor = 1
            : frequenciaValor = qtd * 2; intervaloMeses = 1; break;  // Quinzenal
          case 3: qtd == -1 ? frequenciaValor = -1 : frequenciaValor = qtd; intervaloMeses = 1; break;      // Mensal
          case 4: qtd == -1 ? frequenciaValor = -1 : frequenciaValor = qtd; intervaloMeses = 2; break;      // Bimestral
          case 5: qtd == -1 ? frequenciaValor = -1 : frequenciaValor = qtd; intervaloMeses = 4; break;      // Quadrimestral
          case 6: qtd == -1 ? frequenciaValor = -1 : frequenciaValor = qtd; intervaloMeses = 6; break;      // Semestral
          case 7: qtd == -1 ? frequenciaValor = -1 : frequenciaValor = qtd; intervaloMeses = 12; break;     // Anual
          default: break;
        }

        let findStart = false;

        // Função auxiliar para verificar se o mês/ano é uma competência ativa
        const isCompetenciaAtiva = (element, intervaloAcumulado) => {
          if (intervaloMeses == 1) {
            return element;
          }
          const mesAno = element.split("/");
          const index = mesNomes.indexOf(mesAno[0]);
          const totalMeses = (inicioMes + intervaloAcumulado)
          const currentMes = totalMeses % 12;
          const currentAno = inicioAno + Math.floor(totalMeses / 12);
          return `${mesNomes[currentMes]}/${currentAno % 100}`;
        };

        let intervaloAcumulado = 0;
        this.competenciasLista.forEach((element, index) => {
          if (element === inicioCompetencia) {
            findStart = true;
            intervaloAcumulado = intervaloMeses;
            if (frequenciaValor >= 0) {
              competListaMetaAtividade.push(`${frequenciaValor}`);
            }
            else {
              competListaMetaAtividade.push("");
            }
          } else if (!findStart) {
            competListaMetaAtividade.push("");
          } else if (findStart) {
            if (isCompetenciaAtiva(element, intervaloAcumulado) === element) {
              if (frequenciaValor >= 0) {
                competListaMetaAtividade.push(`${frequenciaValor}`);
              }
              else {
                competListaMetaAtividade.push("");
              }
              intervaloAcumulado = intervaloAcumulado + intervaloMeses;
            }
            else {
              competListaMetaAtividade.push("");
            }
          }
        });

      } else {
        this.competenciasLista.forEach(() => competListaMetaAtividade.push(""));
      }
    }

    return competListaMetaAtividade;
  }

  isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  get paginatedCompetenciasLista(): String[] {
    const start = (this.config.currentPage - 1) * this.config.itemsPerPage;
    const end = start + this.config.itemsPerPage;
    return this.competenciasLista.slice(start, end);
  }

  paginatedCompetenciaListaMetaAtividade(atividade) {
    const competenciaListaMetaAtividade = this.calculaCompetenciaAtividade(atividade);

    const start = (this.config.currentPage - 1) * this.config.itemsPerPage;
    const end = start + this.config.itemsPerPage;
    //console.log("Valores Atividades Mes");
    //console.log(competenciaListaMetaAtividade.slice(start, end))
    return competenciaListaMetaAtividade.slice(start, end);
  }
  // Change page
  changePage(pageNumber: number): void {
    this.config.currentPage = pageNumber;
  }

  // Get the total number of pages
  get totalPages(): number {
    return Math.ceil(this.competenciasLista.length / this.config.itemsPerPage);
  }

}