import { ParametrosMonitoramento } from "./parametroMonitoramento.";

export class ResponsavelMonitoramento {
	
    public idResponsavel: number;
	public nomeResponsavel:string;
	public emailResponsavel:string;
	public parametrosMonitoramento: ParametrosMonitoramento;
	public tipoMonitoramento:string;
	public operacaoData: Date;
	public operacaoUsuario: string;
	public chaveEmail: string;
}