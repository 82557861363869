<div *ngIf="parceriaInfo != undefined">
    <div *ngIf="respDirs.length==0">
        <span class="mensagem-vazio">Nenhum responsável técnico ou dirigente foi encontrado.</span>
    </div>
    <div *ngIf="respDirs.length>0">
        <table class="table table-striped" style="margin-top:30px" id="tabelarespDir" [mfData]="respDirs" #mtRespDir="mfDataTable" [mfRowsOnPage]="5">

            <thead>
                <tr>
                    <th *ngIf="!disableApagarIcone()" data-html2canvas-ignore="true"></th>
                    <th>Nome</th>
                    <th>Telefone</th>
                    <th>Email</th>
                    <th>Função</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of mtRespDir.data; let j = index;">

                    <td *ngIf="!disableApagarIcone()" class="col-auto" data-html2canvas-ignore="true">
                        <i ngbTooltip="apagar" style="margin-top: 16px;"  class="fa fa-trash-o" aria-hidden="true" (click)="openExcluir(contentExcluir, data)"></i>
                    </td>

                    <td class="col-auto">
                        <input [disabled]="disableCampos()" class="form-control input-tabela"
                        id="responsavel" name="responsavel" #responsavel="ngModel" style="width: 100%" type="text " [(ngModel)]="data.responsavelDirigenteNome">
                      
                    </td>
                    <td class="col-md-2">
                        <input [disabled]="disableCampos()" id="telefone " name="telefone" #telefone="ngModel" class="form-control input-tabela"
                         style="width: 100%" type="text " [(ngModel)]="data.responsavelDirigenteTelefone">
                    </td>
                    <td class="col-auto">
                        <input [disabled]="disableCampos()" id="email"  name="email" #email="ngModel" class="form-control input-tabela"
                        type="text " style="width: 100%" id="email" name="email" #email="ngModel" [(ngModel)]="data.responsavelDirigenteEmail " emailvalidator>
                    </td>
                    <td class="col-md-2">
                        <select class="form-control custom-select" name="funcao"
                        [(ngModel)]="data.responsavelDirigenteFuncao">
                            <option value="0">Representante Legal</option>
                            <option value="1">Responsável Técnico</option>
                            <option value="2">Representante Legal/Responsável Técnico</option>
                            <option value="3">Dirigente</option>
                        </select>
                    </td>
                    
                </tr>
            </tbody>
            <tfoot data-html2canvas-ignore="true ">
                <tr>
                    <td colspan="12 ">
                        <div *ngIf="respDirs.length>5" class="resultPorPagina">Resultados por página:</div>
                        <mfBootstrapPaginator [rowsOnPageSet]="[5,10,25]"></mfBootstrapPaginator>
                    </td>
                </tr>
            </tfoot>
        </table>

    </div>
    <div *ngIf="!disableCampos()">
        <button (click)="adicionar() " id="button-insert_doc " class="btn btn-primary btn-info" type="button " title="Adicionar" [hidden]="parceriaInfo.parId===-1">
                        <i class="fa fa-plus "><span class="texto-botao">Adicionar</span> </i>
                    </button>
        <button (click)="salva() " id="button-insert_doc" style="margin-left: 10px;" class="btn btn-primary btn-info" type="button " title="Salvar" [hidden]="respDirs.length==0">
                        <span class="texto-botao">Salvar</span>
                    </button>
    </div>

    <ng-template #contentExcluir let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Excluir Responsável Técnico / Dirigente</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                        <span aria-hidden="true">&times;</span>
                    </button>
        </div>
        <div class="modal-body">
            <p>Tem certeza que deseja excluir o Responável Técnico ou Dirigente
                <b>{{responsavelDirigente.responsavelDirigenteNome}}</b>?</p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="c('Close click')" title="Fechar">Fechar</button>
            <button type="button" class="btn btn-secondary" style="margin-top: -2px;" (click)="excluirResponsavel()" title="Excluir">Excluir</button>
        </div>
    </ng-template>

    <ng-template #contentAtualizarParceria let-c="close" let-d="dismiss">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Atualização de Dirigente/Responsável da parceria</h4>
          <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
        </div>
        <div class="modal-body">
          <p>Tem certeza que deseja atualizar o dados de dirigente/responsável da parceria?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="c('Close click')">Fechar</button>
          <button type="button" class="btn btn-secondary" (click)="atualizaParceria()">Atualizar</button>
        </div>

      </ng-template>
</div>