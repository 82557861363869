<div>
  <div [hidden]="!(loading$ | async) ">
        <div class="loading-overlay "></div>
        <div class="loading-info ">
            <i class="fa fa-spinner fa-4x fa-spin "></i>
            <div id="carregando">Aguarde</div>
        </div> 
    </div>

    <div>
        <app-info-parceria [parceriaInfo]="parceriaInfo" [isPortal]="isPortal" [modulo]="modulo"></app-info-parceria>
    </div>
    <div class="container">
        <div class="planoTrabalho" *ngIf="!this.isPortal && modulo == 'm2'">Plano De Trabalho</div>
        <div *ngIf="parceriaInfo != undefined">
            <div [hidden]="verPlano">
                <div *ngIf="planoPlanTrabalho.length==0">
                    <span class="mensagem-vazio">Nenhum plano de trabalho foi encontrado.</span>
                </div>
                <div *ngIf="planoPlanTrabalho.length>0">

                    <table class="table table-striped" style="margin-top:30px" id="tabelaTermos"
                        [mfData]="planoPlanTrabalho" #mtPlanTrab="mfDataTable" [mfRowsOnPage]="5">

                        <thead>
                            <tr>
                                <th *ngIf="!disableApagarIconeBoolean" data-html2canvas-ignore="true"></th>
                                <th>ID</th>
                                <th>Período Execução</th>
                                <th>Situação</th>
                                <th>Efetivado</th>
                                <th></th>
                                <th *ngIf="(!isPortal)"></th>
                                <th *ngIf="(!isPortal)"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of mtPlanTrab.data ; let j = index;">
                                <td *ngIf="!disableApagarIconeBoolean" class="col-auto" data-html2canvas-ignore="true">
                                    <i ngbTooltip="apagar" *ngIf="data.planoSituacao===1" class="fa fa-trash-o"
                                        aria-hidden="true" (click)="openExcluirPlano(contentExcluirPlano, data)"></i>
                                </td>
                                <td class="col-auto ">{{data.planoIdentificador}}
                                </td>
                                <td class="col-auto">{{data.planoDataIniExecucao | date: 'dd/MM/yyyy'}} -
                                    {{data.planoDataFimExecucao | date: 'dd/MM/yyyy'}}</td>
                                <td class="col-auto">{{getSituacaoPlanoDeTrabalho(data.planoSituacao)}}</td>
                                <td class="col-auto">{{data.planoEfetivacao?'Sim':'Não'}}</td>
                                <td class="col-auto">
                                    <button (click)="visualizarPlano(data)" id="button-insert_doc" style="width: 100%;"
                                        class="btn btn-primary btn-info" type="button " title="Ver Plano"
                                        [hidden]="data.planoId===-1 || data.planoIdentificador.startsWith('SA') || data.planoSituacao ===3">                                            
                                        <i class="fa fa-search"><span class="texto-botao">Ver Plano</span> </i>
                                    </button>
                                    <button (click)="visualizarPlano(data)" id="button-insert2_doc"
                                        style="padding-left: 10px;" class="btn btn-verAlteracao" type="button "
                                        title="Ver Solicitação de Alteração" style="width: 100%;"
                                        [hidden]="data.planoId===-1 || data.planoIdentificador.startsWith('PT') 
                                    || (data.planoIdentificador.startsWith('SA') && data.planoSituacao == 3 && !isFiscal) ">
                                        <i class="fa fa-search" style="color: #fff;"><span class="texto-botao">Ver
                                                Solicitação de Alteração</span> </i>
                                    </button>
                                    <button (click)="visualizarPlano(data)" id="button-corr_doc "
                                        style="padding-left: 10px; width: 100%" class="btn btn-corrigir" type="button "
                                        title="Corrigir Solicitação de Alteração" [hidden]="data.planoId===-1 || isFiscal || isPortal || data.planoIdentificador.startsWith('PT') 
                                || (data.planoIdentificador.startsWith('SA') && data.planoSituacao !==3)">
                                        <i class="fa fa-pencil" style="color: #fff;"><span class="texto-botao">Corrigir
                                                Solicitação de Alteração</span> </i>
                                    </button>
                                    <button (click)="visualizarPlano(data)" id="button-corr_doc "
                                    style="padding-left: 10px; width: 100%" class="btn btn-corrigir" type="button "
                                    title="Corrigir Solicitação de Alteração" [hidden]="data.planoId===-1 || isFiscal || isPortal || data.planoIdentificador.startsWith('SA') 
                                || (data.planoIdentificador.startsWith('PT') && data.planoSituacao !==3)">
                                    <i class="fa fa-pencil" style="color: #fff;"><span class="texto-botao">Corrigir
                                            Plano</span> </i>
                                </button>                                    
                                </td>
                                <td *ngIf="(!isPortal)" class="col-auto">
                                    <button (click)="criaAlteracaoPlano(data)" id="solicitar" class="btn btn-alteracao"
                                        *ngIf="enableIcone()" type="button " style="padding-left: 10px;width: 100%"
                                        title="Solicitar Alteração" [hidden]="inibeBotaoAlteracao(data)">
                                        <i class="fa fa-pencil" style="color: white;"><span
                                                class="texto-botao">Solicitar
                                                Alteração</span> </i>
                                    </button>
                                    <button (click)="avaliarPlano(data)" id="solicitar" class="btn btn-alteracao"
                                        style="margin-left: 10px; width: 100%"
                                        *ngIf="isFiscal && data.planoIdentificador.startsWith('PT')" type="button "
                                        title="Avaliar Plano"
                                        [hidden]="data.planoSituacao < 2 || data.planoSituacao >2">
                                        <i class="fa fa-pencil" style="color: white;"><span class="texto-botao">Avaliar
                                                Plano</span> </i>
                                    </button>
                                    <button (click)="avaliaAlteracaoPlano(data)" id="solicitar"
                                        class="btn btn-alteracao" style="margin-left: 10px;width: 100%"
                                        *ngIf="isFiscal && data.planoIdentificador.startsWith('SA')" type="button "
                                        title="Avaliar Plano"
                                        [hidden]="data.planoSituacao < 2 || data.planoSituacao >2">
                                        <i class="fa fa-pencil" style="color: white;"><span class="texto-botao">Avaliar
                                                Solicitação de Alteração</span> </i>
                                    </button>
                                </td>
                                <td *ngIf="(!isPortal)" class="col-auto">
                                    <button (click)="openDuplicarPlano(contentDuplicarPlano, data)" id="duplicar"
                                        class="btn btn-duplicacao" style="margin-left: 10px;" type="button "
                                        title="Duplicar Plano"
                                        [hidden]="data.planoSituacao < 4 || !data.planoEfetivacao || disableBotaoDuplicarPlanoBoolean">
                                        <i class="fa fa-files-o" style="color: black;"><span
                                                class="texto-botao">Duplicar
                                                Plano</span> </i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot data-html2canvas-ignore="true">
                            <tr>
                                <td colspan="12 ">
                                    <div *ngIf="planoPlanTrabalho.length>5" class="resultPorPagina">Resultados por
                                        página:
                                    </div>
                                    <mfBootstrapPaginator [rowsOnPageSet]="[5,10,25]"></mfBootstrapPaginator>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div
                    *ngIf="!disableBotao() || (planoVisualizado != undefined && planoVisualizado.planoSituacao==4 && !isFiscal)">
                    <button (click)="adicionarNovoPlano() " id="button-insert_doc " class="btn btn-primary btn-info"
                        type="button " title="Adicionar novo" [hidden]="parceriaInfo == undefined">
                        <i class="fa fa-plus "><span class="texto-botao">Adicionar novo</span> </i>
                    </button>
                </div>
            </div>

            <div id="execucao" *ngIf="verPlano" style="margin-top: 50px;">
                <h2><a *ngIf="true" ngbTooltip="Voltar a lista de plano" (click)="verPlano=false; planoVisualizado=undefined;">
                        <i class="fa fa-arrow-circle-left fa-lg" aria-hidden="true"></i>
                    </a>
                    {{planoVisualizado.planoIdentificador}}
                </h2>         
                <div style="margin-left: 35px; margin-top: -20px; font-weight: bold;">
                {{planoVisualizado.planoDataIniExecucao | date: 'dd/MM/yyyy' }} -
                {{planoVisualizado.planoDataFimExecucao | date: 'dd/MM/yyyy' }}
                <BR>
                Plano {{getSituacaoPlanoDeTrabalho(planoVisualizado.planoSituacao) |lowercase}} -
                {{planoVisualizado.planoEfetivacao?'efetivado':'não efetivado'}}
                <BR />
                <BR />
                </div>
                <ngb-accordion #acc="ngbAccordion" (panelChange)="acordiaoChange($event)"  [destroyOnHide]="false">

                    <ngb-panel id="execucao" title="PERÍODO DE EXECUÇÃO">
                        <ng-template ngbPanelContent>
                            <div id="Periodo" style="margin-top: 20px;">
                                <!--<div class="execucao tituloSecao">PERÍODO DE EXECUÇÃO</div>-->

                                <div class="form-row">
                                    <div class="col-md-2">
                                        <label style="padding-top: 5%" for="planoDataIniExecucao">Início da
                                            Execução:</label>
                                        <span class="campoObrigatorio">*</span>
                                    </div>
                                    <div class="col-md-2">
                                        <input [disabled]="disableCampos()" style="width: 100%" maxlength="10"
                                            class="form-control" name="planoDataIniExecucao" placeholder="dd/mm/aaaa"
                                            appMaskInput [appMaskValue]="planoVisualizado.planoDataIniExecucaoFormat"
                                            #planoDataIniExecucao="ngModel"
                                            [(ngModel)]="planoVisualizado.planoDataIniExecucaoFormat" required
                                            ngbDatepicker #d="ngbDatepicker">
                                        <i class="fa fa-calendar icon" (click)="d.toggle()" title="calendário"></i>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-md-2">
                                        <label style="padding-top: 5%" for="planoDataFimExecucao">Fim da
                                            Execução:
                                        </label>
                                        <span class="campoObrigatorio">*</span>
                                    </div>
                                    <div class="col-md-2">
                                        <input [disabled]="disableCampos()" style="width: 100%" maxlength="10"
                                            class="form-control" name="planoDataFimExecucao"
                                            #planoDataFimExecucao="ngModel" placeholder="dd/mm/aaaa" appMaskInput
                                            [appMaskValue]="planoVisualizado.planoDataFimExecucaoFormat"
                                            [(ngModel)]="planoVisualizado.planoDataFimExecucaoFormat" required
                                            ngbDatepicker #df="ngbDatepicker" (focusout)="validaLimiteMaximo()">
                                        <i class="fa fa-calendar icon" (click)="df.toggle()" title="calendário"></i>

                                    </div>
                                    <div class="col-md-3"><label
                                            style="padding-top: 5%; font-style: italic; font-weight: normal">(Limite
                                            máximo 60
                                            meses)</label></div>
                                </div>
                                <div>
                                    <div class="tituloSecundario">Competências da Execução</div>
                                    <div *ngIf="competenciasLista.length>0">
                                        <table class="table">
                                            <tbody>
                                                <tr *ngFor="let chunk of splitIntoChunks(paginatedCompetenciasLista, 12)"
                                                    [class.no-border]="chunk === splitIntoChunks(competenciasLista, 12)[0]">
                                                    <td *ngFor="let item of chunk"
                                                        [ngClass]="{'bold': item.includes('Mês')}">
                                                        {{ item }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="pagination pagination justify-content-center"
                                            *ngIf="competenciasLista.length > 0">
                                            <i (click)="changePage(config.currentPage - 1)"
                                                [ngStyle]="{ 'pointer-events': config.currentPage === 1 ? 'none' : 'auto' }"
                                                class="fa fa-chevron-circle-left"
                                                style="font-size:36px; padding-right:10px"> </i>

                                            <span> Página {{config.currentPage}} de {{totalPages}} </span>

                                            <i (click)="changePage(config.currentPage + 1)"
                                                [ngStyle]="{ 'pointer-events': config.currentPage === totalPages ? 'none' : 'auto' }"
                                                class="fa fa-chevron-circle-right"
                                                style="font-size:36px; padding-left:10px"> </i>

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </ng-template>
                    </ngb-panel>-

                    <ngb-panel id="dadosBancarios" title="DADOS BANCÁRIOS">
                        <ng-template ngbPanelContent>
                            <!--<<div class="execucao tituloSecao">DADOS BANCÁRIOS</div>-->

                            <div class="form-row" style="margin-top: 20px;">
                                <div class="col-md-4">
                                    <label for="planoContaCorrenteOsc" class="mr-3"
                                        style="text-align: right; white-space: nowrap; padding-top: 2%">Conta
                                        corrente específica da Parceria:<span class="campoObrigatorio">*</span></label>
                                </div>
                                <div class="col-md-3">
                                    <input [disabled]="disableCampos()" class="form-control" id="planoContaCorrenteOsc"
                                        name="planoContaCorrenteOsc" [textMask]="{ mask: mascaraCC , guide: false }"
                                         #planoContaCorrenteNumeroOsc="ngModel" type="text" style="flex-grow: 1;"
                                        [(ngModel)]="planoVisualizado.planoContaCorrenteNumeroOsc" maxlength="22"
                                        required>
                                </div>

                                <div class="col-md-1">
                                    <label for="planoContaCorrenteBancoOsc" class="mr-3"
                                        style="text-align: right; padding-top: 10%">Banco:<span
                                            class="campoObrigatorio">*</span></label>
                                </div>
                                <div class="col-md-1">
                                    <input [disabled]="disableCampos()" class="form-control" style="width: 80px;"
                                        id="planoContaCorrenteBancoOsc" name="planoContaCorrenteBancoOsc"
                                         #planoContaCorrenteBancoOsc="ngModel" type="text"
                                        [textMask]="{ mask: mascaraBanco , guide: false }"
                                        [(ngModel)]="planoVisualizado.planoContaCorrenteBancoOsc" maxlength="5"
                                        required>
                                </div>

                                <div class="col-md-1">
                                    <label for="planoContaCorrenteAgenciaOsc" class="mr-3"
                                        style="text-align: right; padding-top: 10%">Agência:<span
                                            class="campoObrigatorio">*</span></label>
                                </div>
                                <div class="col-md-2">
                                    <input [disabled]="disableCampos()" class="form-control" style="width: 100px;"
                                        id="planoContaCorrenteAgenciaOsc" name="planoContaCorrenteAgenciaOsc"
                                        [textMask]="{ mask: mascaraAgencia , guide: false }"
                                        #planoContaCorrenteAgenciaOsc="ngModel" type="text"
                                        [(ngModel)]="planoVisualizado.planoContaCorrenteAgenciaOsc" maxlength="7"
                                        required>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-md-4">
                                    <label for="planoContaProvisaoNumeroOsc" class="mr-3"
                                        style="text-align: right; white-space: nowrap; padding-top: 2%">Conta
                                        provisão:<span class="campoObrigatorio">*</span></label>
                                </div>
                                <div class="col-md-3">
                                    <input [disabled]="disableCampos()" class="form-control"
                                        id="planoContaProvisaoNumeroOsc" name="planoContaProvisaoNumeroOsc"
                                        [textMask]="{ mask: mascaraCC , guide: false }"
                                         #planoContaProvisaoNumeroOsc="ngModel" type="text" style="flex-grow: 1;"
                                        [(ngModel)]="planoVisualizado.planoContaProvisaoNumeroOsc" maxlength="22"
                                        required>
                                </div>

                                <div class="col-md-1">
                                    <label for="planoContaProvisaoBancoOsc" class="mr-3"
                                        style="text-align: right; padding-top: 10%; ">Banco:<span
                                            class="campoObrigatorio">*</span></label>
                                </div>
                                <div class="col-md-1">
                                    <input [disabled]="disableCampos()" class="form-control" style="width: 80px;"
                                        id="planoContaProvisaoBancoOsc" name="planoContaProvisaoBancoOsc"
                                         #planoContaProvisaoBancoOsc="ngModel" type="text"
                                        [textMask]="{ mask: mascaraBanco , guide: false }"
                                        [(ngModel)]="planoVisualizado.planoContaProvisaoBancoOsc" maxlength="5"
                                        required>
                                </div>

                                <div class="col-md-1">
                                    <label for="planoContaProvisaoAgenciaOsc" class="mr-3"
                                        style="text-align: right; padding-top: 10%; ">Agência:<span
                                            class="campoObrigatorio">*</span></label>
                                </div>
                                <div class="col-md-2">
                                    <input [disabled]="disableCampos()" class="form-control" style="width: 100px; "
                                        id="planoContaProvisaoAgenciaOsc" name="planoContaProvisaoAgenciaOsc"
                                        #planoContaProvisaoAgenciaOsc="ngModel" type="text"
                                        [textMask]="{ mask: mascaraAgencia , guide: false }"
                                        [(ngModel)]="planoVisualizado.planoContaProvisaoAgenciaOsc" maxlength="7"
                                        required>
                                </div>
                            </div>

                        </ng-template>
                    </ngb-panel>-

                    <!--<div class="execucao tituloSecao">DESCRIÇÃO DO OBJETO</div>-->
                    <ngb-panel id="apresentacao" title="APRESENTAÇÃO E HISTÓRICO DA PROPONENTE">
                        <ng-template ngbPanelContent>
                            <!--<div class="execucao tituloSecao">APRESENTAÇÃO E HISTÓRICO DA PROPONENTE</div>-->
                            <div class="form-row" style="margin-top: 20px;">
                                <div class="col-md-6">
                                    <label style="padding-top: 4%">Ano de Fundação:</label>
                                    <span class="campoObrigatorio">*</span>
                                </div>
                                <div class="col-md-6">
                                    <input [disabled]="disableCampos()" style="width:100%;" id="parOrgDirigenteCidade"
                                        class="form-control" name="planoAnoFundacaoOsc" #planoAnoFundacaoOsc="ngModel"
                                        type="text" [(ngModel)]="planoVisualizado.planoAnoFundacaoOsc" maxlength="4"
                                        mask="0000" required>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-md-6">
                                    <label style="padding-top: 4%">Foco Atuação:</label>
                                    <span class="campoObrigatorio">*</span>
                                </div>
                                <div class="col-md-6">
                                    <input [disabled]="disableCampos()" style="width:100%;" class="form-control"
                                        name="planoFocoAtuacaoOsc" #planoFocoAtuacaoOsc="ngModel" type="text"
                                        maxlength="250" [(ngModel)]="planoVisualizado.planoFocoAtuacaoOsc" required>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-md-6">
                                    <label style="padding-top: 2%">
                                        Experiência da OSC que a torna apta a realizar as atividades ou projetos
                                        objeto deste
                                        Plano de Trabalho:
                                        <span class="campoObrigatorio">*</span>
                                    </label>

                                </div>
                                <div class="col-md-6">
                                    <!--<i class="fa fa-times icon_input_fontawsome"  style="padding-top: 5%" aria-hidden="true" (click)="planoVisualizado.planoExperienciaOSC=undefined" title="limpar"></i>-->
                                    <textarea [disabled]="disableCampos()" style="width:100%;"
                                        name="planoExperienciaOSC" class="form-control" ng-model="name"
                                        #planoExperienciaOSC="ngModel"
                                        [(ngModel)]="planoVisualizado.planoExperienciaOSC" maxlength="500"></textarea>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-md-6">
                                    <label style="padding-top: 4%">
                                        Quantidade de profissionais vinculados à OSC:</label>
                                    <span class="campoObrigatorio">*</span>
                                </div>
                                <div class="col-md-6">
                                    <!--<i class="fa fa-times icon_input_fontawsome"  style="padding-top: 5%" aria-hidden="true" (click)="planoVisualizado.planoQtdProfissionaisOsc=undefined" title="limpar"></i>-->
                                    <input [disabled]="disableCampos()" style="width:100%;" class="form-control"
                                        name="planoQtdProfissionaisOsc" maxlength="10"
                                        #planoQtdProfissionaisOsc="ngModel" mask="0000000000" type="text"
                                        [(ngModel)]="planoVisualizado.planoQtdProfissionaisOsc" required>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>-

                    <!--<div class="execucao tituloSecao">DESCRIÇÃO DO OBJETO</div>-->
                    <ngb-panel id="descricaoObjeto" title="DESCRIÇÃO DO OBJETO">
                        <ng-template ngbPanelContent>


                            <div class="form-row" style="margin-top: 20px;">
                                <div class="col-md-6">
                                    <label style="padding-top: 1%">Identificação do Objeto:</label>
                                    <span class="campoObrigatorio">*</span>
                                    <label class="comentario">Especificar e descrever as atividades e ações que
                                        serão
                                        desenvolvidas com os recursos decorrentes da parceria</label>
                                </div>
                                <div class="col-md-6">
                                    <!--<i class="fa fa-times icon_input_fontawsome"  style="padding-top: 5%" aria-hidden="true" (click)="planoVisualizado.planoExperienciaOSC=undefined" title="limpar"></i>-->
                                    <textarea [disabled]="disableCampos()" style="width:100%;height: 90%"
                                        name="planoIdentificacaoObjeto" class="form-control" ng-model="name"
                                        #planoIdentificacaoObjeto="ngModel"
                                        [(ngModel)]="planoVisualizado.planoIdentificacaoObjeto"
                                        maxlength="500"></textarea>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-6">
                                    <label>Justificativa:</label>
                                    <span class="campoObrigatorio">*</span>
                                    <label class="comentario">Descrever com clareza e sucintamente as razões que
                                        levaram à
                                        proposição, evidenciando os benefícios econômicos e sociais a serem
                                        alcançados pela
                                        comunidade, a localização geográfica a ser atendida, bem como os
                                        resultados
                                        e o impacto
                                        social que se pretende alcançar com a realização do projeto.</label>

                                </div>
                                <div class="col-md-6">
                                    <!--<i class="fa fa-times icon_input_fontawsome"  style="padding-top: 5%" aria-hidden="true" (click)="planoVisualizado.planoJustificativa=undefined" title="limpar"></i>-->
                                    <textarea [disabled]="disableCampos()" style="width:100%; height: 90%;"
                                        name="planoJustificativa" class="form-control" ng-model="name"
                                        #planoJustificativa="ngModel" [(ngModel)]="planoVisualizado.planoJustificativa"
                                        maxlength="1000"></textarea>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-md-6">
                                    <label style="padding-top: 2%">
                                        Descrição da realidade que será objeto da parceria e demonstração do
                                        nexo
                                        entre a
                                        realidade e as atividades ou projetos e metas a serem atingidas:
                                        <span class="campoObrigatorio">*</span>
                                    </label>

                                    <div></div>

                                </div>
                                <div class="col-md-6">
                                    <!--<i class="fa fa-times icon_input_fontawsome"  style="padding-top: 5%" aria-hidden="true" (click)="planoVisualizado.planoDescricao=undefined" title="limpar"></i>-->
                                    <textarea [disabled]="disableCampos()" style="width:100%;" name="planoDescricao"
                                        class="form-control" ng-model="name" #planoDescricao="ngModel"
                                        [(ngModel)]="planoVisualizado.planoDescricao" maxlength="500"></textarea>
                                </div>
                            </div>

                            <div class="form-row">
                                <div class="col-md-6">
                                    <label style="padding-top: 2%">
                                        Forma de execução das atividades ou dos projetos:</label>
                                    <span class="campoObrigatorio">*</span>
                                </div>
                                <div class="col-md-6">
                                    <!--<i class="fa fa-times icon_input_fontawsome"  style="padding-top: 5%" aria-hidden="true" (click)="planoVisualizado.planoFormaExecucao=undefined" title="limpar"></i>-->
                                    <input [disabled]="disableCampos()" style="width:100%;" class="form-control"
                                        name="planoFormaExecucao" #planoFormaExecucao="ngModel" type="text"
                                        [(ngModel)]="planoVisualizado.planoFormaExecucao" maxlength="250">
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-6">
                                    <label style="padding-top: 2%">
                                        Espaço físico onde será realizado o objeto da parceria:</label>
                                    <span class="campoObrigatorio">*</span>
                                    <label class="comentario">Descrever em que local as atividades serão
                                        realizadas,
                                        se em
                                        espaços públicos como praças, postos de saúde ou outros próprios
                                        municipais,
                                        ou em CAR,
                                        na sede da proponente ou em campo.</label>


                                </div>
                                <div class="col-md-6">
                                    <!--<i class="fa fa-times icon_input_fontawsome"  style="padding-top: 5%" aria-hidden="true" (click)="planoVisualizado.planoEspacoFisico=undefined" title="limpar"></i>-->
                                    <textarea [disabled]="disableCampos()" style="width:100%; height: 90%"
                                        name="planoEspacoFisico" class="form-control" ng-model="name"
                                        #planoEspacoFisico="ngModel" [(ngModel)]="planoVisualizado.planoEspacoFisico"
                                        maxlength="500"></textarea>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>-

                    <ngb-panel id="meta" title="METAS A SEREM ATINGIDAS">
                        <ng-template #meta ngbPanelContent>
                            <app-meta-plano [plano]="planoVisualizado" [isPortal]="portal" [modulo]="modulo" [isCriarSA]="isCriarSA"
                                (metaUpdated)="handleMetaUpdated($event)"></app-meta-plano>
                        </ng-template>
                        <ng-container *ngTemplateOutlet="meta"></ng-container>
                    </ngb-panel>


                    <ngb-panel id="receitas" title="PREVISÃO DE RECEITAS">
                        <ng-template #receita ngbPanelContent>
                            <app-receita-plano [plano]="planoVisualizado" [isPortal]="isPortal" [modulo]="modulo" [isCriarSA]="isCriarSA"
                                (receitaUpdated)="handleReceitaUpdated($event)"></app-receita-plano>
                        </ng-template>
                        <ng-container *ngTemplateOutlet="receita"></ng-container>
                    </ngb-panel>


                    <ngb-panel id="despesa" title="PREVISÃO DE DESPESA">
                        <ng-template #despesa ngbPanelContent>
                            <app-despesa-plano [plano]="planoVisualizado" [isPortal]="isPortal" [modulo]="modulo" [isCriarSA]="isCriarSA"
                                [isSaude]="isSaude" (despesaUpdated)="handleDespesaUpdated($event)"></app-despesa-plano>
                        </ng-template>
                        <ng-container *ngTemplateOutlet="despesa"></ng-container>
                    </ngb-panel>

                    <ngb-panel id="equipe" title="PREVISÃO DE EQUIPE DE TRABALHO PARA REALIZAÇÃO DO OBJETO DA PARCERIA">
                        <ng-template #equipe ngbPanelContent>
                            <app-previsao-equipe [plano]="planoVisualizado" [isPortal]="isPortal" [isCriarSA]="isCriarSA"
                                [modulo]="modulo" (equipeUpdated)="handleEquipeUpdated($event)"></app-previsao-equipe>
                        </ng-template>
                        <ng-container *ngTemplateOutlet="equipe"></ng-container>
                    </ngb-panel>

                    <ngb-panel id="avalia" *ngIf="this.verPlano && planoVisualizado != undefined &&
                            planoVisualizado.planoSituacao >=3 || (planoVisualizado.planoSituacao==2 && this.isFiscal)"
                        title="AVALIAÇÃO DO PLANO DE TRABALHO">
                        <ng-template #avaliacao ngbPanelContent>
                            <!---->
                            <app-avaliacao-plano #avaliacaoComponent [plano]="planoVisualizado" [isPortal]="isPortal"
                                [modulo]="modulo" [isSaude]="isSaude"></app-avaliacao-plano>
                        </ng-template>
                        <ng-container *ngTemplateOutlet="avaliacao"></ng-container>
                    </ngb-panel>

                </ngb-accordion>

                <div style="margin-top: 20px;" *ngIf="planoVisualizado.planoIdentificador != undefined && planoVisualizado.planoIdentificador.startsWith('SA')
                     && planoVisualizado.planoSituacao==1">
                    <div class="row">
                        <div class="col-md-3">
                            <label style="padding-top: 2%">
                                Justificativa da Alteração:
                                <span class="campoObrigatorio">*</span>
                            </label>
                        </div>
                        <div class="col-md-9">
                            <textarea [disabled]="disableCampos()" style="width:100%;"
                                name="planoJustificativaAlteracao" class="form-control" ng-model="name"
                                #planoJustificativaAlteracao="ngModel"
                                [(ngModel)]="planoVisualizado.planoJustificativaAlteracao" maxlength="500"></textarea>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 20px;">
                        <div class="col-md-3 font-weight-bold">Vigência da
                            Alteração&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;De:
                            <span class="text-danger">*</span>
                        </div>
                        <div class="col-md-4">
                            <app-date-selector [disabledFlag]="disableBotao()"
                                [initialDate]="planoVisualizado.planoDataIniExecucao"
                                (dateChanged)="onDateChangedInicio($event)"></app-date-selector>
                        </div>
                        <div class="col-md-1 font-weight-bold">Até:<span class="text-danger">*</span>
                        </div>
                        <div class="col-md-4">
                            <app-date-selector [disabledFlag]="disableBotao()"
                                [initialDate]="planoVisualizado.planoDataFimExecucao"
                                (dateChanged)="onDateChangedFinal($event)"></app-date-selector>
                        </div>

                    </div>
                </div>



                <br />
                <br />
                <div class="row justify-content-center">
                    <div
                        *ngIf="!disableBotao() && planoVisualizado.planoIdentificador != undefined && planoVisualizado.planoIdentificador.startsWith('PT')">
                        <button (click)="salvaPlano()" id="button-insert_doc "
                            class="btn btn-primary btn-info action-btn" type="button " title="Salva Plano"
                            [hidden]="parceriaInfo.parId===-1">
                            <i><span class="texto-botao">Salva Plano</span> </i>
                        </button>
                    </div>

                    <div
                        *ngIf="!disableBotaoSub() && planoVisualizado.planoIdentificador != undefined && planoVisualizado.planoIdentificador.startsWith('PT')">

                        <button (click)="submeterPlano()" style="margin-left: 20px;" id="button-sub_doc "
                            class="btn btn-primary btn-info action-btn" type="button " title="Submeter Plano"
                            [hidden]="parceriaInfo.parId===-1">
                            <i><span class="texto-botao">Submeter Plano</span> </i>
                        </button>
                    </div>
                    <div
                        *ngIf="!disableBotao() && planoVisualizado.planoIdentificador != undefined && planoVisualizado.planoIdentificador.startsWith('SA')">
                        <button (click)="salvaPlano()" id="button-salvaAlte "
                            class="btn btn-primary btn-info action-btn" type="button "
                            title="Salva Solicitação de Alteração" [hidden]="parceriaInfo.parId===-1">
                            <i><span class="texto-botao">Salva Solicitação de Alteração</span> </i>
                        </button>
                    </div>

                    <div
                        *ngIf="!disableBotaoSub() && planoVisualizado.planoIdentificador != undefined && planoVisualizado.planoIdentificador.startsWith('SA')">

                        <button (click)="submeterPlano()" style="margin-left: 20px;" id="button-sub_alteraca "
                            class="btn btn-primary btn-info action-btn" type="button "
                            title="Submeter Solicitação de Alteração" [hidden]="parceriaInfo.parId===-1">
                            <i><span class="texto-botao">Submeter Solicitação de Alteração</span> </i>
                        </button>
                    </div>
                </div>
            </div>


        </div>
        <ng-template #contentExcluirPlano let-c="close" let-d="dismiss">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Excluir Plano de trabalho</h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Tem certeza que deseja excluir o plano de trabalho
                    <b>{{planoASerApagado.planoIdentificador}}</b>?
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" style="margin-top: 7px;" (click)="c('Close click')"
                    title="Fechar">Fechar</button>
                <button type="button" class="btn btn-secondary" (click)="excluirPlanoTrabalho()"
                    title="Excluir">Excluir</button>
            </div>
        </ng-template>
        <ng-template #contentDuplicarPlano let-c="close" let-d="dismiss">
            <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Duplicar Plano de trabalho</h4>
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>Confirma a inclusão de um novo Plano de Trabalho com base no
                    <b>{{planoASerDuplicado.planoIdentificador}}</b>?
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" style="margin-top: 7px;" (click)="c('Close click')"
                    title="Não">Não</button>
                <button type="button" class="btn btn-secondary" (click)="duplicarPlanoTrabalho()"
                    title="Sim">Sim</button>
            </div>
        </ng-template>

    </div>