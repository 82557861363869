import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from '@procempa/ngx-keycloak';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, delayWhen, retry } from 'rxjs/internal/operators';
import { AlertService } from './alert.service';
import { UserService } from './user.service';
import { KeycloakInterceptor } from './keycloak.interceptior';


@Injectable({
  providedIn: 'root'
})
export class interceptor implements HttpInterceptor {
  constructor(private alertService: AlertService, private route: Router, private userService: UserService, public auth: KeycloakService, private keycloakInterceptor: KeycloakInterceptor ) { }

  private retryCount = this.getRetryCountFromLocalStorage() || 0;
  private readonly maxRetryAttempts = 5;
  private readonly retryDelay = 5000; 

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          console.log(`ERRO ${errorResponse}.`)
          console.log(`ERRO ${errorResponse.status}.`)
          if (errorResponse.status === 502) {
            console.log("ERRO 502: Tentando novamente.")
            return this.retryRequest(request, next, this.retryCount);
          }
          if (errorResponse.status === 0) {
            return this.retryRequestWithLogout(request, next, this.retryCount);
          }
          return throwError(errorResponse);
        })
      );
  }

  private retryRequest(request: HttpRequest<any>, next: HttpHandler, retryCount: number): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(retryCount),
        delayWhen(() => timer(this.retryDelay)),
        catchError(() => {
          if (retryCount === this.maxRetryAttempts -1) {
            //window.location.reload();
          }
          if (retryCount < this.maxRetryAttempts) {
            this.saveRetryCountToLocalStorage(retryCount + 1)
            this.keycloakInterceptor.intercept(request, next);
            return this.retryRequest(request, next, retryCount + 1);
          } else {
            localStorage.removeItem('retryCount')
            return throwError(this.alertService.danger("Falha na comunicação com o servidor, tente novamente em alguns minutos"));
          }
        })); 
  }


  private retryRequestWithLogout(request: HttpRequest<any>, next: HttpHandler, retryCount: number): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(retryCount),
        delayWhen(() => timer(this.retryDelay)),
        catchError(() => {
          if (retryCount < this.maxRetryAttempts) {
            this.saveRetryCountToLocalStorage(retryCount + 1)
            this.keycloakInterceptor.intercept(request, next);
            return this.retryRequestWithLogout(request, next, retryCount + 1);
          } else {
            localStorage.removeItem('retryCount');
            this.logout();
            return throwError(this.alertService.danger("Falha na comunicação com o servidor, tente novamente em alguns minutos"));
          }
        })); 
  }

  private logout(){
    setTimeout(() => {
      this.auth.logout();
    }, 5000);
  }

  private getRetryCountFromLocalStorage(): number | null {
    return parseInt(localStorage.getItem('retryCount'), 10);
  }

  private saveRetryCountToLocalStorage(retryCount: number): void {
    localStorage.setItem('retryCount', retryCount.toString());
  }
}
