import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DateConverter } from '../../../@core/date-converter';
import { elementAt, filter, share, switchMap } from 'rxjs/operators';
import { AlertService } from '../../../@core/alert.service';
import { ParceriaInfo } from './../../detalheParceria/parceriaInfo';
import { DetalheParceriaPortalApi } from '../../../apis/detalheParceriaPortalApi';
import { Mascaras } from '../../../@shared/components/Mascaras';
import { UserService } from '../../../@core/user.service';
import { Utils } from 'src/app/@shared/utils';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DetalheParceriaApi } from 'src/app/apis/detalheParceriaApi';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';
import { forEach } from 'lodash';
import { PaginationInstance } from 'ngx-pagination/dist/pagination-instance';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrevisaoDespesa } from './previsaoDespesa';
import { ExecucaoPlanoDeTrabalho } from '../execucaoPlanoDeTrabalho';
import { AuthUser, KeycloakService } from '@procempa/ngx-keycloak';
import { PrevisaoReceitaComponent } from '../previsaoReceita/previsaoReceita.component';
import { PrevisaoReceita } from '../previsaoReceita/previsaoReceita';

@Component({
  selector: 'app-despesa-plano',
  templateUrl: './previsaoDespesa.component.html',
  styleUrls: ['../../informacaoParceria/informacaoParceria.component.scss']
})
export class PrevisaoDespesaComponent implements OnInit {
  @Input()
  public plano: ExecucaoPlanoDeTrabalho;
  @Input()
  public isPortal: boolean;
  @Input()
  public isCriarSA: boolean;
  @Input()
  public isSaude: boolean;
  @Output() valueChange = new EventEmitter();
  @Input()
  public modulo: string;
  isFiscal: boolean;
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  isAuditorExterno: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  isExecPT: boolean;
  isPlanPT: boolean;
  p = 1;
  pagina: number;
  modalEdicaoReference: any;
  username: string;
  previsaoDespesaLista: Array<PrevisaoDespesa> = [];
  previsaoDespesaSelecionada = new PrevisaoDespesa();
  previsaoDespesaAserApagada = new PrevisaoDespesa();
  indicePrevisao: number;
  pieChartLabels1: Array<string> = [];
  pieChartLabelsCustos: Array<string> = ['FRIM', 'Custo Indireto', 'Outros Custos'];
  pieChartData: Array<any> = [120, 0, 3];
  pieChartDataCustos: Array<any> = [120, 0, 3];
  pieChartType = 'pie';
  chartOptions = { responsive: true, aspectRatio: 1 };
  pieChartColors1: Array<any> = [{
    backgroundColor: ['#001f36', '#0b535d', '#79ae92', '#eff4b8', '#654b49', '654b49'],
    hoverBackgroundColor: ['#001f36', '#0b535d', '#79ae92', '#eff4b8', '#654b49', '654b49']
    //borderColor: ['rgba(135,206,250,1)', 'rgba(106,90,205,1)', 'rgba(148,159,177,1)']
  }];
  naturezaOptionsProvisao;
  totalPgtoPessoal = 0;
  totalServTerceiros = 0;
  totalMatConsumo = 0;
  totalMatPermanente = 0;
  totalProvisao = 0;
  totalNaturezas = 0;
  totalFrim = 0;
  totalCustoIndireto = 0;
  totalOutrosCustos = 0;
  totalCustos = 0;
  competenciasLista: String[] = [];
  competenciaListaMetaAtividade: String[] = [];

  pagamentoPessoalList;
  servicosTerceirosList;
  materialConsumoList;
  materialPermanenteList;
  provisaoList;
  previsaoDespList;
  previsaoRecList;
  diferencaRecDespList;
  mostraTabela;

  public config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 13,
    currentPage: 1
  };
  previsaoReceitaList: any[];
  @Output() despesaUpdated = new EventEmitter<PrevisaoDespesa[]>();

  constructor(private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    public userService: UserService,
    private parceriaApi: PublicoPrivadaApi,
    private mascaras: Mascaras,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    private utils: Utils,
    private route: ActivatedRoute,
    public keycloakService: KeycloakService,
    private mascara: Mascaras,
    private cdRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    
    this.naturezaOptionsProvisao = this.utils.naturezaOptions;
    let prov = this.naturezaOptionsProvisao.find(item => item.id === '5');
    if (prov == undefined){
      this.naturezaOptionsProvisao.push({ id: '5', name: 'Provisão' });
    }
    this.naturezaOptionsProvisao.forEach(element => {
      this.pieChartLabels1.push(element.name);
    });

    //console.log("é sdaud" + this.isSaude);
    this.userService.userInfo.subscribe(user => this.username = user.username);

    this.isFiscal = this.userService.isFiscal();
    this.isConsulta = this.userService.isConsulta();
    this.isSMTC = this.userService.isSMTC();
    this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
      this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
    });

    this.previsaoDespesaSelecionada = new PrevisaoDespesa();
    this.previsaoDespesaSelecionada.id = -1;

    //console.log("modulo");
    console.log(this.modulo);

    if (this.modulo === undefined) {
      this.modulo = 'm2';
    }
    if (this.isPortal === undefined) {
      this.isPortal = false;
    }
    //console.log(this.isPortal);

    this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
      this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
    });

    this.route.queryParams.pipe(filter(params => params.isExecPT)).subscribe(params => {
      this.isExecPT = (params['isExecPT'] === undefined || params['isExecPT'].toLowerCase() === 'false' ? false : true)
    });
    console.log(this.isExecPT);

    this.route.queryParams.pipe(filter(params => params.isPlanPT)).subscribe(params => {
      this.isPlanPT = (params['isPlanPT'] === undefined || params['isPlanPT'].toLowerCase() === 'false' ? false : true)
    });

    this.setupInterface(this.isCriarSA);
  }

  setupInterface(fazCopia) {
    if (this.plano != undefined) {
      this.loading.next(true);
      if (!(this.plano.planoId == undefined && this.plano.planoPai == undefined))
      this.route.paramMap.pipe(
        switchMap(() =>
          this.parceriaApi.getPrevisaoDespesa(this.plano.planoId === undefined ? this.plano.planoPai : this.plano.planoId, this.isPortal))
      ).subscribe((response) => {
        if (response) {
          this.previsaoDespesaLista  = [];
             this.previsaoDespesaLista = response as Array<PrevisaoDespesa>;
          this.populaGrafico();
          this.getPrevisaoReceita(this.plano.planoId === undefined ? this.plano.planoPai : this.plano.planoId);
          //console.log(this.previsaoDespesaLista[0]);
          if (fazCopia) {
            this.previsaoDespesaLista = this.previsaoDespesaLista.map(element =>
              element = this.utils.copyWithoutProps(element, ['planoTrabalhoId', 'id',
                'operacaoData', 'operacaoUsuario']))
          }
          this.calculaCompetencia();
          this.despesaUpdated.emit(this.previsaoDespesaLista);
          
        } else {
          this.alertService.warning('Nenhum registro encontrado');
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
    }
  }

  getPrevisaoReceita(planoId) {
    if (this.plano != undefined) {
      this.loading.next(true);
      this.route.paramMap.pipe(
        switchMap(() =>
          this.parceriaApi.getPrevisaoReceita(planoId, this.isPortal))
      ).subscribe((response) => {
        if (response) {
          this.previsaoRecList = response as Array<PrevisaoReceita>;
          this.calculaCompetencia();

        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
    }
  }

  disableCampos() {
    if (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno || this.isFiscal || this.plano == undefined){
      return true;
    }

    if (this.plano.planoSituacao == 2 || this.plano.planoSituacao == 4) {
      return true;
    }
    return false;
  }

  disableBotao() {
    if (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno || this.isFiscal || this.plano == undefined){
      return true;
    }

    if (this.plano.planoSituacao == 2 || this.plano.planoSituacao == 4) {
      return true;
    }
    return false;
  }

  disableApagarIcone() {
    if (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno || this.isFiscal || this.plano == undefined){
      return true;
    }

    if (this.plano.planoSituacao == 2 || this.plano.planoSituacao == 4) {
      return true;
    }
    return false;
  }

  enableEditarIcone() {
    if (this.isConsulta || this.isSMTC || this.isAuditorExterno) {
      return false;
    }
    return true;
  }

  adicionarPrevisaoDespesa() {
    let previsaoDespesa = new PrevisaoDespesa()
    previsaoDespesa.planoTrabalhoId = this.plano.planoId;
    previsaoDespesa.id = undefined;
    previsaoDespesa.vigenciaInicio = new Date();
    previsaoDespesa.vigenciaFinal = new Date();
    previsaoDespesa.frim = false;
    previsaoDespesa.custoIndireto = false;
    this.previsaoDespesaLista = [...this.previsaoDespesaLista, previsaoDespesa];
//    this.previsaoDespesaLista.push(previsaoDespesa);
    this.despesaUpdated.emit(this.previsaoDespesaLista);
  }

  onDateChangedInicio(previsaoDespesa, date: Date): void {
    previsaoDespesa.vigenciaInicio = date;
  }

  onDateChangedFinal(previsaoDespesa, date: Date): void {
    previsaoDespesa.vigenciaFinal = date;
  }

  updateNaturezaDespesa(event, data) {
    const value = event.currentTarget.value;
    data.natureza = value;
  }


  excluirPrevisaoDespesa() {

    const index = this.previsaoDespesaLista.indexOf(this.previsaoDespesaAserApagada); // Encontra o índice do item
    if (index > -1 && this.previsaoDespesaAserApagada.id != undefined) {
      this.detalheParceriaApi.excluirPrevisaoDespesa(this.previsaoDespesaAserApagada).subscribe(async (response) => {
        if (response) {
          this.previsaoDespesaLista.splice(index, 1); // Remove o item com base no índice
          this.despesaUpdated.emit(this.previsaoDespesaLista);
          this.modalEdicaoReference.close();
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));

    }
    else {
      this.previsaoDespesaLista.splice(index, 1); // Remove o item com base no índice
      this.modalEdicaoReference.close();
    }
    this.calculaCompetencia();
  }
  openExcluirPrevisaoDespesa(content, data, index) {
    this.previsaoDespesaAserApagada = data;
    this.indicePrevisao = index;
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
  }

  salvaPrevisaoDespesa(planoId, previsaoDespesaLista) {

    previsaoDespesaLista.forEach(element => element.planoTrabalhoId = planoId);
    this.loading.next(true);
    const jsonPayload = JSON.stringify(previsaoDespesaLista, this.removeCircularReferences());
    this.detalheParceriaApi.updatePrevisaoDespesa(jsonPayload).subscribe(async (response) => {
      if (response) {
        this.setupInterface(false);
        this.loading.next(false);
      }

    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));

  }
  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }

  removeCircularReferences() {
    const seen = new WeakSet();
    return function (key: any, value: any) {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return; // Ignora a propriedade circular
        }
        seen.add(value);
      }
      return value;
    };
  }

  validaCamposObrigatorios(previsaoDespesaLista) {
    let foundIssue = false;
    let foundDateIssue = false;
    previsaoDespesaLista.forEach(element => {
      element.operacaoData = new Date();
      element.operacaoUsuario = this.username;

      if (this.isBlank(element.detalhamento) || this.isBlank(element.valorMensal) ||
        this.isBlank(element.natureza)) {
        foundIssue = true;
        return;
      }
      let meses = this.calcularMesesEntreDatas(element.vigenciaInicio, element.vigenciaFinal);
      if (meses < 0) {
        foundDateIssue = true;
      }

    });

    if (foundIssue) {
      this.alertService.danger('Exitem campos obrigatórios em previsão de despesa não preenchidos');
    }

    if (foundDateIssue) {
      this.alertService.danger('A data final da vigência deve ser superior a data inicial da previsão de despesa');
      foundIssue = foundDateIssue;
    }

    return !foundIssue;
  }

  calcularMesesEntreDatas(dataInicio: Date, dataFinal: Date): number {
    const inicio = new Date(dataInicio);
    const fim = new Date(dataFinal);

    let meses = fim.getFullYear() * 12 + (fim.getMonth() + 1) - (inicio.getFullYear() * 12 + inicio.getMonth());

    return meses;
  }

  aplicaMascaraReais(valor) {
    if (valor != undefined || valor != null) {
      return this.mascara.aplicaMascaraReais(valor);
    }
    else return '0,00';
  }
  populaGrafico() {
    let meses = 0;
    this.totalMatConsumo = 0;
    this.totalPgtoPessoal = 0;
    this.totalServTerceiros = 0;
    this.totalMatPermanente = 0;
    this.totalProvisao = 0;
    this.totalNaturezas = 0;
    this.totalCustos = 0;
    this.totalFrim = 0;
    this.totalCustoIndireto = 0;
    this.totalOutrosCustos = 0;
    this.totalProvisao = 0;

    this.previsaoDespesaLista.forEach(element => {
      switch (element.natureza) {
        case 1:
          meses = this.calcularMesesEntreDatas(element.vigenciaInicio, element.vigenciaFinal);
          this.totalMatConsumo = this.totalMatConsumo + (element.valorMensal * meses);
          break;
        case 2:
          meses = this.calcularMesesEntreDatas(element.vigenciaInicio, element.vigenciaFinal);
          this.totalPgtoPessoal = this.totalPgtoPessoal + (element.valorMensal * meses);
          break;
        case 3:
          meses = this.calcularMesesEntreDatas(element.vigenciaInicio, element.vigenciaFinal);
          this.totalServTerceiros = this.totalServTerceiros + (element.valorMensal * meses);
          break;
        case 4:
          meses = this.calcularMesesEntreDatas(element.vigenciaInicio, element.vigenciaFinal);
          this.totalMatPermanente = this.totalMatPermanente + (element.valorMensal * meses);
          break;
        case 5:
          meses = this.calcularMesesEntreDatas(element.vigenciaInicio, element.vigenciaFinal);
          this.totalProvisao = this.totalProvisao + (element.valorMensal * meses);

          break;
        default:
          break;
      }
      this.totalNaturezas = this.totalPgtoPessoal + this.totalServTerceiros + this.totalMatPermanente
        + this.totalProvisao + this.totalMatConsumo;
      if (element.custoIndireto) {
        this.totalCustoIndireto = this.totalCustoIndireto + 1;
        this.totalCustos = this.totalCustos + 1;
      }
      else if (element.frim) {
        this.totalFrim = this.totalFrim + 1;
        this.totalCustos = this.totalCustos + 1;
      }
      else {
        this.totalOutrosCustos = this.totalOutrosCustos + 1;
        this.totalCustos = this.totalCustos + 1;
      }

    });
    this.pieChartData =
      [Math.round(((this.totalMatConsumo * 100) / this.totalNaturezas) * 100) / 100,
      Math.round(((this.totalPgtoPessoal * 100) / this.totalNaturezas) * 100) / 100,
      Math.round(((this.totalServTerceiros * 100) / this.totalNaturezas) * 100) / 100,
      Math.round(((this.totalMatPermanente * 100) / this.totalNaturezas) * 100) / 100,
      Math.round(((this.totalProvisao * 100) / this.totalNaturezas) * 100) / 100];

    this.pieChartDataCustos =
      [Math.round(((this.totalFrim * 100) / this.totalCustos) * 100) / 100,
      Math.round(((this.totalCustoIndireto * 100) / this.totalCustos) * 100) / 100,
      Math.round(((this.totalOutrosCustos * 100) / this.totalCustos) * 100) / 100];
    //console.log(this.pieChartData);
  }

  construirArrayMeses(dataInicio: Date, quantidadeMeses: number): string[] {
    const mesesArray: string[] = [];
    const mesNomes = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
      'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    for (let i = 0; i < quantidadeMeses; i++) {
      if (i === 0 || i === 12 || i === 24 || i === 36 || i === 48) {
        mesesArray.push('');
      }
      // const dataAtual = new Date(dataInicio);
      // dataAtual.setMonth(new Date(dataInicio).getMonth() + i);

      // const mes = mesNomes[dataAtual.getMonth()];
      // const ano = dataAtual.getFullYear();

      mesesArray.push(this.getMesAno(dataInicio, i));
    }

    return mesesArray;
  }

  getMesAno(dataInicio: Date, i: number): string {
    const mesesArray: string[] = [];
    const mesNomes = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
      'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    const dataAtual = new Date(dataInicio);
    dataAtual.setMonth(new Date(dataInicio).getMonth() + i);

    const mes = mesNomes[dataAtual.getMonth()];
    const ano = dataAtual.getFullYear();

    return `${mes}/${ano % 100}`;
  }

  isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  calculaCompetencia() {

    
    if (this.previsaoDespesaLista.length > 0) {
      // pega a despesa que inicia primeiro e a despesa mais longa
      let vigenciaInicio = this.previsaoDespesaLista[0].vigenciaInicio;
      let vigenciaFinal = this.previsaoDespesaLista[0].vigenciaFinal;
      this.previsaoDespesaLista.forEach(element => {
        if (vigenciaInicio > element.vigenciaInicio) {
          vigenciaInicio = element.vigenciaInicio;
        }
        if (vigenciaFinal < element.vigenciaFinal) {
          vigenciaFinal = element.vigenciaFinal;
        }
      });

      // se a receita tiver um inicio menor ... ou final mais longo
      this.previsaoRecList.forEach(element => {
        if (vigenciaInicio > element.vigenciaInicio) {
          vigenciaInicio = element.vigenciaInicio;
        }
        if (vigenciaFinal < element.vigenciaFinal) {
          vigenciaFinal = element.vigenciaFinal;
        }
      });


      const quantidadeMeses = this.calcularMesesEntreDatas(new Date(vigenciaInicio),
        vigenciaFinal);
      this.competenciasLista = this.construirArrayMeses(vigenciaInicio, quantidadeMeses)
      // console.log("teste");   
      //  this.competenciasLista.forEach(element => {
      //  console.log(element);   
      //   });
      let pgtoPessoalList = this.previsaoDespesaLista.filter(element => element.natureza === 2);
      this.pagamentoPessoalList = [];
      this.servicosTerceirosList = [];
      this.materialConsumoList = [];
      this.materialPermanenteList = [];
      this.provisaoList = [];
      this.previsaoDespList = [];
      this.cdRef.detectChanges();

      // pgtoPessoalList.forEach(element =>
      //   this.pagamentoPessoalList =[...this.pagamentoPessoalList, this.calculaCompetenciaPrevDespesa(element, vigenciaInicio, vigenciaFinal)]);
      // let totalPgtopessoal = this.somaArrayDespesa(this.pagamentoPessoalList, this.utils.getExecNaturezaStr(2));
      // this.pagamentoPessoalList = [...this.pagamentoPessoalList, totalPgtopessoal];

      
      let tempList = [];
      pgtoPessoalList.forEach(element => {
            tempList.push(this.calculaCompetenciaPrevDespesa(element, vigenciaInicio, vigenciaFinal));
      });
      let totalPgtopessoal = this.somaArrayDespesa(tempList, this.utils.getExecNaturezaStr(2));
      tempList.push(totalPgtopessoal);
      this.pagamentoPessoalList = [...tempList];



      //  console.log("pgamto");
      //  console.log( materialConsumoList);
      //this.pagamentoPessoalList.unshift(totalPgtopessoal);

      let servTerceiroList = this.previsaoDespesaLista.filter(element => element.natureza === 3);
      tempList = [];
      servTerceiroList.forEach(element => {
        tempList.push(this.calculaCompetenciaPrevDespesa(element, vigenciaInicio, vigenciaFinal));
      });
      let totalservTerceiro = this.somaArrayDespesa(tempList, this.utils.getExecNaturezaStr(3))
      tempList.push(totalservTerceiro);
       this.servicosTerceirosList = [...tempList];



       tempList = [];
      let matConsumoList = this.previsaoDespesaLista.filter(element => element.natureza === 1);
      matConsumoList.forEach(element => {
        tempList.push(this.calculaCompetenciaPrevDespesa(element, vigenciaInicio, vigenciaFinal));
      });
      let totalMatConsumo = this.somaArrayDespesa(tempList, this.utils.getExecNaturezaStr(1));
      tempList.push(totalMatConsumo);
      this.materialConsumoList = [...tempList];

      let matPermanentList = this.previsaoDespesaLista.filter(element => element.natureza === 4); 
      tempList = [];
      matPermanentList.forEach(element =>
      tempList.push(this.calculaCompetenciaPrevDespesa(element, vigenciaInicio, vigenciaFinal)));
      let totalMatPermanente = this.somaArrayDespesa(tempList, this.utils.getExecNaturezaStr(4));
      tempList.push(totalMatPermanente);
      this.materialPermanenteList = [...tempList];


      let provList = this.previsaoDespesaLista.filter(element => element.natureza === 5);
      tempList = [];
      provList.forEach(element =>
        tempList.push(this.calculaCompetenciaPrevDespesa(element, vigenciaInicio, vigenciaFinal)));
      let totalProvisao = this.somaArrayDespesa(tempList, "Provisão");
      tempList.push(totalProvisao)
      this.provisaoList = [...tempList];

      const prevDespList = [totalPgtopessoal, totalservTerceiro, totalMatConsumo, totalMatPermanente, totalProvisao];

   
      //this.previsaoDespList.push(this.somaArrayDespesa(prevDespList, "Total Despesa"));
      this.previsaoDespList = [...this.previsaoDespList, this.somaArrayDespesa(prevDespList, "Total Despesa")];

      let vigenciaInicioRec = this.previsaoRecList[0].vigenciaInicio;
      let vigenciaFinalRec = this.previsaoRecList[0].vigenciaFinal;
      this.previsaoRecList.forEach(element => {
        if (vigenciaInicioRec > element.vigenciaInicio) {
          vigenciaInicioRec = element.vigenciaInicio;
        }
        if (vigenciaFinalRec < element.vigenciaFinal) {
          vigenciaFinalRec = element.vigenciaFinal;
        }
      });

      this.previsaoReceitaList = new Array();
      let previsaoReceitaParcialList = new Array();
      this.previsaoRecList.forEach(element =>
        previsaoReceitaParcialList.push(this.calculaCompetenciaPrevDespesa(element, vigenciaInicio, vigenciaFinal)));

      this.previsaoReceitaList.push(this.somaArrayDespesa(previsaoReceitaParcialList, "Total Receita"));

      const subtraiList = [this.previsaoReceitaList, this.previsaoDespList];
      this.diferencaRecDespList = new Array();
      // console.log( 'subtraiList');
      // console.log( subtraiList);
      this.diferencaRecDespList.push(this.subtraiArrayDespesa(subtraiList, 'Diferença Rec. - Desp.'));

      this.mostraTabela = false;
      this.cdRef.detectChanges(); // força detecção imediata
      
      setTimeout(() => {
        this.mostraTabela = true;
        this.cdRef.detectChanges(); // força de novo depois que ngIf = true
      }, 50); // 0 pode ser cedo demai
      console.log(this.diferencaRecDespList);
    }
  }

  somaArrayDespesa(matriz: string[][], natureza: string): string[] {
    // Determina o comprimento máximo entre os subarrays
    const maxLength = Math.max(...matriz.map(array => array.length));


    // Itera pelas posições de 0 ao comprimento máximo
    return Array.from({ length: maxLength }, (_, index) => {
      // Obtém os valores correspondentes de cada subarray na posição atual
      const valores = matriz.map(array => array[index] || ""); // Usa "" se o valor estiver ausente.
      if (index === 0 || index === 13 || index === 26 || index === 39 || index === 52) {
        return natureza;
      }
      // Converte os valores para números e soma
      const soma = valores.reduce((total, valor) => {
        // Converte o valor para string antes de tentar substituir os caracteres
        const valorStr = typeof valor === "string" ? valor : String(valor || "");
        const numero = parseFloat(valorStr.replace(".", "").replace(",", ".")) || 0;
        return total + numero;
      }, 0);

      // Se a soma for maior que 0, retorna formatada; caso contrário, retorna o valor não numérico
      if (soma > 0) {
        return soma.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
      }

      // Mantém o primeiro valor não numérico, se existir, ou retorna uma string vazia
      return valores.find(valor => isNaN(parseFloat(String(valor).replace(".", "").replace(",", ".")))) || "";
    });
  }

  subtraiArrayDespesa(matriz: string[][][], natureza: string): string[] {
    // Achatar o array de três níveis para dois níveis
    const matrizFlattened = matriz.reduce((acc, current) => acc.concat(current), []);

    // Determina o comprimento máximo entre os subarrays
    const maxLength = Math.max(...matrizFlattened.map(array => array.length));

    // Itera pelas posições de 0 ao comprimento máximo
    return Array.from({ length: maxLength }, (_, index) => {
      // Obtém os valores correspondentes de cada subarray na posição atual
      const valores = matrizFlattened.map(array => array[index] || ""); // Usa "" se o valor estiver ausente.

      // Se for um dos índices específicos, retorna diretamente 'natureza'
      if (index === 0 || index === 13 || index === 26 || index === 39 || index === 52) {
        return natureza;
      }

      // Converte os valores para números e calcula a diferença
      const diferenca = valores.reduce((total, valor, idx) => {
        // Converte o valor para número
        const numero = parseFloat(
          (typeof valor === "string" ? valor : String(valor || "")).replace(/\./g, "").replace(",", ".")
        ) || 0;

        // Subtrai somente se não for o primeiro elemento
        return idx === 0 ? numero : total - numero;
      }, 0);

      // Retorna a diferença formatada em string
      return diferenca.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
    });
  }

  calculaCompetenciaPrevDespesa(despesa, vigenciaInicio, vigenciaFinal) {

    let competList = new Array();
    // se for percentual
    let i = 0;
    let foundBegin = false;
    let foundEnd = false;

    let k = 0;
    this.competenciasLista.forEach(elementC => {
      if (k === 0 || k === 13 || k === 26 || k === 39 || k === 52) {
        competList.push(despesa.detalhamento == undefined ? '' : despesa.detalhamento);
      }
      k = k + 1;
      if (elementC == this.getMesAno(despesa.vigenciaInicio, i)
        && (elementC != this.getMesAno(despesa.vigenciaFinal, 0) && !foundEnd)) {
        competList.push(this.aplicaMascaraReais(despesa.valorMensal));
        foundBegin = true;
      }
      else {
        if (elementC === this.getMesAno(despesa.vigenciaFinal, 0)) {
          foundEnd = true;
          competList.push(this.aplicaMascaraReais(despesa.valorMensal));
        }
        else if (!this.isBlank(elementC)) {
          competList.push('');
        }
      }
      if (foundBegin && !this.isBlank(elementC)) {
        i = i + 1;
      }
    })
    return competList;
  }

  get paginatedCompetenciasLista(): String[] {
    const start = (this.config.currentPage - 1) * this.config.itemsPerPage;
    const end = start + this.config.itemsPerPage;
    return this.competenciasLista.slice(start, end);
  }

  paginatedPrevisaoDespesaLista(prevDespList: any[]): any[] {
    const start = (this.config.currentPage - 1) * this.config.itemsPerPage;
    const end = start + this.config.itemsPerPage;
    const result = [];
    //result.push(prevDespList.slice(start, end));
    //return [...result];
    return prevDespList.slice(start, end) // retorna nova referência do resultado
  }

  // Change page
  changePage(pageNumber: number): void {
    this.config.currentPage = pageNumber;
  }

  // Get the total number of pages
  get totalPages(): number {
    return Math.ceil(this.competenciasLista.length / this.config.itemsPerPage);
  }
  
  splitIntoChunks(array: any[], chunkSize: number): any[][] {
      if (!array || chunkSize <= 0) return [];
    
      const result = [];
    
      for (let i = 0; i < array.length; i += chunkSize) {
        // sempre cria um novo array com slice
        result.push([...array.slice(i, i + chunkSize)]);
      }
    
      return [...result]; // retorna nova referência do resultado
    }
  visualizaDespesa(planoVisualizado, fazCopia) {
    this.plano = planoVisualizado;
    this.setupInterface(fazCopia);


  }
}