import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.css']
})
export class DateSelectorComponent implements OnChanges {
  @Input() initialDate: Date = new Date();
  @Input() disabledFlag: boolean;
  @Output() dateChanged = new EventEmitter<Date>();

  currentDate: Date;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['initialDate']) {
      this.currentDate = new Date(this.initialDate);
    }
  }

  get formattedDate(): string {
    const monthNames = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];
    const month = monthNames[this.currentDate.getMonth()];
    const year = this.currentDate.getFullYear();
    return `${month}/${year}`;
  }

  previousMonth(): void {
    this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    this.emitDateChange();
  }

  nextMonth(): void {
    this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    this.emitDateChange();
  }

  private emitDateChange(): void {
    this.dateChanged.emit(this.currentDate);
  }
}
