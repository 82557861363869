import { AtividadeMeta } from "./atividadeMeta";

export class Meta {

public planoId: number;
public metaId: number;
public metaDescricao: string;
public metaParamVerificacao: string;
public metaMeioVerificacao: string;
public metaAtividades: Array<AtividadeMeta>;
public metaOperacaoUsuario:string;
public metaOperacaoData:Date;

}