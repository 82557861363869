import { AtividadeMeta } from "../../planoDeTrabalho/meta/atividadeMeta";
import { ParametrosMonitoramento } from "./parametroMonitoramento.";

export class AtividadeMetaMonitoramentoRealizado{
   
    public atividadeMetaMonitoramentoRealizadoId:number;
	public atividade:AtividadeMeta;
	public parametrosMonitoramento: ParametrosMonitoramento;
	public ano: string;
	public mes: string;
	public realizado: string;
	public nomeArquivoAnexado: string;
	public operacaoData:Date;
	public operacaoUsuario: string;
	public uploadArquivo: boolean;
	public arquivo: string;
	public nomeArquivoAnexadoExcluido:string;
	public dadoSensivel:boolean;

}