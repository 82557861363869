<div style="margin-top: 20px">
    <!--<<div class="execucao tituloSecao">PREVISÃO DE RECEITAS</div>-->
    <div *ngIf="previsaoReceitaLista?.length==0">
        <span class="mensagem-vazio">Nenhuma previsão de receita foi encontrada.</span>
    </div>
    <div *ngIf="!disableBotao()">
        <button (click)="adicionarPrevisaoReceita() " id="button-insert_doc " class="btn btn-primary btn-info" type="button "
            title="Adicionar novo" [hidden]="disableBotao()">
            <i class="fa fa-plus "><span class="texto-botao">Adicionar Receita</span> </i>
        </button>
    </div>
    <div *ngIf="previsaoReceitaLista?.length > 0">
        <table class="table" style="margin-top:30px" id="tabelaPrevisaoReceita" [mfData]="previsaoReceitaLista" #mtPrevReceita="mfDataTable">
            <tbody>
                <div *ngFor="let previsaoReceita of mtPrevReceita.data; let j = index;" class="mb-5 border rounded p-3">
                    <div class="row mb-2">
                        <div class="col-md-3 font-weight-bold">Origem:<span class="text-danger">*</span>
                        </div>
                        <div class="col-md-9">
                            <div class="select-wrapper" style="width: 100%">
                                <select [disabled]="disableCampos()"
                                    [ngModel]="previsaoReceita.origem"
                                    [ngModelOptions]="{ standalone: true }" class="form-control"
                                    #tipoAtividade="ngModel" name="origemReceita"
                                    (change)="updateOrigemReceita($event, previsaoReceita)" required>
                                    <option [value]="option.id" *ngFor="let option of this.utils.origemReceita">
                                        {{ option.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-3 font-weight-bold">Valor Mensal:<span class="text-danger">*</span>
                        </div>
                        <div class="col-md-3">
                            <input [disabled]="disableCampos()" class="form-control input-tabela" id="valorMensal"
                                name="valorMensal" #valorMensal="ngModel" type="text" maxlength="100000000000000"
                                [(ngModel)]="previsaoReceita.valorMensal" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" 
                                validaInputZero>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-3 font-weight-bold">Vigência&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;De:
                            <span class="text-danger">*</span>
                        </div>
                        <div class="col-md-2">
                            <app-date-selector [disabledFlag]="disableBotao()"
                                [initialDate]="previsaoReceita.vigenciaInicio"
                                (dateChanged)="onDateChangedInicio(previsaoReceita, $event)"></app-date-selector>
                        </div>
                        <div class="col-md-1 font-weight-bold">Até:<span class="text-danger">*</span>
                        </div>
                        <div class="col-md-2">
                            <app-date-selector [disabledFlag]="disableBotao()"
                                [initialDate]="previsaoReceita.vigenciaFinal"
                                (dateChanged)="onDateChangedFinal(previsaoReceita, $event)"></app-date-selector>
                        </div>

                        <div class="col-md-1"></div>
                        <div *ngIf="!disableBotao()" class="col-md-2">
                            <button (click)="openExcluirPrevisaoReceita(contentExcluirPrevisaoReceita, previsaoReceita, j+1)" id="button-apagar-previsao-receita "
                            class="btn btn-primary btn-info ms-auto custom-margin-left" type="button " title="Excluir Previsão Receita" [hidden]="disableBotao()">
                                <i class="fa fa-trash-o" style="color: white;"><span class="texto-botao">Excluir
                                        Previsão Receita</span> </i>
                            </button>
                        </div>
    
                    </div>

                </div>
            </tbody>
        </table>
    </div>

    <ng-template #contentExcluirPrevisaoReceita let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Excluir Previsão Receita</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Tem certeza que deseja excluir a previsão de receita número:
                <b>{{indicePrevisao}}</b>?
            </p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" style="margin-top: 7px;" (click)="c('Close click')"
                title="Fechar">Fechar</button>
            <button type="button" class="btn btn-secondary" (click)="excluirPrevisaoReceita()" title="Excluir">Excluir</button>
        </div>
    </ng-template>

</div>