import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DateConverter } from '../../../@core/date-converter';
import { filter, share, switchMap } from 'rxjs/operators';
import { AlertService } from '../../../@core/alert.service';
import { ParceriaInfo } from './../../detalheParceria/parceriaInfo';
import { DetalheParceriaPortalApi } from '../../../apis/detalheParceriaPortalApi';
import { Mascaras } from '../../../@shared/components/Mascaras';
import { UserService } from '../../../@core/user.service';
import { Utils } from 'src/app/@shared/utils';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DetalheParceriaApi } from 'src/app/apis/detalheParceriaApi';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';
import { forEach } from 'lodash';
import { PaginationInstance } from 'ngx-pagination/dist/pagination-instance';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrevisaoEquipe } from './previsaoEquipe';
import { ExecucaoPlanoDeTrabalho } from '../execucaoPlanoDeTrabalho';
import { Cbo } from '../../colaboradores/cbo';
import { PrevisaoEquipeList } from './previsaoEquipeList';

@Component({
    selector: 'app-previsao-equipe',
    templateUrl: './previsaoEquipe.component.html',
    styleUrls: ['../../informacaoParceria/informacaoParceria.component.scss']
})
export class PrevisaoEquipeComponent implements OnInit {
    @Input()
    public plano: ExecucaoPlanoDeTrabalho;
    @Input()
    public isPortal: boolean;
    @Input()
    public isCriarSA: boolean;
    @Output() valueChange = new EventEmitter();
    @Input()
    public modulo: string;
    isFiscal: boolean;
    private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loading$ = this.loading.asObservable().pipe(share());
    isAuditorExterno: boolean;
    isSMTC: boolean;
    isConsulta: boolean;
    isExecPT: boolean;
    isPlanPT: boolean;
    p = 1;
    pagina: number;
    modalEdicaoReference: any;
    username: string;
    previsaoEquipeLista: Array<PrevisaoEquipe> = [];
    cboLista: Array<Cbo> = [];
    idsAExcluir: Array<Number> = [];
    totalProfissionais: number = 0;
    totalProfFundamentalIncompleto: number = 0;
    totalProfFundamental: number = 0;
    totalProfMedioTecnico: number = 0;
    totalProfMagisterio: number = 0;
    totalProfSuperior: number = 0;
    totalProfPosGraduacao: number = 0;
    totalProfMestrado: number = 0;
    totalProfDoutorado: number = 0;
    totalProfPosDoutorado: number = 0;
    totalProfMeio: number = 0;
    totalProfFim: number = 0;
    totalProfClt: number = 0;
    totalProfVoluntario: number = 0;
    totalProfEstagioRemunerado: number = 0;
    totalProfEstagioNaoRemunerado: number = 0;
    totalProfMenorAprendiz: number = 0;
    totalProfOutras: number = 0;
    totalRemFundIncAte2000: number = 0;
    totalRemFundIncMaisDe2000: number = 0;
    totalRemFundAte2000: number = 0;
    totalRemFundMaisDe2000: number = 0;
    totalRemMedAte2000: number = 0;
    totalRemMed2001a5000: number = 0;
    totalRemMedMaisDe5000: number = 0;
    totalRemMagAte5000: number = 0;
    totalRemMag5001a7500: number = 0;
    totalRemMagMaisDe7500: number = 0;
    totalRemSupAte5000: number = 0;
    totalRemSup5001a7500: number = 0;
    totalRemSup7501a10000: number = 0;
    totalRemSupMaisDe10000: number = 0;
    totalRemPosAte5000: number = 0;
    totalRemPos5001a7500: number = 0;
    totalRemPos7501a10000: number = 0;
    totalRemPosMaisDe10000: number = 0;
    totalRemMesAte6000: number = 0;
    totalRemMes6001a10000: number = 0;
    totalRemMes10001a15000: number = 0;
    totalRemMesMaisDe15000: number = 0;
    totalRemDoutAte6000: number = 0;
    totalRemDout6001a10000: number = 0;
    totalRemDout10001a15000: number = 0;
    totalRemDoutMaisDe15000: number = 0;
    totalRemPosDoutAte6000: number = 0;
    totalRemPosDout6001a10000: number = 0;
    totalRemPosDout10001a15000: number = 0;
    totalRemPosDoutMaisDe15000: number = 0;
    totalRemMeioAte2000: number = 0;
    totalRemMeio2001a5000: number = 0;
    totalRemMeio5001a10000: number = 0;
    totalRemMeioMaisDe10000: number = 0;
    totalRemFimAte2000: number = 0;
    totalRemFim2001a5000: number = 0;
    totalRemFim5001a10000: number = 0;
    totalRemFimMaisDe10000: number = 0;
    totalRemCltAte2000: number = 0;
    totalRemClt2001a5000: number = 0;
    totalRemClt5001a10000: number = 0;
    totalRemCltMaisDe10000: number = 0;
    totalRemVoluntarioAte2000: number = 0;
    totalRemVoluntarioMaisDe2000: number = 0;
    totalRemEstRemAte1500: number = 0;
    totalRemEstRemMaisDe1500: number = 0;
    totalRemMenAprAte1000: number = 0;
    totalRemMenAprMaisDe1000: number = 0;
    totalRemOutrasAte2000: number = 0;
    totalRemOutras2001a5000: number = 0;
    totalRemOutras5001a10000: number = 0;
    totalRemOutrasMaisDe10000: number = 0;
    pieChartDataProfEscolaridade: Array<any> = [120, 0, 3];
    pieChartLabelsProfEscolaridade: Array<String> = ['Fundamental Incompleto', 'Fundamental', 'Médio', 'Magistério', 'Superior', 'Pós-graduação', 'Mestrado', 'Doutorado', 'Pós-doutorado'];
    pieChartDataProfTipoAtividade: Array<any> = [120, 0, 3];
    pieChartLabelsProfTipoAtividade: Array<String> = ['Meio', 'Fim'];
    pieChartDataProfTipoVinculo: Array<any> = [120, 0, 3];
    pieChartLabelsProfTipoVinculo: Array<String> = ['CLT', 'Voluntário', 'Estágio remunerado', 'Estágio não remunerado', 'Menor aprendiz', 'Outra'];
    pieChartDataRemEscolaridade: Array<any> = [120, 0, 3];
    pieChartLabelsRemEscolaridade: Array<String> = ['Fund. Incompleto, até R$ 2.000', 'Fund. Incompleto, mais de R$ 2.000',
        'Fundamental, até R$ 2.000', 'Fundamental, mais de R$ 2.000',
        'Médio, até R$ 2.000', 'Médio, de R$ 2.000,01 a R$ 5.000,00', 'Médio, mais de R$ 5.000',
        'Magistério, até R$ 5.000', 'Magistério, de R$ 5.000,01 a R$ 7.500', 'Magistério, mais de R$ 7.500',
        'Superior, até R$ 5.000', 'Superior, de R$ 5.000,01 a R$ 7.500', 'Superior, de R$ 7.500,01 a R$ 10.000', 'Superior, mais de R$ 10.000',
        'Pós-graduação, até R$ 5.000', 'Pós-graduação, de R$ 5.000,01 a R$ 7.500', 'Pós-graduação, de R$ 7.500,01 a R$ 10.000', 'Pós-graduação, mais de R$ 10.000',
        'Mestrado, até R$ 6.000', 'Mestrado, de R$ 6.000,01 a R$ 10.000', 'Mestrado, de R$ 10.000,01 a R$ 15.000', 'Mestrado, mais de R$ 15.000',
        'Doutorado, até R$ 6.000', 'Doutorado, de R$ 6.000,01 a R$ 10.000', 'Doutorado, de R$ 10.000,01 a R$ 15.000', 'Doutorado, mais de R$ 15.000',
        'Pós-doutorado, até R$ 6.000', 'Pós-doutorado, de R$ 6.000,01 a R$ 10.000', 'Pós-doutorado, de R$ 10.000,01 a R$ 15.000', 'Pós-doutorado, mais de R$ 15.000'];
    pieChartDataRemTipoAtividade: Array<any> = [120, 0, 3];
    pieChartLabelsRemTipoAtividade: Array<String> = ['Meio, até R$ 2.000', 'Meio, de R$ 2.000,01 a R$ 5.000', 'Meio, de R$ 5.000,01 a R$ 10.000', 'Meio, mais de R$ 10.000',
        'Fim, até R$ 2.000', 'Fim, de R$ 2.000,01 a R$ 5.000', 'Fim, de R$ 5.000,01 a R$ 10.000', 'Fim, mais de R$ 10.000'];
    pieChartDataRemTipoVinculo: Array<any> = [120, 0, 3];
    pieChartLabelsRemTipoVinculo: Array<String> = ['CLT, até R$ 2.000', 'CLT, de R$ 2.000,01 a R$ 5.000', 'CLT, de R$ 5.000,01 a R$ 10.000', 'CLT, mais de R$ 10.000',
        'Voluntário, até R$ 2.000', 'Voluntário, mais de R$ 2.000',
        'Estágio remunerado, até R$ 1.500', 'Estágio remunerado, mais de R$ 1.500', 'Estágio não remunerado',
        'Menor aprendiz, até R$ 1.000', 'Menor aprendiz, mais de R$ 1.000',
        'Outras, até R$ 2.000', 'Outras, de R$ 2.000,01 a R$ 5.000', 'Outras, de R$ 5.000,01 a R$ 10.000', 'Outras, mais de R$ 10.000'];
    pieChartType = 'pie';
    chartOptions = { responsive: true,   maintainAspectRatio: false};
    pieChartColors1: Array<any> = [{
        backgroundColor: ['#3D642D', '#F39F18', '#F44611', '#646B63', '#00BB2D', '#5D9B9B', '#6A5D4D', '#A03472', '#C93C20', '#252850', '#8E402A', '#A65E2E', '#26252D', 
            '#CAC4B0', '#23282B', '#015D52', '#4C9141', '#B8B799', '#424632', '#1B5583', '#0A0A0A', '#015D52', '#6D3F5B', '#1D1E33', '#2C5545', '#6C6874', '#256D7B', 
            '#2F4538', '#45322E', '#9B111E', '#C1876B', '#8B8C7A', '#6A5D4D', '#39352A', '#B5B8B1', '#F39F18', '#212121', '#686C5E', '#5D9B9B', '#A03472', '#F4F4F4', 
            '#FFA420', '#4D5645', '#1F3A3D', '#B5B8B1', '#9D9101', '#4E3B31', '#20214F', '#828282', '#587246'],
        hoverBackgroundColor: ['#3D642D', '#F39F18', '#F44611', '#646B63', '#00BB2D', '#5D9B9B', '#6A5D4D', '#A03472', '#C93C20', '#252850', '#8E402A', '#A65E2E', 
            '#26252D', '#CAC4B0', '#23282B', '#015D52', '#4C9141', '#B8B799', '#424632', '#1B5583', '#0A0A0A', '#015D52', '#6D3F5B', '#1D1E33', '#2C5545', '#6C6874', 
            '#256D7B', '#2F4538', '#45322E', '#9B111E', '#C1876B', '#8B8C7A', '#6A5D4D', '#39352A', '#B5B8B1', '#F39F18', '#212121', '#686C5E', '#5D9B9B', '#A03472', 
            '#F4F4F4', '#FFA420', '#4D5645', '#1F3A3D', '#B5B8B1', '#9D9101', '#4E3B31', '#20214F', '#828282', '#587246']
        //borderColor: ['rgba(135,206,250,1)', 'rgba(106,90,205,1)', 'rgba(148,159,177,1)']
    }];
    @Output() equipeUpdated = new EventEmitter<PrevisaoEquipe[]>();
    mascaraNro = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/], guide: false };
    //previsaoEquipeLista: Array<PrevisaoEquipe> = [];
  
    public config: PaginationInstance = {
        id: 'custom',
        itemsPerPage: 12,
        currentPage: 1
    };

    constructor(private alertService: AlertService,
        private detalheParceriaApi: DetalheParceriaApi,
        private detalheParceriaPortalApi: DetalheParceriaPortalApi,
        public userService: UserService,
        private parceriaApi: PublicoPrivadaApi,
        private mascaras: Mascaras,
        private modalService: NgbModal,
        private dateConverter: DateConverter,
        private utils: Utils,
        private route: ActivatedRoute) {
    }

    ngOnInit() {

        this.userService.userInfo.subscribe(user => this.username = user.username);
        this.isFiscal = this.userService.isFiscal();
        this.isConsulta = this.userService.isConsulta();
        this.isSMTC = this.userService.isSMTC();
        this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
            this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
        });

        console.log("modulo");
        console.log(this.modulo);

        if (this.modulo === undefined) {
            this.modulo = 'm2';
        }
        if (this.isPortal === undefined) {
            this.isPortal = false;
        }
        //console.log(this.isPortal);

        this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
            this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
        });

        this.route.queryParams.pipe(filter(params => params.isExecPT)).subscribe(params => {
            this.isExecPT = (params['isExecPT'] === undefined || params['isExecPT'].toLowerCase() === 'false' ? false : true)
        });
        console.log(this.isExecPT);

        this.route.queryParams.pipe(filter(params => params.isPlanPT)).subscribe(params => {
            this.isPlanPT = (params['isPlanPT'] === undefined || params['isPlanPT'].toLowerCase() === 'false' ? false : true)
        });

        this.setupInterface(this.isCriarSA);
    }

    aplicaMascaraReais(valor) {
        if (valor != undefined || valor != null) {
            return this.mascaras.aplicaMascaraReais(valor);
        }
        else return '0,00';
    }

    setupInterface(fazCopia) {
        if (this.plano != undefined) {
            this.loading.next(true);
            if (!(this.plano.planoId == undefined && this.plano.planoPai == undefined))
            this.route.paramMap.pipe(
                switchMap(() =>
                    this.parceriaApi.getPrevisaoEquipe(this.plano.planoId === undefined? this.plano.planoPai:this.plano.planoId, this.isPortal))
            ).subscribe((response) => {
                if (response) {
                    this.previsaoEquipeLista = response as Array<PrevisaoEquipe>;
                    if (fazCopia) {
                        this.previsaoEquipeLista = this.previsaoEquipeLista.map(element =>
                            element = this.utils.copyWithoutProps(element, ['planoTrabalhoId', 'id',
                                'operacaoData', 'operacaoUsuario']))
                    }
                    this.equipeUpdated.emit(this.previsaoEquipeLista);
                    this.atualizaValoresGraficos();
                } else {
                    this.alertService.warning('Nenhum registro encontrado');
                }
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
            }, () => this.loading.next(false));
        }
        this.getCboLista();
    }

    visualizaPrevisaoEquipe(plano, fazCopia) {
        this.plano = plano;
        this.setupInterface(fazCopia);
    }

    groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    atualizaValoresGraficos() {
        var listaProfFundamentalIncompleto = this.previsaoEquipeLista.filter(item => item.escolaridade == 9);
        var listaProfFundamental = this.previsaoEquipeLista.filter(item => item.escolaridade == 1);
        var listaProfMedioTecnico = this.previsaoEquipeLista.filter(item => item.escolaridade == 2);
        var listaProfMagisterio = this.previsaoEquipeLista.filter(item => item.escolaridade == 3);
        var listaProfSuperior = this.previsaoEquipeLista.filter(item => item.escolaridade == 4);
        var listaProfPosGraduacao = this.previsaoEquipeLista.filter(item => item.escolaridade == 5);
        var listaProfMestrado = this.previsaoEquipeLista.filter(item => item.escolaridade == 6);
        var listaProfDoutorado = this.previsaoEquipeLista.filter(item => item.escolaridade == 7);
        var listaProfPosDoutorado = this.previsaoEquipeLista.filter(item => item.escolaridade == 8);

        var listaProfMeio = this.previsaoEquipeLista.filter(item => item.tipoAtividade == 1);
        var listaProfFim = this.previsaoEquipeLista.filter(item => item.tipoAtividade == 2);

        var listaProfClt = this.previsaoEquipeLista.filter(item => item.tipoVinculo == 1);
        var listaProfVoluntario = this.previsaoEquipeLista.filter(item => item.tipoVinculo == 2);
        var listaProfEstagioRemunerado = this.previsaoEquipeLista.filter(item => item.tipoVinculo == 3);
        var listaProfEstagioNaoRemunerado = this.previsaoEquipeLista.filter(item => item.tipoVinculo == 4);
        var listaProfMenorAprendiz = this.previsaoEquipeLista.filter(item => item.tipoVinculo == 5);
        var listaProfOutras = this.previsaoEquipeLista.filter(item => item.tipoVinculo == 6);

        this.totalProfissionais = this.previsaoEquipeLista.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalProfFundamentalIncompleto = listaProfFundamentalIncompleto.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalProfFundamental = listaProfFundamental.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalProfMedioTecnico = listaProfMedioTecnico.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalProfMagisterio = listaProfMagisterio.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalProfSuperior = listaProfSuperior.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalProfPosGraduacao = listaProfPosGraduacao.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalProfMestrado = listaProfMestrado.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalProfDoutorado = listaProfDoutorado.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalProfPosDoutorado = listaProfPosDoutorado.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalProfMeio = listaProfMeio.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalProfFim = listaProfFim.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalProfClt = listaProfClt.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalProfVoluntario = listaProfVoluntario.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalProfEstagioRemunerado = listaProfEstagioRemunerado.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalProfEstagioNaoRemunerado = listaProfEstagioNaoRemunerado.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalProfMenorAprendiz = listaProfMenorAprendiz.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalProfOutras = listaProfOutras.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        var listaRemFundIncAte2000 = listaProfFundamentalIncompleto.filter(item => item.remuneracaoMensal <= 2000);
        var listaRemFundIncMaisDe2000 = listaProfFundamentalIncompleto.filter(item => item.remuneracaoMensal > 2000);

        var listaRemFundAte2000 = listaProfFundamental.filter(item => item.remuneracaoMensal <= 2000);
        var listaRemFundMaisDe2000 = listaProfFundamental.filter(item => item.remuneracaoMensal > 2000);

        var listaRemMedAte2000 = listaProfMedioTecnico.filter(item => item.remuneracaoMensal <= 2000);
        var listaRemMed2001a5000 = listaProfMedioTecnico.filter(item => (item.remuneracaoMensal > 2000 && item.remuneracaoMensal <= 5000));
        var listaRemMedMaisDe5000 = listaProfMedioTecnico.filter(item => item.remuneracaoMensal > 5000);

        var listaRemMagAte5000 = listaProfMagisterio.filter(item => item.remuneracaoMensal <= 5000);
        var listaRemMag5001a7500 = listaProfMagisterio.filter(item => (item.remuneracaoMensal > 5000 && item.remuneracaoMensal <= 7500));
        var listaRemMagMaisDe7500 = listaProfMagisterio.filter(item => item.remuneracaoMensal > 7500);

        var listaRemSupAte5000 = listaProfSuperior.filter(item => item.remuneracaoMensal <= 5000);
        var listaRemSup5001a7500 = listaProfSuperior.filter(item => (item.remuneracaoMensal > 5000 && item.remuneracaoMensal <= 7500));
        var listaRemSup7501a10000 = listaProfSuperior.filter(item => (item.remuneracaoMensal > 7500 && item.remuneracaoMensal <= 10000));
        var listaRemSupMaisDe10000 = listaProfSuperior.filter(item => item.remuneracaoMensal > 10000);

        var listaRemPosAte5000 = listaProfPosGraduacao.filter(item => item.remuneracaoMensal <= 5000);
        var listaRemPos5001a7500 = listaProfPosGraduacao.filter(item => (item.remuneracaoMensal > 5000 && item.remuneracaoMensal <= 7500));
        var listaRemPos7501a10000 = listaProfPosGraduacao.filter(item => (item.remuneracaoMensal > 7500 && item.remuneracaoMensal <= 10000));
        var listaRemPosMaisDe10000 = listaProfPosGraduacao.filter(item => item.remuneracaoMensal > 10000);

        var listaRemMesAte6000 = listaProfMestrado.filter(item => item.remuneracaoMensal <= 6000);
        var listaRemMes6001a10000 = listaProfMestrado.filter(item => (item.remuneracaoMensal > 6000 && item.remuneracaoMensal <= 10000));
        var listaRemMes10001a15000 = listaProfMestrado.filter(item => (item.remuneracaoMensal > 10000 && item.remuneracaoMensal <= 15000));
        var listaRemMesMaisDe15000 = listaProfMestrado.filter(item => item.remuneracaoMensal > 15000);

        var listaRemDoutAte6000 = listaProfDoutorado.filter(item => item.remuneracaoMensal <= 6000);
        var listaRemDout6001a10000 = listaProfDoutorado.filter(item => (item.remuneracaoMensal > 6000 && item.remuneracaoMensal <= 10000));
        var listaRemDout10001a15000 = listaProfDoutorado.filter(item => (item.remuneracaoMensal > 10000 && item.remuneracaoMensal <= 15000));
        var listaRemDoutMaisDe15000 = listaProfDoutorado.filter(item => item.remuneracaoMensal > 15000);

        var listaRemPosDoutAte6000 = listaProfPosDoutorado.filter(item => item.remuneracaoMensal <= 6000);
        var listaRemPosDout6001a10000 = listaProfPosDoutorado.filter(item => (item.remuneracaoMensal > 6000 && item.remuneracaoMensal <= 10000));
        var listaRemPosDout10001a15000 = listaProfPosDoutorado.filter(item => (item.remuneracaoMensal > 10000 && item.remuneracaoMensal <= 15000));
        var listaRemPosDoutMaisDe15000 = listaProfPosDoutorado.filter(item => item.remuneracaoMensal > 15000);

        var listaRemMeioAte2000 = listaProfMeio.filter(item => item.remuneracaoMensal <= 2000);
        var listaRemMeio2001a5000 = listaProfMeio.filter(item => (item.remuneracaoMensal > 2000 && item.remuneracaoMensal <= 5000));
        var listaRemMeio5001a10000 = listaProfMeio.filter(item => (item.remuneracaoMensal > 5000 && item.remuneracaoMensal <= 10000));
        var listaRemMeioMaisDe10000 = listaProfMeio.filter(item => item.remuneracaoMensal > 10000);

        var listaRemFimAte2000 = listaProfFim.filter(item => item.remuneracaoMensal <= 2000);
        var listaRemFim2001a5000 = listaProfFim.filter(item => (item.remuneracaoMensal > 2000 && item.remuneracaoMensal <= 5000));
        var listaRemFim5001a10000 = listaProfFim.filter(item => (item.remuneracaoMensal > 5000 && item.remuneracaoMensal <= 10000));
        var listaRemFimMaisDe10000 = listaProfFim.filter(item => item.remuneracaoMensal > 10000);

        var listaRemCltAte2000 = listaProfClt.filter(item => item.remuneracaoMensal <= 2000);
        var listaRemClt2001a5000 = listaProfClt.filter(item => (item.remuneracaoMensal > 2000 && item.remuneracaoMensal <= 5000));
        var listaRemClt5001a10000 = listaProfClt.filter(item => (item.remuneracaoMensal > 5000 && item.remuneracaoMensal <= 10000));
        var listaRemCltMaisDe10000 = listaProfClt.filter(item => item.remuneracaoMensal > 10000);

        var listaRemVoluntarioAte2000 = listaProfVoluntario.filter(item => item.remuneracaoMensal <= 2000);
        var listaRemVoluntarioMaisDe2000 = listaProfVoluntario.filter(item => item.remuneracaoMensal > 2000);

        var listaRemEstRemAte1500 = listaProfEstagioRemunerado.filter(item => item.remuneracaoMensal <= 1500);
        var listaRemEstRemMaisDe1500 = listaProfEstagioRemunerado.filter(item => item.remuneracaoMensal > 1500);

        var listaRemMenAprAte1000 = listaProfMenorAprendiz.filter(item => item.remuneracaoMensal <= 1000);
        var listaRemMenAprMaisDe1000 = listaProfMenorAprendiz.filter(item => item.remuneracaoMensal > 1000);

        var listaRemOutrasAte2000 = listaProfOutras.filter(item => item.remuneracaoMensal <= 2000);
        var listaRemOutras2001a5000 = listaProfOutras.filter(item => (item.remuneracaoMensal > 2000 && item.remuneracaoMensal <= 5000));
        var listaRemOutras5001a10000 = listaProfOutras.filter(item => (item.remuneracaoMensal > 5000 && item.remuneracaoMensal <= 10000));
        var listaRemOutrasMaisDe10000 = listaProfOutras.filter(item => item.remuneracaoMensal > 10000);

        this.totalRemFundIncAte2000 = listaRemFundIncAte2000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemFundIncMaisDe2000 = listaRemFundIncMaisDe2000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalRemFundAte2000 = listaRemFundAte2000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemFundMaisDe2000 = listaRemFundMaisDe2000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalRemMedAte2000 = listaRemMedAte2000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemMed2001a5000 = listaRemMed2001a5000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemMedMaisDe5000 = listaRemMedMaisDe5000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalRemMagAte5000 = listaRemMagAte5000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemMag5001a7500 = listaRemMag5001a7500.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemMagMaisDe7500 = listaRemMagMaisDe7500.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalRemSupAte5000 = listaRemSupAte5000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemSup5001a7500 = listaRemSup5001a7500.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemSup7501a10000 = listaRemSup7501a10000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemSupMaisDe10000 = listaRemSupMaisDe10000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalRemPosAte5000 = listaRemPosAte5000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemPos5001a7500 = listaRemPos5001a7500.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemPos7501a10000 = listaRemPos7501a10000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemPosMaisDe10000 = listaRemPosMaisDe10000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalRemMesAte6000 = listaRemMesAte6000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemMes6001a10000 = listaRemMes6001a10000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemMes10001a15000 = listaRemMes10001a15000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemMesMaisDe15000 = listaRemMesMaisDe15000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalRemDoutAte6000 = listaRemDoutAte6000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemDout6001a10000 = listaRemDout6001a10000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemDout10001a15000 = listaRemDout10001a15000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemDoutMaisDe15000 = listaRemDoutMaisDe15000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalRemPosDoutAte6000 = listaRemPosDoutAte6000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemPosDout6001a10000 = listaRemPosDout6001a10000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemPosDout10001a15000 = listaRemPosDout10001a15000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemPosDoutMaisDe15000 = listaRemPosDoutMaisDe15000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalRemMeioAte2000 = listaRemMeioAte2000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemMeio2001a5000 = listaRemMeio2001a5000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemMeio5001a10000 = listaRemMeio5001a10000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemMeioMaisDe10000 = listaRemMeioMaisDe10000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalRemFimAte2000 = listaRemFimAte2000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemFim2001a5000 = listaRemFim2001a5000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemFim5001a10000 = listaRemFim5001a10000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemFimMaisDe10000 = listaRemFimMaisDe10000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalRemCltAte2000 = listaRemCltAte2000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemClt2001a5000 = listaRemClt2001a5000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemClt5001a10000 = listaRemClt5001a10000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemCltMaisDe10000 = listaRemCltMaisDe10000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalRemVoluntarioAte2000 = listaRemVoluntarioAte2000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemVoluntarioMaisDe2000 = listaRemVoluntarioMaisDe2000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalRemEstRemAte1500 = listaRemEstRemAte1500.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemEstRemMaisDe1500 = listaRemEstRemMaisDe1500.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalRemMenAprAte1000 = listaRemMenAprAte1000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemMenAprMaisDe1000 = listaRemMenAprMaisDe1000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.totalRemOutrasAte2000 = listaRemOutrasAte2000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemOutras2001a5000 = listaRemOutras2001a5000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemOutras5001a10000 = listaRemOutras5001a10000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);
        this.totalRemOutrasMaisDe10000 = listaRemOutrasMaisDe10000.reduce((acc, item) => acc + Number(item.numProfissionais ? item.numProfissionais : 0), 0);

        this.pieChartDataProfEscolaridade = [
            Math.round(((this.totalProfFundamentalIncompleto * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalProfFundamental * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalProfMedioTecnico * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalProfMagisterio * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalProfSuperior * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalProfPosGraduacao * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalProfMestrado * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalProfDoutorado * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalProfPosDoutorado * 100) / this.totalProfissionais) * 100) / 100
        ];

        this.pieChartDataProfTipoAtividade = [
            Math.round(((this.totalProfMeio * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalProfFim * 100) / this.totalProfissionais) * 100) / 100
        ];

        this.pieChartDataProfTipoVinculo = [
            Math.round(((this.totalProfClt * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalProfVoluntario * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalProfEstagioRemunerado * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalProfEstagioNaoRemunerado * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalProfMenorAprendiz * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalProfOutras * 100) / this.totalProfissionais) * 100) / 100
        ];

        this.pieChartDataRemEscolaridade = [
            Math.round(((this.totalRemFundIncAte2000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemFundIncMaisDe2000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemFundAte2000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemFundMaisDe2000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemMedAte2000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemMed2001a5000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemMedMaisDe5000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemMagAte5000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemMag5001a7500 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemMagMaisDe7500 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemSupAte5000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemSup5001a7500 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemSup7501a10000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemSupMaisDe10000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemPosAte5000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemPos5001a7500 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemPos7501a10000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemPosMaisDe10000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemMesAte6000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemMes6001a10000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemMes10001a15000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemMesMaisDe15000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemDoutAte6000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemDout6001a10000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemDout10001a15000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemDoutMaisDe15000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemPosDoutAte6000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemPosDout6001a10000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemPosDout10001a15000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemPosDoutMaisDe15000 * 100) / this.totalProfissionais) * 100) / 100
        ];

        this.pieChartDataRemTipoAtividade = [
            Math.round(((this.totalRemMeioAte2000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemMeio2001a5000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemMeio5001a10000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemMeioMaisDe10000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemFimAte2000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemFim2001a5000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemFim5001a10000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemFimMaisDe10000 * 100) / this.totalProfissionais) * 100) / 100
        ];

        this.pieChartDataRemTipoVinculo = [
            Math.round(((this.totalRemCltAte2000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemClt2001a5000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemClt5001a10000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemCltMaisDe10000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemVoluntarioAte2000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemVoluntarioMaisDe2000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemEstRemAte1500 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemEstRemMaisDe1500 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalProfEstagioNaoRemunerado * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemMenAprAte1000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemMenAprMaisDe1000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemOutrasAte2000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemOutras2001a5000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemOutras5001a10000 * 100) / this.totalProfissionais) * 100) / 100,
            Math.round(((this.totalRemOutrasMaisDe10000 * 100) / this.totalProfissionais) * 100) / 100
        ];
    }

    compareFn(item, selected) {
        return item.id === selected.id;
    }

    customSearchFn(term: string, item: any) {
        term = term.toLocaleLowerCase();
        return item.codigo.toString().indexOf(term) > -1 ||
            item.atividade.toLocaleLowerCase().indexOf(term) > -1;
    }

    updateCbo(event, data) {
        if (event) {
            const id = event.id;
            data.cboId = id;
        } else {
            data.cboId = null;
        }
    }

    disableCampos() {
        if (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno || this.isFiscal || this.plano == undefined){
          return true;
        }
    
        if (this.plano.planoSituacao == 2 || this.plano.planoSituacao == 4) {
          return true;
        }
        return false;
      }
    
      disableBotao() {
        if (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno || this.isFiscal || this.plano == undefined){
          return true;
        }
    
        if (this.plano.planoSituacao == 2 || this.plano.planoSituacao == 4) {
          return true;
        }
        return false;
      }
    
      disableApagarIcone() {
        if (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno || this.isFiscal || this.plano == undefined){
          return true;
        }
    
        if (this.plano.planoSituacao == 2 || this.plano.planoSituacao == 4) {
          return true;
        }
        return false;
      }

    getCboLista() {
        this.loading.next(true);
        this.detalheParceriaPortalApi.getCboAll()
            .subscribe((response) => {
                if (response) {
                    this.cboLista = response as Array<Cbo>;
                    if (this.previsaoEquipeLista.length > 0) {
                        this.previsaoEquipeLista.forEach(element => {
                            const cboId = element.cboId;
                            element.cbo = this.cboLista.find(x => x.id === cboId);
                        })
                    }
                }
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
            }, () => this.loading.next(false));
    }

    adicionarPrevisaoEquipe() {
        var prevEquipe = new PrevisaoEquipe();
        prevEquipe.id = undefined;
        prevEquipe.planoTrabalhoId = this.plano.planoId;
        this.previsaoEquipeLista.push(prevEquipe);
        this.equipeUpdated.emit(this.previsaoEquipeLista);
        setTimeout(function () {
            var cargosFuncao = document.getElementsByName("cargoFuncao");
            cargosFuncao[cargosFuncao.length - 1].focus();
        }, 0);
    }

    excluirPrevisaoEquipe(data) {
        const index = this.previsaoEquipeLista.indexOf(data);
        if (index !== -1) {
            if (data.id === undefined) {
                this.previsaoEquipeLista.splice(index, 1);
            } else {
                this.idsAExcluir.push(data.id);
                this.previsaoEquipeLista.splice(index, 1);
            }
            this.atualizaValoresGraficos();
        }
        this.equipeUpdated.emit(this.previsaoEquipeLista);
    }

    isBlank(str) {
        return (!str || /^\s*$/.test(str));
    }

    replacer(key, value) {
        //atributos que só existem no view e não no servidor
        const ignoreList = ['cbo'];
        if (ignoreList.indexOf(key) > -1) {
            return undefined;
        } else {
            return value;
        }
    }
    validaCamposObrigatorios(equipeLista) {
        let temErro = false;

        equipeLista.forEach(element => {
            element.operacaoData = new Date();
            element.operacaoUsuario = this.username;
            if (element.remuneracaoMensal === null || element.remuneracaoMensal === undefined) {
                element.remuneracaoMensal = 0;
            }
            if (element.tipoVinculo != 6) {
                element.outroVinculo = null;
            }
            if (element.tipoVinculo == 4) {
                element.remuneracaoMensal = 0;
            }
            if (!element.cargaHorariaSemanal || this.isBlank(element.cargoFuncao) || !element.cboId || !element.escolaridade ||
                !element.numProfissionais || !element.tipoVinculo || (element.tipoVinculo === 6 && this.isBlank(element.outroVinculo))
                || !element.tipoAtividade || (!element.remuneracaoMensal && element.tipoVinculo != 2 && element.tipoVinculo != 4 && element.tipoVinculo != 5)) {
                temErro = true;
            }
        })

        if (temErro) {
            this.alertService.danger('Todos os campos de previsão de equipe devem ser preenchidos.');
            return;
        }


        return !temErro;
    }

    salvaPrevisaoEquipe(planoId, equipeLista) {
        equipeLista.forEach(element => element.planoTrabalhoId = planoId);        

        let prevEquipeList = new PrevisaoEquipeList();
        prevEquipeList.previsoesEquipe = equipeLista;
        const data = JSON.stringify(prevEquipeList, this.replacer);
        this.detalheParceriaApi.updatePrevisaoEquipeList(data).subscribe((response) => {
            var lista = response.body as unknown as PrevisaoEquipeList;
            this.previsaoEquipeLista = lista.previsoesEquipe;
            if (this.previsaoEquipeLista.length > 0) {
                this.previsaoEquipeLista.forEach(element => {
                    const cboId = element.cboId;
                    element.cbo = this.cboLista.find(x => x.id === cboId);
                })
            }
            this.equipeUpdated.emit(this.previsaoEquipeLista);
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
        }, () => this.loading.next(false));

        if (this.idsAExcluir.length > 0) {
            this.detalheParceriaApi.excluirPrevisoesEquipe(this.idsAExcluir).subscribe((response) => {
                this.setupInterface(false);
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
            }, () => this.loading.next(false));
        }
    }
}