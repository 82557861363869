import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, Host } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DateConverter } from '../../../@core/date-converter';
import { elementAt, filter, share, switchMap } from 'rxjs/operators';
import { AlertService } from '../../../@core/alert.service';
import { ParceriaInfo } from './../../detalheParceria/parceriaInfo';
import { DetalheParceriaPortalApi } from '../../../apis/detalheParceriaPortalApi';
import { Mascaras } from '../../../@shared/components/Mascaras';
import { UserService } from '../../../@core/user.service';
import { Utils } from 'src/app/@shared/utils';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DetalheParceriaApi } from 'src/app/apis/detalheParceriaApi';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';
import { forEach } from 'lodash';
import { PaginationInstance } from 'ngx-pagination/dist/pagination-instance';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemAvaliacaoPlano } from './itemAvaliacaoPlano';
import { ExecucaoPlanoDeTrabalho } from '../execucaoPlanoDeTrabalho';
import { AuthUser, KeycloakService } from '@procempa/ngx-keycloak';
import { PrevisaoReceitaComponent } from '../previsaoReceita/previsaoReceita.component';
import { PrevisaoReceita } from '../previsaoReceita/previsaoReceita';
import { AvaliacaoPlano } from './avaliacaoPlano';
import { omit, isEqual } from 'lodash';
import { PrevisaoDespesa } from '../previsaoDespesa/previsaoDespesa';
import { DatePipe } from '@angular/common';
import { PrevisaoEquipe } from '../previsaoEquipe/previsaoEquipe';
import { Cbo } from '../../colaboradores/cbo';
import { Meta } from '../meta/meta';
import { PlanoDeTrabalhoComponent } from 'src/app/modulo1/planoDeTrabalho/planoDeTrabalho.component';
import { PlanoDeTrabalhoNovoComponent } from '../planoDeTrabalho.component';

@Component({
  selector: 'app-avaliacao-plano',
  templateUrl: './avaliacao.component.html',
  styleUrls: ['../../informacaoParceria/informacaoParceria.component.scss']
})
export class AvaliacaoComponent implements OnInit {

  @Input()
  public plano: ExecucaoPlanoDeTrabalho;
  @Input()
  public isPortal: boolean;
  @Input()
  public isSaude: boolean;
  @Output() valueChange = new EventEmitter();
  @Input()
  public modulo: string;
  isFiscal: boolean;
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  isAuditorExterno: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  isExecPT: boolean;
  isPlanPT: boolean;
  p = 1;
  pagina: number;
  modalEdicaoReference: any;
  username: string;
  avaliacaoPlanoLista: Array<ItemAvaliacaoPlano> = [];
  itemAvaliacaoSelecionado = new ItemAvaliacaoPlano();
  itemAvaliacaoAserApagado = new ItemAvaliacaoPlano();
  indicePrevisao: number;
  selectAll: boolean;
  avaliacaoPlano: AvaliacaoPlano;
  alteracoesPlano: { chave: string; value1: any; value2: any }[] = [];
  planoPai: ExecucaoPlanoDeTrabalho;
  cboList: Array<Cbo> = [];
  public config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 13,
    currentPage: 1
  };
  previsaoReceitaList: any[];
  @ViewChild('contentEfetivarAlt', { static: false }) contentEfetivarAlteracao!: TemplateRef<any>;

  constructor(private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    public userService: UserService,
    private parceriaApi: PublicoPrivadaApi,
    private mascaras: Mascaras,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    private utils: Utils,
    private route: ActivatedRoute,
    public keycloakService: KeycloakService,
    private mascara: Mascaras,
    @Host() public planoT: PlanoDeTrabalhoNovoComponent) {
  }

  ngOnInit() {

    this.userService.userInfo.subscribe(user => this.username = user.username);
    this.selectAll = false;
    this.isFiscal = this.userService.isFiscal();
    this.isConsulta = this.userService.isConsulta();
    this.isSMTC = this.userService.isSMTC();
    this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
      this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
    });

    //console.log("modulo");
    console.log(this.modulo);

    if (this.modulo === undefined) {
      this.modulo = 'm2';
    }
    if (this.isPortal === undefined) {
      this.isPortal = false;
    }
    //console.log(this.isPortal);

    this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
      this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
    });

    this.route.queryParams.pipe(filter(params => params.isExecPT)).subscribe(params => {
      this.isExecPT = (params['isExecPT'] === undefined || params['isExecPT'].toLowerCase() === 'false' ? false : true)
    });
    console.log(this.isExecPT);

    this.route.queryParams.pipe(filter(params => params.isPlanPT)).subscribe(params => {
      this.isPlanPT = (params['isPlanPT'] === undefined || params['isPlanPT'].toLowerCase() === 'false' ? false : true)
    });

    this.setupInterface();
  }

  async setupInterface() {
    this.avaliacaoPlanoLista = new Array();
    this.avaliacaoPlano = new AvaliacaoPlano();

    this.avaliacaoPlano.observacoesGerais = '';
    this.avaliacaoPlano.parecerFinal = 0;
    this.avaliacaoPlano.portalTransparencia = 0;
    this.avaliacaoPlano.itensAvaliacao = new Array();
    this.avaliacaoPlano.alteracao = false;
    this.avaliacaoPlanoLista = this.avaliacaoPlano.itensAvaliacao;
    if (this.plano != undefined && this.plano.planoId != undefined) {
      this.avaliacaoPlano.planoId = this.plano.planoId;
      this.avaliacaoPlano.alteracao = this.plano.planoIdentificador.startsWith('SA');
      this.loading.next(true);
      this.insereItensAvaliacaoObrigatorios();
      this.avaliacaoPlano.itensAvaliacao = this.avaliacaoPlanoLista;
      this.route.paramMap.pipe(
        switchMap(() =>
          this.parceriaApi.getAvaliacaoPlano(this.plano.planoId, this.isPortal))
      ).subscribe((response) => {
        if (response) {
          this.avaliacaoPlano = response as AvaliacaoPlano;
          this.avaliacaoPlanoLista = this.avaliacaoPlano.itensAvaliacao;
          //console.log(this.avaliacaoPlanoLista[0]);
          if (this.avaliacaoPlanoLista.length == 0) {
            this.insereItensAvaliacaoObrigatorios();
          }
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
    }

    if ((this.plano!= undefined && this.plano.planoIdentificador.startsWith('SA'))){
      this.avaliacaoPlano.alteracao =true;
      let planoPai = await this.getPlanoPai(this.plano.planoPai);
      this.planoPai = planoPai;
      console.log(this.plano.planoIdentificador);
      if (this.plano.planoSituacao !=1){
        this.avaliaAlteracaoPlano(this.plano, planoPai);
      }
    }

  }

  insereItensAvaliacaoObrigatorios() {

    if (this.avaliacaoPlanoLista.length == 0 && !this.avaliacaoPlano.alteracao) {
      this.utils.itemAvaliacao.forEach(element => {
        let itemAvaliacao = new ItemAvaliacaoPlano();
        itemAvaliacao.itemPlano = element;
        itemAvaliacao.avaliacaoItem = 1;
        itemAvaliacao.recomendacaoItem = '';
        itemAvaliacao.planoId = this.plano.planoId;
        itemAvaliacao.limpa = false;
        this.avaliacaoPlanoLista.push(itemAvaliacao);
      });
    }
  }

  disableCampos() {
    if (!this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno) {
      return true;
    }

    if (this.isFiscal && this.plano.planoSituacao == 2) {
      return false;
    }

    if (this.plano.planoSituacao == 1 || this.plano.planoSituacao == 3 || this.plano.planoSituacao == 4) {
      return true;
    }
    return false;
  }

  disableBotao() {

    if (!this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno) {
      return true;
    }

    if (this.isFiscal && this.plano.planoSituacao == 2) {
      return false;
    }


    if (this.isFiscal && this.plano.planoSituacao == 4) {
      return false;
    }
 
    if (this.plano.planoSituacao == 1 || this.plano.planoSituacao == 3 || this.plano.planoSituacao == 4 ) {
      return true;
    }
    return false;
  }
  disableItem(idx, tipo) {

    if (!this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno) {
      return true;
    }
    if (this.isFiscal && (this.plano.planoSituacao == 2)) {
      if (idx < 10 && tipo == 'A' && this.config.currentPage == 1) {
        return true;
      }
      return false;
    }
    if (this.plano.planoSituacao == 1 || this.plano.planoSituacao == 4 || this.plano.planoSituacao == 3) {
      return true;
    }

  }

  // disableApagarIcone() {
  //   if (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno) {
  //     return true;
  //   }
  //   if (this.plano.planoSituacao == 1 || this.plano.planoSituacao == 4) {
  //     return true;
  //   }

  // }
  selectAllItem() {
    let i = 0;
    this.avaliacaoPlanoLista.map((element) => {

      if (!this.selectAll && i > 9)
        element.limpa = true;
      else
        element.limpa = false;
      i = i + 1;
    })

  }

  enableEditarIcone() {
    if (this.isConsulta || this.isSMTC || this.isAuditorExterno) {
      return false;
    }
    return true;
  }

  selectAprovadoCorrige(event){
    const newVal = event.currentTarget.value;
    if (newVal == 1){
      this.plano.planoSituacao=2;      
    }
    if (newVal == 2){
      this.plano.planoSituacao=3;  
    }
    this.avaliacaoPlano.parecerFinal=newVal;
  }

  adicionarItemAvaliacaoPlano() {
    let itemAvaliacao = new ItemAvaliacaoPlano();
    itemAvaliacao.itemPlano = '';
    itemAvaliacao.recomendacaoItem = '';
    itemAvaliacao.avaliacaoItem = 1;
    itemAvaliacao.planoId = this.plano.planoId;
    this.avaliacaoPlanoLista.push(itemAvaliacao);
  }



  updateAvaliacao(event, data) {
    const value = event.currentTarget.value;
    data.avaliacaoItem = value;
  }


  excluir(itensLista) {

    this.detalheParceriaApi.excluirItensAvaliacaoPlano(itensLista).subscribe(async (response) => {
      if (response) {
        this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));
  }

  openExcluirItemAvaliacao(content) {
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
  }

  excluirItensAvalicao() {

    const cloneArray = this.avaliacaoPlanoLista.slice();
    const itensExcluidos = new Array<ItemAvaliacaoPlano>();

    this.avaliacaoPlanoLista.map((element) => {

      if (element.limpa) {
        cloneArray.splice(cloneArray.indexOf(element), 1);
        if (element.itemId != undefined) {
          itensExcluidos.push(element);
        }
      }
    })

    if (itensExcluidos.length > 0) {
      this.excluir(itensExcluidos);
    }
    else {
      this.modalEdicaoReference.close();
    }
    this.avaliacaoPlanoLista = cloneArray;
  }

  salvaAvaliacaoPlano() {

    if (!this.validaCamposObrigatorios()) {

      if (this.avaliacaoPlanoLista != null || this.avaliacaoPlanoLista.length > 0) {
        this.avaliacaoPlanoLista.map(element => {
          element.planoId = this.plano.planoId;
          element.itemOperacaoData = new Date();
          element.itemUsuarioOperacao = this.username;
        }
        );
      }
      this.loading.next(true);
      this.avaliacaoPlano.itensAvaliacao = this.avaliacaoPlanoLista;
      this.avaliacaoPlano.operacaoData = new Date();

      this.avaliacaoPlano.usuarioOperacao = this.username;
      this.detalheParceriaApi.updateAvaliacaoPlano(this.avaliacaoPlano).subscribe(async (response) => {
        if (response) {
          this.alertService.info('Avaliação salva com sucesso.')
          //if (!this.avaliacaoPlano.alteracao){
           
            if(this.avaliacaoPlano.portalTransparencia == 1){
              //
              this.planoT.verPlano=false; 
              this.planoT.planoVisualizado=undefined;
              this.planoT.setupInterface();
            }else{
              this.setupInterface();
              if (this.avaliacaoPlano.parecerFinal==1){
                this.plano.planoSituacao=4;
              }
            }
          //}
          this.modalEdicaoReference.close();
          this.loading.next(false);
        }

      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));
    }
  }
  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }

  validaCamposObrigatorios() {
    let foundIssue = false;
    let foundDateIssue = false;
    if (this.plano != undefined && !this.plano.planoIdentificador.startsWith("SA")) {
      this.avaliacaoPlanoLista.forEach(element => {
        element.itemOperacaoData = new Date();
        element.itemUsuarioOperacao = this.username;

        if (this.isBlank(element.itemPlano) || (
          this.isBlank(element.recomendacaoItem) && element.avaliacaoItem == 1)
          || this.isBlank(element.avaliacaoItem)) {
          foundIssue = true;
          return;
        }
      });
    }
    if (this.avaliacaoPlano.parecerFinal == 0) {
      foundIssue = true;
    }

    if (this.isBlank(this.avaliacaoPlano.observacoesGerais)) {
      foundIssue = true;
    }

    if (foundIssue) {
      this.alertService.danger('Exitem campos obrigatórios na avaliação de plano não preenchidos');
    }

    return foundIssue;
  }

  // Change page
  changePage(pageNumber: number): void {
    this.config.currentPage = pageNumber;
  }

  // Get the total number of pages
  get totalPages(): number {
    return Math.ceil(this.avaliacaoPlanoLista.length / this.config.itemsPerPage);
  }
  // Get the total number of pages
  get totalPagesA(): number {
    return Math.ceil(this.alteracoesPlano.length / this.config.itemsPerPage);
  }

  visualizaAvaliacao(planoVisualizado) {
    this.plano = planoVisualizado;
    this.setupInterface();
  }

  openEfetivarAlteracao(content) {
    if (!this.contentEfetivarAlteracao) {
      console.error('Erro: contentEfetivarAlteracao não foi carregado!');
      return;
    }
    this.modalEdicaoReference = this.modalService.open(this.contentEfetivarAlteracao, { backdrop: 'static', keyboard: false });

    //this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
  }

  efetivarAlteracao() {

    if ((this.plano.planoSituacao != 4)
      || (this.plano.planoSituacao == 4 && this.avaliacaoPlano.parecerFinal == 2)) {
      this.modalEdicaoReference.close();
      this.alertService.danger('Para publicizar a avaliação do plano de trabalho, é necessário salvar a avaliação com o parecer APROVADO.');
      return;

    }
    this.avaliacaoPlano.portalTransparencia = 1;
    
    this.salvaAvaliacaoPlano();
  }
  devolverPlano() {
    this.plano.planoSituacao = 3;
    this.plano.planoDataOperacao = new Date();
    this.plano.planoOperacaoUsuario = this.username;

    const data = JSON.stringify(this.plano, this.replacer);
    this.detalheParceriaApi.updatePlanoDeTrabalho(data).subscribe(async (response) => {
      this.alertService.info("Devolvido o plano para OSC para correção.");
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));
  }

  replacer(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['planoDataIniExecucaoFormat', 'planoDataFimExecucaoFormat'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  retornaValorTratado(key, valor) {
    let result;
    const datePipe = new DatePipe('en-US');
    switch (key) {
      case 'atividadeInicioExecucao':
        if (valor != '') {
          result = datePipe.transform(valor, 'dd/MM/yyyy');
        }
        else {
          result = '';
        }
        break;
      case 'vigenciaInicio':
        if (valor != '') {
          result = datePipe.transform(valor, 'dd/MM/yyyy');
        }
        else {
          result = '';
        }
        break;
      case 'vigenciaFinal':
        if (valor != '') {
          result = datePipe.transform(valor, 'dd/MM/yyyy');
        }
        else {
          result = '';
        }
        break;
      case 'natureza':
        result = this.utils.getExecNaturezaStr(valor);
        break;
      case 'valorMensal':
        result = 'R$' + this.mascara.aplicaMascaraReais(valor);
        break;
      case 'remuneracaoMensal':
        result = 'R$' + this.mascara.aplicaMascaraReais(valor);
        break;
      case 'origem':
        result = this.utils.getOrigemReceita(valor);
        break;
      case 'escolaridade':
        result = this.utils.getEscolaridade(valor);
        break;
      case 'tipoVinculo':
        result = this.utils.getModalidadeContratacao(valor);
        break;
      case 'atividadeFrequencia':
        result = this.utils.getAtividadeFrequencia(valor);
        break;
      case 'tipoAtividade':
        if (valor != '') {
          result = valor == 1 ? 'Meio' : 'Fim';
        }
        else {
          result = '';
        }
        break;
      case 'cboId':
        if (Number(valor) != 0 && this.cboList != undefined) {
          let cbo = this.cboList.find(element => element.id === Number(valor));
          result = cbo.atividade;
        }
        else {
          result = '';
        }
        break;
      default:
        result = valor;
        break;
    }
    return result;
  }

  encontraDiferancaEntreObjetos<T extends Record<string, any>>(
    obj1: T,
    obj2: T,
    ignoredAttributes: string[]
  ): { chave: string; value1: any; value2: any }[] {

    // Filtra apenas chaves válidas de T
    const validKeys = Object.keys(obj1) as (keyof T)[];
    const filteredAttributes = ignoredAttributes.filter(attr =>
      validKeys.includes(attr as keyof T)
    ) as (keyof T)[];

    const cleanObject = (obj: T) => omit(obj, filteredAttributes);

    const obj1Clean = cleanObject(obj1);
    const obj2Clean = cleanObject(obj2);

    const differences: { chave: string; value1: any; value2: any }[] = [];

    Object.keys(obj1Clean).forEach(key => {
      const typedKey = key as keyof T;
      if (key == 'metaAtividades') {
        const diff = this.encontraDiferencaEntreListas(obj1Clean[typedKey], obj2Clean[typedKey], ignoredAttributes, 'atividadeDescricao');
        differences.push(...diff);
      }
      else if (!isEqual(obj1Clean[typedKey], obj2Clean[typedKey])) {
        differences.push({
          chave: this.insertSpaces(key), // Aqui usamos key diretamente como string
          value1: this.retornaValorTratado(key, obj1Clean[typedKey]),
          value2: this.retornaValorTratado(key, obj2Clean[typedKey]),
        });
      }
    });

    return differences;
  }

  encontraDiferencaEntreListas<T extends Record<string, any>>(
    lista1: T[],
    lista2: T[],
    ignoredAttributes: string[],
    idKey: keyof T // Chave que identifica cada item
  ): { chave: string; value1: any; value2: any }[] {
    const result: { chave: string; value1: any; value2: any }[] = [];
    //todo testar se o item existe na lista 2 e não na 1


    if (lista1.length > lista2.length) {
      // Cria um mapa para facilitar a busca de objetos pelo ID
      const mapLista2 = new Map(lista2.map(item => [item[idKey], item]));
      lista1.forEach(item1 => {
        const item2 = mapLista2.get(item1[idKey]);
        if (item2) {
          // Compara os objetos usando o método existente

          const differences = this.encontraDiferancaEntreObjetos(item1, item2, ignoredAttributes);
          if (differences.length > 0) {
            differences.forEach(element => {
              result.push(element)
            });
          }

        } else {
          // Caso o item não exista na lista2, considera como diferença completa
          let item2 = this.cloneObjectWithEmptyValues(item1);
          const differences = this.encontraDiferancaEntreObjetos(item1, item2, ignoredAttributes);
          if (differences.length > 0) {
            differences.forEach(element => {
              result.push(element)
            });
          }
        }
      });
    } else {
      // Cria um mapa para facilitar a busca de objetos pelo ID
      const mapLista1 = new Map(lista1.map(item => [item[idKey], item]));
      lista2.forEach(item2 => {
        const item1 = mapLista1.get(item2[idKey]);
        if (item1) {
          // Compara os objetos usando o método existente
          const differences = this.encontraDiferancaEntreObjetos(item1, item2, ignoredAttributes);
          if (differences.length > 0) {
            differences.forEach(element => {
              result.push(element)
            });
          }
        } else {
          // Caso o item não exista na lista2, considera como diferença completa
          let item1 = this.cloneObjectWithEmptyValues(item2);

          if (item2 instanceof Array) {
            const differences = this.encontraDiferencaEntreListas([], item2, ignoredAttributes, 'atividadeDescricao');
            if (differences.length > 0) {
              differences.forEach(element => {
                result.push(element)
              });
            }
          }
          else {

            const differences = this.encontraDiferancaEntreObjetos(item1, item2, ignoredAttributes);
            if (differences.length > 0) {
              differences.forEach(element => {
                result.push(element)
              });
            }
          }
        }
      });
    }

    return result;
  }

  insertSpaces(string) {
    string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
    string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  retornaComparaAvalia(planoAlterado, planoPai, ignoredAttributes) {

    return this.encontraDiferancaEntreObjetos(planoAlterado, planoPai, ignoredAttributes);

  }

  async avaliaAlteracaoPlano(planoAlterado: ExecucaoPlanoDeTrabalho, planoPai: ExecucaoPlanoDeTrabalho) {

    this.plano = planoAlterado;
    this.avaliacaoPlanoLista = [];
    this.alteracoesPlano = [];
    let alteracoesPlanoMap = [];
    this.planoPai = planoPai;

    this.avaliacaoPlano = new AvaliacaoPlano();
    this.avaliacaoPlano.planoId = planoAlterado.planoId;
    // todo ver como fazer com campos obrigatórios
    this.avaliacaoPlano.observacoesGerais = '';
    this.avaliacaoPlano.parecerFinal = 0;
    this.avaliacaoPlano.portalTransparencia = 0;
    this.avaliacaoPlano.alteracao = true;
    // compara os planos de trabalho
    let ignoredAttributes = ['planoIdentificador', 'planoId', 'planoDataIniExecucaoFormat',
      'planoDataFimExecucaoFormat', 'planoDataIniExecucao', 'planoDataFimExecucao',
      'planoDataOperacao', 'planoOperacaoUsuario', 'planoPai', 'planoJustificativaAlteracao',
      'previsaoReceitaId', 'planoSitucao', 'metaOperacaoData', 'metaOperacaoUsuario', 'metaId',
      'nonExistentKey', 'operacaoData', 'operacaoUsuario', 'id', 'planoTrabalhoId', 'atividadeOperacaoUsuario',
      'atividadeOperacaoData', 'atividadeId','planoSituacao', 'planoEfetivacao'
    ];
    await this.getCbo();

    alteracoesPlanoMap.push(this.retornaComparaAvalia(planoPai, planoAlterado, ignoredAttributes));
    this.alteracoesPlano = alteracoesPlanoMap.reduce(
      (acc, curr) => acc.concat(curr),
      []
    );
    //console.log(this.alteracoesPlano);
    this.alteracoesPlano.push({ chave: 'Receita', value1: '', value2: '' });

    let recPlA = await this.getPrevisaoReceita(planoAlterado);
    let recPPai = await this.getPrevisaoReceita(planoPai);
    alteracoesPlanoMap = [];
    // compara as despesas
    alteracoesPlanoMap.push(this.encontraDiferencaEntreListas(recPPai, recPlA, ignoredAttributes, 'origem'));

    this.alteracoesPlano.push(...alteracoesPlanoMap.reduce(
      (acc, curr) => acc.concat(curr),
      []
    ));
   // console.log(this.alteracoesPlano);
    this.alteracoesPlano.push({ chave: 'Despesa', value1: '', value2: '' });

    let despPlA = await this.getPrevisaoDespesa(planoAlterado);
    let despPPai = await this.getPrevisaoDespesa(planoPai);
    alteracoesPlanoMap = [];
    // compara as despesas
    alteracoesPlanoMap.push(this.encontraDiferencaEntreListas(despPPai, despPlA, ignoredAttributes, 'natureza'));

    this.alteracoesPlano.push(...alteracoesPlanoMap.reduce(
      (acc, curr) => acc.concat(curr),
      []
    ));
    //console.log(this.alteracoesPlano);
    this.alteracoesPlano.push({ chave: 'Equipe', value1: '', value2: '' });


    let equipePlA = await this.getPrevisaoEquipe(planoAlterado);
    let equipePPai = await this.getPrevisaoEquipe(planoPai);
    alteracoesPlanoMap = [];
    // compara as despesas
    alteracoesPlanoMap.push(this.encontraDiferencaEntreListas(equipePPai, equipePlA, ignoredAttributes, 'cargoFuncao'));

    this.alteracoesPlano.push(...alteracoesPlanoMap.reduce(
      (acc, curr) => acc.concat(curr),
      []
    ));
    this.alteracoesPlano.push({ chave: 'Meta', value1: '', value2: '' });

    let metaPlA = await this.getMetas(planoAlterado);
    let metaPPai = await this.getMetas(planoPai);
    alteracoesPlanoMap = [];
    // compara as despesas
    alteracoesPlanoMap.push(this.encontraDiferencaEntreListas(metaPPai, metaPlA, ignoredAttributes, 'metaDescricao'));

    this.alteracoesPlano.push(...alteracoesPlanoMap.reduce(
      (acc, curr) => acc.concat(curr),
      []
    ));

    //console.log(this.alteracoesPlano);


  }

  isBold(chave: string): boolean {
    return chave == 'Despesa' || chave == 'Receita' || chave == 'Equipe' || chave == 'Meta' || chave == 'Atividade';
  }
  async getPrevisaoDespesa(pl): Promise<Array<PrevisaoDespesa>> {
    try {
      const data: Array<PrevisaoDespesa> = await this.parceriaApi.getPrevisaoDespesa(pl.planoId, this.isPortal)
        .toPromise();
      //  console.log('Recebido:', data); // Tratar os dados recebidos
      return data;
    } catch (error) {
      //console.error('Erro ao buscar dados:', error); // Tratar erros
      throw error; // Relançar o erro, se necessário
    }
  }
  async getPrevisaoReceita(pl): Promise<Array<PrevisaoReceita>> {
    try {
      const data: Array<PrevisaoReceita> = await this.parceriaApi.getPrevisaoReceita(pl.planoId, this.isPortal)
        .toPromise();
      // console.log('Recebido:', data); // Tratar os dados recebidos
      return data;
    } catch (error) {
     // console.error('Erro ao buscar dados:', error); // Tratar erros
      throw error; // Relançar o erro, se necessário
    }
  }
  async getPrevisaoEquipe(pl): Promise<Array<PrevisaoEquipe>> {
    try {
      const data: Array<PrevisaoEquipe> = await this.parceriaApi.getPrevisaoEquipe(pl.planoId, this.isPortal)
        .toPromise();
      // console.log('Recebido:', data); // Tratar os dados recebidos
      return data;
    } catch (error) {
      //console.error('Erro ao buscar dados:', error); // Tratar erros
      throw error; // Relançar o erro, se necessário
    }
  }

  async getCbo(): Promise<Array<Cbo>> {
    try {
      this.cboList = await this.detalheParceriaPortalApi.getCboAll()
        .toPromise();
      //console.log('Recebido:', this.cboList); // Tratar os dados recebidos
      return this.cboList;
    } catch (error) {
     // console.error('Erro ao buscar dados:', error); // Tratar erros
      throw error; // Relançar o erro, se necessário
    }
  }

  async getMetas(pl): Promise<Array<Meta>> {
    try {
      const data: Array<Meta> = await this.parceriaApi.getMetasPlano(pl.planoId, this.isPortal)
        .toPromise();
      //console.log('Recebido:', this.cboList); // Tratar os dados recebidos
      return data;
    } catch (error) {
     // console.error('Erro ao buscar dados:', error); // Tratar erros
      throw error; // Relançar o erro, se necessário
    }
  }

  cloneObjectWithEmptyValues<T extends Record<string, any>>(obj: T): T {
    return Object.keys(obj).reduce((acc, key) => {
      acc[key as keyof T] = this.getDefaultEmptyValue(typeof obj[key]); // Atribui valores vazios à chave correta
      return acc;
    }, {} as T);
  }

  // Retorna um valor vazio padrão com base no tipo
  getDefaultEmptyValue(type: string): any {
    switch (type) {
      case 'string': return '';
      case 'number': return 0;
      case 'boolean': return false;
      case 'date': return '';
      case 'object': return [];
      default: return null;
    }
  }

  async getPlanoPai(id): Promise<ExecucaoPlanoDeTrabalho> {
    try {
      const data: ExecucaoPlanoDeTrabalho = await  this.parceriaApi.getPlanoDeTrabalhoById(id, this.isPortal, this.isFiscal)
        .toPromise();
      // console.log('Recebido:', data); // Tratar os dados recebidos
      return data;
    } catch (error) {
     // console.error('Erro ao buscar dados:', error); // Tratar erros
      throw error; // Relançar o erro, se necessário
    }
  }
}