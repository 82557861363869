import { ParametrosMonitoramento } from "../../monitoramentoAvaliacao/parametroMonitoramento/parametroMonitoramento.";
import { Meta } from "./meta";

export class AtividadeMeta{


public atividadeId: number;
public meta: Meta;
public atividadeDescricao: string;
public atividadeFrequencia: number;
public atividadeTipoApuracao: number;
public atividadeMeta: string;
public atividadeInicioExecucao:Date;
public atividadeOperacaoUsuario:string;
public atividadeOperacaoData:Date;
public parametrosMonitoramento: ParametrosMonitoramento;
public currentPage:number;
}