import { Injectable } from '@angular/core';

import { DetalheParceriaApi } from './detalheParceriaApi';
import { DetalheParceriaPortalApi } from './detalheParceriaPortalApi';
import { BeneficiarioApi } from './beneficiarioApi';


@Injectable()
export class PublicoPrivadaApi {
    constructor(private detalheParceriaPortalApi: DetalheParceriaPortalApi,
        private detalheParceriaApi: DetalheParceriaApi,
        private beneficiarioApi: BeneficiarioApi) {
    }

    getArquivoDes(parId, execId, tipo, isPortal, isTmp) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getArquivoDes(parId, execId, tipo, isTmp);
        }
        else {
            return this.detalheParceriaApi.getArquivoDes(parId, execId, tipo, isTmp);
        }
    }
    getArquivoDevApl(parId, execDevolId, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getArquivoDevApl(parId, execDevolId);
        }
        else {
            return this.detalheParceriaApi.getArquivoDevApl(parId, execDevolId);
        }
    }
   
    getDespesaParceriaPeriodo(filtroDesembolso, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getDespesaParceriaPeriodo(filtroDesembolso);
        }
        else {
            return this.detalheParceriaApi.getDespesaParceriaPeriodo(filtroDesembolso);
        }
    }
    getDevolucaoAplicacao(id, mes, ano, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getDevolucaoAplicacao(id, mes, ano);
        }
        else {
            return this.detalheParceriaApi.getDevolucaoAplicacao(id, mes, ano);
        }
    }

    getDevolucaoAplicacaoPeriodo(filtro, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getDevolucaoAplicacaoPeriodo(filtro);
        }
        else {
            return this.detalheParceriaApi.getDevolucaoAplicacaoPeriodo(filtro);
        }
    }

    getPrestaContaParcial(id, mes, ano, isPortal) {
        // tslint:disable-next-line:max-line-length
        if (isPortal) {
            return this.detalheParceriaPortalApi.getPrestaContaParcial(id, mes, ano);
        }
        else {
            return this.detalheParceriaApi.getPrestaContaParcial(id, mes, ano);
        }
    }

    getPrestaContaParcialPeriodo(filtro, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getPrestaContaParcialPeriodo(filtro);
        }
        else {
            return this.detalheParceriaApi.getPrestaContaParcialPeriodo(filtro);
        }
    }

    getPrestaContaFinal(id, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getPrestaContaFinal(id);
        }
        else {
            return this.detalheParceriaApi.getPrestaContaFinal(id);
        }
    }

    getParceriaMonitoraAvalia(id, isPortal) {
        // tslint:disable-next-line:max-line-length
        if (isPortal) {
            return this.detalheParceriaPortalApi.getParceriaMonitoraAvalia(id);
        }
        else {
            return this.detalheParceriaApi.getParceriaMonitoraAvalia(id);
        }
    }

    getArquivo(parId, id, tipo, isPortal) {
        // tslint:disable-next-line:max-line-length
        if (isPortal) {
            return this.detalheParceriaPortalApi.getArquivo(parId, id, tipo);
        }
        else {
            return this.detalheParceriaApi.getArquivo(parId, id, tipo);
        }
    }

    getArquivoColaborador(colaboradorId, tipoArquivo, isPortal) {
        // tslint:disable-next-line:max-line-length
        if (isPortal) {
            return this.detalheParceriaPortalApi.getArquivoColaborador(colaboradorId, tipoArquivo);
        }
        else {
            return this.detalheParceriaApi.getArquivoColaborador(colaboradorId, tipoArquivo);
        }
    }

    getBeneficioSituacaoMensal(parId, turId, ano, mes, isPortal) {
        // tslint:disable-next-line:max-line-length

        if (isPortal) {
            return this.detalheParceriaPortalApi.getBeneficioSituacaoMensal(parId, turId, ano, mes);
        }
        else {
            return this.beneficiarioApi.getBeneficioSituacaoMensal(parId, turId, ano, mes);
        }
    }

    getSituacaoMensalFreq(parId, turId, ano, mes, isPortal) {

        if (isPortal) {
            return this.detalheParceriaPortalApi.getSituacaoMensalFreq(parId, turId, ano, mes);
        }
        else {
            return this.beneficiarioApi.getSituacaoMensalFreq(parId, turId, ano, mes);
        }
    }

    getFrequenciaBeneficiarioMensal(parId, turId, benId, ano, mes, isPortal) {

        if (isPortal) {
            return this.detalheParceriaPortalApi.getFrequenciaBeneficiarioMensal(parId, turId, benId, ano, mes);
        }
        else {
            return this.beneficiarioApi.getFrequenciaBeneficiarioMensal(parId, turId, benId, ano, mes);
        }
    }

    getVinculosBeneficiariosParceria(parId, isPortal) {

        if (isPortal) {
            return this.detalheParceriaPortalApi.getVinculosBeneficiariosParceria(parId);
        }
        else {
            return this.beneficiarioApi.getVinculosBeneficiariosParceria(parId);
        }
    }

    getVinculosBeneficiariosParceriaByParam(parId, turId, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getVinculosBeneficiariosParceriaByParam(parId, turId);
        }
        else {
            return this.beneficiarioApi.getVinculosBeneficiariosParceriaByParam(parId, turId);
        }
    }
    
    consultaNFSe(inscricaoMunicipal, nroDoc, token, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.consultaNFSe(inscricaoMunicipal, nroDoc, token);
        }
        else {
            return this.detalheParceriaApi.consultaNFSe(inscricaoMunicipal, nroDoc);
        }
    }

    consultaNFSeNacional(chaveDeAcesso, token, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.consultaNFSeNacional(chaveDeAcesso, token);
        } else {
            return this.detalheParceriaApi.consultaNFSeNacional(chaveDeAcesso);
        }
    }

    consultaNFe(chaveDeAcesso, cpfOperador, token, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.consultaNFe(chaveDeAcesso, cpfOperador, token);
        }
        else {
            return this.detalheParceriaApi.consultaNFe(chaveDeAcesso, cpfOperador);
        }
    }

    getPrestacoesDeContas(parId, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getPrestacoesDeContas(parId);
        }
        else {
            return this.detalheParceriaApi.getPrestacoesDeContas(parId);
        }
    }

    getArquivoPrestacaoDeContas(id, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getArquivoPrestacaoDeContas(id);
        }
        else {
            return this.detalheParceriaApi.getArquivoPrestacaoDeContas(id);
        }
    }
    getArquivoPrestadorServTer(prestadorId, contratoId, tipoArquivo, isPortal) {
        // tslint:disable-next-line:max-line-length
        if (isPortal) {
            return this.detalheParceriaPortalApi.getArquivoPrestadorServTer(prestadorId, tipoArquivo);
        }
        else {
            return this.detalheParceriaApi.getArquivoPrestadorServTer(prestadorId, contratoId, tipoArquivo);
        }
    }
    getPlanoDeTrabalho(parId, isPortal, IsFiscal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getPlanoDeTrabalho(parId);
        }
        else {
            return this.detalheParceriaApi.getPlanoDeTrabalho(parId, IsFiscal);
        }
    }

    getPlanoDeTrabalhoById(Id, isPortal, IsFiscal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getPlanoDeTrabalhoById(Id);
        }
        else {
            return this.detalheParceriaApi.getPlanoDeTrabalhoById(Id, IsFiscal);
        }
    }

    getMetasPlano(planoId, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getMetasPlano(planoId);
        }
        else {
            return this.detalheParceriaApi.getMetasPlano(planoId);
        }
    }

    getPrevisaoEquipe(planoId, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getPrevisaoEquipe(planoId);
        } else {
            return this.detalheParceriaApi.getPrevisaoEquipe(planoId);
        }
    }

    getPrevisaoReceita(planoId, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getPrevisaoReceita(planoId);
        } else {
            return this.detalheParceriaApi.getPrevisaoReceita(planoId);
        }
    }


    getPrevisaoDespesa(planoId, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getPrevisaoDespesa(planoId);
        } else {
            return this.detalheParceriaApi.getPrevisaoDespesa(planoId);
        }
    }

    getAvaliacaoPlano(planoId, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getAvaliacaoPlano(planoId);
        } else {
            return this.detalheParceriaApi.getAvaliacaoPlano(planoId);
        }
    }
    getMonitoramentoAvaliacao(parId, isPortal, IsFiscal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getMonitoramentoAvaliacao(parId);
        }
        else {
            return this.detalheParceriaApi.getMonitoramentoAvaliacao(parId, IsFiscal);
        }
    }
    getMetaAtivadeParametrosMonitoramento(planoId, monitoraId, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getMetaAtivadeParametrosMonitoramento(planoId, monitoraId);
        }
        else {
            return this.detalheParceriaApi.getMetaAtivadeParametrosMonitoramento(planoId, monitoraId);
        }
    }

    getOutrosEventosParametroMonitoramento(planoId, monitoraId, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getOutrosEventosParametroMonitoramento(planoId, monitoraId);
        }
        else {
            return this.detalheParceriaApi.getOutrosEventosParametroMonitoramento(planoId, monitoraId);
        }
    }
    

    getArquivoAtivMonitoramentoRealizado(
        parametroId,  atividadeMetaMonitoramentoRealizadoId, isPortal) {
        if (isPortal) {
            return this.detalheParceriaPortalApi.getArquivoAtivMonitoramentoRealizado( 
                parametroId, atividadeMetaMonitoramentoRealizadoId );
        }
        else {
            return this.detalheParceriaApi.getArquivoAtivMonitoramentoRealizado(parametroId, 
                atividadeMetaMonitoramentoRealizadoId);
        }
    }
    
    
}
