<div style="margin-top: 20px">
    <!-- <div [hidden]="!(loading$ | async) ">
      <div class="loading-overlay "></div>
      <div class="loading-info ">
        <i class="fa fa-spinner fa-4x fa-spin "></i>
        <div id="carregando">Aguarde</div>
      </div>
    </div>
   -->


    <!--<<div class="execucao tituloSecao">METAS A SEREM ATINGIDAS</div>-->
    <div *ngIf="metaDoPlanoLista?.length==0">
        <span class="mensagem-vazio">Nenhuma meta foi encontrada.</span>
    </div>
    <div *ngIf="!disableBotao()">
        <button (click)="adicionarMeta() " id="button-insert_doc " class="btn btn-primary btn-info" type="button "
            title="Adicionar Meta" [hidden]="disableBotao()">
            <i class="fa fa-plus "><span class="texto-botao">Adicionar Meta</span> </i>
        </button>
    </div>
    <div *ngIf="metaDoPlanoLista?.length>0">

        <table class="table" style="margin-top:30px" id="tabelaMeta" [mfData]="metaDoPlanoLista"
            #mtMetaPlan="mfDataTable">

            <tbody>
                <div *ngFor="let data of mtMetaPlan.data; let j = index;" class="mb-5 border rounded p-3">
                    <!-- Linha para Descrição da Meta -->
                    <div class="row mb-2">
                        <div class="col-md-1 custom-width font-weight-bold">{{j+1}}</div>
                        <div class="col-md-3 font-weight-bold">Descrição da Meta:<span class="text-danger">*</span>
                        </div>
                        <div class="col-md-8">
                            <input [disabled]="disableCampos()" class="form-control input-tabela" id="descricaoMeta"
                                name="descricaoMeta" #descricaoMeta="ngModel" type="text"
                                [(ngModel)]="data.metaDescricao">
                        </div>
                    </div>

                    <!-- Linha para Parâmetros de Verificação -->
                    <div class="row mb-2">
                        <div class="col-md-1 custom-width"></div>
                        <div class="col-md-3 font-weight-bold">Parâmetros de Verificação:<span
                                class="text-danger">*</span></div>
                        <div class="col-md-8">
                            <input [disabled]="disableCampos()" class="form-control input-tabela" id="paramVerificacao"
                                name="paramVerificacao" #paramVerificacao="ngModel" type="text"
                                [(ngModel)]="data.metaParamVerificacao">
                        </div>
                    </div>

                    <!-- Linha para Meios de Verificação -->
                    <div class="row mb-4">
                        <div class="col-md-1 custom-width"></div>
                        <div class="col-md-3 font-weight-bold">Meios de Verificação:<span class="text-danger">*</span>
                        </div>
                        <div class="col-md-8">
                            <input [disabled]="disableCampos()" class="form-control input-tabela" id="meioVerificacao"
                                name="meioVerificacao" #meioVerificacao="ngModel" type="text"
                                [(ngModel)]="data.metaMeioVerificacao">
                        </div>
                    </div>

                    <!-- Título para as atividades -->
                    <div *ngIf="data.metaAtividades != null" class="row mb-2">
                        <div class="col-md-1 custom-width"></div>
                        <div class="col-md-11 font-weight-bold">
                            Atividades para atingimento da Meta:
                        </div>
                    </div>

                    <!-- Loop para exibir cada atividade -->
                    <div *ngFor="let atividade of data.metaAtividades; let k = index;" class="border rounded mb-3 p-3">
                        <div class="row mb-2">
                            <div class="col-md-1"></div>
                            <div class="col-md-3 font-weight-bold">Descrição da Atividade:<span
                                    class="text-danger">*</span></div>
                            <div class="col-md-8">
                                <input [disabled]="disableCampos()" class="form-control input-tabela" maxlength="150"
                                    id="descricaoAtividade" name="descricaoAtividade" #descricaoAtividade="ngModel"
                                    type="text" [(ngModel)]="atividade.atividadeDescricao">
                            </div>
                        </div>

                        <!-- Linha para Frequência -->
                        <div class="row mb-2">
                            <div class="col-md-1"></div>
                            <div class="col-md-3 font-weight-bold">Frequência:<span class="text-danger">*</span></div>
                            <div class="col-md-3">
                                <select [disabled]="disableCampos()" class="form-control custom-select"
                                    name="atividadeFrequencia" [(ngModel)]="atividade.atividadeFrequencia">
                                    <option value="1">Diária</option>
                                    <option value="2">Semanal</option>
                                    <option value="3">Quinzenal</option>
                                    <option value="4">Mensal</option>
                                    <option value="5">Bimestral</option>
                                    <option value="6">Quadrimestral</option>
                                    <option value="7">Semestral</option>
                                    <option value="8">Anual</option>
                                </select>
                            </div>
                        </div>

                        <!-- Linha para Tipo de Apuração -->
                        <div class="row mb-2">
                            <div class="col-md-1 font-weight-bold">{{j+1}}.{{k+1}}</div>
                            <div class="col-md-3 font-weight-bold">Tipo de Apuração:<span class="text-danger">*</span>
                            </div>
                            <div class="col-md-3">
                                <select [disabled]="disableCampos()" class="form-control custom-select"
                                    name="atividadeTipoApuracao" [(ngModel)]="atividade.atividadeTipoApuracao">
                                    <option value="1">Quantitativa por Unidade</option>
                                    <option value="2">Quantitativa por Percentual</option>
                                    <option value="3">Qualitativa (Sim/Não)</option>
                                </select>    
                            </div>
                        </div>

                        <!-- Linha para Meta -->
                        <div class="row mb-2">
                            <div class="col-md-1"></div>
                            <div class="col-md-3 font-weight-bold">Meta <small>(Quantidade - % - Sim/Não)</small>:<span
                                    class="text-danger">*</span></div>
                            <div class="col-md-3">
                                <input [disabled]="disableCampos()" class="form-control input-tabela" maxlength="100"
                                    id="atividadeMeta" name="atividadeMeta" #atividadeMeta="ngModel" type="text"
                                    [(ngModel)]="atividade.atividadeMeta" />

                            </div>
                        </div>

                        <!-- Linha para Início da Execução -->
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div class="col-md-3 font-weight-bold">Início da Execução:<span class="text-danger">*</span>
                            </div>
                            <div class="col-md-3">
                                <app-date-selector [disabledFlag]="disableBotao()"
                                    [initialDate]="atividade.atividadeInicioExecucao"
                                    (dateChanged)="onDateChanged(atividade, $event)"></app-date-selector>
                                <!-- <input [disabled]="disableCampos()" class="form-control input-tabela"
                                           id="atividadeInicioExecucao" name="atividadeInicioExecucao" #atividadeInicioExecucao="ngModel"
                                           type="text" [(ngModel)]="atividade.atividadeInicioExecucao"> -->

                            </div>
                            <div class="col-md-3"></div>
                            <div *ngIf="!disableBotao()" class="col-md-2">
                                <button
                                    (click)="openExcluirAtividade(contentExcluirAtividade, atividade, j+1+'.'+(k+1))"
                                    id="button-apagar-ativ " class="btn btn-primary btn-info ms-auto custom-margin-left"
                                    type="button " title="Excluir Atividade" [hidden]="disableBotao()">
                                    <i class="fa fa-trash-o" style="color: white;"><span class="texto-botao">Excluir
                                            Atividade</span> </i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!disableBotao()" class="row mb-2 d-flex">
                        <p class="ml-auto"></p>
                        <div class="col-md-2">
                            <button (click)="adicionarAtividade(data)" id="button-insert_ativ "
                                class="btn btn-primary btn-info ms-auto custom-margin-left" type="button "
                                title="Adicionar Atividade" [hidden]="disableBotao()">
                                <i class="fa fa-plus "><span class="texto-botao">Adicionar Atividade</span> </i>
                            </button>
                        </div>
                        <div *ngIf="!disableBotao()" class="col-md-2">
                            <button (click)="openExcluirMeta(contentExcluirMeta, data, j+1)" id="button-apagar-meta "
                                class="btn btn-primary btn-info ms-auto" type="button " title="Excluir Meta"
                                [hidden]="disableBotao()">
                                <i class="fa fa-trash-o" style="color: white;"><span class="texto-botao">Excluir
                                        Meta</span> </i>
                            </button>
                        </div>
                    </div>
                </div>

            </tbody>

        </table>
        <div>
            <div class="execucao tituloSecao">CRONOGRAMA DE EXECUÇÃO DAS ATIVIDADES</div>
            <div *ngIf="metaDoPlanoLista.length>0 && paginatedCompetenciasLista.length>0">
                <table class="table table-bordered table-hover" id="cron" [mfData]="metaDoPlanoLista"
                    #mtMetaPlanC="mfDataTable">
                    <thead class="custom-thead">
                        <tr>
                            <th class="font-weight-bold custom-header" style="background-color: #f0f0f0;">Meta</th>
                            <th class="font-weight-bold custom-header" style="background-color: #f0f0f0;">Atividades</th>
                            <!-- Colunas de meses -->
                            <ng-container *ngFor="let chunk of splitIntoChunks(paginatedCompetenciasLista, 12)">
                                <ng-container *ngFor="let item of chunk">
                                    <th class="font-weight-bold custom-header" style="background-color: #f0f0f0;">
                                        {{ item }}
                                    </th>
                                </ng-container>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- Loop para cada Meta -->
                        <ng-container *ngFor="let dataM of mtMetaPlanC.data; let o = index;">
                            <!-- Linha da Meta -->
                            <tr>
                                <!-- Coluna da Meta com rowspan baseado na quantidade de atividades -->
                                <td [attr.rowspan]="dataM.metaAtividades.length" class="font-weight-bold align-middle">
                                    {{ o + 1 }}
                                </td>
                                <!-- Primeira atividade da Meta -->
                                <td>
                                    <span class="font-weight-bold">{{ o + 1 }}.1</span>
                                    {{ dataM.metaAtividades[0]?.atividadeDescricao }}
                                </td>
                                <!-- Valores dos meses para a primeira atividade -->
                                <ng-container *ngFor="let chunk of splitIntoChunks(paginatedCompetenciaListaMetaAtividade(dataM.metaAtividades[0]), 12)">
                                    <td *ngFor="let valor of chunk">
                                        {{ valor }}
                                    </td>
                                </ng-container>
                            </tr>
                    
                            <!-- Linhas para as demais atividades da mesma meta -->
                            <ng-container *ngFor="let dataAtividade of dataM.metaAtividades.slice(1); let l = index;">
                                <tr>
                                    <td>
                                        <span class="font-weight-bold">{{ o + 1 }}.{{ l + 2 }}</span>
                                        {{ dataAtividade.atividadeDescricao }}
                                    </td>
                                    <!-- Valores dos meses para cada atividade -->
                                    <ng-container *ngFor="let chunk2 of splitIntoChunks(paginatedCompetenciaListaMetaAtividade(dataAtividade), 12)">
                                        <td *ngFor="let valor2 of chunk2">
                                            {{ valor2 }}
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </table>
                <div class="pagination pagination justify-content-center" *ngIf="competenciasLista.length > 0">
                    <i (click)="changePage(config.currentPage - 1)"
                        [ngStyle]="{ 'pointer-events': config.currentPage === 1 ? 'none' : 'auto' }"
                        class="fa fa-chevron-circle-left" style="font-size:36px; padding-right:10px"> </i>

                    <span> Página {{config.currentPage}} de {{totalPages}} </span>

                    <i (click)="changePage(config.currentPage + 1)"
                        [ngStyle]="{ 'pointer-events': config.currentPage === totalPages ? 'none' : 'auto' }"
                        class="fa fa-chevron-circle-right" style="font-size:36px; padding-left:10px"> </i>

                </div>

            </div>
        </div>
    </div>

    <ng-template #contentExcluirMeta let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Excluir Meta</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Tem certeza que deseja excluir a meta número:
                <b>{{indiceMeta}}</b>?
            </p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" style="margin-top: 7px;" (click)="c('Close click')"
                title="Fechar">Fechar</button>
            <button type="button" class="btn btn-secondary" (click)="excluirMeta()" title="Excluir">Excluir</button>
        </div>
    </ng-template>
    <ng-template #contentExcluirAtividade let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Excluir Atividade</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Tem certeza que deseja excluir atividade
                <b>{{indiceAtividade}}</b>?
            </p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" style="margin-top: 7px;" (click)="c('Close click')"
                title="Fechar">Fechar</button>
            <button type="button" class="btn btn-secondary" (click)="excluiAtividade()" title="Excluir">Excluir</button>
        </div>
    </ng-template>
</div>