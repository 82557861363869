import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { switchMap, share, filter } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../@core/alert.service';
import { ParceriaInfo } from '../../../modulo2/detalheParceria/parceriaInfo';
import { DetalheParceriaApi } from '../../../apis/detalheParceriaApi';
import { DetalheParceriaPortalApi } from '../../../apis/detalheParceriaPortalApi';
import { UserService } from '../../../@core/user.service';
import { ResponsavelDirigente } from './responsavelDirigente';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateConverter } from 'src/app/@core/date-converter';
import { Utils } from 'src/app/@shared/utils';
@Component({
  selector: 'app-responsavelDirigente',
  templateUrl: './responsavelDirigente.component.html',
  styleUrls: ['./../../../modulo2/detalheParceria/detalheParceria.component.scss']
})
export class ResponsavelDirigenteComponent implements OnInit {

  @Input()
  public  parceriaInfo: ParceriaInfo;
  @Input()
  private  isPortal: boolean;
  @Output() valueChange = new EventEmitter();
  respDirs: Array<ResponsavelDirigente> = [];
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  mascaraNroMat = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/], guide: false };
  modalEdicaoReference: any;
  showUpload: boolean = false;
  responsavelDirigente: ResponsavelDirigente;
  isFiscal: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  @Input()
  isAuditorExterno: boolean;
  username: string;

  constructor(private route: ActivatedRoute,
    private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    public userService: UserService,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    private utils: Utils) {

    }

  ngOnInit() {
    this.userService.userInfo.subscribe(user => this.username = user.username);
    this.isFiscal = this.userService.isFiscal();
    this.isSMTC = this.userService.isSMTC();
    this.isConsulta = this.userService.isConsulta();
    this.setupInterface();
  }

   setupInterface() {
 
    this.loading.next(true);

    this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
      this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
    });

    this.route.paramMap.pipe(
        switchMap(() =>
         this.detalheParceriaPortalApi.getResponsavelDirigente(this.parceriaInfo.parId))
         ).subscribe((response) => {
          if (response) {
            this.respDirs = response as Array<ResponsavelDirigente>;
            // console.log(this.valoresLiberados);
          } else {
            this.alertService.warning('Nenhum registro encontrado');
          }
        }, (response) => {
          if (response.status >= 500) {
            this.alertService.danger(response.error);
          } else {
            this.alertService.warning(response.error);
          }
          this.loading.next(false);
        }, () => this.loading.next(false));

  }
  disableCampos() {
    return (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }

  visualizarBotao() {
    return ((this.isSMTC || this.isConsulta || this.isAuditorExterno));
  }
  disableApagarIcone() {
    return (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }
  enableEditarIcone() {
    if (this.isConsulta || this.isSMTC || this.isAuditorExterno) {
      return false;
    }
    return true;
  }

  

  setResponsavelDirigente(doc) {
    if (doc) {
      this.responsavelDirigente = doc;
      
    } else {
      this.responsavelDirigente = new ResponsavelDirigente();      
      this.responsavelDirigente.responsavelDirigenteParId = this.parceriaInfo.parId;         
      this.responsavelDirigente.responsavelDirigenteOperacaoData = new Date();
      this.responsavelDirigente.responsavelDirigenteUsuarioOperacao = this.username;
    }

  }

  salva() {


    this.respDirs.forEach(element => {
      element.responsavelDirigenteOperacaoData = new Date();
      element.responsavelDirigenteUsuarioOperacao = this.username;
    });

    this.detalheParceriaApi.updateResponsavelDirigenteList(this.respDirs).subscribe(async (response) => {
      if (response) {
        this.setupInterface();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
      this.modalEdicaoReference.close();
    }, () => this.loading.next(false));
  }
  
  adicionar(){
    
     let respDirigente = new ResponsavelDirigente();      
     respDirigente.responsavelDirigenteParId = this.parceriaInfo.parId;
    this.respDirs.push(respDirigente);

  }

  contarFuncoes() {
    return this.respDirs.filter(item => item.responsavelDirigenteFuncao === 0
      || item.responsavelDirigenteFuncao === 2 ).length;
  }
  excluirResponsavel() {

    if (this.contarFuncoes() == 1 && (this.responsavelDirigente.responsavelDirigenteFuncao === 0
    || this.responsavelDirigente.responsavelDirigenteFuncao === 2)) {
        return this.alertService.danger("Exclusão não permitida. É necessário ao menos um dirigente cadastrado.");
    }

    if (this.responsavelDirigente.responsavelDirigenteId === undefined){
      //this.respDirs.(this.responsavelDirigente);
      const newArray = this.respDirs.filter(item => item !== this.responsavelDirigente);
      this.respDirs = newArray;
    }

    
    this.responsavelDirigente.responsavelDirigenteOperacaoData = new Date();
    this.responsavelDirigente.responsavelDirigenteUsuarioOperacao = this.username;
    this.detalheParceriaApi.excluirResponsavelDirigente(this.responsavelDirigente).subscribe((response) => {
      if (response) {
        this.setupInterface();
        this.setResponsavelDirigente(undefined);
        this.modalEdicaoReference.close();
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));

  }

  replacerDocComplementar(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['ResponsavelDirigenteDataInicioDesignacaoFormat', 'ResponsavelDirigenteDataInicioDesignacaoFimFormat', 'ResponsavelDirigenteDataFimDesignacaoFormat'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  openExcluir(content, doc) {
    this.setResponsavelDirigente(doc);
    this.modalEdicaoReference = this.modalService.open(content);
  }

}