import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { elementAt, filter, share, switchMap } from 'rxjs/operators';
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { AlertService } from "src/app/@core/alert.service";
import { DateConverter } from "src/app/@core/date-converter";
import { UserService } from "src/app/@core/user.service";
import { Mascaras } from "src/app/@shared/components/Mascaras";
import { Utils } from "src/app/@shared/utils";
import { DetalheParceriaApi } from "src/app/apis/detalheParceriaApi";
import { DetalheParceriaPortalApi } from "src/app/apis/detalheParceriaPortalApi";
import { PublicoPrivadaApi } from "src/app/apis/publicoPrivadaApi";
import { ParametrosMonitoramento } from "./parametroMonitoramento.";
import { MonitoramentoAvaliacao } from "../monitoramentoAvalicao";
import { Meta } from "../../planoDeTrabalho/meta/meta";
import { forEach } from "lodash";
import { ResponsavelMonitoramento } from "./responsavelMonitoramento";
import { PaginationInstance } from "ngx-pagination/dist/pagination-instance";
import { AtividadeMetaMonitoramentoRealizado } from "./atividadeMetaMonitoramentoRealizado";
import { AtividadeMeta } from "../../planoDeTrabalho/meta/atividadeMeta";

@Component({
  selector: 'app-parametro-monitoramento',
  templateUrl: './parametroMonitoramento.component.html',
  styleUrls: ['../../informacaoParceria/informacaoParceria.component.scss']
})
export class ParamentroMonitoramentoComponent implements OnInit {


  @Input()
  public isPortal: boolean;
  @Input()
  public modulo: string;
  @Input()
  public monitoramento: MonitoramentoAvaliacao;
  isFiscal: boolean;
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  isAuditorExterno: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  isExecPT: boolean;
  isPlanPT: boolean;
  username: string;
  metaList: Array<Meta> = [];
  responsavelApagado: ResponsavelMonitoramento;
  indiceResp: number;
  modalEdicaoReference: any;
  atividadeSel: any;
  competenciasLista: string[] = [];
  mesNomes = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
  responsavelNomeUltimaMonitoracao: string;
  private cachedPage = new Map<number, any>();
  private cacheAtvMetaMonitoramentoRealizado = new Map<number, any>();
  private cacheAtvMetaMonitoramento = new Map<number, any>();
  private isOperadorResponsavel:boolean;
 
  public config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 12,
    currentPage: 1
  };

  constructor(private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    public userService: UserService,
    private parceriaApi: PublicoPrivadaApi,
    private mascaras: Mascaras,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    private utils: Utils,
    private route: ActivatedRoute) {

  }
  emailsResponsaveis: { [key: string]: string } = {};
  emailsRespSelect: { id: string; name: string }[] = [];

  async ngOnInit() {

    this.userService.userInfo.subscribe(user => this.username = user.username);
    this.isFiscal = this.userService.isFiscal();
    this.isConsulta = this.userService.isConsulta();
    this.isSMTC = this.userService.isSMTC();
    this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
      this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
    });

    
    this.getEmailsResponsaveis();
    this.setupInterface();
    const quantidadeMeses = this.calculaQtdMeses(new Date(this.monitoramento.plano.planoDataIniExecucao),
      this.monitoramento.plano.planoDataFimExecucao);
    this.competenciasLista = this.construirArrayMeses(this.monitoramento.plano.planoDataIniExecucao, quantidadeMeses)
  }

  setupInterface() {

    this.loading.next(true);

    this.route.paramMap.pipe(
      switchMap(() =>
        this.parceriaApi.getMetaAtivadeParametrosMonitoramento(this.monitoramento.plano.planoId, this.monitoramento.monitoramentoId, this.isPortal))
    ).subscribe((response) => {
      if (response) {
        this.metaList = response as Array<Meta>;
        console.log(this.metaList);
        this.cachedPage = new Map<number, any>();
        this.cacheAtvMetaMonitoramentoRealizado = new Map<number, any>();
        this.cacheAtvMetaMonitoramento = new Map<number, any>();
        this.metaList.forEach(element =>
          element.metaAtividades.forEach(elementA =>{
            if (elementA.parametrosMonitoramento.responsavelMonitoramento != undefined || 
              elementA.parametrosMonitoramento.responsavelMonitoramento != null){
                elementA.parametrosMonitoramento.responsavelMonitoramento.forEach(elementResp => {
                  const email = elementResp.nomeResponsavel.trim() + " <" + elementResp.emailResponsavel + ">";
                  elementResp.chaveEmail = this.getKeyByValue(email);
                  if (elementResp.emailResponsavel==elementA.parametrosMonitoramento.operacaoUsuario){
                      elementA.parametrosMonitoramento.responsavelNomeUltimaMonitoracao=elementResp.nomeResponsavel.trim();
                  }
                  let login = elementResp.emailResponsavel.split('@');
                  if (this.username == elementResp.emailResponsavel || this.username == login[0]){
                    elementA.parametrosMonitoramento.isOperadorResponsavel = true;
                  }
                })
              }
              //this.config.currentPage=-1;
              this.cachedPage.set(elementA.atividadeId, -1);
              elementA.currentPage = 1;
              this.paginatedCompetenciaListaMetaAtividade( elementA);
              this.paginatedCompetenciaListaMetaAtividadeRealizado( elementA);
              this.cachedPage.set(elementA.atividadeId, 1);
        
          }

          )
        )

      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));
    this.getEmailsResponsaveis();

  }

  disableCampos(isOperadorResponsavel) {
    if (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno || !isOperadorResponsavel) {
      return true;
    }

    // if (!this.isOperadorResponsavel) {
    //    return true;
    // }
    return false;
  }

  disableBotao() {
    if (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno || !this.isFiscal) {
      return true;
    }

    return false;
  }
  disableApagarIcone() {
    return (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno);
  }
  enableEditarIcone() {
    if (this.isFiscal || this.isConsulta || this.isSMTC || this.isAuditorExterno) {
      return false;
    }
    return true;
  }




  calculaQtdMeses(dataInicio: Date, dataFim: Date): number {
    let meses: number;

    // Calcule a diferença em anos e meses
    meses = (new Date(dataFim).getFullYear() - dataInicio.getFullYear()) * 12;
    meses -= dataInicio.getMonth();
    meses += new Date(dataFim).getMonth();
    meses = meses + 1;
    // Se a data de fim for antes do dia da data de início, subtraia um mês
    // if (new Date(dataFim).getDate() < dataInicio.getDate()) {
    //   meses--;
    // }

    return meses < 0 ? 0 : meses; // Retorna 0 se o resultado for negativo
  }
  construirArrayMeses(dataInicio: Date, quantidadeMeses: number): string[] {
    const mesesArray: string[] = [];
    const mesNomes = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
      'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    for (let i = 0; i < quantidadeMeses; i++) {
      const dataAtual = new Date(dataInicio);
      dataAtual.setMonth(new Date(dataInicio).getMonth() + i);

      const mes = mesNomes[dataAtual.getMonth()];
      const ano = dataAtual.getFullYear();

      mesesArray.push(`${mes}/${ano % 100}`);
    }

    return mesesArray;
  }


  getKeyByValue(value: string): string | undefined {
    return Object.entries(this.emailsResponsaveis)
      .find(([key, val]) => val === value)?.[0]; // Retorna a chave correspondente ao valor
  }

  getAtividadeFrequencia(atividadeFreq) {
    return this.utils.getAtividadeFrequencia(atividadeFreq);

  }
  getMesAno(dataInicio) {
    const mesNomes = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
      'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    const dataAtual = new Date(dataInicio);
    dataAtual.setMonth(new Date(dataInicio).getMonth());
    const mes = mesNomes[dataAtual.getMonth()];
    const ano = dataAtual.getFullYear();
    return `${mes}/${ano}`;
  }
  updateAlertaEmail(event, data) {
    const newVal = event.currentTarget.value;
    switch (newVal) {
      case '30': data.alertaEmail = 30; break;
      case '15': data.alertaEmail = 15; break;
      case '7': data.alertaEmail = 7; break;
      case '0': data.alertaEmail = 0; break;
      default: data.alertaEmail = 99; break;
    }


  }
  salvaEmailResp(event, data, parMetro, tipoMon) {
    const newVal = event.currentTarget.value;

    Object.keys(this.emailsResponsaveis).forEach(key => {
      if (key === newVal) {
        let part = this.emailsResponsaveis[key].split('<');
        data.nomeResponsavel = part[0];
        data.emailResponsavel = part[1].replace('>', '');
        data.parametroMonitoramento = parMetro;
        data.tipoMonitoramento = tipoMon;
      }

    });


  }

  async getEmailsResponsaveis() {
    try {
      const response = await this.detalheParceriaPortalApi.getEmailsResponsaveis(this.monitoramento.parId).toPromise();
      if (response) {
        this.emailsResponsaveis = response;
        this.emailsRespSelect = [];
  
        Object.keys(this.emailsResponsaveis).forEach(key => {
          console.log(`Chave: ${key}, Valor: ${this.emailsResponsaveis[key]}`);
          this.emailsRespSelect.push({ id: key, name: this.emailsResponsaveis[key] });
        });
      }
    } catch (error) {
      console.error('Erro ao buscar e-mails:', error);
    }
  }
  adicionaResponsavel(data, tipo) {

    const resp = new ResponsavelMonitoramento();
    resp.emailResponsavel = undefined;
    resp.nomeResponsavel = undefined;
    resp.idResponsavel = undefined;
    resp.tipoMonitoramento = tipo;
    resp.parametrosMonitoramento = data;
    resp.operacaoData = new Date();
    resp.operacaoUsuario = this.username;
    data.responsavelMonitoramento.push(resp);
  }

  openExcluir(content, dataRes, index, data) {
    this.responsavelApagado = dataRes;
    this.indiceResp = index;
    this.atividadeSel = data;
    this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
  }

  excluirResponsavel() {

    if (this.atividadeSel.responsavelMonitoramento.length == 1) {
      this.modalEdicaoReference.close();
      this.alertService.danger("É necessário ter ao menos um responsável pelo monitoramento.");
      return;
    }
    const index = this.atividadeSel.responsavelMonitoramento.indexOf(this.responsavelApagado); // Encontra o índice do item
    if (index > -1 && this.responsavelApagado.idResponsavel != undefined) {
      this.detalheParceriaApi.excluirResponsavelMonitoramento(this.responsavelApagado).subscribe(async (response) => {
        if (response) {
          this.atividadeSel.responsavelMonitoramento.splice(index, 1); // Remove o item com base no índice
          //this.metaUpdated.emit(this.metaDoPlanoLista);
          this.modalEdicaoReference.close();
        }
      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));

    }
    else {
      this.atividadeSel.responsavelMonitoramento.splice(index, 1); // Remove o item com base no índice
      this.modalEdicaoReference.close();
    }
  }

  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }

  salvaPlano() {

    let parametrolist = new Array<ParametrosMonitoramento>();
    let hasError = false;
    this.metaList.forEach(element =>
      element.metaAtividades.forEach(el => {
        if (this.isBlank(el.parametrosMonitoramento.periodoAtualizacao)
          || el.parametrosMonitoramento.responsavelMonitoramento == null
          || el.parametrosMonitoramento.responsavelMonitoramento.length == 0) {
          hasError = true;
          return;
        }
        el.parametrosMonitoramento.monitoramentoId = this.monitoramento.monitoramentoId;
        el.parametrosMonitoramento.operacaoData = new Date();
        el.parametrosMonitoramento.operacaoUsuario = this.username;
        el.parametrosMonitoramento.atividadeMeta = el;
        el.parametrosMonitoramento.meta = element;
        el.parametrosMonitoramento.frequenciaMonitoracao = el.atividadeFrequencia;
        el.parametrosMonitoramento.tipoEvento = el.atividadeDescricao;

        el.parametrosMonitoramento.responsavelMonitoramento.forEach(elResp => {
          if (elResp.nomeResponsavel == undefined) {
            hasError = true;
            return;
          }
        })
        if (el.parametrosMonitoramento.atividadeMetaMonitoramentoRealizado.length == 0){
          const arrayAtividadeRealizado = new Array<AtividadeMetaMonitoramentoRealizado>();
          this.competenciasLista.forEach(element =>{
            const mesAno = element.split("/");
            let atividadeMetaMonitoramentoRealizado = new AtividadeMetaMonitoramentoRealizado();
            atividadeMetaMonitoramentoRealizado.ano = mesAno[1];
            atividadeMetaMonitoramentoRealizado.mes =  mesAno[0];
            atividadeMetaMonitoramentoRealizado.operacaoData = new Date();
            atividadeMetaMonitoramentoRealizado.operacaoUsuario = this.username;
            atividadeMetaMonitoramentoRealizado.dadoSensivel = false;
            atividadeMetaMonitoramentoRealizado.realizado = "";
            arrayAtividadeRealizado.push(atividadeMetaMonitoramentoRealizado);
          })
          el.parametrosMonitoramento.atividadeMetaMonitoramentoRealizado = arrayAtividadeRealizado;
        }
        else{
          if (el.parametrosMonitoramento.anexoObrigatorio && this.isOperadorResponsavel
            && el.parametrosMonitoramento.atividadeMetaMonitoramentoRealizado[0].atividadeMetaMonitoramentoRealizadoId != undefined
          ){
            let data = new Date();
            let ano = data.getFullYear().toString().slice(-2);
            el.parametrosMonitoramento.atividadeMetaMonitoramentoRealizado.forEach(element =>{
            const index = this.mesNomes.findIndex(el => el === element.mes);
              if ((index < data.getMonth()) && (
                ano ==element.ano)
                ||((index > data.getMonth() &&
                parseInt(element.ano)  < parseInt(ano) ))){
                  if (element.arquivo == null || element.arquivo == undefined){
                    hasError = true;
                  }

                } 

            })
          }
        }
        
        parametrolist.push(el.parametrosMonitoramento);
      }
      )
    )
    if (hasError) {
      this.alertService.danger('Existem campos obrigatórios não preenchidos ou arquivo não foi anexado.');
      return;
    }

    const jsonString = JSON.stringify(parametrolist, (key, value) => {
      if (key === 'parametrosMonitoramento'
        || key === 'parametroMonitoramento'
        || key === 'metaAtividades'
        || key === 'atividade'
        || key === 'currentPage'
        || key === 'responsavelNomeUltimaMonitoracao'
        || key === 'uploadArquivo'
        || key === 'isOperadorResponsavel'
      ) {
        return undefined;  // Excluir campo que cria o ciclo ou campo temporário
      }
      return value;
    });

    this.detalheParceriaApi.updateParametroMonitoramento(jsonString).subscribe(async (response) => {
      if (response) {
        this.setupInterface();
        // if (this.metaList != undefined){
        //   this.metaComponent.salvaMeta(mId, this.metaList);
        // }
        this.alertService.info('Plano de Monitoramento salvo com sucesso!')
        this.setupInterface();
        this.loading.next(false);
      }

    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));


  }
  updateValue(value: string, atividadeMetaMonitoramentoRealizado) {
    atividadeMetaMonitoramentoRealizado.realizado = value;
  }
  updateValueDado(value: string, atividadeMetaMonitoramentoRealizado) {
    atividadeMetaMonitoramentoRealizado.dadoSensivel = value;
  }


  calculaCompetenciaAtividade(atividade) {
    const competListaMetaAtividade: string[] = [];

    let atividadeMeta;
    // se for percentual

    if (atividade != undefined && atividade.atividadeTipoApuracao !== undefined && !this.isBlank(atividade.atividadeTipoApuracao)) {
      if (atividade.atividadeTipoApuracao.toUpperCase().includes('PERCENTUAL') || atividade.atividadeTipoApuracao.includes('%')) {
        atividadeMeta = atividade.atividadeMeta.split('%')[0];
      }
      else if (atividade.atividadeTipoApuracao.toUpperCase().includes('UNIDADE') && this.isNumber(atividade.atividadeMeta)) {
        atividadeMeta = atividade.atividadeMeta;
      }
      else {
        // se for textual Sim -> 1 Não -> 0
        if (atividade.atividadeMeta.toUpperCase() === 'SIM') {
          atividadeMeta = 1;
        }
        else if (atividade.atividadeMeta.toUpperCase() === 'NÃO') {
          atividadeMeta = 0;
        }
        else {
          atividadeMeta = -1;
        }

      }


      if (atividade && atividade.atividadeMeta !== undefined) {
        const qtd = Number(atividadeMeta);
        const inicioData = new Date(atividade.atividadeInicioExecucao);
        const inicioMes = inicioData.getMonth();
        const inicioAno = inicioData.getFullYear();
        const inicioCompetencia = `${this.mesNomes[inicioMes]}/${inicioAno % 100}`;

        let frequenciaValor = 0;
        let intervaloMeses = 0;

        // Define o valor e o intervalo de meses com base na frequência
        switch (Number(atividade.atividadeFrequencia)) {
          case 1: qtd == -1 ? frequenciaValor = -1 : atividade.atividadeMeta.toUpperCase() === 'SIM' ? frequenciaValor = 1
            : frequenciaValor = qtd * 30; intervaloMeses = 1; break; // Diário
          case 2: qtd == -1 ? frequenciaValor = -1 : atividade.atividadeMeta.toUpperCase() === 'SIM' ? frequenciaValor = 1
            : frequenciaValor = qtd * 4; intervaloMeses = 1; break;  // Semanal
          case 3: qtd == -1 ? frequenciaValor = -1 : atividade.atividadeMeta.toUpperCase() === 'SIM' ? frequenciaValor = 1
            : frequenciaValor = qtd * 2; intervaloMeses = 1; break;  // Quinzenal
          case 4: qtd == -1 ? frequenciaValor = -1 : frequenciaValor = qtd; intervaloMeses = 1; break;      // Mensal
          case 5: qtd == -1 ? frequenciaValor = -1 : frequenciaValor = qtd; intervaloMeses = 2; break;      // Bimestral
          case 6: qtd == -1 ? frequenciaValor = -1 : frequenciaValor = qtd; intervaloMeses = 4; break;      // Quadrimestral
          case 7: qtd == -1 ? frequenciaValor = -1 : frequenciaValor = qtd; intervaloMeses = 6; break;      // Semestral
          case 8: qtd == -1 ? frequenciaValor = -1 : frequenciaValor = qtd; intervaloMeses = 12; break;     // Anual
          default: break;
        }

        let findStart = false;

        // Função auxiliar para verificar se o mês/ano é uma competência ativa
        const isCompetenciaAtiva = (element, intervaloAcumulado) => {
          if (intervaloMeses == 1) {
            return element;
          }
          const mesAno = element.split("/");
          const index = this.mesNomes.indexOf(mesAno[0]);
          const totalMeses = (inicioMes + intervaloAcumulado)
          const currentMes = totalMeses % 12;
          const currentAno = inicioAno + Math.floor(totalMeses / 12);
          return `${this.mesNomes[currentMes]}/${currentAno % 100}`;
        };

        let intervaloAcumulado = 0;
        this.competenciasLista.forEach((element, index) => {
          if (element === inicioCompetencia) {
            findStart = true;
            intervaloAcumulado = intervaloMeses;
            if (frequenciaValor >= 0) {
              competListaMetaAtividade.push(`${frequenciaValor}`);
            }
            else {
              competListaMetaAtividade.push("");
            }
          } else if (!findStart) {
            competListaMetaAtividade.push("");
          } else if (findStart) {
            if (isCompetenciaAtiva(element, intervaloAcumulado) === element) {
              if (frequenciaValor >= 0) {
                competListaMetaAtividade.push(`${frequenciaValor}`);
              }
              else {
                competListaMetaAtividade.push("");
              }
              intervaloAcumulado = intervaloAcumulado + intervaloMeses;
            }
            else {
              competListaMetaAtividade.push("");
            }
          }
        });

      } else {
        this.competenciasLista.forEach(() => competListaMetaAtividade.push(""));
      }
    }

    return competListaMetaAtividade;
  }
  
  paginatedCompetenciasLista(atividade): String[] {    
   
    const start = (atividade.currentPage - 1) * this.config.itemsPerPage;
    const end = start + this.config.itemsPerPage;
   
    return this.competenciasLista.slice(start, end);
  }
  splitIntoChunks(array: any[], chunkSize: number) {
    const result = [];
    
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }
  isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  paginatedCompetenciaListaMetaAtividade(atividade: AtividadeMeta) {
    if (this.cachedPage.get(atividade.atividadeId) !== atividade.currentPage) {
      const competenciaListaMetaAtividade = this.calculaCompetenciaAtividade(atividade);
      const start = (atividade.currentPage - 1) * this.config.itemsPerPage;
      const end = start + this.config.itemsPerPage;
      //this.totalPages = competenciaListaMetaAtividade.length/this.config.itemsPerPage;
      this.cacheAtvMetaMonitoramento.set(atividade.atividadeId, 
        this.splitIntoChunks(competenciaListaMetaAtividade.slice(start, end),12));
      
       
     }
    return this.cacheAtvMetaMonitoramento.get(atividade.atividadeId);
    //this.splitIntoChunks(competenciaListaMetaAtividade.slice(start, end),12);
  }

  paginatedCompetenciaListaMetaAtividadeRealizado(atividade: AtividadeMeta) {

    if (this.cachedPage.get(atividade.atividadeId) !== atividade.currentPage) {
      //const competenciaListaMetaAtividade = this.calculaCompetenciaAtividade(atividade);
      const start = (atividade.currentPage - 1) * this.config.itemsPerPage;
      const end = start + this.config.itemsPerPage;
      this.cacheAtvMetaMonitoramentoRealizado.set(atividade.atividadeId, 
        this.splitIntoChunks(atividade.parametrosMonitoramento.atividadeMetaMonitoramentoRealizado.slice(start, end),12));
        let i=0;
        let atividadePlanList = this.cacheAtvMetaMonitoramento.get(atividade.atividadeId)
        let size = atividadePlanList[0].length; 
        atividadePlanList[0].forEach(element => {
           if (this.isBlank(element)){
            // Habilita o ultimo mes para inclusão do monitoramento
            if ((this.totalPages == atividade.currentPage) && (i === size-1)){
              this.cacheAtvMetaMonitoramentoRealizado.get(atividade.atividadeId)[0][i].uploadArquivo = true;
            }else{
              this.cacheAtvMetaMonitoramentoRealizado.get(atividade.atividadeId)[0][i].uploadArquivo = false;
            }
           }
           else{
            this.cacheAtvMetaMonitoramentoRealizado.get(atividade.atividadeId)[0][i].uploadArquivo = true;
           }
           i++;
        });
    
    }
    //console.log("Valores Atividades Mes");
    //console.log(competenciaListaMetaAtividade.slice(start, end))
    return this.cacheAtvMetaMonitoramentoRealizado.get(atividade.atividadeId);
  }
  // Change page
  changePage(atividade: AtividadeMeta,pageNumber: number): void {
    atividade.currentPage = pageNumber;
    this.paginatedCompetenciaListaMetaAtividade(atividade);
    this.paginatedCompetenciaListaMetaAtividadeRealizado( atividade);
    if (this.cachedPage.get(atividade.atividadeId) !== atividade.currentPage) {
      this.cachedPage.set(atividade.atividadeId, atividade.currentPage);
    }
  }

  verificaExibicaoAnexo(atividade:AtividadeMetaMonitoramentoRealizado){

    if (!this.isBlank(atividade.nomeArquivoAnexado)){
      return true;
    }
    else
      return false;
  }
  verificaUploadAnexo(atividade:AtividadeMetaMonitoramentoRealizado){

    if (atividade.uploadArquivo && this.isBlank(atividade.nomeArquivoAnexado)){
      return true;
    }
    else
      return false;
  }
  habilitaRealizado(atividade:AtividadeMetaMonitoramentoRealizado){

    if (atividade.uploadArquivo){
      return true;
    }
    else
      return false;
  }

  onFileChanged(event, atividade:AtividadeMetaMonitoramentoRealizado){

    const fileList: FileList = event.currentTarget.files;
    

      //console.log(fileList);
      const reader = new FileReader();
      if (fileList.length > 0) {
        const array = fileList[0].type.split('/');
        const fileSize = fileList[0].size / 1024 / 1024; // in MB
        if (fileSize > 10) {
          this.alertService.danger('Tamanho de arquivo superior ao limite de 10 MB. Esse arquivo possui ' + fileSize.toPrecision(2) + " MB");
          return;
        }
        if (array[1] === 'pdf') {
  
          reader.readAsDataURL(fileList[0]);
          reader.onload = () => {
              atividade.arquivo = (reader.result).toString().split(',')[1];
              //_desepesaSend.execDocumentoTipo = array[1];
              atividade.nomeArquivoAnexado = fileList[0].name;
              
            }
          } else {
          event.currentTarget.value = '';
          this.alertService.warning('Formato de arquivo não suportado!');
        }
      }
  }
  
  getArquivo(atividade:AtividadeMetaMonitoramentoRealizado, parametro: ParametrosMonitoramento){

    this.loading.next(true);
    this.parceriaApi.getArquivoAtivMonitoramentoRealizado(parametro.parametroId,
      atividade.atividadeMetaMonitoramentoRealizadoId, this.isPortal)
      .subscribe((response) => {
        if (response) {
          console.log(response);
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(response.body);
          a.href = url;
          a.download = atividade.nomeArquivoAnexado;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else {
          this.alertService.warning('Nenhum arquivo encontrado');
        }
      }, (response) => {
        // if (response.status >= 500) {
        //   this.alertService.danger(response.error);
        // } else {
        //   this.alertService.warning(response.error);
        // }
        this.alertService.danger('Erro ao buscar arquivo de atividade monitoramento.');
        this.loading.next(false);
      }, () => this.loading.next(false));

  }
  get totalPages(): number {
    return Math.ceil(this.competenciasLista.length / this.config.itemsPerPage);
  }
}