import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class ResponsavelDirigente{

    public responsavelDirigenteParId: number;
    public responsavelDirigenteId: number;
    public responsavelDirigenteNome: string;
    public responsavelDirigenteTelefone: string;
    public responsavelDirigenteEmail: string;
    public responsavelDirigenteFuncao: number;
    public responsavelDirigenteOperacaoData:Date;
    public responsavelDirigenteUsuarioOperacao:string;
}

