import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DateConverter } from '../../../@core/date-converter';
import { filter, share, switchMap } from 'rxjs/operators';
import { AlertService } from '../../../@core/alert.service';
import { ParceriaInfo } from './../../detalheParceria/parceriaInfo';
import { DetalheParceriaPortalApi } from '../../../apis/detalheParceriaPortalApi';
import { Mascaras } from '../../../@shared/components/Mascaras';
import { UserService } from '../../../@core/user.service';
import { Utils } from 'src/app/@shared/utils';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DetalheParceriaApi } from 'src/app/apis/detalheParceriaApi';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';
import { forEach } from 'lodash';
import { PaginationInstance } from 'ngx-pagination/dist/pagination-instance';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PrevisaoReceita } from './previsaoReceita';
import { ExecucaoPlanoDeTrabalho } from '../execucaoPlanoDeTrabalho';
import { PrevisaoReceitaListDTO } from './previsaoReceitaWrapper';

@Component({
    selector: 'app-receita-plano',
    templateUrl: './previsaoReceita.component.html',
    styleUrls: ['../../informacaoParceria/informacaoParceria.component.scss']
})
export class PrevisaoReceitaComponent implements OnInit {
    @Input()
    public plano: ExecucaoPlanoDeTrabalho;
    @Input()
    public isPortal: boolean;
    @Input()
    public isCriarSA: boolean;
    @Output() valueChange = new EventEmitter();
    @Input()
    public modulo: string;
    isFiscal: boolean;
    private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public loading$ = this.loading.asObservable().pipe(share());
    isAuditorExterno: boolean;
    isSMTC: boolean;
    isConsulta: boolean;
    isExecPT: boolean;
    isPlanPT: boolean;
    p = 1;
    pagina: number;
    modalEdicaoReference: any;
    username: string;
    previsaoReceitaLista: Array<PrevisaoReceita> = [];
    previsaoReceitaSelecionada = new PrevisaoReceita();
    previsaoAserApagada: PrevisaoReceita;
    indicePrevisao: number;
    idsAExcluir: Array<Number> = [];
    @Output() receitaUpdated = new EventEmitter<PrevisaoReceita[]>();

    public config: PaginationInstance = {
        id: 'custom',
        itemsPerPage: 12,
        currentPage: 1
    };

    constructor(private alertService: AlertService,
        private detalheParceriaApi: DetalheParceriaApi,
        private detalheParceriaPortalApi: DetalheParceriaPortalApi,
        public userService: UserService,
        private parceriaApi: PublicoPrivadaApi,
        private mascaras: Mascaras,
        private modalService: NgbModal,
        private dateConverter: DateConverter,
        private utils: Utils,
        private route: ActivatedRoute) {
    }

    ngOnInit() {

        this.userService.userInfo.subscribe(user => this.username = user.username);
        this.isFiscal = this.userService.isFiscal();
        this.isConsulta = this.userService.isConsulta();
        this.isSMTC = this.userService.isSMTC();
        this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
            this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
        });

        this.previsaoReceitaSelecionada = new PrevisaoReceita();
        this.previsaoReceitaSelecionada.previsaoReceitaId = -1;


        console.log("modulo");
        console.log(this.modulo);

        if (this.modulo === undefined) {
            this.modulo = 'm2';
        }
        if (this.isPortal === undefined) {
            this.isPortal = false;
        }
        //console.log(this.isPortal);

        this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
            this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
        });

        this.route.queryParams.pipe(filter(params => params.isExecPT)).subscribe(params => {
            this.isExecPT = (params['isExecPT'] === undefined || params['isExecPT'].toLowerCase() === 'false' ? false : true)
        });
        console.log(this.isExecPT);

        this.route.queryParams.pipe(filter(params => params.isPlanPT)).subscribe(params => {
            this.isPlanPT = (params['isPlanPT'] === undefined || params['isPlanPT'].toLowerCase() === 'false' ? false : true)
        });

        this.setupInterface(this.isCriarSA);
    }

    setupInterface(fazCopia) {
        if (this.plano != undefined) {
            this.loading.next(true);
            if (!(this.plano.planoId == undefined && this.plano.planoPai == undefined))
                this.route.paramMap.pipe(
                    switchMap(() =>
                        this.parceriaApi.getPrevisaoReceita(this.plano.planoId === undefined ? this.plano.planoPai : this.plano.planoId, this.isPortal))
                ).subscribe((response) => {
                    if (response) {
                        this.previsaoReceitaLista = response as Array<PrevisaoReceita>;
                        if (fazCopia) {
                            this.previsaoReceitaLista = this.previsaoReceitaLista.map(element =>
                                element = this.utils.copyWithoutProps(element, ['planoTrabalhoId', 'previsaoReceitaId',
                                    'operacaoData', 'operacaoUsuario']))
                        }
                        this.receitaUpdated.emit(this.previsaoReceitaLista);
                    } else {
                        this.alertService.warning('Nenhum registro encontrado');
                    }
                }, (response) => {
                    if (response.status >= 500) {
                        this.alertService.danger(response.error);
                    } else {
                        this.alertService.warning(response.error);
                    }
                    this.loading.next(false);
                }, () => this.loading.next(false));
        }
    }

    disableCampos() {
        if (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno || this.isFiscal || this.plano == undefined) {
            return true;
        }

        if (this.plano.planoSituacao == 2 || this.plano.planoSituacao == 4) {
            return true;
        }
        return false;
    }

    disableBotao() {
        if (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno || this.isFiscal || this.plano == undefined) {
            return true;
        }

        if (this.plano.planoSituacao == 2 || this.plano.planoSituacao == 4) {
            return true;
        }
        return false;
    }

    disableApagarIcone() {
        if (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno || this.isFiscal || this.plano == undefined) {
            return true;
        }

        if (this.plano.planoSituacao == 2 || this.plano.planoSituacao == 4) {
            return true;
        }
        return false;
    }

    enableEditarIcone() {
        if (this.isConsulta || this.isSMTC || this.isAuditorExterno) {
            return false;
        }
        return true;
    }

    adicionarPrevisaoReceita() {
        let previsaoReceita = new PrevisaoReceita()
        previsaoReceita.planoTrabalhoId = this.plano.planoId;
        previsaoReceita.previsaoReceitaId = undefined;
        previsaoReceita.origem = undefined;
        previsaoReceita.vigenciaInicio = new Date();
        previsaoReceita.vigenciaFinal = new Date();
        this.previsaoReceitaLista.push(previsaoReceita);
        this.receitaUpdated.emit(this.previsaoReceitaLista);
    }

    onDateChangedInicio(previsaoReceita, date: Date): void {
        date.setDate(1);
        previsaoReceita.vigenciaInicio = date;
    }

    onDateChangedFinal(previsaoReceita, date: Date): void {
        date.setDate(1);
        previsaoReceita.vigenciaFinal = date;
    }

    updateOrigemReceita(event, data) {
        const value = event.currentTarget.value;
        data.origem = value;
    }

    salvaPrevisaoReceita(planoId, previsaoReceitaLista) {

        previsaoReceitaLista.forEach(element => element.planoTrabalhoId = planoId);
        console.log("plano id " + planoId);
        let receitaList = new PrevisaoReceitaListDTO();
        receitaList.receitas = previsaoReceitaLista;
        console.log(receitaList);

        let sucesso: boolean;
        const data = JSON.stringify(receitaList);

        this.detalheParceriaApi.updatePrevisaoReceitaList(data).subscribe((response) => {
            if (response) {
                this.setupInterface(false);
                this.loading.next(false);
            }
        }, (response) => {
            if (response.status >= 500) {
                this.alertService.danger(response.error);
            } else {
                this.alertService.warning(response.error);
            }
            this.loading.next(false);
        }, () => this.loading.next(false));

        if (this.idsAExcluir.length > 0) {
            console.log("VAI EXCLUIR RECEITAS MARCADAS");
            this.detalheParceriaApi.excluirPrevisoesReceita(this.idsAExcluir).subscribe((response) => {
                this.setupInterface(false);
            }, (response) => {
                if (response.status >= 500) {
                    this.alertService.danger(response.error);
                } else {
                    this.alertService.warning(response.error);
                }
                this.loading.next(false);
            }, () => this.loading.next(false));
        }

    }

    isBlank(str) {
        return (!str || /^\s*$/.test(str));
    }

    validaCamposObrigatorios(previsaoReceitaLista) {
        let foundIssue = false;
        let foundDateIssue = false;
        previsaoReceitaLista.forEach(element => {
            element.operacaoData = new Date();
            element.operacaoUsuario = this.username;

            if (this.isBlank(element.origem) || this.isBlank(element.valorMensal)) {
                foundIssue = true;
                return;
            }
            let meses = this.calcularMesesEntreDatas(element.vigenciaInicio, element.vigenciaFinal);
            if (meses < 0) {
                foundDateIssue = true;
            }

        });

        if (foundIssue) {
            this.alertService.danger('Exitem campos obrigatórios em previsão de receita não preenchidos');
        }

        if (foundDateIssue) {
            this.alertService.danger('A data final da vigência deve ser superior a data inicial da previsão de receita');
            foundIssue = foundDateIssue;
        }

        return !foundIssue;
    }

    calcularMesesEntreDatas(dataInicio: Date, dataFinal: Date): number {
        const inicio = new Date(dataInicio);
        const fim = new Date(dataFinal);

        let meses = fim.getFullYear() * 12 + (fim.getMonth() + 1) - (inicio.getFullYear() * 12 + inicio.getMonth());

        return meses;
    }


    removeCircularReferences() {
        const seen = new WeakSet();
        return function (key: any, value: any) {
            if (typeof value === "object" && value !== null) {
                if (seen.has(value)) {
                    return; // Ignora a propriedade circular
                }
                seen.add(value);
            }
            return value;
        };
    }

    excluirPrevisaoReceita() {
        const index = this.previsaoReceitaLista.indexOf(this.previsaoAserApagada); // Encontra o índice do item
        if (index > -1 && this.previsaoAserApagada.previsaoReceitaId != undefined) {
            this.idsAExcluir.push(this.previsaoAserApagada.previsaoReceitaId);
            console.log("id a ser apagada " + this.previsaoAserApagada.previsaoReceitaId);
            this.previsaoReceitaLista.splice(index, 1); // Remove o item com base no índice
            this.modalEdicaoReference.close();
        } else {
            console.log("só removeu item lista");
            this.previsaoReceitaLista.splice(index, 1); // Remove o item com base no índice
            this.modalEdicaoReference.close();
        }
    }

    openExcluirPrevisaoReceita(content, data, index) {
        this.previsaoAserApagada = data;
        this.indicePrevisao = index;
        console.log("index: " + index);
        console.log("previsao: " + data.id + " " + data.planoId + " " + data.origem + " " + data.valorMensal);
        this.modalEdicaoReference = this.modalService.open(content, { backdrop: 'static', keyboard: false });
    }

    excluirPrevisaoReceitaIcone(data) {
        const index = this.previsaoReceitaLista.indexOf(data);
        if (index !== -1) {
            console.log("index: " + index);
            if (data.id === undefined) {
                console.log("nao existia");
                this.previsaoReceitaLista.splice(index, 1);
            } else {
                console.log("id a excluir");
                this.idsAExcluir.push(data.id);
                this.previsaoReceitaLista.splice(index, 1);
            }
        }
    }

    visualizaReceita(planoVisualizado, fazCopia) {
        this.plano = planoVisualizado;
        this.setupInterface(fazCopia);
    }

}