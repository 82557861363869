import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, ViewChildren, AfterContentInit, ComponentFactoryResolver, ViewContainerRef, ComponentRef, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DateConverter } from '../../@core/date-converter';
import { elementAt, filter, share, switchMap } from 'rxjs/operators';
import { AlertService } from '../../@core/alert.service';
import { ParceriaInfo } from '../detalheParceria/parceriaInfo';
import { DetalheParceriaPortalApi } from '../../apis/detalheParceriaPortalApi';
import { Mascaras } from '../../@shared/components/Mascaras';
import { UserService } from '../../@core/user.service';
import { Utils } from 'src/app/@shared/utils';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DetalheParceriaApi } from 'src/app/apis/detalheParceriaApi';
import { MonitoramentoAvaliacao } from './monitoramentoAvalicao';
import { PublicoPrivadaApi } from 'src/app/apis/publicoPrivadaApi';
import { forEach } from 'lodash';
import { PaginationInstance } from 'ngx-pagination/dist/pagination-instance';
import { NgbAccordion, NgbModal, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { MetaComponent } from '../planoDeTrabalho/meta/meta.component';
import { Meta } from '../planoDeTrabalho/meta/meta';
import { ParamentroMonitoramentoComponent } from './parametroMonitoramento/parametroMonitoramento.component';
import { OutrosEventosMonitoramentoComponent } from './outrosEventosMonitoramento/outrosEventosMonitoramento.component';

@Component({
  selector: 'app-monitoramento-avaliacao-parceria',
  templateUrl: './monitoramentoAvaliacao.component.html',
  styleUrls: ['../informacaoParceria/informacaoParceria.component.scss']
  
})
export class MonitoramentoAvaliacaoComponent implements OnInit{

  @Input()
  public parceriaInfo: ParceriaInfo;
  @Input()
  public isPortal: boolean;
  @Output() valueChange = new EventEmitter();
  @Input()
  public modulo: string;
  isFiscal: boolean;
  private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$ = this.loading.asObservable().pipe(share());
  isAuditorExterno: boolean;
  isSMTC: boolean;
  isConsulta: boolean;
  isExecPT: boolean;
  isPlanPT: boolean;
  parId: number;
  username: string;
  monitoramentoAvaliacaoList: Array<MonitoramentoAvaliacao> = [];
  @Output()
  monitAvalVisualizado: MonitoramentoAvaliacao;
  qtdPlano: number;
  competenciasLista: String[] = [];
  verPlano: boolean = false;
  p = 1;
  pagina: number;
  modalEdicaoReference: any;
  dataInicioPlanoPai:Date;
  dataFimPlanoPai:Date; 
  ultimoAccordionAberto:string;

  @ViewChild( NgbAccordion, { static: false })  accordionComponent: NgbAccordion;
  @ViewChild(ParamentroMonitoramentoComponent, { static: false }) parametroComponent!: ParamentroMonitoramentoComponent;
  @ViewChild(OutrosEventosMonitoramentoComponent, { static: false }) outrosEventosComponent!: OutrosEventosMonitoramentoComponent;
  
//  @ViewChild(MetaComponent, { static: false }) metaComponent: MetaComponent;
  metaList : Meta[];


  isAccordionRendered:boolean;
  public config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 24,
    currentPage: 1
  };


  
  constructor(private alertService: AlertService,
    private detalheParceriaApi: DetalheParceriaApi,
    private detalheParceriaPortalApi: DetalheParceriaPortalApi,
    public userService: UserService,
    private parceriaApi: PublicoPrivadaApi,
    private mascaras: Mascaras,
    private modalService: NgbModal,
    private dateConverter: DateConverter,
    private utils: Utils,
    private route: ActivatedRoute) {

  }


  ngOnInit() {

    this.verPlano=false;
    this.isAccordionRendered = false;
    this.userService.userInfo.subscribe(user => this.username = user.username);
    this.isFiscal = this.userService.isFiscal();
    this.isConsulta = this.userService.isConsulta();
    this.isSMTC = this.userService.isSMTC();
    this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
      this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
    });

   // console.log("modulo");
   // console.log(this.modulo);

    if (this.modulo === undefined) {
      this.modulo = 'm2';
    }
   // console.log(this.modulo);
    if (this.isPortal === undefined) {
      this.isPortal = false;
    }
    //console.log(this.isPortal);

    this.route.queryParams.pipe(filter(params => params.isAuditorExterno)).subscribe(params => {
      this.isAuditorExterno = params.isAuditorExterno === undefined || params.isAuditorExterno.toLowerCase() === 'false' ? false : true;
    });

    // this.route.queryParams.pipe(filter(params => params.isExecPT)).subscribe(params => {
    //   this.isExecPT = (params['isExecPT'] === undefined || params['isExecPT'].toLowerCase() === 'false' ? false : true)
    // });
    // //console.log(this.isExecPT);

    // this.route.queryParams.pipe(filter(params => params.isPlanPT)).subscribe(params => {
    //   this.isPlanPT = (params['isPlanPT'] === undefined || params['isPlanPT'].toLowerCase() === 'false' ? false : true)
    // });

    this.route.paramMap.subscribe(params => {
      this.parId = Number(params.get('id')) == 0?undefined: Number(params.get('id')); 
      
    });

    if (this.parId== undefined && this.parceriaInfo !== undefined) {
      this.parId = this.parceriaInfo.parId;
    }
    if (this.parId !== undefined) {
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          this.detalheParceriaApi.getParceriaInfo(this.parId))
      ).subscribe(async d => {
        this.parceriaInfo = d;
        // console.log(this.parceriaInfo);
        this.loading.next(false);
      });
    }
    this.setupInterface();
    
  }

 

  setupInterface() {

    this.loading.next(true);

    this.route.paramMap.pipe(
      switchMap(() =>
        this.parceriaApi.getMonitoramentoAvaliacao(this.parId, this.isPortal, this.isFiscal))
    ).subscribe((response) => {
      if (response) {
        this.monitoramentoAvaliacaoList = response as Array<MonitoramentoAvaliacao>;
        this.loading.next(false);
      }
    }, (response) => {
      if (response.status >= 500) {
        this.alertService.danger(response.error);
      } else {
        this.alertService.warning(response.error);
      }
      this.loading.next(false);
    }, () => this.loading.next(false));


  }

  disableCampos() {
    if (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno)
      return true;

    if (this.monitAvalVisualizado != undefined){
      if(!this.isFiscal && this.monitAvalVisualizado.monitoramentoSituacao == 2 ){
        return true;  
      }
      if (this.monitAvalVisualizado.monitoramentoSituacao == 4) {
        return true;
      }
    }
    return false; 

  }

  disableBotao() {
    if (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno)
    return true;

    if (this.monitAvalVisualizado != undefined){
      if(!this.isFiscal && this.monitAvalVisualizado.monitoramentoSituacao == 2 ){
        return true;  
      }
      // if (this.monitAvalVisualizado.monitoramentoSituacao == 4) {
      //   return true;
      // }
    }
  return false; 
}
  
  disableBotaoSub() {
    if (this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno || !this.isFiscal)
      return true;
    
    if (this.monitAvalVisualizado != undefined){
          if (this.monitAvalVisualizado.monitoramentoEfetivacao == true) {
        return true;
      }
    }
    return false;
  }



  disableApagarIcone() {
    if (this.isFiscal || this.isSMTC || this.isPortal || this.isConsulta || this.isAuditorExterno)
      return true;

    if (this.monitAvalVisualizado != undefined && (this.monitAvalVisualizado.monitoramentoSituacao == 2 || this.monitAvalVisualizado.monitoramentoSituacao == 4)) {
      return true;
    }
    return false; 

  }
  
  
  enableIcone() {
    if (this.isFiscal || this.isConsulta || this.isSMTC || this.isAuditorExterno) {
      return false;
    }
    return true;
  }

  getSituacaoPlanoDeTrabalho(situacao) {

    return this.utils.getStatusPlanoDeTrabalho(situacao);
  }

  async visualizarPlano(planoSelecionado) {

   
    if (this.monitAvalVisualizado === undefined) {
      this.verPlano = true;
    }
    else if (this.monitAvalVisualizado == planoSelecionado) {
      this.verPlano = !this.verPlano;
    }
    this.monitAvalVisualizado = planoSelecionado;

    if (this.verPlano) {

      if (this.monitAvalVisualizado.plano.planoDataFimExecucao != null)
        this.monitAvalVisualizado.plano.planoDataFimExecucaoFormat = this.dateConverter.fromTimestampToNgbDateStruct(
          this.dateConverter.fromDateToTimestamp(this.monitAvalVisualizado.plano.planoDataFimExecucao));
      if (this.monitAvalVisualizado.plano.planoDataIniExecucao != null)
        this.monitAvalVisualizado.plano.planoDataIniExecucaoFormat = this.dateConverter.fromTimestampToNgbDateStruct(
          this.dateConverter.fromDateToTimestamp(this.monitAvalVisualizado.plano.planoDataIniExecucao));

      //  if (this.metaComponent != undefined) {
        
      //    this.metaComponent.visualizaMeta(this.monitAvalVisualizado,false);
      //   }
     //await this.getMetasPlano();
      
      this.calculaCompetencia();
    
    }
  }

    splitIntoChunks(array: any[], chunkSize: number) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  calculaQtdMeses(dataInicio: Date, dataFim: Date): number {
    let meses: number;

    // Calcule a diferença em anos e meses
    meses = (new Date(dataFim).getFullYear() - dataInicio.getFullYear()) * 12;
    meses -= dataInicio.getMonth();
    meses += new Date(dataFim).getMonth();
    meses = meses + 1;
    // Se a data de fim for antes do dia da data de início, subtraia um mês
    // if (new Date(dataFim).getDate() < dataInicio.getDate()) {
    //   meses--;
    // }

    return meses < 0 ? 0 : meses; // Retorna 0 se o resultado for negativo
  }

  construirArrayMeses(dataInicio: Date, quantidadeMeses: number): string[] {
    const mesesArray: string[] = [];
    const mesNomes = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
      'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

    for (let i = 0; i < quantidadeMeses; i++) {
      const dataAtual = new Date(dataInicio);
      dataAtual.setMonth(new Date(dataInicio).getMonth() + i);

      const mes = mesNomes[dataAtual.getMonth()];
      const ano = dataAtual.getFullYear();
      let numero = '' + (i + 1);
      numero = numero.padStart(2, "0");
      mesesArray.push('Mês ' + `${numero}` + ':');
      mesesArray.push(`${mes}/${ano}`);
    }

    return mesesArray;
  }

  public calculaCompetencia() {

    const quantidadeMeses = this.calculaQtdMeses(new Date(this.monitAvalVisualizado.plano.planoDataIniExecucao),
      this.monitAvalVisualizado.plano.planoDataFimExecucao);
    this.competenciasLista = this.construirArrayMeses(this.monitAvalVisualizado.plano.planoDataIniExecucao, quantidadeMeses)
  }

  replacer(key, value) {
    //atributos que só existem no view e não no servidor
    const ignoreList = ['planoDataIniExecucaoFormat', 'planoDataFimExecucaoFormat'];
    if (ignoreList.indexOf(key) > -1) {
      return undefined;
    } else {
      return value;
    }
  }

  // handleMetaUpdated(updatedList: Meta[]) {
  //   this.metaList = updatedList;
  //   //console.log('Received updated meta list:', updatedList);
  // }
 
  async getMetasPlano(){
   await  this.parceriaApi.getMetasPlano(this.monitAvalVisualizado.plano.planoId, this.isPortal).subscribe((response) => {
            if (response) {
              this.metaList = response as Array<Meta>;
            } else {
              this.alertService.warning('Nenhum registro encontrado');
            }
          }, (response) => {
            if (response.status >= 500) {
              this.alertService.danger(response.error);
            } else {
              this.alertService.warning(response.error);
            }
            this.loading.next(false);
          }, () => this.loading.next(false));
  }

  salvaPlano() {
          
    //if (this.ultimoAccordionAberto=="meta"){
      this.parametroComponent.salvaPlano();
    //}
   // else if (this.ultimoAccordionAberto=="evento"){
      this.outrosEventosComponent.salvaPlano();
   // }

  }

  isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }

  submeterPlano(){  

     
      this.monitAvalVisualizado.monitoramentoSituacao = 2;
      this.monitAvalVisualizado.operacaoData = new Date();
      this.monitAvalVisualizado.monitoramentoEfetivacao = true;

      const data = JSON.stringify(this.monitAvalVisualizado, this.replacer);
      this.detalheParceriaApi.updateMonitoramentoPlano(data).subscribe(async (response) => {
        if (response) {
          this.setupInterface();
          this.loading.next(false);
          this.monitAvalVisualizado = undefined;
          this.verPlano =false;
          this.alertService.info("O monitoramento foi submetido com sucesso!");
          
        }

      }, (response) => {
        if (response.status >= 500) {
          this.alertService.danger(response.error);
        } else {
          this.alertService.warning(response.error);
        }
        this.loading.next(false);
      }, () => this.loading.next(false));

  }

  get paginatedCompetenciasLista(): String[] {
    const start = (this.config.currentPage - 1) * this.config.itemsPerPage;
    const end = start + this.config.itemsPerPage;
    return this.competenciasLista.slice(start, end);
  }
  // Change page
  changePage(pageNumber: number): void {
    this.config.currentPage = pageNumber;
  }

  // Get the total number of pages
  get totalPages(): number {
    return Math.ceil(this.competenciasLista.length / this.config.itemsPerPage);
  }


  calcularTotal(previsaoDespesaReceitaLista): number {
    let total = 0;

    previsaoDespesaReceitaLista.forEach(previsao => {
        // Calcular a diferença entre a data de início e a data final em meses
        const meses = this.calcularMesesEntreDatas(previsao.vigenciaInicio, previsao.vigenciaFinal);
        
        // Calcular o valor total considerando o número de meses e o valor mensal
        total += previsao.valorMensal * meses;
    });

    return total;
 }

 calcularMesesEntreDatas(dataInicio: Date, dataFinal: Date): number {
    const inicio = new Date(dataInicio);
    const fim = new Date(dataFinal);
    
    let meses = fim.getFullYear() * 12 + fim.getMonth() - (inicio.getFullYear() * 12 + inicio.getMonth());
    
    // Verificar se a data final está antes do dia de início no mesmo mês, ajustando o cálculo.
    if (fim.getDate() < inicio.getDate()) {
        meses--;
    }

    return meses > 0 ? meses : 0;  // Não permitir meses negativos
  }


  inibeBotaoAlteracao(planoSelecionado){
    
    if (this.isPortal || this.isFiscal){
      return true;
    }
    if (planoSelecionado.planoSituacao < 4){
      return true;
    }

    let find = this.monitoramentoAvaliacaoList.filter(element =>
     (element.monitoramentoSituacao < 4)
    );

    if (find.length > 0){
      return true;
    }
     
    else{
     return false;
    }
  }
    acordiaoChange(props: NgbPanelChangeEvent): void {
  
      this.ultimoAccordionAberto=props.panelId;
      if (this.accordionComponent) {
        const activeArray: Array<string> = Array.from(this.accordionComponent.activeIds);
  
        // fecha outros elementos do acordion quando abertos (ativos)
        if (activeArray.length >0)
          activeArray.forEach(element => {

            if (element !== props.panelId) {
              this.accordionComponent.toggle(element);

            }
          });
       }
    }


}