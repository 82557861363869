import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { ExecucaoPlanoDeTrabalho } from "../planoDeTrabalho/execucaoPlanoDeTrabalho";

export class MonitoramentoAvaliacao {

    public parId: number;
    public monitoramentoId: number;
    public plano: ExecucaoPlanoDeTrabalho;
    public monitoramentoIdentificador: string; 
    public monitoramentoSituacao: number;
    public monitoramentoEfetivacao: boolean;
    public operacaoData: Date;
    public operacaoUsuario: string;

}

