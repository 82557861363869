<div [hidden]="!isFiscal && !monitoramento.monitoramentoEfetivacao">
    <table class="table" style="margin-top:10px" id="tabelaMeta" [mfData]="metaList" #mtMetaPlan="mfDataTable">

        <tbody>
            <div *ngFor="let data of mtMetaPlan.data; let o = index;">
                <!-- Linha para Descrição da Meta -->
                <div [hidden]="isOperadorResponsavel && !isFiscal">
                    <div class="row">
                        <div class="col-md-12 monitoramento tituloSecaoM" style="font-size: large;">Meta: {{o+1}}.
                            {{data.metaDescricao}}</div>
                    </div>
                </div>
                <!-- Loop para exibir cada atividade -->
                <div *ngFor="let atividade of data.metaAtividades; let k = index;" style="margin-top: -30px;">
                    <div [hidden]="isOperadorResponsavel && !isFiscal">
                        <div class="row">
                            <div class="col-md-12 monitoramento tituloSecaoM"
                                style="font-size: large; margin-left: 50px;">
                                Atividade: {{o+1}}.{{k+1}} {{atividade.atividadeDescricao}}</div>
                        </div>

                        <!-- Linha para Frequência -->
                        <div class="row mb-2">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">Frequência:</div>
                            <div class="col-md-3">{{getAtividadeFrequencia(atividade.atividadeFrequencia)}}
                            </div>
                        </div>

                        <!-- Linha para Tipo de Apuração -->
                        <div class="row mb-2">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">Tipo de Apuração:
                            </div>
                            <div class="col-md-3">{{atividade.atividadeTipoApuracao}}
                            </div>
                        </div>

                        <!-- Linha para Meta -->
                        <div class="row mb-2">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">Meta <small>(Quantidade - % - Sim/Não)</small>:</div>
                            <div class="col-md-3">
                                {{atividade.atividadeMeta}}

                            </div>
                        </div>

                        <!-- Linha para Início da Execução -->
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div class="col-md-3">Início da Execução:
                            </div>
                            <div class="col-md-3">
                                {{getMesAno(atividade.atividadeInicioExecucao)}}
                            </div>
                            <div class="col-md-3"></div>
                        </div>
                    </div>
                    <div *ngIf="isFiscal"> <!--*ngIf="isFiscal" -->
                        <div class="row">
                            <div class="col-md-12 monitoramento tituloSecaoM"
                                style="font-size: large; margin-left: 50px;">Parâmetros de Monitoramento: </div>
                        </div>
                        <div
                            *ngFor="let responsavel of atividade.parametrosMonitoramento.responsavelMonitoramento; let l = index;">

                            <div class="row mb-2">
                                <div class="col-md-1"></div>
                                <div *ngIf="l == 0" class="col-md-3 font-weight-bold">Responsável monitoramento:<span
                                        class="text-danger">*</span>
                                </div>
                                <div *ngIf="l != 0" class="col-md-3 font-weight-bold">
                                </div>
                                <div class="col-md-0"><i ngbTooltip="apagar" class="fa fa-trash-o" aria-hidden="true"
                                        *ngIf="!disableBotao()"
                                        (click)="openExcluir(contentExcluir, responsavel, l+1, atividade.parametrosMonitoramento)"
                                        style="margin-top: 15px;"></i>
                                </div>
                                <div class="col-md-7">
                                    <select [ngModel]="responsavel.chaveEmail" [disabled]="disableBotao()"
                                        (change)="salvaEmailResp($event, responsavel, atividade.parametrosMonitoramento,'P')"
                                        class="form-control form-select custom-select">
                                        <option [value]="option.id" *ngFor="let option of emailsRespSelect">
                                            {{option.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-1"></div>
                            <div class="col-md-8 font-weight-bold">
                            </div>
                            <div class="col-md-3">
                                <button (click)="adicionaResponsavel(atividade.parametrosMonitoramento, 'P')"
                                    *ngIf="!disableBotao()" id="button-insert_doc" style="width: 90%;"
                                    class="btn btn-primary" type="button " title="Adiciona Responsavel"
                                    [hidden]="isPortal">
                                    <i class="fa fa-plus" style="color: white;"><span class="texto-botao">Adicionar
                                            Responsável</span> </i>
                                </button>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-1"></div>
                            <div class="col-md-3 font-weight-bold">Período de Atualização:<span
                                    class="text-danger">*</span>
                            </div>
                            <div class="col-md-8">
                                <input class="form-control input-tabela" id="periodoAtualizacao"
                                    [disabled]="disableBotao()" name="periodoAtualizacao" #periodoAtualizacao="ngModel"
                                    type="text" [(ngModel)]="atividade.parametrosMonitoramento.periodoAtualizacao">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-1"></div>
                            <div class="col-md-3 font-weight-bold">Alerta por e-mail:
                            </div>
                            <div class="col-md-2" style="margin-top: 10px;">
                                <input type="radio" class="form-check-input" name="'alertaEmail'+{{o+1}}{{k+1}}"
                                    id="30D{{o+1}}{{k+1}}" value="30" [disabled]="disableBotao()"
                                    style="width: 15px; height: 20px; margin-top: 1px;"
                                    (change)="updateAlertaEmail($event, atividade.parametrosMonitoramento)"
                                    [checked]="atividade.parametrosMonitoramento.alertaEmail === 30" />
                                <label for="30D{{o+1}}{{k+1}}" class="form-check">30 Dias antes</label>
                            </div>
                            <div class="col-md-2" style="margin-top: 10px;">
                                <input type="radio" class="form-check-input" name="'alertaEmail'+ {{o+1}}{{k+1}}"
                                    id="15D{{o+1}}{{k+1}}" value="15"  [disabled]="disableBotao()"
                                    (change)="updateAlertaEmail($event, atividade.parametrosMonitoramento)"
                                    [checked]="atividade.parametrosMonitoramento.alertaEmail === 15"
                                    style="width: 15px; height: 20px; margin-top: 1px; margin-left: -10px" />
                                <label for="15D{{o+1}}{{k+1}}" class="form-check" style="margin-left: -10px">15 Dias
                                    antes</label>
                            </div>
                            <div class="col-md-2" style="margin-top: 10px;">
                                <input type="radio" class="form-check-input" name="'alertaEmail'+ {{o+1}}{{k+1}}"
                                    id="7D{{o+1}}{{k+1}}" value="7" style="width: 15px; height: 20px; margin-top: 1px;"
                                    (change)="updateAlertaEmail($event, atividade.parametrosMonitoramento)"
                                    [disabled]="disableBotao()"
                                    [checked]="atividade.parametrosMonitoramento.alertaEmail === 7" />
                                <label for="7D{{o+1}}{{k+1}}" class="form-check">7 Dias antes</label>
                            </div>
                            <div class="col-md-2" style="margin-top: 10px;">
                                <input type="radio" class="form-check-input" name="'alertaEmail'+ {{o+1}}{{k+1}}"
                                    id="0D{{o+1}}{{k+1}}" value="0"  [disabled]="disableBotao()"
                                    (change)="updateAlertaEmail($event, atividade.parametrosMonitoramento)"
                                    [checked]="atividade.parametrosMonitoramento.alertaEmail === 0"
                                    style="width: 15px; height: 20px; margin-top: 1px; margin-left: -30px" />
                                <label for="0D{{o+1}}{{k+1}}" style="margin-left: -10px">No dia da atualização</label>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-1"></div>
                            <div class="col-md-3 font-weight-bold">Observação Órgão Gestor:
                            </div>
                            <div class="col-md-8">
                                <input class="form-control input-tabela" id="observacaoOrgaoGestor" maxlength="300"
                                    name="observacaoOrgaoGestor" #observacaoOrgaoGestor="ngModel" type="text"
                                    [disabled]="disableBotao()"
                                    [(ngModel)]="atividade.parametrosMonitoramento.observacaoOrgaoGestor">
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-1"></div>
                            <div class="col-md-3 font-weight-bold">Anexo Obrigatório:
                            </div>
                            <div class="col-md-8">
                                <input type="checkbox" id="anexoObrigatorio" style="width: 20px; height: 25px;"
                                    name="anexoObrigatorio" [disabled]="disableBotao()"
                                    [(ngModel)]="atividade.parametrosMonitoramento.anexoObrigatorio"
                                    [value]="atividade.parametrosMonitoramento.anexoObrigatorio" />


                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <BR *ngIf="isOperadorResponsavel && !isFiscal">
                        <div *ngIf="k==0" class="col-md-12 monitoramento tituloSecaoM"
                            style="font-size: large; margin-left: 50px;">
                            Execução do Monitoramento: </div>
                    </div>
                    <div class="row mb-2">

                        <div class="col-md-11" style="margin-left: 40px;">
                            <thead class="custom-thead">
                                <tr>
                                    <th class="col-md-4 font-weight-bold custom-header"
                                        style="background-color: #f0f0f0;padding: 5px;margin: 5px;">
                                        Atividade</th>
                                    <!-- Colunas de meses -->
                                    <th style="background-color: #f0f0f0;padding: 5px; margin: 5px"></th>
                                    <ng-container
                                        *ngFor="let chunk of splitIntoChunks(paginatedCompetenciasLista(atividade), 12)">

                                        <ng-container *ngFor="let item of chunk">
                                            <th class="font-weight-bold custom-header"
                                                style="background-color: #f0f0f0; padding: 5px; margin: 5px;">
                                                {{ item }}
                                            </th>
                                        </ng-container>
                                    </ng-container>
                                </tr>
                            </thead>
        <tbody>
            <!-- Linha da Meta -->
            <tr>

                <!-- Primeira atividade da Meta -->
                <td class="col-md-4" style="padding: 5px;margin: 5px;">
                    <span class="font-weight-bold">{{ o + 1 }}.{{ k + 1 }}</span>
                    {{ atividade.atividadeDescricao }}
                </td>
                <td style="padding: 5px;margin: 5px;">Planej.</td>
                <!-- Valores dos meses para a primeira atividade -->
                <ng-container *ngFor="let chunk of paginatedCompetenciaListaMetaAtividade(atividade)">
                    <td *ngFor="let valor of chunk" style="padding: 5px;margin: 5px;">
                        {{ valor }}
                    </td>
                </ng-container>
            </tr>
            <tr>
                <td></td>
                <td>Realiz.</td>
                <ng-container *ngFor="let chunk of paginatedCompetenciaListaMetaAtividadeRealizado(atividade)">
                    <td *ngFor="let atividadeMetaMonitoramentoRealizado of chunk; let r = index;"
                        style="padding: 5px;margin: 5px;">
                        <!--<input class="form-control input-tabela"  name="atividadeMetaMonitoramentoRealizado'+{{o+1}}{{k+1}}{{r+1}}"
                                           id="atividadeMetaMonitoramentoRealizado{{o+1}}{{k+1}}{{r+1}}" 
                                           #AMMRmodel="ngModel"  type="text" [(ngModel)]="atividadeMetaMonitoramentoRealizado">
                                        -->
                        <input *ngIf="habilitaRealizado(atividadeMetaMonitoramentoRealizado) "
                            [disabled]="disableCampos(atividade.parametrosMonitoramento.isOperadorResponsavel)" class="form-control input-tabela"
                            [value]="atividadeMetaMonitoramentoRealizado.realizado"
                            (blur)="updateValue($event.target.value, atividadeMetaMonitoramentoRealizado)">
                    </td>
                </ng-container>
            </tr>
            <tr>
                <td></td>
                <td>Arquivo</td>
                <ng-container *ngFor="let chunk of paginatedCompetenciaListaMetaAtividadeRealizado(atividade)">
                    <td *ngFor="let atividadeMetaMonitoramentoRealizado of chunk; let r = index;"
                        style="padding: 5px;margin: 5px;">
                        <!--<input class="form-control input-tabela"  name="atividadeMetaMonitoramentoRealizado'+{{o+1}}{{k+1}}{{r+1}}"
                                           id="atividadeMetaMonitoramentoRealizado{{o+1}}{{k+1}}{{r+1}}" 
                                           #AMMRmodel="ngModel"  type="text" [(ngModel)]="atividadeMetaMonitoramentoRealizado">
                                        -->
                        <div *ngIf="verificaUploadAnexo(atividadeMetaMonitoramentoRealizado)">
                            <i ngbTooltip="anexar" (click)="fileInput.click()" *ngIf="!disableCampos(atividade.parametrosMonitoramento.isOperadorResponsavel)"
                                class="fa fa-upload justify-content-center" style="font-size:25px;" aria-hidden="true">
                            </i>
                            <input type="file" #fileInput style="display: none" accept=".pdf"
                                [disabled]="disableCampos(atividade.parametrosMonitoramento.isOperadorResponsavel)"
                                (change)="onFileChanged($event, atividadeMetaMonitoramentoRealizado)" />
                        </div>
                        <div *ngIf="verificaExibicaoAnexo(atividadeMetaMonitoramentoRealizado)">
                            <i ngbTooltip="download {{atividadeMetaMonitoramentoRealizado.nomeArquivoAnexado}}"
                                class="fa fa-download justify-content-center" style="font-size:25px;" aria-hidden="true"
                                (click)="getArquivo(atividadeMetaMonitoramentoRealizado, atividade.parametrosMonitoramento)"
                                alt="download"></i>
                            <i style="margin-left: 10px;" class="fa fa-times" aria-hidden="true"
                                *ngIf="!disableCampos(atividade.parametrosMonitoramento.isOperadorResponsavel)" (click)="atividadeMetaMonitoramentoRealizado.nomeArquivoAnexadoExcluido=atividadeMetaMonitoramentoRealizado.nomeArquivoAnexado;
                                                atividadeMetaMonitoramentoRealizado.nomeArquivoAnexado=undefined; 
                                                atividadeMetaMonitoramentoRealizado.arquivo= undefined;"
                                alt="download"></i>
                        </div>
                    </td>
                </ng-container>
            </tr>
            <tr>
                <td></td>
                <td>Dado Sensível</td>
                <ng-container *ngFor="let chunk of paginatedCompetenciaListaMetaAtividadeRealizado(atividade)">
                    <td *ngFor="let atividadeMetaMonitoramentoRealizado of chunk; let r = index;"
                        style="padding: 5px;margin: 5px;">
                        <!--<input class="form-control input-tabela"  name="atividadeMetaMonitoramentoRealizado'+{{o+1}}{{k+1}}{{r+1}}"
                                           id="atividadeMetaMonitoramentoRealizado{{o+1}}{{k+1}}{{r+1}}" 
                                           #AMMRmodel="ngModel"  type="text" [(ngModel)]="atividadeMetaMonitoramentoRealizado">
                                        -->
                        <input type="checkbox"  *ngIf="habilitaRealizado(atividadeMetaMonitoramentoRealizado) "
                            [disabled]="disableCampos(atividade.parametrosMonitoramento.isOperadorResponsavel)" class="form-control input-tabela"
                            [value]="atividadeMetaMonitoramentoRealizado.dadoSensivel" style="width: 20px; height: 25px;padding: 5px;margin: 5px;"
                            (blur)="updateValueDado($event.target.value, atividadeMetaMonitoramentoRealizado)">
                           
                    </td>
                </ng-container>
            </tr>
        </tbody>
        <div class="pagination pagination justify-content-center" *ngIf="competenciasLista.length > 0">
            <i (click)="changePage(atividade, atividade.currentPage - 1)"
                [ngStyle]="{ 'pointer-events': atividade.currentPage === 1 ? 'none' : 'auto' }"
                class="fa fa-chevron-circle-left" style="font-size:36px; padding-right:10px"> </i>

            <span> Página {{atividade.currentPage}} de {{totalPages}} </span>

            <i (click)="changePage(atividade, atividade.currentPage + 1)"
                [ngStyle]="{ 'pointer-events': atividade.currentPage === totalPages ? 'none' : 'auto' }"
                class="fa fa-chevron-circle-right" style="font-size:36px; padding-left:10px"> </i>

        </div>

</div>

</div>
<div class="row mb-2">
    <div class="col-md-1"></div>
    <div class="col-md-3 font-weight-bold">Observação Órgão Gestor:
    </div>
    <div class="col-md-8">
        {{atividade.parametrosMonitoramento.observacaoOrgaoGestor}}
    </div>
</div>
<div class="row mb-2">
    <div class="col-md-1"></div>
    <div class="col-md-3 font-weight-bold">Observação Responsável Monitoramento:
    </div>
    <div class="col-md-8">
        <input class="form-control input-tabela" id="observacaoResponsavelMonitoramento" maxlength="300"
            [disabled]="disableCampos(atividade.parametrosMonitoramento.isOperadorResponsavel)" 
            name="observacaoResponsavelMonitoramento"
            #observacaoResponsavelMonitoramento="ngModel" type="text"
            [(ngModel)]="atividade.parametrosMonitoramento.observacaoResponsavelMonitoramento">
    </div>
</div>
<div class="row mb-2" *ngIf="atividade.parametrosMonitoramento.responsavelNomeUltimaMonitoracao != undefined">
    <div class="col-md-1"></div>
    <div class="col-md-11 font-weight-bold">Monitoramento realizado em {{atividade.parametrosMonitoramento.operacapdata
        | date: 'dd/MM/yyyy'}} - {{atividade.parametrosMonitoramento.responsavelNomeUltimaMonitoracao}} - Responsável
        Técnico
    </div>
</div>
<BR />
</div>
</div>
</tbody>
</table>

<ng-template #contentExcluir let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Excluir Responsável</h4>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Tem certeza que deseja excluir o responsável:
            <b>{{indiceResp}}</b>?
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" style="margin-top: 7px;" (click)="c('Close click')"
            title="Fechar">Fechar</button>
        <button type="button" class="btn btn-secondary" (click)="excluirResponsavel()" title="Excluir">Excluir</button>
    </div>
</ng-template>
</div>