<div class="container-fluid" style="margin-top: 20px">
    <!--<<div class="execucao tituloSecao">PREVISÃO DE DESPESA</div>-->
    <div *ngIf="previsaoDespesaLista?.length==0">
        <span class="mensagem-vazio">Nenhuma previsão de despesa foi encontrada.</span>
    </div>
    <div *ngIf="!disableBotao()">
        <button (click)="adicionarPrevisaoDespesa() " id="button-insert_doc " class="btn btn-primary btn-info" type="button "
            title="Adicionar Despesa" [hidden]="disableBotao()">
            <i class="fa fa-plus "><span class="texto-botao">Adicionar Despesa</span> </i>
        </button>
    </div>
    <div *ngIf="previsaoDespesaLista?.length > 0">
        <table class="table" style="margin-top:30px" id="tabelaPrevisaoDespesa" [mfData]="previsaoDespesaLista" #mtPrevDespesa="mfDataTable">
            <tbody>
                <div *ngFor="let previsaoDespesa of mtPrevDespesa.data; let j = index;" class="mb-5 border rounded p-3">
                    <div class="row mb-2">
                        <div class="col-md-1 custom-width"></div>
                        <div class="col-md-3 font-weight-bold">Natureza:<span class="text-danger">*</span>
                        </div>
                        <div class="col-md-3">
                            <div class="select-wrapper" style="width: 100%">
                                <select [disabled]="disableCampos()"
                                    [ngModel]="previsaoDespesa.natureza"
                                    [ngModelOptions]="{ standalone: true }" class="form-control"
                                    #tipoAtividade="ngModel" name="natureza"
                                    (change)="updateNaturezaDespesa($event, previsaoDespesa)" required>
                                    <option [value]="option.id" *ngFor="let option of naturezaOptionsProvisao">
                                        {{ option.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-1 font-weight-bold">FRIM:
                        </div>
                        <div class="col-md-1">
                            <input type="checkbox" style="width: 20px; height: 25px;" [(ngModel)]="previsaoDespesa.frim" name="frim">
                        </div>
                        <div class="col-md-2 font-weight-bold">Custo Indireto:
                        </div>
                        <div class="col-md-1">
                            <input type="checkbox" style="width: 20px; height: 25px;" [(ngModel)]="previsaoDespesa.custoIndireto" name="custoIndireto">
                        </div>
                    </div>
                    
                    <div class="row mb-2">
                        <div class="col-md-1 custom-width"></div>
                        <div class="col-md-3 font-weight-bold">Detalhamento:<span class="text-danger">*</span>
                        </div>
                        <div class="col-md-8">
                            <input [disabled]="disableCampos()" class="form-control input-tabela" id="detalhamento"
                                name="detalhamento" #detalhamento="ngModel" type="text" [(ngModel)]="previsaoDespesa.detalhamento"
                                maxlength="250">

                        </div>
                    </div>
                   
                    <div class="row mb-2">
                        <div class="col-md-1 custom-width font-weight-bold">{{j+1}}</div>
                        <div class="col-md-3 font-weight-bold">Valor Mensal:<span class="text-danger">*</span>
                        </div>
                        <div class="col-md-3">
                            <input [disabled]="disableCampos()" class="form-control input-tabela" id="valorMensal" maxlength="100000000000000"
                                name="valorMensal" #valorMensal="ngModel" type="text" [(ngModel)]="previsaoDespesa.valorMensal" 
                                currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }" validaInputZero/>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col-md-1 custom-width"></div>
                        <div class="col-md-3 font-weight-bold">Vigência&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;De:
                            <span class="text-danger">*</span>
                        </div>
    <!--                    <div class="col-md-1 font-weight-bold">De:<span class="text-danger">*</span>
                        </div>-->
                        <div class="col-md-2">
                            <app-date-selector [disabledFlag]="disableBotao()"
                                [initialDate]="previsaoDespesa.vigenciaInicio"
                                (dateChanged)="onDateChangedInicio(previsaoDespesa, $event)"></app-date-selector>
                        </div>
                        <div class="col-md-1 font-weight-bold">Até:<span class="text-danger">*</span>
                        </div>
                        <div class="col-md-2">
                            <app-date-selector [disabledFlag]="disableBotao()"
                                [initialDate]="previsaoDespesa.vigenciaFinal"
                                (dateChanged)="onDateChangedFinal(previsaoDespesa, $event)"></app-date-selector>
                        </div>
                    
                    <div class="col-md-1"></div>
                    <div *ngIf="!disableBotao()" class="col-md-2">
                        <button
                            (click)="openExcluirPrevisaoDespesa(contentExcluirPrevisaoDespesa, previsaoDespesa, j+1)"
                            id="button-apagar-ativ " class="btn btn-primary btn-info ms-auto custom-margin-left"
                            type="button " title="Excluir Previsao Despesa" [hidden]="disableBotao()">
                            <i class="fa fa-trash-o" style="color: white;"><span class="texto-botao">Excluir Previsão
                                Despesa</span> </i>
                        </button>
                    </div>
                </div>
                </div>
            </tbody>
        </table>
        <div class="row" >
            <div class="execucao tituloSecao">CRONOGRAMA DE DESMBOLSOS</div>
            <div *ngIf="previsaoDespesaLista.length>0 && paginatedCompetenciasLista.length>0">
                <table *ngIf="mostraTabela" class="table-responsive table-bordered table-hover" style="width: 1080px; overflow-x: auto;" id="cron">
                <thead class="custom-thead">
                    <tr>
                         <ng-container *ngFor="let chunk of splitIntoChunks(paginatedCompetenciasLista, 13)">
                            <ng-container *ngFor="let item of chunk">
                                <th class="font-weight-bold custom-header" style="background-color: #f0f0f0; color:#04292e">
                                    {{ item }}
                                </th>
                            </ng-container>
                        </ng-container>
                    </tr>
                </thead>
                <tr *ngIf="pagamentoPessoalList?.length > 0"  style="height: 5px;"></tr> 
                <tbody *ngIf="pagamentoPessoalList?.length > 0" >
                    <ng-container *ngFor="let pgtoPess of pagamentoPessoalList let l = index; ">
                        <tr [ngStyle]="l === (pagamentoPessoalList.length-1) ? { 'border': 'none', 'font-weight': 'bold', 'color':'#04292e' } : null"> 
                        <ng-container *ngFor="let chunk1 of splitIntoChunks(paginatedPrevisaoDespesaLista(pgtoPess), 13)">
                            <td *ngFor="let valor of chunk1; let i = index"  class="col-auto"
                            [ngStyle]="i === (pagamentoPessoalList.size-1) ? { 'color':'#195e63' } : {'color':'#195e63'}">
                                    {{ valor }}
                            </td>
                        </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
                <tr  id="ter" *ngIf="servicosTerceirosList?.length > 0" style="height: 5px;"></tr> 
                <tbody *ngIf="servicosTerceirosList?.length > 0" >
                    <ng-container *ngFor="let servTer of servicosTerceirosList let l = index; ">
                        <tr [ngStyle]="l === (servicosTerceirosList.length-1) ? { 'border': 'none', 'font-weight': 'bold', 'color':'#04292e' } : null"> 
                        <ng-container *ngFor="let chunk of splitIntoChunks(paginatedPrevisaoDespesaLista(servTer), 13)">
                            <td *ngFor="let valor of chunk; let i = index"  class="col-auto"
                            [ngStyle]="i === (servicosTerceirosList.length-1) ? { 'color':'#195e63' } : {'color':'#195e63'}">
                                    {{ valor }}
                            </td>
                        </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
                <tr  id="comns" *ngIf="materialConsumoList?.length > 0" style="height: 5px;"></tr> 
                <tbody *ngIf="materialConsumoList?.length > 0">
                    <ng-container *ngFor="let matCons of materialConsumoList let l = index; ">
                        <tr [ngStyle]="l === (materialConsumoList.length-1) ? { 'border': 'none', 'font-weight': 'bold', 'color':'#04292e' } : null"> 
                        <ng-container *ngFor="let chunk of splitIntoChunks(paginatedPrevisaoDespesaLista(matCons), 13)">
                            <td *ngFor="let valor of chunk; let i = index" class="col-auto"
                            [ngStyle]="i === (materialConsumoList.length-1) ? { 'color':'#195e63' } : {'color':'#195e63'}">
                                    {{ valor }}
                            </td>
                        </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
                <tr id="perm" *ngIf="materialPermanenteList?.length > 0" style="height: 5px;"></tr> 
                <tbody *ngIf="materialPermanenteList?.length > 0">
                    <ng-container *ngFor="let matPerm of materialPermanenteList let l = index; ">
                        <tr [ngStyle]="l === (materialPermanenteList.length-1) ? { 'border': 'none', 'font-weight': 'bold', 'color':'#04292e' } : null"> 
                        <ng-container *ngFor="let chunk of splitIntoChunks(paginatedPrevisaoDespesaLista(matPerm), 13)">
                            <td *ngFor="let valor of chunk; let i = index" class="col-auto"
                            [ngStyle]="i === (materialPermanenteList.length-1) ? { 'color':'#195e63' } : {'color':'#195e63'}">
                                    {{ valor }}
                            </td>
                        </ng-container>
                        </tr>
                    </ng-container>
                </tbody>
                <tr *ngIf="provisaoList?.length > 0 " style="height: 5px;"></tr> 
                <tbody *ngIf="provisaoList?.length > 0" >
                     <ng-container *ngFor="let prov of provisaoList let l = index; ">
                        <tr [ngStyle]="l === (provisaoList.length-1) ? { 'border': 'none', 'font-weight': 'bold', 'color':'#04292e' } : null"> 
                        <ng-container *ngFor="let chunk of splitIntoChunks(paginatedPrevisaoDespesaLista(prov), 13)">
                            <td *ngFor="let valor of chunk; let i = index" class="col-auto"
                            [ngStyle]="i === (provisaoList.length-1) ? { 'color':'#195e63' } : {'color':'#195e63'}">
                                    {{ valor }}
                            </td>
                        </ng-container>
                        </tr>
                    </ng-container>
                </tbody>

                <tr style="height: 5px;"></tr> 
                <tbody>
                    <ng-container *ngFor="let prevRec of previsaoReceitaList let l = index; ">
                       <tr [ngStyle]="l === (previsaoReceitaList.length-1) ? { 'border': 'none', 'font-weight': 'bold', 'color':'#04292e' } : null"> 
                       <ng-container *ngFor="let chunk of splitIntoChunks(paginatedPrevisaoDespesaLista(prevRec), 13)">
                           <td *ngFor="let valor of chunk; let i = index" class="col-auto"
                           [ngStyle]="i === (previsaoReceitaList.length-1) ? { 'color':'#2b4c7e' } : {'color':'#2b4c7e'}">
                                   {{ valor }}
                           </td>
                       </ng-container>
                       </tr>
                   </ng-container>
               </tbody>

                <tr style="height: 5px;"></tr> 
                <tbody>
                    <ng-container *ngFor="let prevDesp of previsaoDespList let l = index; ">
                       <tr [ngStyle]=" { 'border': 'none', 'font-weight': 'bold', 'color':'#195e63' }"> 
                       <ng-container *ngFor="let chunk of splitIntoChunks(paginatedPrevisaoDespesaLista(prevDesp), 13)">
                           <td *ngFor="let valor of chunk; let i = index" class="col-auto"
                           [ngStyle]="{'color':'#195e63'}">
                                   {{ valor }}
                           </td>
                       </ng-container>
                       </tr>
                   </ng-container>
               </tbody>
               <tr style="height: 5px;"></tr> 
               <tbody>
                   <ng-container *ngFor="let diferenca of diferencaRecDespList let l = index; ">
                      <tr [ngStyle]="l === (diferencaRecDespList.length-1) ? { 'border': 'none', 'font-weight': 'bold', 'color':'#04292e' } : null"> 
                      <ng-container *ngFor="let chunk of splitIntoChunks(paginatedPrevisaoDespesaLista(diferenca), 13)">
                          <td *ngFor="let valor of chunk; let i = index" class="col-auto"
                          [ngStyle]="i === (diferencaRecDespList.length-1) ? { 'color':'#195e63' } : {'color':'#195e63'}">
                                  {{ valor }}
                          </td>
                      </ng-container>
                      </tr>
                  </ng-container>
              </tbody>
            </table>
            <div class="pagination pagination justify-content-center" *ngIf="competenciasLista?.length > 0">
                <i (click)="changePage(config.currentPage - 1)"
                    [ngStyle]="{ 'pointer-events': config.currentPage === 1 ? 'none' : 'auto' }"
                    class="fa fa-chevron-circle-left" style="font-size:36px; padding-right:10px"> </i>

                <span> Página {{config.currentPage}} de {{totalPages}} </span>

                <i (click)="changePage(config.currentPage + 1)"
                    [ngStyle]="{ 'pointer-events': config.currentPage === totalPages ? 'none' : 'auto' }"
                    class="fa fa-chevron-circle-right" style="font-size:36px; padding-left:10px"> </i>

            </div>
        </div> 
        <div class="row">
            <div class="col-md-6">
                <div class="col-md-5 pt-4" style="padding-left: 0.67rem !important; width: 50%; height: 100px;" >
                    <canvas id="i" baseChart [data]="pieChartData" *ngIf="totalNaturezas > 0 " 
                        [labels]="pieChartLabels1" [chartType]="pieChartType" [legend]="false"
                        [options]="chartOptions" [colors]="pieChartColors1">
                    </canvas>
                </div>
                <div class="col-md-7">
                    <div class="legenda-grafico" *ngIf="totalNaturezas > 0 ">
                        <ul>
                            <li>
                                <div class="cor-legenda cor1"></div>
                                Material de Consumo: R$ {{aplicaMascaraReais(totalMatConsumo)}} 
                                ({{aplicaMascaraReais((totalMatConsumo*100)/this.totalNaturezas)}}%)
                            </li>
                            <li>
                                <div class="cor-legenda cor2"></div>
                                Pagamento Pessoal: R$ {{aplicaMascaraReais(totalPgtoPessoal)}}
                                ({{aplicaMascaraReais((totalPgtoPessoal*100)/this.totalNaturezas)}}%)
                            </li>
                            <li>
                                <div class="cor-legenda cor3"></div>
                                Serviços de Terceiros: R$ {{aplicaMascaraReais(totalServTerceiros)}}
                                ({{aplicaMascaraReais((totalServTerceiros*100)/this.totalNaturezas)}}%)
                            </li>
                            <li>
                                <div class="cor-legenda cor4"></div>
                                Material Permanente: R$ {{aplicaMascaraReais(totalMatPermanente)}}
                                ({{aplicaMascaraReais((totalMatPermanente*100)/this.totalNaturezas)}}%)
                            </li>
                            <li>
                                <div class="cor-legenda cor5"></div>
                                Provisão: R$ {{aplicaMascaraReais(totalProvisao)}}
                                ({{aplicaMascaraReais((totalProvisao*100)/this.totalNaturezas)}}%)
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="col-md-5 pt-4" style="padding-left: 0.67rem !important; width: 50%; height: 100px;" >
                    <canvas id="i" baseChart [data]="pieChartDataCustos" *ngIf="isSaude" 
                        [labels]="pieChartLabelsCustos" [chartType]="pieChartType" [legend]="false"
                        [options]="chartOptions" [colors]="pieChartColors1">
                    </canvas>
                </div>
                <div class="col-md-7">
                    <div class="legenda-grafico" *ngIf="isSaude">
                        <ul>
                            <li>
                                <div class="cor-legenda cor1"></div>
                                FRIM
                                ({{aplicaMascaraReais((totalFrim*100)/this.totalCustos)}}%)
                            </li>
                            <li>
                                <div class="cor-legenda cor2"></div>
                            Custo Indireto
                                ({{aplicaMascaraReais((totalCustoIndireto*100)/this.totalCustos)}}%)
                            </li>
                            <li>
                                <div class="cor-legenda cor3"></div>
                            Outros Custos
                                ({{aplicaMascaraReais((totalOutrosCustos*100)/this.totalCustos)}}%)
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    <br><br>
    </div>
    <ng-template #contentExcluirPrevisaoDespesa let-c="close" let-d="dismiss">
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Excluir Previsão Despesa</h4>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" title="Fechar">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Tem certeza que deseja excluir a previsão despesa número:
                <b>{{indicePrevisao}}</b>?
            </p>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" style="margin-top: 7px;" (click)="c('Close click')"
                title="Fechar">Fechar</button>
            <button type="button" class="btn btn-secondary" (click)="excluirPrevisaoDespesa()" title="Excluir">Excluir</button>
        </div>
    </ng-template>
</div>